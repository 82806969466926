// importing required packages, icons and material ui components
import React from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Grid, TableCell, TableContainer, TableFooter, TableRow, FormHelperText } from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import Select from '@material-ui/core/Select';
import jsPDF from "jspdf";
import "jspdf-autotable";
import DateFnsUtils from '@date-io/date-fns';
import { BiFilterAlt } from "react-icons/bi";
import { useQuery } from "react-apollo";
import { client } from "../graphql/apolloClient";
import FormControl from '@material-ui/core/FormControl';
import { format } from 'date-fns';
import Nodata from './Nodata';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// importing required css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";

// importing required queries
import { AuditLogsData } from "../graphql/queries";

// importing required pages/components
import { StyledTableCell, StyledTableRow } from "./TableComponent";
import { default as CustomizedDialogs } from './Dialog';
import { AuditLogsColumns } from "./Column"
import { TableSkeleton } from "./skeleton";


const RowPerPage = 10


const useStyles = makeStyles((theme) => ({
  mycheckbox: {
    color: "#8A2387",
  },
  mail: {
    fontSize: "14px",
    fontFamily: "Pangram",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "purple",
    "&:hover": {
      backgroundColor: "#FFF"
    }
  },
  download: {
    fontSize: "14px",
    fontFamily: "Pangram",
    fontStyle: "normal",
    color: "purple",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#FFF"
    },
    "&:active": {
      backgroundColor: "#FFF"
    }
  },
  tableHead: {
    height: '2px',
  },
  table_action: {
    marginLeft: '2%',
    marginRight: '2%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  inputheight: {
    height: '40px',
  },
  paper: {
    width: '100%',
    borderRadius: '12px',
  },
  rowid: {
    paddingLeft: 10
  },
  selectcheck: {
    marginRight: 850,
    marginTop: -70
  }
}));


const AuditTable = ({ data, checkid, checked, setCheckId }) => {
  const columns = React.useMemo(() => AuditLogsColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },
    useSortBy,
    usePagination,
  );
  const MyCheckbox = withStyles({
    root: {
      color: "gray",
      '&$checked': {
        color: "#8A2387",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(RowPerPage);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheck = (event, data) => {
    if (checkid.includes(data))
      setCheckId(checkid.filter((item) => item !== data))
    else
      setCheckId([...checkid, data])
  };


  return (
    <React.Fragment>
      <TableContainer className={classes.paper} component={Paper}>
        <Table size="small" className={classes.table}  {...getTableProps()}>
          <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
            {headerGroups.map(headerGroup => (
              <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <StyledTableCell {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps()}>
                    <>{column.render("Header")}</>
                    <span style={{}}></span>
                    {column.isSorted ? (column.isSortedDesc ? null : null) : null}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell === row.cells[0] && row.index < 10)
                      return (
                        <StyledTableCell key={i} {...cell.getCellProps()}>
                          <span className={classes.rowid}>
                            {"00" + (row.index).toString()}
                          </span>
                          <MyCheckbox size="small" className={classes.mycheckbox} id={(row.index).toString()} style={{}} onClick={((event) => handleCheck(event, row.index.toString()))} checked={(checkid.includes((row.index).toString())) ? true : checked} />
                        </StyledTableCell>);
                    if (cell === row.cells[0] && row.index >= 10)
                      return (
                        <StyledTableCell key={i} {...cell.getCellProps()}>
                          <span className={classes.rowid}>
                            {"0" + (row.index).toString()}
                          </span>
                          <MyCheckbox size="small" className={classes.mycheckbox} id={(row.index).toString()} style={{}} onClick={((event) => handleCheck(event, row.index.toString()))} checked={(checkid.includes((row.index).toString())) ? true : checked} />
                          {cell.render("Cell")}
                        </StyledTableCell>);


                    else
                      return (
                        <StyledTableCell key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </StyledTableCell>);
                  })}
                </StyledTableRow>
              );
            })
            }
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: emptyRows }}>
                <TableCell colSpan={8} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function AuditLogs(props) {

  const [username, setUserName] = React.useState(localStorage.getItem("username"));
  const [dateTo, setdateTo] = React.useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateFrom, setdateFrom] = React.useState(format(new Date(new Date().setFullYear(new Date().getFullYear() - 2)), 'yyyy-MM-dd'));
  const [bot, setBot] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(100);

  const [filteruser, setFilterUser] = React.useState(localStorage.getItem("username"));
  const [filterdateTo, setFilterdateTo] = React.useState(dateTo);
  const [filterdateFrom, setFilterdateFrom] = React.useState(dateFrom);
  const [filterbotlist, setfilterbotlist] = React.useState([]);
  const [filterbot, setfilterBot] = React.useState([]);

  const classes = useStyles();
  const commonClasses = commonStyles();
  const [checked, setChecked] = React.useState(false);
  const [checkid, setCheckId] = React.useState([]);
  const [Disable, setDisable] = React.useState(false);


  const { loading, error, data, refetch } = useQuery(AuditLogsData,
    { variables: { username: username, dateFrom: dateFrom, dateTo: dateTo, botIds: bot, offset: offset, limit: limit } },
    { client: client });


  React.useEffect(() => {
    if (data) {
      data.userDetails.additionalInfo.organization.userAdditionalInfo.map(user_idx => {
        if (user_idx.user.username === filteruser)
          setfilterbotlist(user_idx.user.bots)
        return (null)
      }
      )
    }
  }, [filteruser, data])

  function selectBot(event, bots) {
    setfilterBot(event.target.value)
  }

  function selectdateTo(event, bots) {
    if (format(event, 'yyyy-MM-dd') > dateFrom)
      setFilterdateTo(format(event, 'yyyy-MM-dd'))
    else
      setFilterdateTo(format(new Date(), 'yyyy-MM-dd'))
  }

  function selectdateFrom(event, bots) {
    if (format(event, 'yyyy-MM-dd') < dateTo)
      setFilterdateFrom(format(event, 'yyyy-MM-dd'))
    else
      setFilterdateFrom(dateTo)
  }

  const checkChanged = (event) => {
    setChecked(!checked)
    if (!checked)
      setCheckId([])
  };
  const exportPDF = (Data) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(20);
    doc.setFont("Pangram");

    const title = "Audit Logs";
    var columns = [["ID", "User", "Bot", "Action", "Event", "Log", "Timestamp"]]
    const celldata = [];
    if (checkid.length > 0) {
      Data.filterAuditLogs.map((cell, index) => {
        if (index in checkid)
          celldata.push(
            [cell.id,
            cell.user.username,
            cell.bot.name,
            cell.action,
            cell.event,
            (cell.logString).substring(0, 40),
            new Date(cell.dateTime).toLocaleString()
            ])
      }
      )
    }
    else {
      Data.filterAuditLogs.map(cell =>
        celldata.push(
          [cell.id,
          cell.user.username,
          cell.bot.name,
          cell.action,
          cell.event,
          (cell.logString).substring(0, 40),
          new Date(cell.dateTime).toLocaleString()
          ])
      )
    }

    let content = {
      startY: 50,
      head: columns,
      body: celldata,
      headStyles: {
        fillColor: "purple",
        fontSize: 15,
        fontFamily: "Pangram"
      },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Lokibots_Auditlogs.pdf")
  }

  function Botfilter() {
    return (
      filterbotlist.map(Bot_index => (
        <option value={Bot_index.id} >{Bot_index.name}</option>
      ))
    )
  }


  if (loading)
    return (
      <div>
        <h5 className={commonClasses.title}>Audit Logs</h5>
        <div className={classes.table_action}>
          <br />
          <TableSkeleton />
        </div>
      </div>
    )
  if (error)
    return (
      <div>
        <h5 className={commonClasses.title}>Audit Logs</h5>
        <div className={classes.table_action}>
          <CustomizedDialogs Error={error.message} />
          <h5> Some Error has occurred, Please Try Again! </h5>
        </div>
      </div>
    )
  else
    return (
      <div>
        <h5 className={commonClasses.title}>Audit Logs</h5>
        <br />
        <div className={classes.table_action}>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            {/* <Grid item xs={1}></Grid> */}
            <Grid item xs={6} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="button_no_bg">
                <KeyboardDatePicker
                  id="date-picker-from-dialog"
                  openTo="year"
                  allowKeyboardControl='false'
                  disableFuture='true'
                  multiline='false'
                  format="MM/dd/yyyy"
                  label="Date - From"
                  views={["year", "month", "date"]}
                  className="button_no_bg"
                  value={filterdateFrom}
                  onChange={event => selectdateFrom(event, "dateFrom")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="button_no_bg">
                <KeyboardDatePicker
                  id="date-picker-to-dialog"
                  className="button_no_bg"
                  allowKeyboardControl='false'
                  multiline='false'
                  openTo="year"
                  disableFuture='true'
                  format="MM/dd/yyyy"
                  value={filterdateTo}
                  onChange={event => selectdateTo(event, "dateTo")}
                  label="Date - To"
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl variant="outlined" size="small" className={classes.formControl} >
                <FormHelperText>Select User</FormHelperText>
                <Select
                  native
                  value={filteruser}
                  onChange={(event) => setFilterUser(event.target.value)}
                >
                  <option disabled={true} value="username" >Select User</option>
                  {data.userDetails.additionalInfo.organization.userAdditionalInfo.map(idx => (
                    <option value={idx.user.username}  >{idx.user.username}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl variant="outlined" size="small" className={classes.formControl} >
                <FormHelperText>Select Bot</FormHelperText>
                <Select
                  native
                  value={filterbot}
                  onChange={(event) => selectBot(event, data.userDetails.additionalInfo.organization.userAdditionalInfo)}
                >
                  <option value={[]} disabled={true} > Select Bot</option>
                  <Botfilter />

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button endIcon={<BiFilterAlt />}
                onClick={async (e) => {
                  e.preventDefault();
                  try {
                    setUserName(filteruser)
                    setdateTo(filterdateTo)
                    setdateFrom(filterdateFrom)
                    setBot(filterbot)
                    await refetch({ variables: { username: username, dateFrom: dateFrom, dateTo: dateTo, botIds: [bot] } })
                  }
                  catch (error) { console.log("Error !") }
                }}
                className={"gradient_round_btn " + commonClasses.Gradient_Btn + " " + classes.CreateBotBtn}
                variant="contained" >
                Filter
              </Button>
            </Grid>
            <Grid item xs={2} md={2} />
          </Grid>
        </div>
        <div className={classes.table_action}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ float: 'left' }}>
              <Button
                disableRipple
                className="button_no_bg"
                style={{ textTransform: 'capitalize' }}
                startIcon={!checked ? <CheckBoxOutlineBlankIcon className={classes.mycheckbox} /> : <CheckBoxIcon className={classes.mycheckbox} />}
                onClick={checkChanged}
              >
                Select All
              </Button>
            </div>
            <div style={{ position: 'absolute', right: '2%' }}>
              <Button
                disabled={Disable}
                onClick={() => exportPDF(data)}
                className={`button_no_bg ${classes.download}`}
                startIcon={<GetAppIcon style={{ marginRight: '1%' }} />}
              >
                Download
              </Button>

              {/* <Button
                className={`button_no_bg ${classes.mail}`}
                startIcon={<MailIcon style={{ marginRight: '1%' }} />}
              >
                E-mail
              </Button> */}
            </div>
          </div>

        </div>
        <br />
        {
          (data.filterAuditLogs.length === 0)
            ?
            (
              <div className={classes.table_action} onChange={() => setDisable(true)}>
                <Nodata message="No Logs to display" description="When user executes or automate a bot, logs will appear here!" />
              </div>
            )
            :
            (
              <div className={classes.table_action}>
                <AuditTable data={data.filterAuditLogs}
                  users={data.userDetails.additionalInfo.organization.userAdditionalInfo}
                  checkid={checkid} setCheckId={setCheckId} checked={checked} />
              </div>
            )
        }
      </div>
    )


}


export default AuditLogs;

// importing required packages
import React, { useEffect } from 'react';
import DraftsRoundedIcon from '@material-ui/icons/DraftsRounded';
import { Close } from '@material-ui/icons';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Typography, Grid, Slide, ListItemText, ListItem, ListItemIcon, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'
import { useMutation } from 'react-apollo';
import { useQuery } from '@apollo/client';

//importing required components/Pages
import MySkeleton from "../skeleton";
import { client } from "../../graphql/apolloClient";

// importing required variables
import { RefetchContext } from "../navbar";

//importing queries 
import { GetNotifications, ReadNotification, ReadAllNotification } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
    notiHeader: {
        position: 'fixed',
        height: '30%',
        top: '0%',
    },
    hideNoti: {
        animation: `$slideRight 0.2s ease-in-out`,
        display: 'none',
    },
    "@keyframes slideRight": {
        "from": {
            opacity: 1,
            transform: "translateX(0%)"
        },
        "to": {
            transform: "translateX(50%)",
            opacity: 0.2,
            display: 'none',
        }
    },
    list: {
        width: 400,
    },
    noti_body: {
        position: 'relative',
        width: 500,
        overflowWrap: 'break-word',
    },
    notification_drawer: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        position: 'relative',
    },
}));

function notidiff(noti_time) {
    const oneday = 24 * 60 * 60 * 1000;
    const diff = new Date() - new Date(Date.parse(noti_time))

    if (diff > oneday) {
        return (Math.floor(diff / oneday) + " days ago")
    }
    else {
        const hours = Math.floor(diff / (oneday / 24))
        if (hours > 0)
            return (hours + " hours ago")
        else {
            const minutes = Math.floor(diff / (oneday / 24 / 60));
            if (minutes === 0 || minutes === 1)
                return (" A minute ago")
            return (minutes + " minutes ago")
        }
    }

}

function checkgroup(noti_time) {
    const today = new Date();
    const someday = new Date(noti_time)
    if (someday.getDate() === today.getDate() &&
        someday.getMonth() === today.getMonth() &&
        someday.getFullYear() === today.getFullYear()) {
        return ("Today")
    }
    else if (someday.getDate() === (today.getDate() - 1) &&
        someday.getMonth() === today.getMonth() &&
        someday.getFullYear() === today.getFullYear()) {
        return ("Yesterday")
    }
    else {
        return ("Earlier")
    }

}

function GetNotificationsQuery(props) {
    const classes = useStyles();
    const [NotificationId, setNotificationId] = React.useState([]);
    const [Readnoti, { _, data: Notisuccess, error: Notierror }] = useMutation(ReadNotification);
    const { loading, error, data, refetch } = useQuery(GetNotifications, { client: client });
    const SkeletonVariants = ['h1', 'h2', 'h3', 'h4', 'body1', 'caption'];

    const { RefetchFlag, setRefetchFlag } = React.useContext(RefetchContext);

    function Notification_group(Combo) {
        const arr = Combo.map(function (num) { return checkgroup(num["timestamp"]) })
        return (arr.filter((v, i, a) => a.indexOf(v) === i));
    }

    useEffect(() => {
        const fetchData = async () => {
            await refetch();
            setRefetchFlag(false)
        }
        if (RefetchFlag) {
            console.log("its true refetching ", RefetchFlag)
            fetchData();
        }
    }, [refetch, RefetchFlag, setRefetchFlag])

    const MarkNotification = async (Notification_id) => {
        try {
            setNotificationId([...NotificationId, Notification_id])
            console.log("Read notifications with id ", Notification_id)
            await Readnoti({ variables: { id: parseInt(Notification_id, 10) } })
            refetch();
        } catch (error) {
            console.info("Error ", Notierror)
        }
    }

    if (loading) {
        return (
            <div className={classes.list} style={{ width: 400, borderTop: '0.7px solid #E8EAEE', padding: '5%' }} >
                <MySkeleton SkeletonVariants={SkeletonVariants} />
            </div>
        );
    }
    if (error) {
        console.log("ERROR: " + error);
        return (
            <div className={classes.list} style={{ width: 400, borderTop: '0.7px solid #E8EAEE', padding: '5%' }} >
                <MySkeleton SkeletonVariants={SkeletonVariants} />
                <p>{error.message} ! Try again later </p>
            </div>)
    }
    if (data) {

        const Data = data.notifications;
        const Combo = Object.keys(Data).map((key) => Data[key]);
        const Groups = Notification_group(Combo);

        return (
            data.notifications.length === 0 ?
                <div className={classes.list} style={{ position: 'relative', width: 380, borderTop: '0.7px solid #E8EAEE', margin: 'auto' }}>
                    <ul>
                        <ListSubheader style={{ background: 'white' }}>All caught up! You have no more unread notifications.</ListSubheader>
                    </ul>
                </div>
                : Groups.map((grp_id) => (
                    <ul key={`section-${grp_id}`}>
                        <ListSubheader style={{ background: 'white' }}>{`${grp_id}`}</ListSubheader>
                        {Combo.map((item, index) => {
                            if (grp_id === checkgroup(item["timestamp"]))
                                return (
                                    <div
                                        className={(NotificationId.includes((item["id"]).toString())) ? `${classes.list} ${classes.hideNoti}` : classes.list}
                                        style={{ width: 380, borderTop: '0.7px solid #E8EAEE', paddingRight: '5%' }}>
                                        <Slide key={index} direction="left" in={(NotificationId.includes((item["id"]).toString())) ? false : true}>
                                            <ListItem key={item["id"]} style={{}}>
                                                <ListItemText className={classes.noti_body} style={{ color: "black", marginRight: '-25%' }}
                                                    primary={
                                                        <Grid container
                                                            justify="flex-start"
                                                            alignItems="center"
                                                            style={{ width: 350 }}
                                                        >
                                                            <Grid item xs={11} sm={11} md={11}>
                                                                <b>{item["verb"]}</b>                                                                        </Grid>
                                                            <Grid item xs={1} sm={1} md={1}>
                                                                <Tooltip
                                                                    title={"Mark as read"}
                                                                    placement="top-start"
                                                                    key={index}
                                                                >
                                                                    <Close style={{ float: 'right', cursor: 'pointer' }} onClick={() => MarkNotification(item["id"])} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="span" variant="body2" className={classes.noti_desc} color="textPrimary">
                                                                {item["description"]}
                                                                <br />
                                                                <Tooltip
                                                                    title={new Date(item["timestamp"]).toLocaleString()}
                                                                    placement="top-start"
                                                                    style={{ width: 400, cursor: 'pointer' }}
                                                                    key={index}
                                                                >
                                                                    <p>{notidiff(item["timestamp"])}</p>
                                                                </Tooltip>
                                                            </Typography>
                                                        </React.Fragment>
                                                    }>
                                                </ListItemText>
                                                <ListItemIcon className="right">
                                                </ListItemIcon>
                                            </ListItem>
                                        </Slide>
                                    </div>
                                )
                            return (null)
                        }

                        )}
                    </ul>
                )))
    }
}

export default function Notification(props) {
    const classes = useStyles();
    const [Readallnoti, { __, data: AllNotisuccess, error: AllNotierror }] = useMutation(ReadAllNotification);
    // const [RefetchFlag, setRefetchFlag] = React.useState( props ? props.refetchFlag : false );

    const { setRefetchFlag } = React.useContext(RefetchContext);

    const MarkAllNotification = async () => {
        try {
            await Readallnoti();
        } catch (error) {
            console.info("Error ", AllNotierror)
        }
        console.log("Read all")
        // ChangeRefetchFlag(true);
        setRefetchFlag(true)
    }

    // function ChangeRefetchFlag(flag){
    //     setRefetchFlag(flag)
    // }
    return (
        <Drawer className={classes.notification_drawer} onClose={() => { props.BdHandler() }} anchor="right" open={props.flag} >
            <Grid
                container
                alignItems="center"
                style={{ overflowX: 'hidden', overflowY: 'auto', padding: '5% 0% 3% 0%', }}
                className={classes.list}
            >
                <Grid item xs={9} sm={9} md={9} style={{ color: "black", paddingLeft: '5%' }} >
                    <h5>Updates</h5>
                </Grid>
                <Grid item xs={3} sm={3} md={3} style={{ position: 'relative', right: '-5%' }}>
                    <Tooltip
                        title={"Mark all as read"}
                        placement="top-start"
                        style={{ width: 400, cursor: 'pointer' }}
                    >
                        <DraftsRoundedIcon className="center"
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => MarkAllNotification()}
                        />
                    </Tooltip>
                    <ListItemIcon className="right" onClick={(e) => { props.BdHandler(); }}>
                        <Close style={{
                            cursor: 'pointer',
                        }} />
                    </ListItemIcon>
                </Grid>
                {/* <GetNotificationsQuery refetchFlag={RefetchFlag} callbackFunction={ChangeRefetchFlag} /> */}
                <GetNotificationsQuery />
            </Grid>
        </Drawer>
    )
}


// importing Required packages
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { Grid, Button, CircularProgress, Backdrop } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useMutation } from 'react-apollo';

// importing required state variables and other required variables

// importing required graphql queries
import { ResetForgotPassword } from "../../graphql/queries";

// importing the required images
import logo from '../../styles/images/company_logo_name.png'

// importing css
import '../../styles/css/common.css';
import { commonStyles } from "../../styles/css/CommonCSS";



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    login: {
        fontFamily: 'Pangram',
        backgroundColor: 'linear-gradient(180deg, #8A2387 0%, #701B6D 100%)',
        backgroundClip: 'text',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    button1: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
        height: '50px',
        fontSize: '16px',
        margin: '1%',
    },
    input: {
        width: 'auto',
        borderRadius: 4,
        border: '0.5px solid #ebe6e6',
        padding: '0% 2% 0% 1%',
        marginBottom: '7%'
    },
    LoginProgress: {
        color: 'white',
        marginLeft: '14%',
    },
    ErrorMsg: {
        textAlign: 'left',
        color: 'red',
        paddingLeft: '-15%',
    },

}));


function ForgotPasswordReset(props) {

    const classes = useStyles();
    const CommonClasses = commonStyles()
    const history = useHistory();

    const [password, setPassword] = React.useState("");
    const [password1, setPassword1] = React.useState("");
    const [passwordMatch, setPasswordMatch] = React.useState(true);
    const [readPolicy, setReadPolicy] = React.useState(false);
    const [ErrorMessage, setErrorMessage] = React.useState("");
    const [BackdropMessage, setBackdropMessage] = React.useState("");

    const{ hashed_email, hashed_timestamp } = useParams();

    const [ResetForgotPasswordAPI, {loading: ResetForgotPasswordLoading}] = useMutation(ResetForgotPassword, {
        onCompleted(data){
            if (data.passwordReset.success){
                console.log("Successfully reset the password")
                setTimeout(function() { setBackdropMessage("Redirecting to login page"); }, 700);
                setErrorMessage("Password Change was successful.")
                setTimeout(function() { history.replace("/login"); }, 2000);
            }else{
                if (data.passwordReset.error === "WeakPassword"){
                    console.log("Error because password is weak "+JSON.stringify(data))
                    setPassword("")
                    setPassword1("")
                    setErrorMessage("Your password doesn't match the Password Policy")
                }
                else{
                    setPassword("")
                    setPassword1("")
                    localStorage.setItem("forgotPasswordFlag","true")
                    
                    setTimeout(function() { setBackdropMessage("Redirecting to forgot password page"); }, 700);
                    setTimeout(function() { history.replace("/"); }, 4000);
                    setErrorMessage("The link has expired please request for a new link.")
                    console.log("Error because link is old "+JSON.stringify(data))
                }
            }
        }
    })

    const ResetPassword = async(event) => {
        event.preventDefault()
        console.log("resetting password")
        try{
            await ResetForgotPasswordAPI({
                variables:{
                    newPassword: password,
                    emailHash: hashed_email,
                    dateTimeHash: hashed_timestamp,
                }
            })
        }catch(error){
            console.log("Error while resetting the password "+error)
            setErrorMessage("Last password reset was unsuccessful. Please try again")
        }
    }

    React.useEffect(() => {
        console.log("password ", password, " password1 ", password1)
        if (password1 !== password) {
            if (password1.length === 0)
                setPasswordMatch(true)
            else
                setPasswordMatch(false)
        } else
            setPasswordMatch(true)
        if (password.length > 0 || password1.length > 0 )
            setErrorMessage("")
    }, [password, password1])

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={BackdropMessage.length > 0 ? true : false} >
                <Backdrop className={classes.backdrop} open={true} >
                    <Grid container direction="column" justify="center" alignItems="center" >
                        <Grid justify="center" item xs={6}>
                            <h6>{ErrorMessage}</h6>
                        </Grid>
                        <Grid justify="center" item xs={6}>
                            <h6>{BackdropMessage}</h6>
                        </Grid>
                        <Grid justify="center" item xs={6}>
                            <br />
                            <CircularProgress color="inherit" />
                        </Grid>
                    </Grid>   
                </Backdrop>
            </Backdrop>
            <Grid container alignItems="center" style={{ position: 'absolute', height: '92.5%'}} >
                <Grid item lg={4} xs={2}>
                </Grid>
                <Grid item lg={4} xs={8} className="password_reset_container">
                    <img style={{ width: '50%', paddingBottom: '5%', paddingRight: '7%', paddingLeft: '7%' }} src={logo} alt="Company logo" />
                    <Grid style={{ position: 'relative', paddingRight: '5%', paddingLeft: '5%' }} container >
                        <form onSubmit={(event) => ResetPassword(event)} style={{width: '100%'}}>
                            <Grid item xs={12} className="input_container">
                                <h4 className="valign-wrapper">Reset Password</h4>
                                <input
                                    type="password"
                                    className={classes.input}
                                    placeholder="Password"
                                    required value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <input
                                    disabled={password.length > 0 ? false : true}
                                    type="password"
                                    className={classes.input}
                                    placeholder="Re-Enter Password"
                                    required value={password1}
                                    onChange={(event) => {
                                        setPassword1(event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <text className={`left ${classes.ErrorMsg} `}> {passwordMatch ? null : "Two passwords don't Match"} </text>
                            </Grid>
                            <Grid item xs={12} >
                                <text className={`left ${classes.ErrorMsg} `}> {ErrorMessage.length > 0 ? ErrorMessage : null } </text>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12} className="input_container">
                                <Button
                                    type="submit"
                                    disabled={!passwordMatch}
                                    variant="contained"
                                    className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.button1}`}
                                    endIcon={ResetForgotPasswordLoading ? <CircularProgress className={classes.LoginProgress} size="21px" /> : null }
                                >
                                    Change Password
                                </Button>
                            </Grid>
                            <Grid item xs={12} className="input_container">
                                <Button
                                    variant="text"
                                    color="inherit"
                                    className={classes.login}
                                    endIcon={<ArrowForward style={{ fontSize: 21 }} />}
                                    onClick={(event) => { history.replace("/login") }}
                                >
                                    Go Back to Login
                            </Button>
                            </Grid>
                        </form>
                        <Grid item>
                            <p style={{ fontFamily: "DM SansB", width: '250px', color: 'purple', cursor: 'pointer', fontSize: '17px' }}
                                onClick={() => setReadPolicy(!readPolicy)}
                                className="valign-wrapper"
                            >
                                {readPolicy ? <React.Fragment ><BsFillEyeSlashFill style={{ marginRight: '1%' }} /> Hide</React.Fragment> : <React.Fragment><BsFillEyeFill style={{ marginRight: '1%' }} /> Read</React.Fragment>} Password Policy
                            </p>
                            {readPolicy
                                ?
                                <ol style={{ listStyleType: 'inherit' }}>
                                    <li>Be at least 7 characters in length</li>
                                    <li>Contain characters from three of the following four categories</li>
                                    <li>
                                        <ol style={{ listStyleType: 'inherit' }}>
                                            <li>English uppercase characters (A through Z)</li>
                                            <li>English lowercase characters (a through z)</li>
                                            <li>Base 10 digits (0 through 9)</li>
                                            <li>Non-alphabetic characters (for example, !, $, #, %)</li>
                                        </ol>
                                    </li>
                                </ol>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default (ForgotPasswordReset);
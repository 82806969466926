// importing required packages
import React from 'react';
import Menu from '@material-ui/core/Menu';
import { Select, IconButton, MenuItem, CircularProgress, Button, Grid, DialogContent, DialogActions, Typography } from '@material-ui/core/';
import Fade from '@material-ui/core/Fade';
import {
  MoreVert, ScheduleRounded, CheckBoxOutlineBlank, DeleteRounded, ArchiveRounded, CallToAction,
  FileCopyRounded, EditRounded, AddRounded, PublishRounded, CloseRounded , UndoRounded, BarChartRounded,
} from '@material-ui/icons';

import { FaMagic } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useQuery } from '@apollo/client';
import { BotTabCheck } from "../utils/cache";
import FormControl from '@material-ui/core/FormControl';
import { AiFillDelete } from "react-icons/ai";
import { ImWarning } from "react-icons/im";
// importing required components/pages
import { CustomModal } from "./modal";
import { RecordReplayBot } from "./Record_Replay_Bot";

// importing required variables
import { SideNavContext } from "./sidemenu";
import { client } from "../graphql/apolloClient";

// importing required queries
import { PublishBot } from "../graphql/queries";
import { botDeactivate, botActivate, botArchive, DeleteBot, DuplicateBot, BotTabsTitle, addBotsFromBotstores } from '../graphql/queries';


// importing required css
import "../styles/css/common.css"
import { commonStyles } from "../styles/css/CommonCSS"
import MySkeleton from "./skeleton";


export default function FadeMenu(props) {
  const classes = commonStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [publishSnack, setPublishSnack] = React.useState(false);
  const [openDeleteSnack, setOpenDeleteSnack] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [archiveFlag, setArchiveFlag] = React.useState(false);
  const [clone, setClone] = React.useState(false);
  const [changebotstate] = useMutation(botDeactivate);
  const [cloneBot] = useMutation(DuplicateBot);
  const [Activate] = useMutation(botActivate);
  const [Archive] = useMutation(botArchive);
  const [deleteMyBot, { loading: DeleteBotLoading, error: DeleteBotError }] = useMutation(DeleteBot);
  const [openDialog, setOpendialog] = React.useState(false);
  const [openPublish, setPublish] = React.useState(false);
  const [openExecuteBotDialog, setOpenExecuteBotDialog] = React.useState(null);
  const [load, setLoad] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [opacity, setOpacity] = React.useState(1);
  const { setBotScheduleData } = React.useContext(SideNavContext);
  const [orgBot, SetOrg] = React.useState(props.Tab === "organizationalBots" ? true : false)
  const [GlobalBot, SetGlobalBot] = React.useState(props.Tab === "globalBots" ? true : false)
  const [errorMsg, setErrorMsg] = React.useState("")
  const [tabSave, setTabSave] = React.useState("")
  const [TabID, SetID] = React.useState("")
  const [publishFlag,setFlag] = React.useState(false)

  const { loading: TabLoading, data: TabList, refetch: TabListRefetch } = useQuery(BotTabsTitle, { client: client }, {
    onError(data) {
      TabListRefetch()
    }
  });
  const [publishBot] = useMutation(PublishBot, {
    onCompleted: (data) => {
      console.log(data.publishBot.success, data.publishBot.error)
      setOpendialog(false)
      if (data.publishBot.success) {
        setLoad(true)
        setPublish(false)
        setPublishSnack(true)
        setDisable(false)
      }
      else {
        setErrorMsg(data.addBotsFromBotstores.error)
        setError(true)
        handleError(data.publishBot.error)
      }
      setLoad(false);
    },
    onError: (err) => {
      console.log(err)
    }
  });
  const [AddBot, { error: AddBotError }] = useMutation(addBotsFromBotstores, {
    onCompleted: (data) => {
      console.log("This is at Add bot on complete ", AddBotError)
      console.log(data.addBotsFromBotstores.success, data.addBotsFromBotstores.error)
      BotTabCheck(true)
      if (!data.addBotsFromBotstores.success) {
        setLoad(false)
        setErrorMsg(data.addBotsFromBotstores.error)
        setError(true)
      }
      else {
        setPublishSnack(true);
        handleClosePublish()
      }
    },
    onError: (err) => {
      setError(true)
      setErrorMsg(err.message)
      console.log(err)
    }

  })

  React.useEffect(() => {
    if (!openDialog)
      handleClosePublish()
  }, [openDialog])
  React.useEffect(() => {
    if (!openPublish)
      handleClosePublish()
  }, [openPublish])

  const [ErrorMsg, SetMsg] = React.useState("")

  const handleGlobalBot = (event) => {
    SetGlobalBot(event.target.checked)
    setDisable(false)
  }
  const handleOrgBot = (event) => {
    SetOrg(event.target.checked)
    setDisable(false)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBot = async (event) => {
    setLoad(true)
    await publishBot({ variables: { botId: Number.parseInt(props.botId, 10), globalPublish: GlobalBot, orgPublish: orgBot } });
    props.refetch();
  }
  const handleUnpublishGlobal = async (event) => {
    setFlag(true)
     await publishBot({ variables: { botId: Number.parseInt(props.botId, 10), globalPublish: false, orgPublish: props.original.organizationPublished } });
     props.refetch()
  }
  const handleUnpublishOrg = async (event) => {
    setFlag(true)
    await publishBot({ variables: { botId: Number.parseInt(props.botId, 10), globalPublish: props.original.published, orgPublish: false } });
    props.refetch()
 }
  const handleAddBot = async (event) => {
    setError(false)
    console.log("BotID ", props.botId, " Tab= ", TabID)
    console.log(" This is tab ", props.Tab)
    setLoad(true)
    setDisable(true)
    props.Tab === "globalBots" ?
      await AddBot({ variables: { botId: props.botId, tabId: TabID, orgStore: false } })
      : await AddBot({ variables: { botId: props.botId, tabId: TabID, orgStore: true } })
  }
  const handleDeactivate = async (event) => {
    event.preventDefault()
    try {
      await changebotstate({ variables: { botId: props.botId, favourite: false, active: false } });
      BotTabCheck(true)
      setOpenSnack(true);
      props.refetch()
    }
    catch (error) {
      console.log(error)
      setError(error);
    }
  }
  const handleError = (data) => {
    SetMsg(data)
    setDisable(true)
  }
  const handleSelect = (Tab) => {
    console.log("I am selecte ", Tab.id, Tab.title)
    SetID(Tab.id)
  }
  const handleTabChange = (event) => {

    setTabSave(event.target.value);

  };
  function DeleteModal() {
    return (
      <CustomModal icon={<AiFillDelete />} title="Bot Deletion" subheader="" openDialog={openDialog} ParentCallBackFunction={setOpendialog}
        render={() => {
          return (
            <DialogContent >
              <Grid container>
                <Grid item xs={1} style={{ margin: '0% 2% 0% 2%' }} >
                  <ImWarning style={{ fontSize: '34px', color: 'red' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" component="p">
                    The Bot will be Deleted Permanently! <br />Do you want to delete it ?
                    {DeleteBotError ? <p style={{ color: 'red', fontSize: '14px' }}>There was a problem while deleting. Please try again.</p> : null}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.cardActionsWrapper}>
                <DialogActions>
                  {DeleteBotLoading
                    ?
                    <CircularProgress size="21px" />
                    :
                    <Button
                      onClick={() => setOpendialog(false)}
                      style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  }
                  <Button disabled={DeleteBotLoading} onClick={handleDelete} type="submit" style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </div>
            </DialogContent>
          )
        }}
      />
    );
  }
  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      setLoad(true)
      setDisable(true)
      setOpacity(0.5)
      await deleteMyBot({ variables: { botId: props.botId } });
      setDisable(false)
      BotTabCheck(true)
      props.refetch();
      setOpenDeleteSnack(true);
      setOpendialog(false);
      setLoad(false);
    }
    catch (error) {
      setError(true)
      console.log("Error in delete bot ", error)
    }
  }

  const handleDuplicate = async (event) => {
    event.preventDefault()
    try {
      setClone(true)
      await cloneBot({ variables: { botId: props.botId } });
      BotTabCheck(true)
      setOpenSnack(true);
      props.refetch()

    }
    catch (error) {
      console.log(error);
      setError(error);
    }

  }

  const handleActivate = async (event) => {
    event.preventDefault()
    try {
      await Activate({ variables: { botId: props.botId, active: true } });
      BotTabCheck(true)
      setOpenSnack(true);
      setArchiveFlag(false);
      props.refetch()

    }
    catch (error) {
      console.log(error);
      setError(error);
    }
  }

  const handleArchive = async (event) => {
    event.preventDefault()
    try {
      await Archive({ variables: { botId: props.botId, archived: true } });
      BotTabCheck(true)
      setOpenSnack(true);
      setArchiveFlag(true);
      props.refetch()

    }
    catch (error) {
      console.log(error);
      setError(error);
    }
  }
  const handleClosePublish = () => {
    setFlag(false)
    setOpendialog(false);
    SetID("");
    setTabSave("")
    setLoad(false)
    setDisable(false)
    setError(false)
    setErrorMsg("")
    SetOrg(false)
    SetGlobalBot(false)
  }
  const handleSnackClose = (event) => {
    setOpenDeleteSnack(false);
    setOpenSnack(false);
    setPublishSnack(false);
    setFlag(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  //this is for different tabs

  if (props.menuId === 0 || props.menuId === 1) {
    return (
      <div>
        {openExecuteBotDialog === 1
          ?
          <RecordReplayBot action="replay" title={`Execute Bot: ${props.botname}`} subheader="Executing all task(s)" botId={props.botId} CallBack={setOpenExecuteBotDialog} taskId={""} />
          :
          null
        }
        <IconButton className="button_no_bg" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          className={classes.MenuStyle}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          style={{ fontSize: 2 }}
        >
          <MenuItem dense onClick={(event) => { setOpenExecuteBotDialog(1); handleClose() }}>
            <Button
              size="small"
              href="#!"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<FaMagic style={{ fontSize: '16px' }} />}
            >
              Execute Bot
            </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              onClick={(event) => {
                history.replace("/my-bots/bot-details");
                setBotScheduleData(props.original);
                localStorage.setItem("botname", props.botname);
                localStorage.setItem("botdes", props.botdes);
                localStorage.setItem("ActiveEditBotTaskListTab", 2)
              }}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<ScheduleRounded />}
            >
              Schedule Bot
            </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              onClick={(event) => {
                history.replace("/my-bots/bot-details");
                setBotScheduleData(props.original);
                localStorage.setItem("botname", props.botname);
                localStorage.setItem("botdes", props.botdes);
                localStorage.setItem("ActiveEditBotTaskListTab", 3)
              }}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<BarChartRounded />}
            >
              Bot Statistics 
            </Button>
          </MenuItem>
           

          {
            props.original.organizationPublished ?
            <MenuItem dense onClick={handleClose}>
            <Button
              href="#!"
              size="small"
              variant="text"
              disableElevation
              disableRipple
              onClick={() => { handleUnpublishOrg() }}
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<UndoRounded />}
            >
              Unpublish(Organisation)
            </Button>
          </MenuItem>
          : null
          }
          {
            props.original.published ?
            <MenuItem dense onClick={handleClose}>
            <Button
              href="#!"
              size="small"
              variant="text"
              disableElevation
              disableRipple
              onClick={() => { handleUnpublishGlobal() }}
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<UndoRounded />}
            >
              Unpublish(Global)
            </Button>
          </MenuItem>
            : 
            <MenuItem dense onClick={handleClose}>
            <Button
              href="#!"
              size="small"
              variant="text"
              disableElevation
              disableRipple
              onClick={() => { setPublish(true) }}
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<PublishRounded />}
            >
              Publish
            </Button>
          </MenuItem>
           /* <MenuItem dense onClick={handleClose}>
              <Button
                href="#!"
                size="small"
                variant="text"
                disableElevation
                disableRipple
                className={classes.Plain_Btn}
                startIcon={<ShareRoundedIcon />}
              >
                Share
              </Button>
              </MenuItem>*/}
          <MenuItem dense onClick={handleClose}>
            <Button
              type="submit"
              onClick={handleDeactivate}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<CallToAction />}
            >
              Deactivate
            </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              type="submit"
              onClick={handleDuplicate}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<FileCopyRounded />}
            >
              Clone
            </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose} >
            <Button
              onClick={(event) => {
                history.replace("/my-bots/bot-details");
                setBotScheduleData(props.original);
                localStorage.setItem("AvatarUrl", props.botAvtUrl);
                localStorage.setItem("botname", props.botname);
                localStorage.setItem("botid", props.botId);
                localStorage.setItem("botdes", props.botdes)
              }}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<EditRounded />}
            >
              Edit
            </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              onClick={() => setOpendialog(true)}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<DeleteRounded />}
            >
              Delete
            </Button>
          </MenuItem>
        </Menu>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : clone ? <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been cloned !</Alert> : <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been deactivated !</Alert>}
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={publishSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : 
          publishFlag ?
          <Alert onClose={handleSnackClose} severity="success">  
            Bot has been Unpulished!</Alert>
            : 
            <Alert onClose={handleSnackClose} severity="success">  
            Bot has been Published!</Alert>}
        </Snackbar>
        <DeleteModal />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={openDeleteSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton className="button_no_bg" size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been deleted successfully !</Alert>}
        </Snackbar>
        <CustomModal title="Publish a bot" subheader="" openDialog={openPublish} ParentCallBackFunction={setPublish} ErrorDisplay={setDisable}
          render={() => {
            return (
              error ?
                <DialogContent>
                  <Typography style={{ fontSize: "20px", fontFamily: 'DM Sans', color: "red" }}>
                    {ErrorMsg}
                  </Typography>
                </DialogContent>
                :
                <form>
                  <DialogContent>
                    <Typography style={{ fontSize: "20px", fontFamily: 'DM Sans' }}>
                      Where do you wish to publish the bot ?
                    </Typography>
                    <DialogActions>
                      <label style={{ textTransform: 'None', fontFamily: 'Pangram', color: "black" }} >
                        <input required type="checkbox" value={GlobalBot} onChange={(e) => handleGlobalBot(e)} />
                        <span>Global</span>
                      </label>
                      <p>
                        <label style={{ textTransform: 'None', fontFamily: 'Pangram', color: "black" }}>
                          <input required type="checkbox" value={orgBot} onChange={(e) => handleOrgBot(e)} />
                          <span>Organization </span>
                        </label>
                      </p>
                      <br />
                      {load ? <CircularProgress size="21px" /> :

                        <Button onClick={() => setPublish(false)} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                          Cancel
                        </Button>}

                      {GlobalBot === false
                        ? orgBot === false ?
                          <Button disabled={true} type="button" onClick={(e) => handleBot()} style={{ opacity: "0.5", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                            Share
                          </Button> :
                          <Button type="button" onClick={(e) => handleBot()} style={{ opacity: "1", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                            Share
                          </Button>
                        : <Button type="button" onClick={(e) => handleBot()} style={{ opacity: "1", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                          Share
                        </Button>
                      }


                    </DialogActions>
                  </DialogContent>
                </form>
            )
          }}
        />
      </div>

    );
  } else if (props.menuId === 2) {
    return (
      <div>
        <IconButton className="button_no_bg" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>
            <form onSubmit={handleActivate}
            >
              <Button
                type="submit"
                size="small"
                variant="text"
                disableElevation
                disableRipple
                className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
                startIcon={<CheckBoxOutlineBlank />}
              >
                Activate
              </Button>
            </form>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
              <Button
                size="small"
                variant="text"
                disableElevation
                disableRipple
                className={classes.Plain_Btn}
                startIcon={<PublishRounded />}
              >
                Publish
              </Button>
      </MenuItem>*/}
          <MenuItem onClick={handleClose}>
            <Button
              type="submit"
              onClick={handleArchive}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<ArchiveRounded />}
            >
              Archive
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Button
              onClick={() => setOpendialog(true)}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<DeleteRounded />}
            >
              Delete
            </Button>
          </MenuItem>
        </Menu>
        <DeleteModal />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton className="button_no_bg" size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : archiveFlag ? <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been archived !</Alert> : <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been activated !</Alert>}
        </Snackbar>
      </div>
    );
  }
  else if (props.menuId === 3) {
    return (
      <div>
        <IconButton className="button_no_bg" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>
            <Button
              type="submit"
              onClick={handleActivate}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<CheckBoxOutlineBlank />}
            >
              Activate
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Button
              onClick={() => setOpendialog(true)}
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<DeleteRounded />}
            >
              Delete
            </Button>
          </MenuItem>
        </Menu>
        <DeleteModal />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={handleSnackClose} severity="success">
            {props.botname} bot has been activated !</Alert>}
        </Snackbar>
      </div>
    );
  }
  else if (props.menuId === 4) //for bot store
  {
    return (
      <div>
        <IconButton className="button_no_bg" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {/* <MenuItem onClick={handleClose}>
            <Button
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<VisibilityIcon />}
            >
              Preview
              </Button>
          </MenuItem> */}
          <MenuItem onClick={handleClose}>
            <Button
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={`${classes.MenuStyle} ${classes.Plain_Btn}`}
              startIcon={<AddRounded />}
              onClick={() => { setOpendialog(true) }}
            >
              Add Bot
            </Button>
          </MenuItem>
        </Menu>
        <CustomModal title="Add a Bot   " subheader="" openDialog={openDialog} ParentCallBackFunction={setOpendialog} ErrorDisplay={setDisable}

          render={() => {
            return (
              <>
                <form onSubmit={(event) => {
                  event.preventDefault()
                  handleAddBot()

                }}>
                  <DialogContent>
                    <Typography style={{ fontSize: "20px", fontFamily: 'DM Sans' }}>
                      Add Bot To Your Tab
                    </Typography>

                    <DialogActions>
                      <div>
                        <FormControl style={{ minWidth: 350 }}>
                          <Select required
                            //disabled={JSON.parse(localStorage.getItem("disable"))}
                            labelId="demo-customized-select-save-label"
                            value={tabSave}
                            onClick={(event) => handleTabChange(event)}
                            style={
                              { overflowY: "auto" }

                            }
                          >
                            <MenuItem value="" disabled>
                              Select Bot Tab*
                            </MenuItem>
                            {TabLoading ?
                              <MySkeleton /> :
                              TabList.botTabs.map((tab) => (
                                <MenuItem key={tab.id} value={tab.title} onClick={(event) => handleSelect(tab)} >
                                  {tab.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <br />
                      {load ? error ? <Button onClick={() => { handleClosePublish() }} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                        Cancel
                      </Button> : <CircularProgress size="21px" /> :
                        <Button onClick={() => { handleClosePublish() }} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                          Cancel
                        </Button>}
                      <Button type="submit" style={{ opacity: opacity, background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                        Add
                      </Button>
                    </DialogActions>
                  </DialogContent>
                  {error ?
                    <DialogContent>
                      <Typography style={{ fontSize: "16px", fontFamily: 'DM Sans', color: "red" }}>
                        {"Could not add bot from bot store "}<br></br>

                      </Typography>

                    </DialogContent>
                    : null}
                </form>

              </>)
          }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={publishSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>
          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={handleSnackClose} severity="success">
            Bot has been added Successfully!</Alert>}
        </Snackbar>
      </div>
    );
  }
  else return null;
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from "@material-ui/core/Table";
import { CircularProgress, Input, TableContainer } from "@material-ui/core";
import { AddRounded, BorderBottom, SettingsRounded } from '@material-ui/icons'
import { commonStyles } from '../styles/css/CommonCSS';
import { TableSkeleton } from "./skeleton";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { purple } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from '@material-ui/icons/Close';
import { useLazyQuery } from "react-apollo";

// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { CustomModal } from "./modal";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

// importing required queries/mutation
import { client } from "../graphql/apolloClient";
import { useQuery, useMutation } from 'react-apollo';
import { PreviousVmTracking, MyVmInstanceTracking, StartVmInstance, StopVmInstance, createNewVm, CustomDate, RequestBotReport, AdjustIdleTIme ,RDPFiles, EditStep} from "../graphql/queries";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// chart 
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import Paper from '@material-ui/core/Paper';

const PurpleRadio = withStyles({
    root: {
        color: purple[400],
        "&$checked": {
            color: purple[600]
        }
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

const chartRootStyles = {
    chart: {
        paddingRight: '20px',
    },
};
const legendStyles = {
    root: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'row',
    },
};
const legendLabelStyles = theme => ({
    label: {
        paddingTop: theme.spacing(1),
    },
});
const legendItemStyles = {
    item: {
        flexDirection: 'column',
    },
};



const UseStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
const useStyles = makeStyles({
    charttable: {
        width: '80%',
    },
    FormControl: {
        margin: "2%",
    },
    buttonDisplay: {
        float: "left",
        color: "red",
        float: "left",
    },
    root: {
        width: '90%',
        margin: 'auto',
        borderRadius: '12px',
        maxHeight: 40,
    },
    redstatus: {
        backgroundColor: 'red',
        'border-radius': '50%',
        'border-width': '0.5px',
        height: '30px',
        width: '30px',
        display: 'inline-block',
    },

    greenstatus: {
        backgroundColor: 'green',
        'border-radius': '50%',
        'border-width': '0.5px',
        height: '30px',
        width: '30px',
        display: 'inline-block',
    },
    BtnGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > *:hover': {
            backgroundColor: 'transparent',
        }
    },
    paper: {
        width: '95%',
        margin: 'auto',
        borderRadius: '12px',
    },
    table_action: {
        width: '100%',
        marginLeft: '5%',
        ">h5": {
            fontFamily: 'Pangram',
            fontWeight: 'bolder',
        },
    },

});



function PersonalVMDetails(props) {
    const [value, setValue] = React.useState(props.CustomDate ? "3" : "1");
    const classes = useStyles();
    const StyleClasses = UseStyles();
    const [vmName, setVmName] = React.useState(props.CustomDate ? props.Remember : null);
    const Data = [];
    
    const [Filter, SetFilter] = React.useState("1");
    const [Start] = useMutation(StartVmInstance);
    const [Stop] = useMutation(StopVmInstance);
    const [openDialog, setDialog] = React.useState(false);
    const [checkwait, setWait] = React.useState(false);
    const [DateRange, setDateRange] = React.useState(new Date());
    let CusDate = "Total Vm Usage Chart From " + props.StartDate + " TO " + props.EndDate
    let ChartData = [];
    const [Report, setReport] = React.useState(false);
    const [IdleMin, setMin] = React.useState(0);
    const [IdleHr, setHr] = React.useState(0);
    const [VMName, setVMName] = React.useState("");
    const [ActiveVm,setActiveVM] = React.useState(""); 
    const [RdpName,setRdpName] = React.useState("");

    const [getRDP, { data: RdpData,loading:RdpLoading }] = useLazyQuery(
        RDPFiles,
        {
            fetchPolicy: "cache-and-network",
        }
        )
    const [EditIdleTime, { error: IdleError, loading: IdleTimeLoading }] = useMutation(AdjustIdleTIme, {
        onCompleted: (data) => {
            if (data.setVmIdleTime.success) {
                console.log("done")
                props.RefetchData();
                setDialog(false)
            }
        }
    })
    const [SummaryMail, { error: ReportError, loading: ReportLoading }] = useMutation(RequestBotReport, {
        onCompleted: (data) => {
            if (data.botVmUsageReport.success) {
                setReport(true)
            }
        }
    });

    for (const [index, value] of props.data.myVmInstanceTracking.result.entries()) {
        ChartData[index] = [];
        if (Filter === "1") {
            for (const [temp, time] of value.trackTime.entries()) {
                Data.push({
                    usage: time,
                    month: value.trackLabels[temp]
                })
                ChartData[index][temp] = Data[value.trackTime.length * index + temp]
            }
        }
        else if (Filter === "2") {
            for (const [temp, time] of value.trackWeeklyTime.entries()) {
                console.log("Time", time, " Temp: ", temp, " label is ", value.trackWeeklyLabels[temp])
                Data.push({
                    usage: time,
                    month: value.trackWeeklyLabels[temp]
                })
                ChartData[index][temp] = Data[value.trackWeeklyTime.length * index + temp]
            }
        }
        else {
            for (const [temp, time] of value.trackMonthlyTime.entries()) {
                Data.push({
                    usage: time,
                    month: value.trackMonthlyLabels[temp]
                })
                ChartData[index][temp] = Data[value.trackMonthlyTime.length * index + temp]
            }
        }
    }
    const handleChangeDate = (event) => {
        DateRange.map((date, index) => (
            index === 0 ?
                props.setStart(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
                : props.setEnd(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
        ))
        props.setthis(false)
        props.setRemember(vmName)
        props.setDate(true)
    }
    const handleChange = (event) => {
        setValue(event.target.value);
        if (event.target.value === "2") {
            props.setthis(true)
            props.setDate(false)
        }
        else if (event.target.value === "1") {
            props.setDate(false)
            props.setthis(false)
        }
    };
    const handleReport = () => {
        SummaryMail();
    }
    const handleClose = () => {
        setValue("1")
        setDateRange(new Date());
        setVmName(null);
        props.setDate(false)
        props.setthis(false)
    };
    const handleSwitchChange = async (event) => {
        console.log("I am called for loading ")
        setWait(true);
        try {
            if (event.target.checked) { await Start({ variables: { name: event.target.id } }) }
            else { await Stop({ variables: { name: event.target.id } }) }
            console.log("Success")
            props.RefetchData();
            setWait(false);
        }
        catch (error) {
            console.log("Error")
            console.log(error)
            setWait(false);
        }
    };
    const FilterOption = (event) => {
        SetFilter(event.target.value);
    }
    React.useEffect(() => {
        if (vmName === false)
            handleClose()
    }, [vmName])
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ fontFamily: "Pangram" }}
                open={Report}
                autoHideDuration={6000}
                onClose={() => setReport(false)}
                message={ReportError ? "Error!!! Failed to sent mail" : "Successfully bot report has been mailed !!"}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setReport(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <CustomModal
                title="Adjust VM Idle Time"
                openDialog={openDialog}
                ParentCallBackFunction={setDialog}
                render={() => {
                    return (
                        <DialogContent align="center">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                EditIdleTime({ variables: { hours: IdleHr, minutes: IdleMin, name: VMName } })
                            }} >
                                <h6>Please Select the VM Idle Time limit </h6>
                                <input required type="number" onChange={(e) => { setHr(e.target.value) }} placeholder='hours 0-24' required name="SeriesValue" min="0" max="24" />
                                <input required type="number" onChange={(e) => { setMin(e.target.value) }} placeholder='mins 1-60' required name="SeriesValue" min="1" max="60" />
                                {
                                    IdleTimeLoading ?
                                        <CircularProgress size="21px" />
                                        :
                                        <Button type="submit" className={'button_no_bg'} style={{ float: "right", color: "purple" }}>Submit </Button>
                                }
                                {
                                    IdleError ?
                                        <h5 style={{ color: "red" }} >Failed:Try Again Later</h5>
                                        : null
                                }
                            </form>
                        </DialogContent>
                    )
                }}
            />
            <TableContainer className={classes.paper}>
                <Table size="small" className={classes.table} >
                    <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                        <StyledTableRow>
                            <StyledTableCell align="left">Sl No.</StyledTableCell>
                            <StyledTableCell align="left">USER</StyledTableCell>
                            <StyledTableCell align="left">IP Address</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="left">Switch</StyledTableCell>
                            <StyledTableCell align="left">RDP</StyledTableCell>
                            <StyledTableCell align="left">Track</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.userVmInstance.map((row, num) => (
                            <React.Fragment>
                                <StyledTableRow key={row.ipAddress}>
                                    <StyledTableCell align="left" style={{ paddingLeft: '2%' }}>{num + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row.user.username}</StyledTableCell>
                                    <StyledTableCell align="left">{row.ipAddress}</StyledTableCell>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    {row.active ?
                                        (
                                            <StyledTableCell align="left" >
                                                <span className={classes.greenstatus}></span>
                                            </StyledTableCell>
                                        )
                                        :
                                        (
                                            <StyledTableCell align="left" >
                                                <span className={classes.redstatus}></span>
                                            </StyledTableCell>
                                        )}
                                    <StyledTableCell align="left"  >
                                        <div class="toggle switch switch-dropdown">
                                            {checkwait ? row.ipAddress === ActiveVm ?
                                                <CircularProgress size="21px" /> :
                                                <label>
                                                    OFF
                                                    <input className={classes.toggle}
                                                        checked={row.active}
                                                        onChange={(event)=>(setActiveVM(row.ipAddress),handleSwitchChange(event))}
                                                        id={row.name}
                                                        type="checkbox"></input>
                                                    <span class="lever"></span>
                                                    ON
                                                </label> : 
                                                <label>
                                                OFF
                                                <input className={classes.toggle}
                                                    checked={row.active}
                                                    onChange={(event)=>(setActiveVM(row.ipAddress),handleSwitchChange(event))}
                                                    id={row.name}
                                                    type="checkbox"></input>
                                                <span class="lever"></span>
                                                ON
                                            </label>
                                            }
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" >
                                        {row.active ?
                                            RdpName===row.name ?
                                                RdpData?
                                                <a  style={{color:"green"}} href={window.location.origin + RdpData.rdpFile}  >
                                                      Download RDP File
                                                 </a> //change 
                                                :
                                                RdpLoading? 
                                                <CircularProgress size="21px" />
                                                :
                                                <Button onClick={(e)=>{setRdpName(row.name);getRDP({variables:{vmName:row.name}})}} style={{textAlign:"left", marginLeft:"-4%",fontWeight:"normal",textTransform:"none",paddingLeft:"-2%",color:"red",fontSize:"12px"}}>Request for RDP</Button>   
                                            :        
                                                <Button onClick={(e)=>{setRdpName(row.name);getRDP({variables:{vmName:row.name}})}} style={{textAlign:"left", marginLeft:"-4%",fontWeight:"normal",textTransform:"none",paddingLeft:"-2%",color:"red",fontSize:"12px"}}>Request for RDP</Button>   
                                            :
                                            <span>Wait to switch ON</span>
                                        }
                                    </StyledTableCell>                                     
                                    <IconButton color="secondary" className="button_no_bg" onClick={(event) => { setVmName(row.name) }}>
                                        <ShowChartIcon align="left" />
                                    </IconButton>
                                    {
                                        props.data.myVmInstanceTracking.result.map((instance, index) => (
                                            instance.instanceName === vmName
                                                ?
                                                <CustomModal
                                                    title="Track VM Instance"
                                                    openDialog={vmName}
                                                    ParentCallBackFunction={setVmName}
                                                    size={"lg"}
                                                    render={() => {
                                                        return (
                                                            <DialogContent align="center">
                                                                <Grid container spacing={2} container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="flex-start" >
                                                                    <Grid item xs={12}>

                                                                        {
                                                                            ReportLoading ?
                                                                                <IconButton size={"small"} style={{ float: "right" }} className={`button_no_bg`} >
                                                                                    <CircularProgress size={30} />
                                                                                </IconButton>
                                                                                :
                                                                                 <Button className='button_no_bg'   variant="contained" onClick={() => handleReport()} style={{ color: "white", backgroundColor: "purple", float: "right" }}>Get Report</Button>
                                                                               
                                                                        }

                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <h5>Total Usage Data</h5>
                                                                    </Grid>

                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 600 }}>
                                                                            User/Instance
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 600 }}>
                                                                            Total usage
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 600 }}>
                                                                            Total cost
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 600 }}>
                                                                            VM Idle Time
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 400 }}>
                                                                            {props.data.myVmInstanceTracking.user + " / " + instance.instanceName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 400 }}>
                                                                            {"hr:mins:sec"} <br></br>
                                                                            {instance.uptime}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 400 }}>
                                                                            {"$" + instance.cost}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography style={{ fontWeight: 400 }}>
                                                                            {row.idleTimeSetMinutes + " mins"}
                                                                        </Typography>
                                                                            <SettingsRounded onClick={() => { setDialog(true); setVMName(instance.instanceName) }} style={{ color: "grey" }} />
                                                                    </Grid>
                                                                </Grid>
                                                                <br>
                                                                </br>
                                                                <div>
                                                                    <Grid container>
                                                                        {
                                                                            value === "3"
                                                                                ?
                                                                                <Grid item xs={11}>
                                                                                    <form onSubmit={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleChangeDate(e)
                                                                                    }}>
                                                                                        <FormControl component="fieldset" style={{ float: "right" }}>
                                                                                            <DateRangePicker className="button_no_bg"
                                                                                                style={{ color: "purple" }}
                                                                                                views={["year", "month", "date"]}
                                                                                                onChange={setDateRange}
                                                                                                value={DateRange}
                                                                                                maxDate={new Date()}
                                                                                                label="Date Range"
                                                                                                format={"dd-MM-y"}
                                                                                                default={new Date()}
                                                                                                borderRadius="10px"
                                                                                                required
                                                                                            />
                                                                                            <Button type="submit" color=" purple" className="button_no_bg" variant="contained">Submit</Button>
                                                                                        </FormControl>
                                                                                    </form>
                                                                                </Grid>
                                                                                : null
                                                                        }
                                                                        <Grid item xs={4}>
                                                                            <FormControl className={classes.FormControl} style={{ float: "left", maxWidth: '80px', maxHeight: '20px', minWidth: '50px', minHeight: '20px' }}>
                                                                                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={Filter}
                                                                                    onChange={FilterOption}
                                                                                >
                                                                                    <MenuItem value={"1"}>Daily</MenuItem>
                                                                                    <MenuItem value={"2"}>Weekly</MenuItem>
                                                                                    <MenuItem value={"3"}>Monthly</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={8}  >
                                                                            <FormControl component="fieldset" style={{ float: "right" }}>
                                                                                <RadioGroup
                                                                                    row
                                                                                    aria-label="gender"
                                                                                    value={value}
                                                                                    onChange={(event) => { handleChange(event) }}
                                                                                >
                                                                                    <FormControlLabel value="2" control={<PurpleRadio />} label="Last Month" />
                                                                                    <FormControlLabel value="1" control={<PurpleRadio />} label="This Month" />
                                                                                    <FormControlLabel value="3" control={<PurpleRadio />} label="Custom date" />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            props.CustomDate ?
                                                                                <h6 align="center">Total VM Usage From {props.StartDate +" To "+props.EndDate}</h6>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        </Grid>
                                                                    <Grid item xs={12}>
                                                                    <Paper>
                                                                        <LineChart width={1000} height={350} data={ChartData[index]}
                                                                            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                                                                            <CartesianGrid strokeDasharray="3 3" />
                                                                            <XAxis dataKey="month" />
                                                                            <YAxis />
                                                                            <Tooltip/>
                                                                            <Legend />
                                                                            <Line type="monotone" dataKey="usage" name="Uptime (hrs)" stroke="#9932CC"  />
                                                                        </LineChart>
                                                                    </Paper>
                                                                    </Grid>
                                                                </div>
                                                                <DialogActions>
                                                                    <Button onClick={() => setVmName(null)} className="button_no_bg">
                                                                        CLOSE
                                                                    </Button>
                                                                </DialogActions>
                                                            </DialogContent>
                                                        )
                                                    }}

                                                />
                                                :
                                                console.log("Not match ")
                                        ))}
                                </StyledTableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

function PersonalVM() {
    const [NewVM] = useMutation(createNewVm);
    const [opensnackbar, setOpensnackbar] = React.useState(false);
    const [msgsnackbar, setMsgsnackbar] = React.useState("");
    const handleClicksnackbar = () => {
        setOpensnackbar(true);
    };
    const [checkDate, setDate] = React.useState(false);
    const handleClosesnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpensnackbar(false);
    };
    const handleNewVM = async (event) => {
        try {
            await NewVM({ variables: { isSchedulerVm: RequestVM === "1" ? false : true } })
            console.log("Success")
            setOpenDialog(false)
            setMsgsnackbar("Request for new VM has been submitted successfully!");
            handleClicksnackbar();
        }
        catch (error) {
            console.log("Error")
            console.log(error)
            setMsgsnackbar("There was an Error, Please Try Again");
            handleClicksnackbar();
        }
    };

    const classes = useStyles();
    const CommonClasses = commonStyles();
    let date = new Date()
    const { loading, error, data, refetch: DataRefetch } = useQuery(MyVmInstanceTracking, { client: client });
    const { loading: Loads, error: prevError, data: prevData } = useQuery(PreviousVmTracking, { client: client });
    const [StartDate, setStartDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
    const [EndDate, setEndDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
    const { loading: CustomLoad, error: DateError, data: CustomData, refetch } = useQuery(CustomDate,
        { variables: { isPersonal: true, tzOffset: 1, startDate: StartDate, endDate: EndDate } },
        { client: client });
    const [PreMonth, setPrevous] = React.useState(false);
    const [remember, setRemember] = React.useState("");
    const [RequestVM, setVMRequest] = React.useState("1");
    const [OpenDialog, setOpenDialog] = React.useState(false);
    const handleChange = (event) => {
        event.target.value === "1" ?
            setVMRequest("1")
            : setVMRequest("2")
    }
    React.useEffect(() => {
        if (checkDate) {
            refetch({ variables: { isPersonal: true, tzOffset: 1, startDate: StartDate, endDate: EndDate } })
        }
    }, [checkDate])

    React.useEffect(() => {
        if (!OpenDialog) {
            setVMRequest("1")
        }
    }, [OpenDialog])
    if (loading || Loads || CustomLoad)
        return (
            <TableSkeleton />
        );
    if (error)
        return (
            <div>
                <h5> Please Try Again! </h5>
            </div>);
    if (data)
        return (
            <div className={classes.paper}>
                <h5 align="left"  >MANAGE INSTANCE
                    <Button style={{ float: 'right' }}
                        endIcon={<AddRounded />}
                        className={"gradient_round_btn " + CommonClasses.Gradient_Btn + " " + classes.CreateBotBtn}
                        variant="contained" to="#!"
                        onClick={() => setOpenDialog(true)}
                    >
                        REQUEST NEW VM
                    </Button>
                    <CustomModal
                        title="Track VM Instance"

                        openDialog={OpenDialog}
                        ParentCallBackFunction={setOpenDialog}

                        render={() => {
                            return (
                                <DialogContent style={{ alignContent: "center", alignItems: "center" }} >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="baseline"
                                    >
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="h6" component="p" style={{ alignContent: "center" }}>
                                                Do you want Schduler VM ?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                        </Grid>
                                        <FormControl component="fieldset" style={{ alignContent: "center" }}>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    row
                                                    aria-label="gender"
                                                    value={RequestVM}
                                                    onChange={(event) => { handleChange(event) }}
                                                >
                                                    <FormControlLabel value={"2"} control={<PurpleRadio />} label="Yes" />
                                                    <FormControlLabel value={"1"} control={<PurpleRadio />} label="No" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={8}>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Button style={{ float: "right", padding: "2%" }} className="button_no_bg" variant="contained" onClick={() => handleNewVM()} >Submit</Button>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                </DialogContent>

                            )
                        }}
                    />
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={opensnackbar}
                        autoHideDuration={6000}
                        onClose={handleClosesnackbar} >
                        <MuiAlert elevation={6}
                            variant="filled"
                            onClose={handleClosesnackbar}
                            severity="info">
                            {msgsnackbar}
                        </MuiAlert>
                    </Snackbar>
                </h5>
                <PersonalVMDetails RefetchData={DataRefetch} StartDate={StartDate} EndDate={EndDate} Remember={remember} setRemember={setRemember} data={PreMonth ? prevData : checkDate ? CustomData : data} CustomDate={checkDate} setStart={setStartDate} setEnd={setEndDate} setDate={setDate} setthis={setPrevous} />
            </div>);
}

export default PersonalVM;
// importing required packages
import React from "react";
import Divider from "@material-ui/core/Divider"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from "./TableComponent";
import { commonStyles } from "../styles/css/CommonCSS";
import { SubscriptionData } from "../graphql/queries";
import { client } from "../graphql/apolloClient";
import { useQuery, useMutation } from 'react-apollo';
import { Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

function SubscriptionsDetails(data) {
  const classes = useStyles();
  const commonClasses = commonStyles();
  //const { loading, error, data, refetch } = useQuery(SubscriptionData, { client: client });
  console.log("subdata: ", data.subData);
  return (

    <div className="home">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-5">
            <h2 className={commonClasses.title}>Subscription Info</h2>
            <Card style={{marginTop:"4%"}}>
              <CardContent >
                <List aria-label="main mailbox folders">
                  <ListItem >
                    <ListItemText  primary='USERNAME' className={classes.heading} />
                    <span className={classes.body}>
                      {data.subData.userDetails.username}
                    </span>
                  </ListItem>
                </List>
                <Divider />
                <h6 style={{fontFamily:"DM Sans"}} className={classes.heading}>User Details</h6>
                <Divider />
                <List style={{fontFamily:"DM Sans"}} className={classes.header}>
                  <ListItem >
                    <ListItemText primary='No. of Instances' />
                    <span className={classes.body}>
                      {data.subData.userDetails.additionalInfo.user.subscriptionDetails.instances}
                    </span>
                  </ListItem>
                  <ListItem >
                    <ListItemText primary='No. of Users' />
                    <span className={classes.body}>
                      {data.subData.userDetails.additionalInfo.user.subscriptionDetails.users}
                    </span>
                  </ListItem>
                  <ListItem >
                    <ListItemText primary='Total Instance Consumption' />
                    <span className={classes.body}>
                      {data.subData.userDetails.additionalInfo.user.subscriptionDetails.totalInstanceConsumption}
                    </span>
                  </ListItem>
                  <ListItem >
                    <ListItemText primary='Organization' />
                    <span className={classes.body}>
                      {data.subData.userDetails.additionalInfo.organization.name}
                    </span>
                  </ListItem>
                  <Divider />
                  <h6 className={classes.heading}>Subscriptions Details</h6>
                  <Divider />
                  <ListItem style={{textTransform:"capitalize"}}>
                    <ListItemText primary={data.subData.userDetails.additionalInfo.user.subscriptionDetails.plan} />
                    <span className={classes.body}>
                      Validity: {data.subData.userDetails.additionalInfo.user.subscriptionDetails.planValidityInDays} Days
                    </span>
                  </ListItem>
                  <ListItem style={{textTransform:"capitalize"}}>
                    <ListItemText primary='Next billing Date' />
                    <span className={classes.body}>
                      {data.subData.userDetails.additionalInfo.user.subscriptionDetails.nextBillingDate}
                    </span>
                  </ListItem>
                </List>
              </CardContent>
            </Card>


          </div>
        </div>
      </div>
    </div>

  );
}

function Subscriptions() {
  return (
    < Query query={SubscriptionData} >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div>

            </div>
          );
        if (error) {
          return (
            <div style={{ margin: "2%" }}>

            </div>);
        }
        else {
          return (
            <div>
              <SubscriptionsDetails subData={data} />
            </div>);
        }
      }
      }
    </Query >
  );

}

export default Subscriptions;

// importing required packages
import { Tooltip, Avatar } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UpdateIcon from '@material-ui/icons/Update';

// importing required css
import '../styles/css/table_module.css';
// importing required images
let uri = window.location.origin
function TruncateString(props) {
    let n = props.size
    return (
        <div>
            {props.str.length > n ? props.str.substr(0, n - 1) + '.......' : props.str}
        </div>
    )
}
export const InvoiceColumns = [
    {
        Header: "Invoice Id",
        accessor: "invoiceId",
    },
    {
        Header: "Date",
        accessor: "date",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Status",
        accessor: "status",
    },
]

export const TransactionColumns = [
    {
        Header: "Date",
        accessor: "date",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Payment Method",
        accessor: "paymentMethod",

    }
]
export const MyBotColumns = [
    {
        Header: '',
        accessor: 'favourite',
        disableSortBy: true,
    },
    {
        Header: 'Avatar',
        accessor: 'botAvatarUrl',
        maxWidth: 50,
        minWidth: 50,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (

            <Avatar
                src={uri + value}
                width={60}
                alt="no botavatar"
            />
        )
        /* Cell: ({ cell: { value } }) => (
             <img className='MyBotAvatar' src={botAvatarUrl} alt="botimg" width={60} />
         )*/
    },
    {
        Header: <>Name
            <IconButton>
                <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
            </IconButton>
        </>,
        accessor: 'name',
    },
    {
        Header: <header style={{ minWidth: '420px' }}>Description</header>,
        accessor: 'description',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            <Tooltip title={value}>
                <TruncateString str={value} size={80} />
            </Tooltip>
        )
    },
    // {
    //     Header:
    //         <>Run Time
    //             <IconButton>
    //                 <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
    //             </IconButton>
    //         </>,
    //     accessor: 'execTime',
    //     Cell: ({ cell: { value } }) => (
    //         (value === null || value === 0) ?
    //             (<data> Not executed </data>)
    //             :
    //             (<data>
    //                 <IconButton size="small">
    //                     <UpdateIcon />
    //                 </IconButton>
    //                 {value}
    //             </data>)
    //     ),

    // },
    {
        Header:
            <>Recent Run
                <IconButton>
                    <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
                </IconButton>
            </>,
        accessor: 'lastExecutedOn',
        Cell: ({ cell: { value } }) => (
            (value === null || value === 0) ?
                (
                    <data>
                        Not executed
                    </data>
                )
                :
                <data>
                    
                    {/* <IconButton size="small">
                        <UpdateIcon />
                    </IconButton> */}
                    {/* {value.getDate() +"-"+(value.getMonth()+1)+"-"+value.getFullYear()} */}
                    {value.slice(0,10)}
                </data>
        ),
    },
    {
        Header:
            <>Scheduled On
                <IconButton>
                    <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
                </IconButton>
            </>,
        accessor: 'replaySchedules',


        Cell: ({ cell: { value } }) => (
            (value === null || value.length === 0) ?
                (
                    <data>
                        Not Scheduled
                    </data>
                )
                :
                <data>
                    <IconButton size="small">
                        <UpdateIcon />
                    </IconButton>
                    {value.length}
                </data>
        ),
    },
    {
        Header: 'Runs',
        accessor: 'totalRuns',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            (value === null || value === 0) ?
                (
                    <data>
                        Not executed
                    </data>
                )
                :
                <data>
                    {value}
                </data>
        ),
    },

]

export const InstanceUsageColumns = [
    {
        Header: 'User',
        accessor: 'users',
    },
    {
        Header: 'Total Instance Consumption',
        accessor: 'totalInstanceConsumption',
    },
    {
        Header: 'Instance',
        accessor: 'instances',
    },
    {
        Header: 'Customer Name',
        accessor: 'customerName',

    },
    {
        Header: 'Status',
        accessor: 'status',
    },
]

export const AuditLogsColumns = [
    {
        Header: "ID",
        accessor: "",
        sortType: "number",
        disableSortBy: true
    },
    {
        Header: "User",
        accessor: "user.username",
        sortType: "number",
        disableSortBy: true
    },
    {
        Header: "Bot",
        accessor: "bot.name",
        disableSortBy: true,
    },
    {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
    },
    {
        Header: "Event",
        accessor: "event",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            value.length > 20 ? (<Tooltip title={value}><data>  {value.length > 16 ? value.substring(0, 16) + "..." : value}  </data></Tooltip>)
                : <data>{value}</data>),
    },
    {
        Header: "Log",
        accessor: "logString",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            value.length > 20 ? (<Tooltip title={value}><data>  {value.length > 16 ? value.substring(0, 16) + "..." : value}  </data></Tooltip>)
                : <data>{value}</data>),
    },
    {
        Header: "Date",
        accessor: "dateTime",
        sortType: "basic",
        Cell: ({ cell: { value } }) => (
            new Date(value).toLocaleString()
        ),
        disableSortBy: true
    },

]

export const BotStoreColumns = [
    {

        Header: 'Avatar',
        accessor: 'botAvatarUrl',
        maxWidth: 50,
        minWidth: 50,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (

            <Avatar
                src={uri + value}
                width={60}
                alt="no botavatar"
            />
        )
    },
    {
        Header: <>Name
            <IconButton>
                <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
            </IconButton>
        </>,
        accessor: 'name',
    },
    {
        Header: <header style={{ minWidth: '420px' }}>Description</header>,
        accessor: 'description',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            <TruncateString str={value} size={60} />
        )
    },
    // {
    //     Header:
    //         <>Run Time
    //             <IconButton>
    //                 <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
    //             </IconButton>
    //         </>,
    //     accessor: 'execTime',
    //     Cell: ({ cell: { value } }) => (
    //         <data style={{ textAlign: "right" }}>
    //             <IconButton size="small">
    //                 <UpdateIcon />
    //             </IconButton>
    //             {"" + value}
    //         </data>),
    // },
    {
        Header:
            <>Apps Used
                <IconButton>
                    <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
                </IconButton>
            </>,
        accessor: 'appsUsed',
        Cell: ({ cell: { value } }) => (
            <data>
                {"" + value}
            </data>),
    },
    {
        Header:
            <>Process
                <IconButton>
                    <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
                </IconButton>
            </>,
        accessor: 'processTags',
        Cell: ({ cell: { value } }) => (
            <data>
                {"" + value}
            </data>),
    },
    {
        Header:
            <>Shared By
                <IconButton>
                    <UnfoldMoreIcon style={{ color: "white", position: 'absolute', marginTop: -0.5, }} />
                </IconButton>
            </>,
        accessor: 'user.username',
        Cell: ({ cell: { value } }) => (
            <data style={{ fontSize: "13px" }}   >
                <TruncateString str={value} size={8} />
            </data>),
    },
    {
        Header: '',
        accessor: 'menuoption',
        disableSortBy: true,
    },
]

export const TaskStoreColumns = [
    // {

    //     Header: 'Avatar',
    //     accessor: 'botAvatarUrl',
    //     maxWidth: 50,
    //     minWidth: 50,
    //     disableSortBy: true,
    //     Cell: ({ cell: { value } }) => (

    //         <Avatar
    //             src={uri + value}
    //             width={60}
    //             alt="no botavatar"
    //         />
    //     )
    // },
    {
        Header: <>Name
            <IconButton>
                <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
            </IconButton>
        </>,
        accessor: 'title',
    },
    {
        Header: <header style={{ minWidth: '420px' }}>Description</header>,
        accessor: 'description',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            <TruncateString str={value} size={60} />
        )
    },
    {
        Header:
            <>Published By
                <IconButton>
                    <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
                </IconButton>
            </>,
        accessor: 'bot.user.username',
    },
    {
        Header: <>Bot Name
            <IconButton>
                <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
            </IconButton>
        </>,
        accessor: 'bot.name',
    },
    {
        Header: 'Add Bot',
        accessor: 'action',
        disableSortBy: true,
    },
]

export const InteractiveApiColumns = [
    {
        Header: '',
        accessor: 'favourite',
        disableSortBy: true,
    },
    {
        Header: <>Name
            <IconButton>
                <ArrowDropDownIcon style={{ color: "white", position: 'absolute', marginTop: -0.5 }} />
            </IconButton>
        </>,
        accessor: 'name',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
            <data hidden={true}></data>)
    },
]

export const TaskListStepsColumn = [
    {
        Header: '',
        accessor: 'action',
        Cell: ({ cell: { value } }) => (
            <data> {value}</data>)
    },
    {
        Header: '',
        accessor: 'keys',
        Cell: ({ cell: { value } }) => (
            <data> {value}</data>)
    },
]

export const ManageBotsColumn = [
    {
        Header: <header style={{ paddingLeft: '7%' }}>CREATOR</header>,
        accessor: 'user.username',
        Cell: ({ cell: { value } }) => (
            <header style={{ paddingLeft: '7%' }}>{value}</header>
        )
    },
    {
        Header: 'Bot Name',
        accessor: 'name',
        sortType: "basic"
    },
    {
        Header: 'Global',
        accessor: 'published',
    },
    {
        Header: 'Organization',
        accessor: 'organizationPublished',
    },

]

export const OrgUsersColumn = [
    {
        Header: <header style={{ paddingLeft: '7%' }}>USER</header>,
        accessor: 'user.username',
        Cell: ({ cell: { value } }) => (
            <header style={{ paddingLeft: '7%' }}>{value}</header>
        )
    },
    {
        Header: 'ROLE',
        accessor: 'user.groups[0].name',
    },
    {
        Header: '#Bots Created',
        accessor: 'user.bots',
    },
    {
        Header: 'Action',
        accessor: '',
    },
]
export const IndividualUsersColumn = [
    {
        Header: "Delete",
        assessor: '',

    },
    {
        Header: 'SL No.',
        accessor: '',
    },
    {
        Header: 'Bot Name',
        accessor: 'name',
    },
    {
        Header: 'Bot Description',
        accessor: 'description',
        Cell: ({ cell: { value } }) => (
            <TruncateString str={value} size={60} />
        )
    },
    {
        Header: 'Created',
        accessor: 'dateOfBirth',
    },
]
export const ArchivedUsersColumn = [
    {
        Header: 'NAME',
        accessor: 'username',
    },
    {
        Header: 'LAST LOGIN',
        accessor: 'lastLogin',
        Cell: ({ cell: { value } }) => (
            new Date(value).toLocaleString()
        ),
    },
    {
        Header: 'ACTION',
        accessor: '',
    },
]

export const ManageNewUsersColumn = [
    {
        Header: <header style={{ paddingLeft: '7%' }}>EMAIL</header>,
        accessor: 'email',
        Cell: ({ cell: { value } }) => (
            <header style={{ paddingLeft: '7%' }}>{value}</header>
        )
    },
    {
        Header: 'ROLE',
        accessor: 'userRole',
        sortType: "basic"
    },
    {
        Header: 'ACTION',
        accessor: '',
    },
]

export const ScheduleBotsColumn = [
    {
        Header: <header style={{ paddingLeft: '7%' }}>CREATOR</header>,
        accessor: 'user.username',
        Cell: ({ cell: { value } }) => (
            <header style={{ paddingLeft: '7%' }}>{value}</header>
        )
    },
    {
        Header: 'Bot Name',
        accessor: 'bot.name',
        sortType: "basic"
    },
    {
        Header: 'SCHEDULE STATUS',
        accessor: 'enabled',
    },

]

export const OrgVMColumn = [
    {
        Header: <header style={{ paddingLeft: '7%' }}>USER</header>,
        accessor: 'user.username',
        Cell: ({ cell: { value } }) => (
            <header style={{ paddingLeft: '7%' }}>{value}</header>
        )

    },
    {
        Header: 'Number of VM',
        accessor: 'user.vmInstances',
    },
]

export const IndividualVMColumn = [
    {
        Header: 'SL No.',
        accessor: '',
    },
    {
        Header: 'IP Address',
        accessor: 'ipAddress',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'STATUS',
        accessor: 'active',
    },
    {
        Header: 'SWITCH',
        accessor: '',
    },
    {
        Header: 'TRACK',
        accessor: '',
    },


]

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, StyledTableRow } from "./TableComponent";
import { InstanceUsageColumns } from "../components/Column";
import { useTable } from "react-table";
import { InstanceUse } from "../graphql/queries";
import Grid from "@material-ui/core/Grid";
import { TableSkeleton } from "./skeleton";
import { Query } from 'react-apollo'
import { commonStyles } from '../styles/css/CommonCSS';

const useStyles = makeStyles({
  paper: {
    width: "95%",
    margin: "auto",
    borderRadius: "12px",
  },
});

const mockInvoices = [
  {
    user: "user",
    ip: 12345,
    instance: "instance",
    usage: "usage",
  },
  {
    user: "user",
    ip: 12345,
    instance: "instance",
    usage: "usage",
  },
  {
    user: "user",
    ip: 12345,
    instance: "instance",
    usage: "usage",
  },
  {
    user: "user",
    ip: 12345,
    instance: "instance",
    usage: "usage",
  },
];

function InstanceUsageDetails(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const columns = React.useMemo(() => InstanceUsageColumns, []);
  const data = props.data.subscriptionsDashboardSidepane;
  console.log("instance usage: ", data)
  /*const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  });*/

  return (
    <>
      <h5 className={commonClasses.title}>Instance Usage</h5>

      <TableContainer style={{ marginTop: "2%" }} className={classes.paper} component={Paper}>
        <Table
          size="small"
          className={classes.table}

          aria-label="a dense table"
        >
          <TableHead
            style={{ textTransform: "uppercase", border: "3px solid #440042" }}
          >

            <StyledTableRow
              className={classes.tableHead}>
              <StyledTableCell >
                Users
              </StyledTableCell>
              <StyledTableCell >
                Total Instance Consumption
              </StyledTableCell>
              <StyledTableCell >
                Instances
              </StyledTableCell>
              <StyledTableCell  >
                Plan
              </StyledTableCell>
              <StyledTableCell  >
                Status
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody >
            <StyledTableRow style={{ fontFamily: "DM Sans", fontSize: "5%" }}>
              <StyledTableCell >
                {data.subscriptionDetails.users}
              </StyledTableCell>
              <StyledTableCell >
                {data.subscriptionDetails.totalInstanceConsumption}
              </StyledTableCell>
              <StyledTableCell >
                {data.subscriptionDetails.instances}
              </StyledTableCell>
              <StyledTableCell >
                {data.subscriptionDetails.plan}
              </StyledTableCell>
              <StyledTableCell >
                {data.subscriptionDetails.status}
              </StyledTableCell>
            </StyledTableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </>

    // <TableContainer className={classes.paper} component={Paper}>
    //   <Table
    //     size="small"
    //     className={classes.table}
    //     {...getTableProps()}
    //     aria-label="a dense table"
    //   >
    //     <TableHead
    //       style={{ textTransform: "uppercase", border: "3px solid #440042" }}
    //     >
    //       {headerGroups.map((headerGroup) => (
    //         <StyledTableRow
    //           className={classes.tableHead}
    //           {...headerGroup.getHeaderGroupProps()}
    //         >
    //           {headerGroup.headers.map((column) => (
    //             <StyledTableCell align="left" {...column.getHeaderProps()}>
    //               {column.render("Header")}
    //             </StyledTableCell>
    //           ))}
    //         </StyledTableRow>
    //       ))}
    //     </TableHead>
    //     <TableBody {...getTableBodyProps()}>
    //       {rows.length === 0 ? (
    //         <StyledTableRow>
    //           <StyledTableCell align="center" colSpan={8} key="data null">
    //             <h5>No Data Available!</h5>
    //           </StyledTableCell>
    //         </StyledTableRow>
    //       ) : null}
    //       {rows.map((row, i) => {
    //         prepareRow(row);
    //         return (
    //           <StyledTableRow {...row.getRowProps()}>
    //             {row.cells.map((cell) => {
    //               return (
    //                 <StyledTableCell align="left" key={i}>
    //                   <span>{cell.value}</span>
    //                 </StyledTableCell>
    //               );
    //             })}
    //           </StyledTableRow>
    //         );
    //       })}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
  );
}

function InstanceUsage() {
  const commonClasses = commonStyles();

  return (
    < Query query={InstanceUse} >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <Grid style={{ marginTop: "2%" }} container justify="space-evenly" alignItems="center">
              <Grid item xs={12}>
                <h5 className={commonClasses.title}>Instance Usage</h5>
              </Grid>
              <Grid container item xs={12}>
                <div style={{ marginTop: "1%", width: '100%' }}>
                  <TableSkeleton />
                </div>
              </Grid>
            </Grid>
          );
        if (error) {
          return (
            <div style={{ margin: "2%" }}>

            </div>);
        }
        else {
          return (
            <div>
              <InstanceUsageDetails data={data} />
            </div>);
        }
      }
      }
    </Query >


  );
}

export default InstanceUsage;

// importing required packages
import React from 'react';
import Menu from '@material-ui/core/Menu';
import { MenuItem, Button } from '@material-ui/core/';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

// importing required css
import "../styles/css/common.css"
import { commonStyles } from "../styles/css/CommonCSS"


export function DependencyMenu(props) {
    const classes = commonStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <IconButton className="button_no_bg" size="small" disableRipple aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem dense onClick={handleClose}>
            <Button
              href="#!"
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={classes.Plain_Btn}
              startIcon={<FileCopyRoundedIcon style={{ color: "#8C91A6" }} />}
            >
              Clone
              </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              href="#!"
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={classes.Plain_Btn}
              startIcon={<EditRoundedIcon style={{ color: "#8C91A6" }} />}
            >
              Edit
              </Button>
          </MenuItem>
          <MenuItem dense onClick={handleClose}>
            <Button
              size="small"
              variant="text"
              disableElevation
              disableRipple
              className={classes.Plain_Btn}
              startIcon={<DeleteRoundedIcon style={{ color: "#8C91A6" }} />}
            >
              Delete
            </Button>
          </MenuItem>
        </Menu>
      </div>
    )
  }
  
import { createHttpLink } from "apollo-link-http";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { typeDefs } from "../utils/resolvers"

const serverLink = createUploadLink({
    //uri: 'https://qa.lokibots.com:8000/graphql/',
    //uri: 'https://my.lokibots.ai/graphql/',
     //uri:"https://stage.lokibots.ai/graphiql/",
    //	uri: 'https://34.70.203.107:8000/graphql/',
    uri: window.location.origin + '/graphql/',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
});


export const cache = new InMemoryCache()
export const client = new ApolloClient({
  link: authLink.concat(serverLink),
  cache: cache,
  typeDefs,
  opts: {
    credentials: 'same-origin',
  },
});

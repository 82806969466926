
import React from 'react';
import { Grid, Typography, FormHelperText, CircularProgress, LinearProgress, colors, Icon } from '@material-ui/core';
// importing required state variables and other required variables
import { activeNavVar } from '../../utils/cache';
import { AntTab, AntTabs, TabPanel, TabStyle } from "../TabComponent";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import bgImage from "../../styles/images/company_logo_name.png";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//accordian
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//icon
import DehazeRoundedIcon from '@material-ui/icons/DehazeRounded';
//function
import { TableSkeleton } from "../skeleton";

//querries
import { LokipediaAuth } from '../../graphql/queries'
import { useQuery } from 'react-apollo';
import { client } from "../../graphql/apolloClient";


import { SideNavContext } from "../sidemenu";
import ReactPlayer from 'react-player/youtube'


const MyAccordion = withStyles({
    root: {
        "&$expanded": {
            margin: "0",
            BorderTop:"0.5px solid #f0f2f3"
        }
    },
    expanded: {
        margin: "0 auto",
        padding: "0%",
        BorderTop:"0.5px solid #f0f2f3"
    }
},
)(Accordion);
const MyAccordionDetail = withStyles({
    root: {
        "&$expanded": {
            margin: "0"
        }
    },
    expanded: {
        margin:"0%",
        padding:"0%"
    },
    disablePadding: "true",

},
)(AccordionDetails);
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase",
        fontFamily: 'Pangram',
    },
    heading1: {
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: 'Pangram',
    },
    active: {
        background: "#EFEFEF",
        width: '100%',

        borderLeft: '3px solid #ff5c33',
        // color: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',

    },
    title: {
        paddingTop: '0%',
        fontSize: '42px',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        borderLeft: '5px solid #ff5c33',
        paddingLeft: "1%"
    },
    Content: {
        paddingTop: '2%',
        fontSize: '17px',
        fontFamily: 'DM Sans',
        fontWeight: '300',

    },
    formControl: {
        padding: '0% 7% 0% 7%',
        minWidth: "100%",
        maxWidth: "100%"
    },
    headerStyle: {
        marginLeft:"2%",
        paddingLeft: "5%",
        fontSize: '200%',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: '0%',

    },
    SubTitle: {
        marginLeft:"2%",
        paddingLeft: "5%",
        fontSize: '110%',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: '0%',
    },
    list: {
        width: "100%",
    },
    TextMenu: {
        width: '100%',
        display: "flex",
        padding: '1.5%',
        borderBottom: '0.5px solid #f0f2f3',
        transition: 'all 0.2s ease-in',
        '&:hover': {
            // fontStyle: "italic",
            fontWeight: 'bolder',
        }
    }
    ,
    ImageCover: {
        width: "90%",
        height: "300px",
        alignItems: "center",
        paddingLeft: "8%",
        paddingBottom: "4%",


    }
}))

function Lokipedia() {
    const TabStyleClass = TabStyle();
    const Classes = useStyles()
    const { setSideNavFlag } = React.useContext(SideNavContext);
    const [clickMe, setClick] = React.useState("NoClick");
    const [ContentTitle, setContentTitle] = React.useState("");
    const [Title, setTitle] = React.useState("default");
    const [description, setDescription] = React.useState("");
    const [CoverImage, setCoverImage] = React.useState("");
    const [Video, setVideo] = React.useState("");
    const [markTitle, setMark] = React.useState("");
    const { loading, error, data } = useQuery(LokipediaAuth, { client: client },);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);
    const sClick = (data) => {
        setMark(data.title);
        setTitle(data.title)
        //setContentTitle()
        setCoverImage("https://stage.lokibots.ai/media/" + data.coverImage.coverImageFile)
        setDescription(data.description)
        // setVideo()
    }
    function SetData() {
        setTitle(data.allCategory[0].content[0].title)
        setDescription(data.allCategory[0].content[0].description)
        setCoverImage("https://stage.lokibots.ai/media/" + data.allCategory[0].content[0].coverImage.coverImageFile)
    }
    if (data) {
        if (Title === "default")
            SetData()
    }
    return (
        <div style={{ padding: '0% 0% % 0%', height: "100%" }}>
            {
                loading ?
                    <TableSkeleton />
                    :
                    <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={2.5}  style={{ borderRight: " .3rem groove white", overflowY: "hidden", overflowX: "hidden" }} >
                            <br></br>
                            <br></br>
                            < Typography className={Classes.headerStyle}   >Lokipedia</Typography>
                            <div >
                                <Typography className={Classes.SubTitle}>Developing Your Employees</Typography>
                            </div>
                            <br></br>
                            {
                                data.allCategory.map((category, index) => {
                                    return (
                                        <MyAccordion disableGutters={true} spacing={"0%"} expanded={expanded === index} onChange={handleChange(index)}>

                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography className={Classes.heading}>{category.name} </Typography>
                                            </AccordionSummary>
                                            <MyAccordionDetail disablePadding={true} disableGutters={true} divider={true} style={{ width: "100%",position:"relative",marginTop:"-5%",marginBottom:"-3%", }}>
                                                <List style={{ width: "100%",padding:"0%" }}>
                                                    {category.content.map((detail, index) => {
                                                        return (
                                                            <ListItem button disablePadding={true} divider={true} style={{ width: "100%", }} className={markTitle === detail.title ? Classes.active : null} onClick={() => { sClick(detail) }} >
                                                                <ListItemText >
                                                                    {detail.title}</ListItemText>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List>
                                            </MyAccordionDetail>
                                        </MyAccordion>
                                    )
                                })
                            }
                        </Grid>

                        <Grid item xs={9}  style={{ overflowY: "hidden" }} >
                            <div>
                                <Grid container sx={{ height: '100%' }}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <img className={Classes.ImageCover} src={CoverImage} alt={"cover image"} style={{ objectFit: "cover" }} />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={2} >
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={Classes.title}>
                                            {Title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        <center>
                                            <Typography align='justify' className={Classes.Content}>
                                                {description}
                                            </Typography>
                                        </center>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={12}></Grid>
                                    <br></br>
                                    <Grid item xs={2}> </Grid>
                                    <Grid item xs={8}>
                                        <ReactPlayer width={"100%"} controls='true' url='https://www.youtube.com/watch?v=oLaYnT3-K5Q' />
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

export default Lokipedia
// importing Required packages
import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ArrowForward, CloseRounded, EmailRounded } from '@material-ui/icons';
import { Grid, Button, Link, CircularProgress, Backdrop, Card, CardHeader, IconButton, CardContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { default as AlertDialog } from './Alert';
import { useMutation } from 'react-apollo';

// importing required state variables and other required variables
import { isLoggedInVar, activeNavVar, activeMenuVar, sideNavActive } from "../utils/cache";
import { client } from "../graphql/apolloClient";

// importing required graphql queries
import { TokenAuthentication, ForgotPasswordCreateLink } from "../graphql/queries";


// importing the required images
import logo from '../styles/images/company_logo_name.png'

// importing css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    ModalButton: {
        position: 'relative',
        padding: '2.5%',
        fontFamily: "Pangram",
        fontWeight: 'bold',
        margin: '10% 0% 1% 25%',
        width: '50%',
    },
    ModalBody: {
        fontFamily: "DM Sans",
        margin: '2%',
        fontSize: '14px',
    },
    Modalroot: {
        position: 'absolute',
        width: '452px',
        borderRadius: '12px',
        // left: '38%',
        zIndex: '1',
    },
    ModalHeader: {
        background: `linear-gradient(180deg, #8A2387 0%, #701B6D 100%)`,
        position: 'relative',
        padding: '1% 5% 1% 5%',
    },
    ModalHeaderTitle: {
        fontFamily: "Pangram",
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold',
    },
    ModalHeaderSub: {
        padding: '0%',
        marginTop: '-5%',
        fontFamily: "Pangram",
        fontWeight: '200',
        fontSize: '12px',
        color: 'white',
    },
    LoginProgress: {
        color: 'white',
        marginLeft: '14%',
    },
    register: {
        fontFamily: 'Pangram',
        backgroundColor: 'linear-gradient(180deg, #8A2387 0%, #701B6D 100%)',
        backgroundClip: 'text',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    forgot_password: {
        fontFamily: `'Pangram'`,
        fontStyle: 'normal',
        // fontWeight: '500',
        // paddingLeft: '5%',
        fontSize: '11px',

    }, ErrorMsg: {
        textAlign: 'left',
        color: 'red',
        paddingLeft: '-15%',
    },
    Msg: {
        textAlign: 'left',
        paddingLeft: '-15%',
    },
    ForgotPasswordContainer: {
        position: 'relative',
    },
    emailIcon: {
        position: 'absolute',
        marginTop: '3%',
        color: 'purple',
    },
}));


// This is for the modal that pops up when Automate option is clicked
function ForgotPasswordModal(props) {
    const classes = useStyles();
    const [showMessage, setShowMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const urlPrefix = window.location.origin + "/reset-password/";
    const [createForgotPasswordLink, { loading: forgotPasswordLoading }] = useMutation(ForgotPasswordCreateLink, {
        onCompleted(data) {
            console.log("Data is ", data)
            if (data.forgotPasswordVerifyEmail.success) {
                console.log("Password Link sent to the registered email")
                setShowMessage("success")
            } else
                setShowMessage(data.forgotPasswordVerifyEmail.error)
        }
    });

    const functionForgotPassword = async (event) => {
        event.preventDefault();
        console.log("getting link for forgot password")
        try {
            await createForgotPasswordLink({ variables: { email: email, urlPrefix: urlPrefix } })
        } catch (error) {
            console.log("error")
        }
    }

    const CommonClasses = commonStyles();
    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Card className={classes.Modalroot}>
                <CardHeader
                    className={classes.ModalHeader}
                    action={
                        <IconButton onClick={(event) => { props.parentCallbackFunction(false) }} style={{ color: 'white', marginTop: '30%', }} aria-label="settings">
                            <CloseRounded />
                        </IconButton>
                    }
                    title={<p className={classes.ModalHeaderTitle}>Forgot Password</p>}
                    subheader={<p className={classes.ModalHeaderSub}>{props.name}</p>}
                />
                <CardContent>
                    <form onSubmit={(event) => { functionForgotPassword(event) }}>
                        <Typography variant="body2" color="textSecondary" className={classes.ModalBody}>
                            <Grid container>
                                <Grid item md={12} className={classes.ForgotPasswordContainer}>
                                    <EmailRounded className={classes.emailIcon} />
                                    <input type="email" value={email} onChange={(event) => { setEmail(event.target.value) }} style={{ paddingLeft: '7%', width: '90%', }} required placeholder="Enter you registered email" />
                                </Grid>
                                {showMessage === "success"
                                    ?
                                    <p>
                                        If your email is registered with us, then a link for resetting your password has been sent. Hurry up the link expires in
                                        <b> 1 hour </b>.
                                        Please check in different labels or in Spam folder if you don't see the mail in your inbox.
                                    </p>
                                    :
                                    <p style={{ color: 'red' }}>{showMessage}</p>
                                }
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                className={"gradient_round_btn " + CommonClasses.Gradient_Btn + " " + classes.ModalButton}
                                disabled={forgotPasswordLoading ? true : false}
                                endIcon={forgotPasswordLoading ? <CircularProgress color={"white"} size={21} /> : null}
                            >
                                Submit
                            </Button>
                        </Typography>
                    </form>
                </CardContent>
            </Card>
        </Backdrop>
    )
}


function LoginPage(props) {

    const [getToken, { _, data: loginData, error: loginError }] = useMutation(TokenAuthentication);
    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loginWait, setLoginWait] = React.useState(false);
    const [Message, setLoginMessage] = React.useState(null);
    const [ErrorFlag, setLoginErrorFlag] = React.useState(false);

    const [ForgotPassword, setForgotPassword] = React.useState(Boolean(localStorage.getItem("forgotPasswordFlag")) || false);

    const GetForgotPasswordFlag = ((flag) => {
        setForgotPassword(flag)
    });

    const classes = useStyles();
    const CommonClasses = commonStyles()
    const history = useHistory();
    const registered = localStorage.getItem('registration');

    localStorage.setItem("sideNavActive", false);

    useEffect(() => {
        // Flushing out all the reactive variables
        activeNavVar("MyBots")
        activeMenuVar(null)
        sideNavActive(false)

        // Flushing out the localStorage
        window.localStorage.clear()

        // Flushing out the cash data
        client.clearStore()
    }, [])

    // Here we are handling the token after successful token authentication
    useEffect(() => {
        if (loginData) {
            setLoginWait(false)
            setLoginErrorFlag(false)
            setLoginMessage(null)
            console.log('Token is: ', loginData.tokenAuth.token);
            isLoggedInVar(true)
            localStorage.setItem("login", true)
            localStorage.setItem("token", loginData.tokenAuth.token)
            localStorage.removeItem("forgotPasswordFlag")
            history.replace('/login')
        }
    }, [loginData, history]);

    const LoginSubmit = async (event) => {
        event.preventDefault()

        // Indicating that login authentication is happening
        setLoginWait(true)
        setLoginErrorFlag(false)

        // Here we are authenticating the user and getting the token
        console.log("Authenticating user ", username)
        setLoginMessage("Waiting for Authentication")
        try {
            await getToken({ variables: { username: username, password: password } })
            localStorage.setItem("username", username)
        } catch (error) {
            console.info("loginError ", loginError)
            setLoginErrorFlag(true)
            setLoginWait(false)

            if (error.networkError) {

                console.log("Network ERROR ", error.networkError.statusCode)
                if (error.networkError.statusCode === 404)
                    setLoginMessage("Server not found. Please try again")
                else if (error.networkError.statusCode === 400)
                    setLoginMessage(null)
                else
                    setLoginMessage("Network Error. Please try again")
            }
            else if (error.graphQLErrors) {
                console.log("GraphQL ERROR ", error.graphQLErrors[0].message)
                setLoginMessage(error.graphQLErrors[0].message)
            } else
                if (loginError) {
                    console.log("login error ", loginError.graphQLErrors + " " + loginError.networkError)
                    setLoginErrorFlag(true)
                    console.error("There was some error in authentication")
                    setLoginWait(false)
                    setLoginMessage("Authentication Error. Please try again.")
                    return (
                        <div>div ERROR </div>
                    )
                }
        }
        console.log("Finished Authentication ", loginData)
    };

    return (
        <React.Fragment>
            <Grid style={{ background: 'white', position: 'absolute', width: '100%', height: '75.5%', marginTop: '-1.5%' }} container>
                <Grid item lg={4} xs={2}>
                </Grid>
                <Grid item lg={4} xs={8} className="login_container">
                    <img className="login_media" src={logo} alt="Company logo"></img>
                    <Grid container>
                        <Grid item xs={12} className="input_container">
                            {registered === "true" ?
                                <AlertDialog
                                    title="Successful"
                                    body="Your registration request is submitted. Please wait for an email from the LokiBots team with further instructions." />
                                : null}
                            <p className="login_header">Login</p>
                        </Grid>
                        <form onSubmit={LoginSubmit}>
                            <Grid item xs={12} className="input_container">
                                <input type="text" style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', padding: '0% 2% 0% 1%', marginBottom: '7%' }} placeholder="User ID" required value={username} onChange={(event) => setUserName(event.target.value)} />
                                <input type="password" style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', padding: '0% 2% 0% 1%', marginBottom: '7%' }} placeholder="Password" required value={password} onChange={(event) => setPassword(event.target.value)} />
                                <Link
                                    style={{ cursor: 'pointer', }}
                                    onClick={(Event) => { setForgotPassword(true); }}
                                    className={"left gradientText " + classes.forgot_password}
                                    underline="none"
                                >
                                    Forgot Password ?
                                </Link>
                            </Grid>
                            <Grid item xs={12} >
                                <br />
                                <text className={"left " + (ErrorFlag === true ? classes.ErrorMsg : classes.Msg)} > {Message ? Message : null} </text>
                            </Grid>
                            <br />
                            <Grid item xs={12} className="input_container">
                                <Button type="submit" disabled={loginWait} variant="contained" className={"login_btn gradient_round_btn " + CommonClasses.Gradient_Btn}>
                                    Login {loginWait ? <CircularProgress className={classes.LoginProgress} size="21px" /> : null}
                                </Button>
                            </Grid>
                            <Grid item xs={12} className="input_container">
                                <Button
                                    variant="text"
                                    color="inherit"
                                    className={classes.register}
                                    endIcon={<ArrowForward style={{ fontSize: 21 }} />}
                                    onClick={(event) => { history.replace("/registration") }}
                                >
                                    Register
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            {ForgotPassword ? <ForgotPasswordModal parentCallbackFunction={GetForgotPasswordFlag} /> : null}
        </React.Fragment>
    );
};

export default withStyles(useStyles, { withTheme: true })(LoginPage);
import { withStyles, Tabs, Tab, Typography, Box, makeStyles } from "@material-ui/core";
import { PropTypes } from 'prop-types';

export const TabStyle = makeStyles((theme) => ({
  MyContainer: {
    // margin: '2%',
    width: 'auto',
  },
  MyTabPanel: {
    width: '100%',
  },
  TabContainer: {
    paddingLeft: '2%',
    paddingRight: '2%',
    flexGrow: 1,

    backgroundColor: theme.palette.background.paper,
  },
  BtnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    // alignItems: 'flex-end',
    // border: '1px solid black',
    // width:'5%',
    marginLeft: '15%',
    '& > *:hover': {
      backgroundColor: 'transparent',
    }
  },

}));

// For the small tabs
export const AntTabs = withStyles({
  indicator: {
    margin: 0,
    padding: 2,
    background: 'linear-gradient(180deg, #8A2387 0%, #701B6D 100%)',
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    bottom: '-10px',
    position: 'relative',
    minWidth: '15%',
    fontFamily: 'Pangram',
    fontStyle: 'normal',
    fontSize: '1em',
    fontWeight: 'bolder',
    textTransform: 'capitalize',
    '&:active': {
      background: 'transparent',
      fontWeight: 'bold',
    },
    color: '#402E3C',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
    '&$selected': {
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      color: 'Purple',
      fontFamily: 'PangramB',

    },
    '&:focus': {
      background: 'transparent',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
import React from 'react';
import { Typography, Grid, } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10%',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    fontFamily: 'DM Sans'
},
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Nodata(props){
    const classes = useStyles();

    return(
        <div className={classes.root}>
        <Grid alignContent="center" alignItems="center" direction="column" container >
            <Grid item xs={12}>
                <div style={{transform: 'scale(4)', color:'purple'}}><FindInPageIcon fontSize='large'  /></div>
            </Grid>
            <Grid item xs={6}>
                <Typography style={{ fontFamily:'DM Sans', paddingTop:'14%'}} variant="h5">{props.message}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography style={{ color:'gray', paddingTop:'1%'}} variant="h8">{props.description}</Typography>
            </Grid>
        </Grid>
        </div>
    );
}
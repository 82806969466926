// importing required packages, icons and material ui components
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { TableCell, TableRow, withStyles, Table, TableBody, TableContainer, Button, Typography} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import '../styles/css/common.css';
import { useMutation } from 'react-apollo';
import { UpdatePassword } from '../graphql/queries';
import { commonStyles } from "../styles/css/CommonCSS";
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';



const StyledTableRow = withStyles((theme) => ({
    root: {
        overflow: 'hidden',

    },
}
))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    root: {
        overflow: 'hidden',
        width: '25%',
    },
}
))(TableCell);



const useStyles = makeStyles((theme) => ({
    email: {
        background: 'purple',
        color: 'white',
        marginLeft: '2%',
        fontFamily: 'DM Sans',
        '&:hover': {
            background: 'purple',
            color: 'white',
        },
    },

    cell: {
        'width': '85%',
    },
    table: {
        tableLayout: 'fixed',
        width: '92%',
        textAlign: 'center',
        marginLeft: '7%',
        overflow: 'hidden',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        borderBottom: 'none',
    },
    heading: {
        fontFamily: 'Pangram',
        fontWeight: "bold",
        float: 'left',
        color: 'purple',
        borderBottom: '3px solid purple',
        textDecorationColor: 'purple',
        width: '10%',
        textAlign: 'center',
    },
    subheads: {
        fontSize: '16px',
        fontFamily: 'DM Sans',
    },
    edit: {
        textAlign: 'right',
        float: 'right',
        color: 'purple',
        border: 'none',
        marginTop: '3%',
        marginRight: '0%',
        fontSize: '14px',
        fontFamily: 'Pangram',
        fontWeight: "bold",
        textDecorationColor: 'purple',
        width: '15%',
        fontVariant: 'normal',
        fontStyle: 'normal',
        textTransform: 'none'

    },
    table_action: {
        margin: '2%',
        ">h5": {
            fontFamily: 'Pangram',
            fontWeight: 'bolder',
        },
    },
    body: {

        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
    },

}));

const PswdNotificationsTable = ({ data }) => {
    const [Message, setMessage] = React.useState(null);
    const [EditValue, SetValue] = React.useState(false);
    const [Show, SetShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("");
    const handleChange = (newValue) => {
        SetValue(newValue);
        SetShow(newValue);
    };
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
    });
    const handleSwitchChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    function close(oldpswd, newpswd) {
        if (oldpswd === 0 || newpswd === 0) {
            setMessage("Error:occur! Try Again Later");
        }
        else {
            setMessage(null);
        }
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const classes = useStyles();
    const commonClasses = commonStyles()
    const [updateUserInfo, { loading: PswdUpdateLoad }] = useMutation(UpdatePassword, {
        onCompleted(obj){
            console.log("status: ",obj.updateUserInfo.success);
            setSuccess(obj.updateUserInfo.success);
            setErrMsg(obj.updateUserInfo.error);
        }
    });
    let oldPassword, newPassword;
    return (

        <React.Fragment>
            <h4 className={commonClasses.title}>Passwords & Notifications</h4>
            <div className={classes.table_action}>
                <h6 className={classes.heading}> Password</h6>
                <Button  className={classes.edit + " button_no_bg"} onClick={() => handleChange(true)}> Edit&nbsp;{<EditIcon style={{ marginTop: '-2px', width: '18px', height: '18px' }} />}  </Button>
                <TableContainer component={Paper}>
                    {PswdUpdateLoad ? <LinearProgress /> : null}
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell>
                                    <Typography className={classes.subheads}>{Show ? "Update Password" : "Current Password"} </Typography>
                                </StyledTableCell>
                                <TableCell className={classes.body}>
                                    {
                                        EditValue
                                            ?
                                            <div>
                                                <form
                                                    onSubmit={async (e) => {
                                                        handleChange(false)
                                                        e.preventDefault();
                                                        try {
                                                            await updateUserInfo({ variables: { oldPassword: oldPassword.value, newPassword: newPassword.value } })
                                                            close(oldPassword.value, newPassword.value)
                                                        }
                                                        catch (error) {
                                                            close(0)
                                                        }
                                                        setOpen(true);
                                                    }}>
                                                    <input required type='password' ref={value => oldPassword = value} placeholder="Enter Old Password" id='oldpswd' />
                                                    <Tooltip title="Password must contain 8 characters(atleast one number and special character)">
                                                        <input required type='password' ref={value => newPassword = value} placeholder="Enter New Password" id='newpswd' />
                                                    </Tooltip>
                                                    <Button type='submit' className={[classes.email, "button_no_bg", "button_gray_bg"].join(' ')} > Save </Button>
                                                    <Button className={[classes.email, "button_no_bg", "button_gray_bg"].join(' ')} onClick={() => handleChange(false)}> Cancel</Button>
                                                </form>
                                            </div>

                                            :
                                            <div>
                                                <span> &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>
                                            </div>
                                    }
                                </TableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                    <h6 className={classes.heading}> Notifications </h6>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell className={classes.cell}>
                                    <Typography className={classes.subheads}> Email when my bot is published </Typography>
                                </StyledTableCell>
                                <TableCell style={{ width: '10%' }} className={classes.body}>
                                    <div class="toogle switch switch-dropdown">
                                        <label>
                                            <input className={classes.toggle} checked={state.checkedA} onChange={handleSwitchChange} name="checkedA" type="checkbox"></input>
                                            <span class="lever"></span>
                                        </label>
                                    </div>

                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell className={classes.cell}>
                                    <Typography className={classes.subheads}> Email weekly audit logs </Typography>
                                </StyledTableCell>
                                <TableCell className={classes.body}>
                                    <div class="toogle switch switch-dropdown">
                                        <label>
                                            <input checked={state.checkedB} onChange={handleSwitchChange} name="checkedB" type="checkbox"></input>
                                            <span class="lever"></span>
                                        </label>
                                    </div>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell className={classes.cell}>
                                    <Typography className={classes.subheads}> Email error messages in bot execution </Typography>
                                </StyledTableCell>
                                <TableCell className={classes.body}>
                                    <div class="toogle switch switch-dropdown">
                                        <label>
                                            <input checked={state.checkedC} onChange={handleSwitchChange} name="checkedC" type="checkbox"></input>
                                            <span class="lever"></span>
                                        </label>
                                    </div>
                                </TableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }} open={open} autoHideDuration={6000} onClose={handleClose}>
                    {success ? <Alert onClose={handleClose} severity="success">
                            Password has been updated successfully!
                        </Alert>:
                        <Alert onClose={handleClose} severity="error">
                        Something went wrong! Error Message: {errMsg}
                    </Alert> 
                        }
                </Snackbar>
            </div>
        </React.Fragment>
    );
}

function PswdNotifications() {
    return (
        <div>
            <PswdNotificationsTable />
        </div>
    );
}

export default PswdNotifications;

// importing required packages
import { Button } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import React, { useContext } from "react";
import { NewDependency } from "./CreateBotAddTaskDependency";
import { Task } from "./CreateBotContext"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    AddNewTask: {
      background: '#ebe6e6',
      borderRadius: '0px 0px 7px 7px',
      padding: '1%',
      fontSize: '12px',
      textTransform: 'capitalize',
      color: 'purple',
    },
}))
  
const AddNewButton = (props) => {
    const classes = useStyles()  
    const { tasks, addTask } = useContext(Task);
    const { botId } = props 
    
  return (
      <div className="form-main">
        {props.context !== 0 && (
            tasks.map((task) => {
              return (
                <NewDependency TasksList={props.TasksList} Conditions={props.Conditions} key={task.id} i={tasks.length + 1} id={task.id} />
              );
            })
        )
        }
        <Button
            startIcon={<AddRounded />}
            fullWidth
            className={classes.AddNewTask+" button_gray_bg"}
            onClick={(event) => addTask({ botId })} 
        >
            {props.text}
        </Button>
      </div>
    );
  };
  
  export default AddNewButton;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import { TableSkeleton } from "./skeleton";
import { useTable } from "react-table";
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


import IconButton from '@material-ui/core/IconButton';
import { CloseRounded } from '@material-ui/icons';
import { CircularProgress, Button, Typography } from '@material-ui/core/';


// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { ManageBotsColumn } from "./Column";
import { TabStyle } from "./TabComponent";
import { AddRounded } from '@material-ui/icons'
import { commonStyles } from '../styles/css/CommonCSS';

// importing required queries/mutation
import { useQuery, useMutation } from 'react-apollo';
import { ManageBotsData, PublishBotGlobal, PublishBotOrg, CreateApp, CreateProcess } from "../graphql/queries";

// importing required variables
import { client } from "../graphql/apolloClient";


const useStyles = makeStyles({
  paper: {
    width: '100%',
    margin: 'auto',
    borderRadius: '12px',
  },
  size: {
    fontSize: '14px',
  },
  table_action: {
    paddingLeft: '2%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
});

function ManageBotsDetails(props) {
  const classes = useStyles();
  const TabStyleClass = TabStyle();
  const CommonClasses = commonStyles();

  const data = props.data.totalOrganizationPublishedBots;
  const columns = React.useMemo(() => ManageBotsColumn, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const [openDialog, setOpendialog] = React.useState(false);
  const [PublishBottoGlobal] = useMutation(PublishBotGlobal);
  const [PublishBottoOrg] = useMutation(PublishBotOrg);
  const [CreateProcessTag, { loading: ProcessTagLoading }] = useMutation(CreateProcess);
  const [CreateAppsUsed, { loading: AppsUsedLoading }] = useMutation(CreateApp);
  const [checkwait, setWait] = React.useState(false);
  const [addStr, setAddStr] = React.useState("");
  const [error, setError] = React.useState(false);
  const [submitStr, setSubmitStr] = React.useState("");
  const [addName, setAddName] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);


  const handleSwitchChange = async (event) => {
    setWait(true);
    try {
      if (event.target.name === "global") {
        if (event.target.checked) { await PublishBottoGlobal({ variables: { botid: parseInt(event.target.id, 10), global: true } }) }
        else { await PublishBottoGlobal({ variables: { botid: parseInt(event.target.id, 10), global: false } }) }
      }
      else if (event.target.name === "organization") {
        if (event.target.checked) { await PublishBottoOrg({ variables: { botid: parseInt(event.target.id, 10), org: true } }) }
        else { await PublishBottoOrg({ variables: { botid: parseInt(event.target.id, 10), org: false } }) }
      }
      console.log("Success")
      props.Refetch();
      setWait(false);
    }
    catch (error) {
      console.log("Error")
      console.log(error)
      setWait(false);
    }
  };
  const handleClose = () => {
    setOpendialog(false);
    setAddName("")
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleSnackClose = (event) => {
    setOpenSnack(false);
  };
  const handlesubmit = async () => {
    try {
      if (submitStr === "Application")
        await CreateAppsUsed({ variables: { appName: addName } })
      if (submitStr === "Process Tag")
        await CreateProcessTag({ variables: { tagName: addName } })
      setOpendialog(false)
      setAddName("")
      setOpenSnack(true);
    }
    catch (error) {
      setError(error)
    }
    return (false)
  }
  const handleAdd = (event) => {
    setAddName(event.target.value)
  }
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundImage: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',
      backgroundClip: 'text',
      color: 'white'

    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseRounded />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const placeHold = `Enter ${submitStr} Name`



  return (
    <React.Fragment>
      <Dialog maxWidth="sm" fullWidth="true" onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography style={{ fontSize: '16px', fontFamily: "Pangram" }}>{addStr}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={(e) => {
            e.preventDefault();
            handlesubmit();
          }
          }>
            <input
              required
              autoFocus
              margin="dense"
              label="Tab Name"
              placeholder={placeHold}
              type="text"
              value={addName}
              onChange={(e) => handleAdd(e)}
              fullWidth
            />
            <DialogActions>
              {(submitStr === "Process Tag" ? ProcessTagLoading : AppsUsedLoading) ? <CircularProgress size="21px" /> : null}
              <Button disabled={submitStr === "Process Tag" ? ProcessTagLoading : AppsUsedLoading} type="submit"
                style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary" >
                Proceed
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} >
        <h4 className={CommonClasses.title}>Manage Bots</h4>
        <div style={{ marginTop: '2%' }} className={classes.table_action}>
          <Button
            endIcon={<AddRounded />}
            className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn} ${classes.Button}`}
            variant="contained"
            onClick={() => { setOpendialog(true); setAddStr("Add new application"); setSubmitStr("Application") }}
            style={{ marginLeft: "-10%" }}>
            New Applications
          </Button>
          < Button
            endIcon={<AddRounded />}
            className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn} ${classes.Button}`}
            variant="contained"
            onClick={() => { setOpendialog(true); setAddStr("Add new process tag"); setSubmitStr("Process Tag") }}
            style={{ marginLeft: "2%" }} >
            New Process Tags
          </Button>
        </div>
      </div>
      <div>
        <br />
        <div className={TabStyleClass.TabContainer}>
          <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" className={classes.table}  {...getTableProps()}>
              <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                {headerGroups.map(headerGroup => (
                  <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <StyledTableCell
                        {...column.getHeaderProps()}
                        align="left" className={classes.size}>
                        {column.render("Header")}
                        <span style={{}}></span>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.length === 0 ?
                  <StyledTableRow >
                    <StyledTableCell colSpan={8} align="center" className={classes.size}>
                      <h5>No Data Available!</h5>
                    </StyledTableCell>
                  </StyledTableRow>
                  : null}
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <StyledTableRow {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        if (cell === row.cells[2]) {
                          return (
                            <StyledTableCell align="left" className={classes.size}>
                              <div className="toggle switch switch-dropdown">
                                {checkwait ?
                                  <CircularProgress size="21px" /> :
                                  <label>
                                    <input className={classes.toggle} checked={cell.value} onChange={handleSwitchChange} id={cell.row.original.id} name="global" type="checkbox"></input>
                                    <span class="lever"></span>
                                  </label>}
                              </div>
                            </StyledTableCell>
                          )
                        }

                        if (cell === row.cells[3]) {
                          return (
                            <StyledTableCell align="left" className={classes.size}>
                              <div className="toggle switch switch-dropdown">
                                {checkwait ?
                                  <CircularProgress size="21px" /> :
                                  <label>
                                    <input className={classes.toggle} checked={cell.value} onChange={handleSwitchChange} id={cell.row.original.id} name="organization" type="checkbox"></input>
                                    <span class="lever"></span>
                                  </label>}
                              </div>
                            </StyledTableCell>
                          )
                        }

                        return (
                          <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </StyledTableCell>);
                      })}
                    </StyledTableRow>
                  );
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          action={

            <IconButton className="button_no_bg" size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>

          }
        >
          {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={handleSnackClose} severity="success">
            {submitStr}  has been added successfully !</Alert>}
        </Snackbar>
      </div>
    </React.Fragment>
  );

}

function ManageBots() {
  const { loading, error, data, refetch } = useQuery(ManageBotsData, { client: client });
  if (loading)
    return (
      <TableSkeleton />
    );
  if (error)
    return (
      <div>
        <h5> Please Try Again! </h5>
      </div>);
  if (data)
    return (
      <div>
        <ManageBotsDetails data={data} Refetch={refetch} />
      </div>
    );
}

export default ManageBots;

// importing required packages
import React, { useContext } from "react";
import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//importing required pages/ components
import { Task } from "./CreateBotContext";
import { useMutation } from 'react-apollo';
import { Task as TaskMutation } from "../../graphql/queries";

// importing required css
import "../../styles/css/fonts.css";
import { CloseRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    NewTaskBtnCancel: {
        height: '60%',
        marginTop: '5%',
        background: '#e0dddc',
        '&:hover': {
            background: '#E94057',
            color: 'white',
        },
    },
    NewTaskBtnSave: {
        height: '60%',
        marginTop: '5%',
        marginRight: '7%',
        background: '#e0dddc',
        '&:hover': {
            background: '#8C91A6',
            color: 'white',
        },
    },
    TaskList: {
        border: '0.5px solid #e0dddc',
        borderBottom: 'none',
        borderRadius: '2px',
        background: 'white',
        padding: '0% 1% 0% 1%',
        fontSize: '12px',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
    },
    Taskinput: {
        borderRight: '25px solid transparent',
    },
    dependencyList: {
        border: '0.5px solid #e0dddc',
        borderBottom: 'none',
        borderRadius: '2px',
        background: 'white',
        padding: '1% 2% 1% 2%',
        fontSize: '12px',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
    },
    Condition: {
        background: '#e0dddc',
        padding: '4%',
        borderRadius: '5px',
    },
    input: {
        paddingRight: '3%',
        width: '100%',
    },
    buttonContainer: {
        display: 'inline-flex'
    }
}));


export const CreateBotNewTask = (props) => {
    const classes = useStyles();
    const { id, description, title, isCreated, bot } = props.task
    const [TaskDataTitle, setTaskTitle] = React.useState(title)
    const [TaskDataDesc, setTaskDesc] = React.useState(description)

    const { removeTask, editTask, setTaskEditable } = useContext(Task);
    const [task, { loading: createTaskLoading }] = useMutation(TaskMutation, {
        onCompleted: (data) => {
            editTask({
                title: TaskDataTitle,
                id,
                newId: data.task.task.id,
                description: TaskDataDesc,
                isEdited: false,
            })
        }
    })

    const isLoading = createTaskLoading

    const handleSaveTask = () => {
        isCreated ?
            task({
                variables: {
                    input: {
                        title: TaskDataTitle,
                        description: TaskDataDesc,
                        bot: bot,
                        clientMutationId: ""
                    }
                }
            })
            : task({
                variables: {
                    input: {
                        title: TaskDataTitle,
                        description: TaskDataDesc,
                        bot: bot,
                        id: id,
                        clientMutationId: ""
                    }
                }
            })
    }

    const handleCancelTask = () => {
        isCreated ?
            removeTask(id)
            : setTaskEditable({ id, isCreated: false, isEdited: false })
    }

    return (
        <Grid container className={classes.TaskList}>
            <Grid item xs={5} className={classes.Taskinput}>
                <input
                    type="text"
                    value={TaskDataTitle}
                    placeholder="Title"
                    onChange={(event) => { setTaskTitle(event.target.value) }}
                />
            </Grid>
            <Grid item xs={5} className={classes.Taskinput}>
                <input
                    placeholder="Description"
                    value={TaskDataDesc}
                    onChange={(event) => { setTaskDesc(event.target.value) }}
                />
            </Grid>
            <Grid item xs={2} className={classes.buttonContainer}>
                <Button
                    className={`${classes.NewTaskBtnSave} button_no_bg`}
                    onClick={handleSaveTask}
                    disabled={isLoading}
                >
                    Save
                </Button>
                <Button
                    className={`${classes.NewTaskBtnCancel} button_no_bg`}
                    onClick={handleCancelTask}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    )
}


export function NewDependency({ Conditions, TasksList, id }) {
    const classes = useStyles();
    const [DependencyData, setDependencyData] = React.useState(
        {
            dependencyData: {
                "Task": "Select Task",
                "LHS": "Select Task or enter Value",
                "Condition": "Enter condition",
                "RHS": "Select Task or enter Value"
            }
        }
    )
    const { removeTask } = useContext(Task);


    const handleDependencyChange = (event, id) => {
        console.log("id ", id, " val ", event.target.value)
        switch (id) {
            case 0:
                setDependencyData((prevState) => ({ dependencyData: { ...prevState.dependencyData, Task: event.target.value } }));
                break;
            case 1:
                setDependencyData((prevState) => ({ dependencyData: { ...prevState.dependencyData, LHS: event.target.value } }));
                break;
            case 2:
                setDependencyData((prevState) => ({ dependencyData: { ...prevState.dependencyData, Condition: event.target.value } }));
                break;
            case 3:
                setDependencyData((prevState) => ({ dependencyData: { ...prevState.dependencyData, RHS: event.target.value } }));
                break;
            default:
                break;
        }
    }

    return (
        <Grid container className={classes.dependencyList}>
            <Grid item xs={1}>
                Execute
            </Grid>
            <Grid item xs={3}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={DependencyData.dependencyData['Task']}
                    onChange={(event) => { handleDependencyChange(event, 0) }}
                    className={classes.input}
                    displayEmpty
                >
                    <MenuItem value="">Thirty</MenuItem>

                    {TasksList.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={1}>
                <mark className={classes.Condition}>if</mark>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    // value={DependencyData.dependencyData.LHS}
                    select
                    className={classes.input}
                    onChange={(event) => { handleDependencyChange(event, 1) }}
                >
                    {TasksList.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={1}>

                <TextField
                    // value={DependencyData.dependencyData.Condition}
                    select
                    className={classes.input}
                    onChange={(event) => { handleDependencyChange(event, 2) }}
                >
                    {Conditions.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3} style={{ display: 'inline-flex' }}>

                <TextField
                    // value={DependencyData.dependencyData.RHS}
                    select
                    placeholder="Select Task or enter Value"
                    className={classes.input}
                    onChange={(event) => { handleDependencyChange(event, 3) }}
                >
                    {TasksList.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <CloseRounded className="right" onClick={(event) => removeTask(id)} style={{ cursor: 'pointer', }} />
            </Grid>
        </Grid>
    )
}


export default CreateBotNewTask;
// importing required packages
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// importing required components
import { DependencyMenu } from "../DependencyMenu";
import AddNewButton from "./AddNewButton";

// this is used just to create the static data
import "../../styles/css/fonts.css";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    dependenciesContainer: {
    },
    dependencyList: {
        border: '0.5px solid #e0dddc',
        borderBottom: 'none',
        borderRadius: '2px',
        background: 'white',
        padding: '1% 2% 1% 2%',
        fontSize: '14px',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
    },
    Condition: {
        background: '#e0dddc',
        padding: '4%',
        borderRadius: '5px',
    },
    AddNewTask: {
        background: '#ebe6e6',
        borderRadius: '0px 0px 7px 7px',
        padding: '1%',
        fontSize: '12px',
        textTransform: 'capitalize',
        color: 'purple',
    },
}));

const dependencies = [
    {
        "Task1": "Extract Generated Forecast",
        "LHS": "Extract Actuals",
        "Condition": ">",
        "RHS": "Upload Forecast",
    },
    {
        "Task1": "Extract Generated Forecast",
        "LHS": "Extract Actuals",
        "Condition": ">",
        "RHS": "100",
    },
]

const Conditions = [
    {
        value: '0',
        label: '>',
    },
    {
        value: '1',
        label: '<',
    },
    {
        value: '2',
        label: '==',
    },
    {
        value: '3',
        label: '!=',
    },
]
const Tasks = [
    {
        value: 'Extract Values',
        label: 'Extract Values',
    },
    {
        value: 'Upload Forecast',
        label: 'Upload Forecast',
    },
    {
        value: 'Extract Generated Forecast',
        label: 'Extract Generated Forecast',
    },
];


export default function Dependencies() {
    const classes = useStyles();

    return (
        <div className={classes.dependenciesContainer}>
            {dependencies.map((data, index) => {
                return (
                    <Grid container className={classes.dependencyList}>
                        <Grid item xs={1}>
                            Execute
                        </Grid>
                        <Grid item xs={3}>
                            {data.Task1}
                        </Grid>
                        <Grid item xs={1}>
                            <mark className={classes.Condition}>if</mark>
                        </Grid>
                        <Grid item xs={3}>
                            {data.LHS}
                        </Grid>
                        <Grid item xs={1}>
                            <mark className={classes.Condition} >{data.Condition}</mark>
                        </Grid>
                        <Grid item xs={2}>
                            {data.RHS}
                        </Grid>
                        <Grid item xs={1}>
                            <DependencyMenu />
                        </Grid>
                    </Grid>
                )
            })}
            <div>
                <AddNewButton TasksList={Tasks} Conditions={Conditions} context={1} text="Add a new Dependency" />
            </div>
        </div>
    );
}
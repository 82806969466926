// importing Required packages
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, } from '@material-ui/core';
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { TableContainer, Table, TableBody, Button, Paper, TableHead ,DialogContent,DialogActions,FormControl,MenuItem,CircularProgress,Select,IconButton} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import {GlobalTask,OrgTask,ActiveBotId,AddTask} from "../../graphql/queries"
import { useQuery } from '@apollo/client';
import { client } from "../../graphql/apolloClient";
import { useMutation } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import {CloseRounded} from "@material-ui/icons"
// importing required components
import { AntTab, AntTabs, TabPanel, TabStyle } from "../TabComponent";
import { StyledTableCell, StyledTableRow } from "../TableComponent";
import { Globalfilter } from "../GlobalFilter";
import { TableSkeleton } from "../skeleton";

// importing required state variables and other required variables
import { SideNavContext } from "../sidemenu";
import { TaskStoreColumns } from "../Column";
import { Query } from 'react-apollo';
import { CustomModal } from '../modal';
import { default as CustomizedDialogs } from '../Dialog'
import MuiAlert from '@material-ui/lab/Alert';


//importing required queries 

// importing the required images

// importing css
import '../../styles/css/common.css';
import { commonStyles } from "../../styles/css/CommonCSS";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        margin: 'auto',
        borderRadius: '12px',
    },
    fontStyle: {
        fontFamily: 'DM Sans',
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#402E3C',

    },
    chips: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.2),
        },
    },
    title: {
        paddingLeft: '2%',
        fontSize: '150%',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: '0%',
    },
    formControl: {
        padding: '0% 7% 0% 7%',
        minWidth: "100%",
        maxWidth: "100%"
    },

}));

function TaskStoreTable({ data, filterString, tab }) {

    const columns = React.useMemo(() => TaskStoreColumns, []);
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [openDialog,SetDialog] = React.useState(false);
    const [Bot,setBot] = React.useState("");
    const [publishSnack,setPublishSnack ] = React.useState(false);
    const [taskId,setTaskId]= React.useState("");
    const [error,setError]= React.useState(false);
    const [load,setLoad]= React.useState(false);
    const [AddingTask , { error: taskError, data:taskdata,load: processing }] = useMutation(AddTask, {
        onCompleted: (data) => {
          console.log("This is at Add bot on complete ", data)
          if (data.addTaskFromTaskStore.success) {
            setPublishSnack(true);
            handleclose()
          }
          else {
            setLoad(false)
            setError(true)
           
          }
        },
        onError: (err) => {
          setError(true)
        }
      })
      function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter
    } = useTable({
        columns,
        data,
    },
        useGlobalFilter,
        useSortBy,
    );
    const { loading: BotLoading, data: BotList,refetch : BotRefetch } = useQuery(ActiveBotId, { client: client }, {
        onError(data) {
            BotRefetch()
        }
      });
    const handleTabChange = (event) => {
        setBot(event.target.value);  
      };
    const handleAddTask = async (event) => {
        //setError(false)
        console.log("BotID ", Bot, " Taskid= ",taskId )
       // console.log(" This is tab ", props.Tab)
        setLoad(true)
       // setDisable(true)
         tab === "global" ?
           await AddingTask({ variables: { botId: Bot, orgStore: false, taskId: taskId } })
           : await AddingTask({ variables: { botId: Bot, orgStore: true, taskId: taskId } })
      }
      const handleSelect = (Tab) => {
        console.log("I am selecte ", Tab.id, Tab.name)
        setBot(Tab.id)
      }
      const handleclose =()=>{
          setBot("")
          SetDialog(false)

      }
      const handleAdd=(id)=>{
          console.log("I am called inside add",id)
        setTaskId(id);
        SetDialog(true);
      }
    React.useEffect(() => {
        setGlobalFilter(filterString)
    }, [filterString, setGlobalFilter]);

    return (
        <>
        <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" className={classes.table}  {...getTableProps()}>
                <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                    {headerGroups.map(headerGroup => (
                        <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <StyledTableCell  {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps()}>
                                    <div style={{ fontSize: '13px' }}>{column.render("Header")}</div>
                                    <span style={{}}></span>
                                    {column.isSorted ? (column.isSortedDesc ? null : null) : null}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.length === 0
                        ?
                        <StyledTableRow >
                            <StyledTableCell colSpan={8} align="center" key="data null">

                                <h6>  <SearchRoundedIcon style={{ fontSize: "30px", marginBottom: "-0.5%" }} /> No result found</h6>
                                <p>You may want to try using different keywords</p>
                            </StyledTableCell>
                        </StyledTableRow>
                        :
                        null
                    }
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <StyledTableRow className={commonClasses.rowStyle} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if (cell === row.cells[4]) {
                                        return (
                                            <StyledTableCell key={i}>
                                                {/* set task id row.original.id */}
                                                <Button className="button_no_bg" onClick={(event)=>{handleAdd(row.original.id)}}>
                                                    Add
                                                </Button>
                                            </StyledTableCell>)
                                    }
                                    return (
                                        <StyledTableCell {...cell.getCellProps()}>
                                            <div className={commonClasses.BotName}>{cell.render("Cell")}</div>
                                        </StyledTableCell>);
                                })}
                            </StyledTableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <CustomModal title="Add Task  " subheader="" openDialog={openDialog} ParentCallBackFunction={SetDialog} 

render={() => {
  return (
    
      <form onSubmit={(event) => {
        event.preventDefault()
        handleAddTask()

      }}>
        <DialogContent>
          <Typography style={{ fontSize: "20px", fontFamily: 'DM Sans' }}>
            Add Task To Your Bot
          </Typography>

          <DialogActions>
            <div>
              <FormControl style={{ minWidth: 350 }}>
                <Select required
                  //disabled={JSON.parse(localStorage.getItem("disable"))}
                  labelId="demo-customized-select-save-label"
                  value={Bot}
                  onClick={(event) => handleTabChange(event)}
                  style={
                    { overflowY: "auto" }
                  }
                >
                  <MenuItem value="" disabled>
                    Select Bot*
                  </MenuItem>
                  {BotLoading ?
                    <TableSkeleton /> :
                    BotList.userActiveBots.map((tab) => (
                      <MenuItem  value={tab.id} onClick={(event) => handleSelect(tab)} >
                        {tab.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <br />
            {load ? error ? <Button onClick={() => { handleclose() }} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
              Cancel
            </Button> : <CircularProgress size="21px" /> :
              <Button onClick={() => { handleclose() }} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                Cancel
              </Button>}
            <Button type="submit" style={{ opacity: 1, background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
              Add
            </Button>
          </DialogActions>
        </DialogContent>
        {error ?
          <DialogContent>
            <Typography style={{ fontSize: "16px", fontFamily: 'DM Sans', color: "red" }}>
              {"Could not add Task  "}<br></br>

            </Typography>

          </DialogContent>
          : null}
      </form>

    )
}}
/>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ fontFamily: "Pangram" }}
          open={publishSnack}
          autoHideDuration={6000}
          onClose={()=>setPublishSnack(false)}
          action={

            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{setPublishSnack(false)}}>
              <CloseRounded fontSize="small" />
            </IconButton>

          }
        >
           {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={()=>{setPublishSnack(false)}} severity="success">
            Task has been added Successfully!</Alert>}
        </Snackbar>
        </>
    ); 
}

export const TaskStore = (props) => {

    const [TabStoreTab, setValue] = React.useState(parseInt(localStorage.getItem("TaskStoreTab")) || 0);
    const [RefetchFlag, setRefetchFlag] = React.useState(false)
    const [FilterString, setFilterString] = React.useState("");
    const classes = useStyles()
    const TabStyleClass = TabStyle();
    const { setSideNavFlag } = React.useContext(SideNavContext);

    // const [globalfilter, { loading: globalload, data: DataID }] = useLazyQuery(FilterBot,
    //     { variables: { processTags: getProcessTag(), appsUsed: getApp() } },
    // )

    const GetFilterString = ((ChildFilterString) => {
        setFilterString(ChildFilterString)
    });


    const handleChange = (event, newValue) => {
        localStorage.setItem("TabStoreTab", newValue)
        setValue(newValue);
    };

    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);

    return (
        <div>
            <Typography className={TabStyleClass.MyContainer}>
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: "0% 5% 0% 5%" }}>
                        <h4 className={classes.title}>Task Store</h4>
                        <div>
                            <div>
                                <Grid container alignItems="center" justifyContent="flex-end">
                                    <Grid item xs={12} style={{ display: 'flex' }}>
                                        <AntTabs value={TabStoreTab} onChange={handleChange} aria-label="ant example">
                                            <AntTab label={"My Organization"} />
                                            <AntTab label={"Global"} />
                                        </AntTabs>
                                        <div style={{ float: "left", flex: 1 }} >
                                            <Globalfilter parentCallbackFunction={GetFilterString} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <TabPanel style={{ marginTop: '2%' }} value={TabStoreTab} index={0}>
                                <Query query={OrgTask}>{
                                ({ loading, error, data, refetch }) => {
                                    if (loading) {                        
                                    return (
                                        <TableSkeleton />
                                    )
                                    }
                                    if (error) {
                                    return (
                                        <CustomizedDialogs Error={error.message} />
                                    )
                                    }
                                    if (data) {
                                        return(
                                        <TaskStoreTable tab={"org"} data={data.organizationalTasks} filterString={FilterString} />
                                        )
                                    }                                                                    
                                }}
                                </Query>
                            </TabPanel>

                            <TabPanel style={{ marginTop: '2%' }} value={TabStoreTab} index={1}>
                            <Query query={GlobalTask}>{
                                ({ loading, error, data, refetch }) => {
                                    if (loading) {                        
                                    return (
                                        <TableSkeleton />
                                    )
                                    }
                                    if (error) {
                                    return (
                                        <CustomizedDialogs Error={error.message} />
                                    )
                                    }
                                    if (data) {
                                        return(
                                            <TaskStoreTable tab={"global"} data={data.globalTasks} filterString={FilterString} />
                                        )
                                    }                                                                    
                                }}
                                </Query>
                            </TabPanel>
                        </div>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    )


}


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Done from '@material-ui/icons/Done';
import SpeedDial from "@material-ui/lab/SpeedDial"
import EditIcon from '@material-ui/icons/Edit';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import Modal from "@material-ui/core/Modal"
import PreviewSubscriptionChanges from "./PreviewSubscriptionChanges"

import { Query } from 'react-apollo';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { green, pink } from '@material-ui/core/colors';
import { commonStyles } from '../styles/css/CommonCSS';
import { CustomModal } from './modal';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { SubscriptionData, CancelSubscription } from "../graphql/queries";
import { useMutation } from "react-apollo";

import { StyledTableCell, StyledTableRow } from './TableComponent'
import { TableSkeleton } from './skeleton';
import { Button, CircularProgress } from '@material-ui/core';

var mockSubscriptions = [
  {
    name: "Starter Pack",
    id: "starter-pack",
    isActive: false,
    billed: "Monthly",
    planAmount: 200
  },
  {
    name: "Team",
    id: "team",
    isActive: false,
    billed: "Monthly",
    planAmount: 500
  },
  {
    name: "Enterprise",
    id: "enterprise",
    isActive: false,
    billed: "Monthly",
    planAmount: 1000
  }
]

const useStyles = makeStyles({
  paper: {
    width: '75%',
    margin: 'auto',
    borderRadius: '12px',
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  pink: {
    color: '#fff',
    backgroundColor: pink[500],
  },
  subscriptionWrapper: {
    padding: "1%"
  },
  boldWrapper: {
    fontWeight: "bold"
  },
  activeWrapper: {
    borderRadius: "4px",
    border: "2px solid #8A2387"
  }
});

const actions = [
  { icon: <PersonIcon color="primary" />, name: 'Individual Monthly', planid: "starter-pack-monthly" },
  { icon: <PersonIcon color="secondary" />, name: 'Individual Yearly', planid: "starter-pack-yearly" },
  { icon: <GroupIcon color="secondary" />, name: 'Team Yearly', planid: "team-monthly" },
  { icon: <BusinessIcon color="primary" />, name: 'Enterprise Monthly', planid: "team-yearly" },
  { icon: <BusinessIcon color="secondary" />, name: 'Enterprise Yearly', planid: "starter-pack-monthly" },
];

const useSpeedDialStyles = makeStyles({
  speedDial: {
    position: 'relative',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: "20px",
      right: "20px",
    },
  }
})
function Subscription({ id, name, isActive, billed, planAmount, plan, planAmt }) {
  const classes = useStyles();
  var status = plan.includes(id)
  var bill = plan.includes("monthly")
  if (bill)
    billed = "Monthly"
  else
    billed = "Yearly"
  console.log("status: ", status)
  if (status)
    isActive = true
  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" className={[classes.subscriptionWrapper, isActive && classes.activeWrapper]} >
        <Grid item xs={2}>
          {status ?
            <Avatar className={classes.green}>
              <TouchAppIcon />
            </Avatar>
            :
            <Avatar className={classes.pink}>
              <TouchAppIcon />
            </Avatar>
          }
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.boldWrapper} variant="body1">
            {name}
          </Typography>
          <Typography variant="body2">
            <Typography className={classes.boldWrapper} variant="body2" display="inline">Status: </Typography>
            {isActive ? "Active" : "Inactive"}
          </Typography>
          <Typography variant="body2">
            <Typography className={classes.boldWrapper} variant="body2" display="inline">Billed: </Typography>
            {isActive ? billed : "Monthly / Yearly"}
          </Typography>
          {isActive ? <Typography weight="bold" variant="body2">
            <Typography className={classes.boldWrapper} variant="body2" display="inline">Plan Amount: </Typography>
            {planAmt.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </Typography> : null}
        </Grid>
        <Grid item xs={1}>
          {isActive && <Done />}
        </Grid>
      </Grid>
    </>
  )
}

function SubscriptionsData(props) {
  const data = props.subData
  console.log("data sub: ", data.userDetails.additionalInfo.user.subscriptionDetails.plan)
  var activePlan = data.userDetails.additionalInfo.user.subscriptionDetails.plan
  var planAmt = data.userDetails.additionalInfo.user.subscriptionDetails.planPrice
  const classes = useStyles();
  const speedDialClasses = useSpeedDialStyles()
  const [plan, setPlan] = React.useState()
  const [open, setOpen] = React.useState(false);

  const [openPreview, setOpenPreview] = React.useState()
  const [openCancel, setOpenCancel] = React.useState(false)
  const [cancelSuccess, setSuccess] = React.useState(false)
  const [openSpeedDial, setOpenSpeedDial] = React.useState()
  const [errMsg, setErrMsg] = React.useState("")
  const [CancelSub, { loading: cancelLoad, error: cancelError }] = useMutation(CancelSubscription,
    {
      onCompleted(data) {
        const success = data.cancelSubscription.success
        console.log("cancel sub: ", success)
        setSuccess(!success)
        setErrMsg(data.cancelSubscription.error)
        setOpenCancel(false)
        setOpen(true)

      }
    });
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const CancelSubs = async () => {
    try {
      await CancelSub();
    }
    catch (e) {
      console.log("cancel subscription errors: ", e)
    }
  }
  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleOpenPreview = (planid) => {
    setOpenPreview(true);
    console.log("planid: ", planid)
    setPlan(planid)
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleCancel = () => {
    console.log("in cancel")
    setOpenCancel(true);
  };

  React.useEffect(() => console.log(openPreview), [openPreview])
  function cancel() {
    try {
      CancelSub({})
    }
    catch (e) {
      console.log("Cancel Subscription error")
    }
  }
  return (
    <>
      <TableContainer className={classes.paper} component={Paper}>
        <Table className={classes.table}>
          <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042' }} >
            <StyledTableRow>
              <StyledTableCell align="left">Subscription Details</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {mockSubscriptions.map((subscription) => (
              <StyledTableRow key={subscription.name}>
                <Subscription {...subscription} plan={activePlan} planAmt={planAmt} />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SpeedDial
        ariaLabel="Change subscription plan"
        className={speedDialClasses.speedDial}
        icon={<EditIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        direction={"left"}
      >
        {/* {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              onClick={handleOpenPreview}
              tooltipTitle={action.name}
            />
          ))} */}
        <SpeedDialAction
          key="starter-pack-monthly" icon={<PersonIcon color="primary" />}
          onClick={() => handleOpenPreview("starter-pack-monthly")}
          tooltipTitle="starter-pack-monthly" />
        <SpeedDialAction
          key="starter-pack-yearly" icon={<PersonIcon color="secondary" />}
          onClick={() => handleOpenPreview("starter-pack-yearly")}
          tooltipTitle="starter-pack-yearly" />
        <SpeedDialAction
          key="team-monthly" icon={<GroupIcon color="primary" />}
          onClick={() => handleOpenPreview("team-monthly")}
          tooltipTitle="team-monthly" />
        <SpeedDialAction
          key="team-yearly" icon={<GroupIcon color="secondary" />}
          onClick={() => handleOpenPreview("team-yearly")}
          tooltipTitle="team-yearly" />
        <SpeedDialAction
          key="enterprise-monthly" icon={<BusinessIcon color="primary" />}
          onClick={() => handleOpenPreview("enterprise-monthly")}
          tooltipTitle="enterprise-monthly" />
        <SpeedDialAction
          key="enterprise-yearly-revise" icon={<BusinessIcon color="secondary" />}
          onClick={() => handleOpenPreview("enterprise-yearly-revise")}
          tooltipTitle="enterprise-yearly" />
      </SpeedDial>

      <Modal
        open={openPreview}
        onClose={handleClosePreview}
      >
        <PreviewSubscriptionChanges
          invoice1={mockSubscriptions[0]}
          invoice2={mockSubscriptions[1]}
          planid={plan}
          isActive={false}
          handleClose={handleClosePreview}
        />
      </Modal>
      <CustomModal title="Cancel Subscription" subheader="" openDialog={openCancel} ParentCallBackFunction={setOpenCancel}
        render={() => {
          return (
            <>
              <div style={{ fontFamily: 'DM Sans' }}>Do you want to cancel your subscription</div>

              {cancelLoad ? <CircularProgress style={{ marginLeft: "85%" }} /> : <Button onClick={CancelSubs} type="submit" style={{ marginLeft: "85%", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                Confirm
              </Button>}
            </>
          )
        }}
      />
      <div style={{ marginLeft: "155px" }}>
        <Button style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram', }}
          color="primary" onClick={handleCancel}>Cancel Subscription</Button>
      </div>
      {<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open} autoHideDuration={6000} onClose={handleClose}>
        {cancelSuccess ? <Alert severity="error">{errMsg}</Alert> : <><Alert severity="success">Successfully cancelled subscription</Alert></>}
      </Snackbar>}

    </>
  );
}

export default function SubscriptionDetails() {
  const plans = ['starter-pack-monthly', 'starter-pack-yearly', 'team-monthly', 'team-yearly', 'enterprise-monthly', 'enterprise-yearly-revise'];
  const commonClasses = commonStyles();



  return (
    < Query query={SubscriptionData} >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div>
              <Grid item xs={12} >
                <h5 className={commonClasses.title}>Subscription Details</h5>
              </Grid>
              <TableSkeleton />
            </div >
          );
        if (error) {
          return (
            <div style={{ margin: "2%" }}>

            </div>);
        }
        else {
          return (
            <div>
              <SubscriptionsData subData={data} />
            </div>);
        }
      }
      }
    </Query >
  );
}
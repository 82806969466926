import { TableCell, TableRow, withStyles,makeStyles } from "@material-ui/core";

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#440042',
        color: theme.palette.common.white,
        fontSize: '12px',
        fontWeight: 'lighter',
        paddingLeft: 7,
        fontFamily: 'DM Sans , Pangram',
        fontStyle: 'normal',
        whiteSpace: 'nowrap',
    },
    body: {
        fontSize: '12px',
        padding: '5px 0px 3px 2px',
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  
  
    Header: {
      
      textAlign: 'left',
      overflow: 'hidden',
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      textTransform: 'capitalize',
      borderBottom: 'none',
    },
    heading: {
      textAlign: 'left',
      color: 'purple',
      marginLeft: '1%',
      marginTop: '3%',
      textDecorationColor: 'purple',
      fontWeight: '500',
    },
    body: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      textTransform: 'capitalize'
    },
    title: {
      fontSize: '32px',
      fontWeight: 'normal',
    },
  }));


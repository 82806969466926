// importing required packages
import React from 'react';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiPaperclip } from "react-icons/fi";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import validator from 'validator'
import { Query } from 'react-apollo';
import { default as CustomizedDialogs } from '../Dialog';
//import { Chargebee } from '@chargebee/chargebee-js-react-wrapper';

// importing require components
import { TabStyle } from "../TabComponent"

// this is used just to create the static data
import { commonStyles } from "../../styles/css/CommonCSS";
import { useMutation } from 'react-apollo';

import { FetchUserData, SendFeedback } from '../../graphql/queries';
import AlertDialogSlide from '../../components/msgSent'

const useStyles = makeStyles((theme) => ({
    CreateBotBtn: {
        width: '170px',
        height: '40px',
    },
    sizeAvatar: {
        height: theme.spacing(10),
        width: 'auto',
        opacity: 0.9,
        background: 'light gray',
    },
    text: {
        fontSize: '1.2em',
        fontFamily: 'DM Sans'
    },
    attach: {
        fontSize: '1.1em',
        color: 'purple',
        fontFamily: 'DM Sans',
    },
    contactContainer: {
        margin: '2%',
    },
}));

function Contact(props) {
    const email = (props.data ? JSON.stringify(props.data.userDetails.email) : "");
    const name = (props.data ? JSON.stringify(props.data.userDetails.username) : "");
    const classes = useStyles();
    const CommonClasses = commonStyles();
    const TabStyleClass = TabStyle();
    const [username, setUserName] = React.useState(name.substring(1, name.length - 1));
    const [mail, setMail] = React.useState(email.substring(1, email.length - 1));
    const [message, setMessage] = React.useState("")
    const [emailError, setEmailError] = React.useState('')
    const [modalFlag, setmodalFlag] = React.useState(false);
    const [imgFlag, setImgFlag] = React.useState(false);
    const [image, setImage] = React.useState();
    const [SendFeedBack] = useMutation(SendFeedback);
    const [open, setOpen] = React.useState(false);

    const [imgFile, setImgFile] = React.useState();
    const ImgUploadHandler = (event) => {
        let img = event.target.files[0];
        if (!img)
            return
        setImage(URL.createObjectURL(img));
        setImgFlag(true);
        setImgFile(event.target.files[0]);
    }
    const handleClose = () => {
        setOpen(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const validateEmail = (e) => {
        var email = e.target.value
        if (validator.isEmail(email)) {
            setEmailError(' ')
        } else {
            setEmailError('Enter valid Email!')
        }
    }
    function closeModal(flag) {
        setmodalFlag(flag)
    }
    
    return (
        <Typography>
            <h3 className={CommonClasses.title}>Contact</h3>
            <Grid className={`${classes.contactContainer} ${TabStyleClass.MyContainer}`} >
                <p className={classes.text}>Let us know what you need help with!</p> <br></br>
                {modalFlag ? <AlertDialogSlide parentcallback={closeModal} /> : null}
                <TableContainer >
                    <form
                        onSubmit={async (e) => {
                            e.preventDefault();

                            try {
                                await SendFeedBack({
                                    variables: {
                                        email: mail,
                                        message: message,
                                        subject: '',
                                        attachment: imgFile,

                                    }
                                })
                                setOpen(true)
                                setImgFlag(false)
                                setMessage("")
                                setmodalFlag(!modalFlag)
                            }
                            catch (error) {

                                return
                            }
                        }}>
                        <Table className={classes.table} aria-label="simple table" style={{ width: 750 }}>
                            <TableBody >
                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                        <h6 className={classes.text}>Name:</h6>
                                    </TableCell>
                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                        <TextField required width="50%" id="outlined-full-width" placeholder="Enter Name" value={username}
                                            variant="outlined" rows={1} multiline name="mailid" fullWidth disabled onChange={(event) => setUserName(event.target.value)} />
                                    </TableCell>
                                </TableRow>

                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                        <h6 className={classes.text}>Email Address:</h6>
                                    </TableCell>
                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                        <TextField type="email" width="50%" id="outlined-full-width" value={mail}
                                            variant="outlined" rows={1} multiline name="mailid" fullWidth onChange={(event) => { validateEmail(event); setMail(event.target.value); }} />
                                        <p style={{
                                            fontWeight: 'bold',
                                            color: 'purple',
                                        }}>{emailError}</p>
                                    </TableCell>
                                </TableRow>

                                <TableRow style={{ borderBottom: "none" }} >
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }} >
                                        <h6 className={classes.text}>Message*:</h6>
                                    </TableCell>
                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                        <TextField required id="outlined-multiline-static" placeholder="Enter your Message:" variant="outlined" value={message} onChange={(event) => { setMessage(event.target.value) }} rows={10} multiline name="message" fullWidth />
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>

                                    </TableCell>
                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                        {imgFlag ?
                                            <img className={classes.sizeAvatar} src={image} alt="avatarImage" />
                                            : <>
                                                <input accept="image/*" style={{ display: 'none' }} className={classes.input} onChange={ImgUploadHandler} id="icon-button-file" type="file" />
                                                <label htmlFor="icon-button-file" style={{ cursor: "pointer" }}>
                                                    <IconButton className={classes.attach} aria-label="upload picture" component="span">
                                                        <FiPaperclip />
                                                    </IconButton>
                                                    Attach a Screenshot
                                                </label></>}
                                    </TableCell>
                                </TableRow>

                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>

                                    </TableCell>
                                    <TableCell align="right" style={{ borderBottom: "none" }}>
                                        <Button
                                            type="submit"// onClick= {(event) => {setmodalFlag(!modalFlag); setMessage("") }}
                                            className={"gradient_round_btn " + CommonClasses.Gradient_Btn} style={{ color: 'white' }} variant="contained" >
                                            Submit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                </TableContainer>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Feedback has been submitted successfully !
                </Alert>
            </Snackbar>
        </Typography>
    );

}
const fetchData = () => {
    const commonClasses = commonStyles()
    return (
        < Query query={FetchUserData} >
            {({ loading, error, data }) => {
                if (loading)
                    return (
                        <div>
                            <h3 className={commonClasses.title}>Contact</h3>
                            <div>
                                <div className="valign-wrapper">
                                    <p size="25px" style={{ margin: "2%" }} /> Loading ...
                                </div>
                            </div>
                        </div>
                    );
                if (error) {
                    return (
                        <div>
                            <h3 className={commonClasses.title}>Contact</h3>
                            <div>
                                <div style={{ margin: '2%' }}>
                                    <CustomizedDialogs Error={error.message} />
                                    <h5> Graphql Query Error ! Try again later </h5>
                                    <Contact />
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    if (data.length === 0) {
                        return (
                            <div>
                                <h3 className={commonClasses.title}>Contact</h3>
                                <div>
                                    <div>
                                        <h5> No Data Available! </h5>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div>
                                <Contact data={data} />
                            </div>
                        );
                    }
                }
            }
            }
        </Query >
    );
};

export default fetchData;
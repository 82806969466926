// importing required packages
import React from "react";
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles';
import { TableSkeleton } from "./skeleton";
import { commonStyles } from '../styles/css/CommonCSS';
import { Query, useMutation } from 'react-apollo';
import {AddonsList, SubscribeAddons } from "../graphql/queries";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useAddonStyles = makeStyles(() => ({
    addonContainer: {
        minWidth: "170px",
    },
    addonWrapper: {
        padding: "13%",
        paddingBottom: "15%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    isHighLighted: {
        border: "2px solid #8A2387"
    }
}));

const useAddonsStyles = makeStyles(() => ({
    gridContainer: {
        minWidth: "700px",
        paddingTop: "10%",
    },
}));



function Addon({ addonName, isActive, addonPrice, addonId, planId, id}) {
    const classes = useAddonStyles();
    console.log("addonid: ",id);
    const [ subscribeAddons, { loading: AddonLoad }] = useMutation(SubscribeAddons);
    function SubscribeAddon(props){
       // const planId = props.planId;
        //const addonId = props.addonId;
        console.log("planId addonid: ",planId,addonId)
        try{
            subscribeAddons({
                variables: {
                    addonId: id,
                    planId: planId,
                }
            })
        }
        catch(e){
            console.log("addons errors: ",e)
        }
    }
    return (
        <Grid item xs={3}>
            <Paper className={[classes.addonContainer, isActive && classes.isHighLighted]}>
                <div className={classes.addonWrapper} >
                <Typography noWrap variant="body2">{addonName}</Typography>
                
                {/* <Typography noWrap variant="body2">{planId}</Typography> */}
                <Typography noWrap variant="body1">{`${addonPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}/ month`}
                </Typography>
                </div>
                <Divider />
                <Button fullWidth disabled={isActive} onClick={() => SubscribeAddon(id,planId)}>Subscribe</Button>
        </Paper>
      </Grid>
    )
}

function AddonsGrid(props) {
    const classes = useAddonsStyles();
    const commonClasses = commonStyles();


    const data = props.data.subscriptionAddons;
    console.log("addons: ",data)
    const mockData = [
        { name: "Addon 1", isActive: true, price: 1 },
        { name: "Addon 2", isActive: true, price: 10.22 },
        { name: "Addon 3", isActive: false, price: 19120000 },
        { name: "Addon 4", isActive: true, price: 10 },
        { name: "Addon 5", isActive: true, price: 0.99 },
        { name: "Addon 6", isActive: false, price: 10 }
    ]

  return (
    <div className="home">
        <h5 className={commonClasses.title}>Addons</h5>
      <div className="container" style={{marginTop:"-6%"}}>
            <Grid container spacing={5} className={classes.gridContainer}>
                {data.map((addon) => <Addon id={addon.addonId}  {...addon} />)}
            </Grid>
      </div>
    </div>
  );
}

function Addons() {
    const commonClasses = commonStyles();

  return (
    < Query query={AddonsList} >
    {({ loading, error, data }) => {
      if (loading)
      return (
        <Grid style={{marginTop:"2%"}} container justify="space-evenly" alignItems="center">
            <Grid item xs={12}>
                <h5 className={commonClasses.title}>Addons</h5>
            </Grid>
            <Grid container item xs={12}>
                <div style={{marginTop:"1%", width: '100%' }}>
                    <TableSkeleton />
                </div>
            </Grid>
        </Grid>
    );
      if (error) {
        return (
          <div style={{ margin: "2%" }}>
           
          </div>);
      }
      else {
        return (
          <div>
            <AddonsGrid  data={data}/>
          </div>);
      }
    }
    }
  </Query >
     
  );
}

export default Addons;

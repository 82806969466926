// importing required packages, icons and material ui components
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FaGooglePlus, FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserDetails } from "../graphql/queries";
import { Query } from 'react-apollo'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { commonStyles } from "../styles/css/CommonCSS";
import '../styles/css/common.css';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useMutation } from 'react-apollo';
import { default as CustomizedDialogs } from './Dialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { IconButton } from '@material-ui/core';
import { AntTab, AntTabs, TabPanel } from "./TabComponent"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import UndoIcon from '@material-ui/icons/Undo';
import ListItemText from '@material-ui/core/ListItemText';
import { updateUserInfo } from "../graphql/queries";
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import {
  Snackbar, Badge
} from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import banner from "../styles/images/banner.jpg";


const useStyles = makeStyles((theme) => ({
  email: {
    background: 'purple',
    color: 'white',
    marginLeft: '2%',
    '&:hover': {
      background: 'purple',
      color: 'white',
    },
    root: {
      height: '140',
      width: '100'
    },
  },
  list: {
    width: '100rem',
    marginLeft: '1rem',
    marginTop: '1rem',
    fontFamily: 'DM Sans',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    marginLeft: '6%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    marginTop: '30'
  },
  listItemText: {
    fontFamily: 'DM Sans'//Insert your required size
  },
  table: {
    tableLayout: 'fixed',
    width: '90%',
    textAlign: 'center',
    marginLeft: '7%',
    overflow: 'hidden',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderBottom: 'none',
  },
  heading: {
    float: 'left',
    color: 'purple',
    marginLeft: '5%',
    marginTop: '3%',
    borderBottom: '3px solid purple',
    textDecorationColor: 'purple',
    width: '10%',
    textAlign: 'center',
    fontWeight: '500',
  },
  edit: {
    textAlign: 'right',
    float: 'right',
    color: 'purple',
    border: 'none',
    marginTop: '3%',
    marginRight: '5%',
    textDecorationColor: 'purple',
    width: '15%',
    fontVariant: 'normal',
    fontStyle: 'normal',


  },
  table_action: {
    marginLeft: '5%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
  body: {

    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textTransform: 'capitalize'
  },
  input: {
    display: 'none',
  },
}));

const UserTable = ({ data, refetch }) => {
  const uri = window.location.origin;

  const [image, setImage] = React.useState();
  const [updateUserAvt, { loading: uploadLoad, error: uploadError }] = useMutation(updateUserInfo);
  const [BotAvatar, setBotAvatar] = React.useState(uri + data.avatarUrl);
  const [show, SetShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);



  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function PersonalInfo() {
    const lastlogin = new Date(data.lastLogin);
    return (
      <Grid container>
        <Grid items xs={12} sm={12} lg={12}>
          <List className={classes.list} aria-label="mailbox folders">
            <ListItem >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                {`${data.firstName} ${data.lastName}`}
              </ListItemText>
            </ListItem>
            <Divider light variant="fullWidth" />
            <ListItem >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                {data.email}
              </ListItemText>
            </ListItem>
            <Divider variant="fullWidth" />
            <ListItem >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                Last Login: {lastlogin.toLocaleDateString() + " " + lastlogin.toTimeString()}
              </ListItemText>
            </ListItem>
            <Divider variant="fullWidth" />
            <ListItem >
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                Personality
              </ListItemText>
            </ListItem>
            <Divider variant="fullWidth" />
            <ListItem >
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }}>
                Status
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }

  function OrgInfo() {
    return (
      <List className={classes.list} aria-label="mailbox folders">
        <ListItem >
          <ListItemText classes={{ primary: classes.listItemText }}>
            Organization
          </ListItemText>
          <ListItemText classes={{ primary: classes.listItemText }}>
            {data.additionalInfo.organization.name}
          </ListItemText>
        </ListItem>
        <Divider light variant="fullWidth" />
        <ListItem >
          <ListItemText classes={{ primary: classes.listItemText }}>
            Role
          </ListItemText>
          <ListItemText style={{ marginLeft: '23%' }} classes={{ primary: classes.listItemText }}>
            Organization Admin
          </ListItemText>
        </ListItem>
        <Divider variant="fullWidth" />

      </List>
    );
  }

  function BotInfo() {
    return (
      <List className={classes.list} aria-label="mailbox folders">
        <ListItem >
          <ListItemText classes={{ primary: classes.listItemText }}>
            Bots Created
          </ListItemText>
          <ListItemText classes={{ primary: classes.listItemText }}>
            {
              data.bots === null
                ? 0
                : Object.keys(data.bots).length
            }
          </ListItemText>
        </ListItem>
        <Divider light variant="fullWidth" />
        <ListItem >
          <ListItemText classes={{ primary: classes.listItemText }}>
            Bots Published
          </ListItemText>
          <ListItemText style={{ marginLeft: '16.5%' }} classes={{ primary: classes.listItemText }}>
            30 bots, 40 Tasks
          </ListItemText>
        </ListItem>
        <Divider variant="fullWidth" />
        <ListItem >
          <ListItemText style={{ marginLeft: '7%' }} classes={{ primary: classes.listItemText }}>
            Global Bot Store
          </ListItemText>
          <ListItemText style={{ marginLeft: '8%' }} classes={{ primary: classes.listItemText }}>
            10 bots, 20 Tasks
          </ListItemText>
        </ListItem>
        <Divider variant="fullWidth" />
        <ListItem >
          <ListItemText style={{ marginLeft: '7%' }} classes={{ primary: classes.listItemText }}>
            Organization Bot Store
          </ListItemText>
          <ListItemText style={{ marginLeft: '1%' }} classes={{ primary: classes.listItemText }}>
            20 bots, 20 Tasks
          </ListItemText>
        </ListItem>
        <Divider variant="fullWidth" />
      </List>
    );
  }
  function PermissionsInfo() {
    return (
      <List className={classes.list} >
        {/* <ListItemText style={{marginLeft:'7%'}} classes={{primary:classes.listItemText}}>
              Permissions
            </ListItemText>
            <ListItemText style={{marginLeft:'1%'}} classes={{primary:classes.listItemText}}>
                
    </ListItemText>*/}
      </List>
    );
  }
  const ImgUploadHandler = (event) => {

    let img = event.target.files[0];
    if (!img)
      return
    setBotAvatar(URL.createObjectURL(img));
    setImage(event.target.files[0]);
    SetShow(true);
  }
  const UpdateAvt = async (event) => {
    event.preventDefault();
    try {
      await updateUserAvt({
        variables: {
          avatar: image
        }
      });
    }
    catch (error) {
    }
    setOpen(true)
    SetShow(false);
    refetch()
  }
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const classes = useStyles();

  return (
    <>
      <Card style={{ margin: '5% 3% 0% 7%', minHeight: '55vh', height: '90%', width: '80%' }}>
        {uploadLoad
          ?
          <div>
            <LinearProgress style={{ color: "green" }} />
          </div>
          :
          null
        }
        <CardContent>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justify="center"

          >

            <Grid item xs={12} md={3} style={{ paddingTop: '3%' }}>
              <Grid item xs={12} md={12}>

                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}

                  badgeContent={
                    show ? <div style={{ color: 'purple', display: 'flex', marginLeft: '-15%', marginTop: '85%' }}>
                      <Tooltip title="Undo">
                        <Button style={{ color: 'purple' }} className="button_gray_bg" onClick={() => { SetShow(false); setBotAvatar(uri + data.avatarUrl) }}><UndoIcon /></Button>
                      </Tooltip>
                      <Tooltip title="Update">
                        <Button style={{ color: 'purple' }} className="button_gray_bg" onClick={UpdateAvt}><SystemUpdateAltIcon /></Button>
                      </Tooltip>
                    </div>
                      : <>   <input accept="image/*" className={classes.input} onChange={ImgUploadHandler} id="icon-button-file" type="file" />
                        <label htmlFor="icon-button-file">
                          <IconButton style={{ marginTop: '90%', marginLeft: '65%' }} color="primary" aria-label="upload picture" component="span">
                            <Box borderRadius="50%" borderColor="gray" border="2px solid" style={{ background: 'white', position: 'relative', width: '2.3rem', height: '2.3rem' }} >
                              <EditRounded style={{ fontSize: 20, color: 'purple', position: 'absolute', top: '14%', left: '20%' }} />
                            </Box>
                          </IconButton></label></>}
                >
                  <Avatar variant="square" src={BotAvatar} className={classes.large} />
                </Badge>
              </Grid>
              <Grid item xs={12} md={12} style={{ paddingTop: '10%', marginLeft: '6%' }} >
                <IconButton className="button_gray_bg" size="large" style={{ color: '#0077b5' }}>
                  <FaLinkedin style={{ fontSize: 30 }} />
                </IconButton>
                <IconButton className="button_gray_bg" style={{ color: '#db4a39' }}>
                  <FaGooglePlus style={{ fontSize: 30 }} />
                </IconButton>
                <IconButton className="button_gray_bg" size="large" style={{ color: '#1877f2 ' }}>
                  <FaFacebook style={{ fontSize: 30 }} />
                </IconButton>
                <IconButton className="button_gray_bg" size="large" style={{ color: 'purple' }}>
                  <FaInstagram style={{ fontSize: 30 }} />
                </IconButton>
                {uploadError && <p style={{ fontFamily: 'DM Sans', color: 'red' }}>Error : Please try again</p>}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} spacing={1} style={{ alignItems: 'flex-end', justifyContent: 'flex-end', alignContent: 'flex-end', padding: ' 0% 7% 3% 6%', marginTop: '0%', marginLeft: '0%' }}>
              <Grid item xs={12} md={12}>
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                  <AntTab label={"Personal Info"} />
                  <AntTab label={"Organization Info"} />
                  <AntTab label={"Bot Info"} />
                  <AntTab label={"Permissions Info"} />
                </AntTabs>
                <TabPanel value={value} index={0}>
                  <PersonalInfo />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <OrgInfo />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <BotInfo />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <PermissionsInfo />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open} autoHideDuration={6000} onClose={handleClose}
      >
        {uploadError
          ?
          <Alert onClose={handleClose} severity="error">Error! Please try again</Alert>
          :
          <Alert onClose={handleClose} severity="success">
            User avatar has been updated successfully
          </Alert>
        }
      </Snackbar>
    </>
  );
}

function UserInfo() {
  const commonClasses = commonStyles()
  return (
    < Query query={UserDetails} >
      {({ loading, error, data, refetch }) => {
        if (loading)
          return (
            <div>
              <div>
                <h4 className={commonClasses.title}>User Info</h4>
              </div>
              <div className="valign-wrapper">
                <CircularProgress size="25px" style={{ margin: "2%" }} /> Loading data ...
              </div>
            </div>
          );
        if (error) {
          return (
            <div>
              <div>
                <h4 className={commonClasses.title}>User Info</h4>
              </div>
              <div style={{ margin: '2%' }}>
                <CustomizedDialogs Error={error.message} />
                <h5> Error {error.message} ! Try again later </h5>
              </div>
            </div>
          )

        }
        else {

          return (
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: "cover", paddingBottom: '7%' }}>
              <UserTable data={data.userDetails} refetch={refetch} />
            </div>);
        }
      }
      }
    </Query >

  );
}

export default UserInfo;

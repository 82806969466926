// importing required packages
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { EditRounded, DeleteRounded, CloseRounded, CancelRounded, } from '@material-ui/icons';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ListItem from '@material-ui/core/ListItem';
import { Link, ListItemText, ListItemAvatar, Avatar, CircularProgress, Tooltip } from '@material-ui/core/';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import { CgChevronDoubleRight, CgChevronDoubleLeft } from 'react-icons/cg';
import { useLazyQuery } from "react-apollo";
import { useReactiveVar } from '@apollo/client';
import Popover from '@material-ui/core/Popover';
import { FaSave } from "react-icons/fa";
import { FiFolder, FiCheck } from "react-icons/fi";
import { AiFillFolder, AiTwotoneFolderOpen } from "react-icons/ai";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// importing required images
import minilogo from '../styles/images/company_logo.png';
import logoname from '../styles/images/company_logo_name.png';

// importing required css files
import '../styles/css/common.css';
import "../styles/css/CommonCSS";
import { commonStyles } from '../styles/css/CommonCSS';
import '../styles/css/navbar.css';


// importing required other pages (components)
import ProtectedRoute from "./ProtectedRoute";
import { default as ErrorPage } from "./ErrorPage";
import { default as Banking } from "./SideMenuComponents/Banking";
import { default as AuditLogs } from './Audit_Logs';
import { default as LoginPage } from "./Login";
import { default as BotStore } from "./BotStore"
import { default as InteractiveApi } from './InteractiveApi';
import { default as Lokipedia } from './Pages/Lokipedia';
import { default as UserInfo } from './UserInfo';
import { default as Subscriptions } from './Subscriptions';
import { default as Contact } from "./SideMenuComponents/Contact";
import { default as OrganizationInfo } from "./OrganizationInfo";
import { default as OrganizationUsers } from "./OrganizationUsers";
import { default as OrganizationVMs } from "./OrganizationVMs";
import { default as ScheduleBots } from "./ScheduleBots";
import { default as ManageBots } from "./ManageBots";
import { default as ManageUsers } from "./ManageUsers";
import { default as Addons } from "./Addons";
import { default as SubscriptionDetails } from "./SubscriptionDetails"
import { default as PersonalVM } from "./PersonalVM"
import { default as InstanceUsage } from "./InstanceUsage"
import { default as Invoices } from "./Invoices"
import { default as Transactions } from "./Transactions"
import { TaskStore } from "./Pages/TaskStore";

import CreateBotTopSection from './CreateBotTopSection';
import PswdNotifications from './Password&Notifications';
import BotEmptyState from './BotEmptyState';
import EditBotMainScreen from './EditBotMainScreen';
import MySkeleton from "./skeleton";
import { CustomModal } from './modal';


// importing required queries
import { BotTabs, BotTab } from '../graphql/queries'
import { CreateNewTab, RenameBotTab, DeleteBotTab, EditBotTab } from '../graphql/queries';

// importing other required variables
import { isLoggedInVar, activeMenuVar, activeNavVar, sideNavActive, BotCount, BotTabCheck } from "../utils/cache";


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  LinksContainer: {
    position: 'relative',
    width: '100%',
    height: '7%',
    padding: '1.5%',
    borderBottom: '0.5px solid #f0f2f3',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      // fontStyle: "italic",
      fontWeight: 'bolder',
    }
  },
  Links: {
    width: '80%',
    color: 'gray',
    fontFamily: 'DM Sans',
  },
  SectionEditIcon: {
    position: 'relative',
    marginTop: '0%',
    color: 'purple',
    height: '30px',
    width: '30px',
    float: "right",
    paddingRight: "2",
  },
  HideDiv: {
    display: 'none',
  },
  root: {
    display: 'flex',
  },
  SectionAction: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    top: '-130%',
  },
  SectionContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  SectionHeight: {
    height: '95.5%',
    overflowY: "auto",
    overflowX: "hidden",
    paddingBottom: '2%',
  },
  active: {
    background: "#EFEFEF",
    width: '100%',
    height: '7%',
    borderBottom: '0.5px solid transparent',
    borderRight: '5px solid #c7c8c9',
    // color: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',
    '& $Links': {
      fontFamily: 'DM SansB',
      marginLeft: '0%',
    }
  },
  drawer_logo: {
    width: '100%',
    height: '10%',
    borderRadius: '0px',
    background: 'transparent',
  },
  logo_container: {
    background: "transparent",
    width: '85%',
    cursor: 'pointer',
    '&:hover': {
      background: "#fffff",
    },
  },
  drawer_minilogo: {
    width: '80%',
    height: '10%',
    borderRadius: '0px',
    position: 'relative',
    left: '-20px',
  },
  menuButton: {
    marginRight: '-10px',
    color: '#402E3C',
  },
  hide: {
    display: 'none',
  },
  hide1: {
    opacity: 0.5,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: 'none',
    overflowY: 'auto',
    boxShadow: `0 0 14px 7px #f0f2f3`,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    boxShadow: `0 0 7px 3px #f0f2f3`,
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: theme.spacing(0, 1.5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    cursor: 'pointer',
  },
  drawer_close_icon: {
    position: 'relative',
    cursor: 'pointer',
    fontSize: '24px',
    opacity: 0.5,
    color: '#402E3C',
  },
  drawer_close_icon_shift: {
    position: 'relative',
    cursor: 'pointer',
    fontSize: '24px',
    opacity: 0.5,
    color: '#402E3C',
    marginLeft: "-30%",
  },
  content: {
    flexGrow: 1,
    background: '#ffffff',
    overflowX: 'hidden',
    overflowY: 'hidden',
    paddingBottom: '7%',
  },
  section: {
    color: '#440042',
    fontFamily: 'DM SansB',
    textTransform: 'capitalize',
    fontSize: '15px'
  },
  newSection: {
    position: "absolute",
    bottom: "0%",
    left: "0%",
    width: '100%',
    background: "#EFEFEF",
  },
  typography: {
    padding: theme.spacing(2),
  },
  gradientColor: {
    // color:` color:linear-gradient(
    //   113.62deg,
    //   #8a2387 24.45%,
    //   #e94057 58.83%,
    //   #f27121 94.26%
    // );`,
    color: '#DA70D6',
    backgroundImage: ` color:linear-gradient(
      113.62deg,
      #8a2387 24.45%,
      #e94057 58.83%,
      #f27121 94.26%
    );`
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundImage: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',
    backgroundClip: 'text',
    color: 'white'

  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseRounded />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const HandleBotCount = (data) => {
  if (data === null)
    return (null)
  let Count = []
  if (data.length === 0)
    BotCount([])
  else {
    Count[0] = data.favouriteBotsCount;
    Count[1] = data.activeBotsCount;
    Count[2] = data.deactivatedBotsCount;
    Count[3] = data.archivedBotsCount;
    BotCount(Count)
  }
}

function SideMenuSlider(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const history = useHistory();
  const ActiveNavItem = activeNavVar()
  const [SectionAction, setSectionAction] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [open, SetOpen] = React.useState(sideNavActive());
  const [Message, setMessage] = React.useState(null);
  const SkeletonVariants = ['h1', 'h2', 'h3', 'h4', 'body1', 'caption'];
  const [SectionId, setSectionId] = React.useState(null)
  const [ActiveTab, setActiveTab] = React.useState(activeMenuVar())
  const [ActiveTabId, SetTabID] = React.useState(window.TAB)
  const [Title, setTitle] = React.useState("");
  const [checkTab, setCheckTab] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hover, sethover] = React.useState("");
  console.log("I am length paramerter:",props)
  let sidenavitems = null 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  React.useEffect(() => {
    activeMenuVar(ActiveTab)
    console.log("CHange here : ", activeMenuVar())
    localStorage.setItem("SideTab", ActiveTab)
  }, [ActiveTab])

  const RefetchTabCount = () => {
    getTabCount({ variables: { id: ActiveTabId } })
  }

  let check = useReactiveVar(BotTabCheck)

  React.useEffect(() => {
    if (BotTabCheck()) {
      BotTabCheck(false)
      RefetchTabCount()
    }

  }, [check])

  const [createBotTab, { loading: addTabLoading }] = useMutation(CreateNewTab, {
    onCompleted(data) {
      history.replace("/my-bots")
    }
  });
  const [renameBotTabAPI, { loading: renameTabLoading }] = useMutation(RenameBotTab, {
    onCompleted(data) {
      if (data.renameBotTab.success) {
        setMessage(`Tab was rename to ${Title} successfully.`)
        setTitle("");
        setSectionId(null)
        setCheckTab(true)
        setSectionAction("");
        if (localStorage.getItem('tabCount') === "1") {
          history.replace("/my-bots")
        }
        handleClose()
      }
      else {
        setMessage(`Error: Tab rename was not successfully. Please try again later.`)
        setSectionAction("");
      }
    }
  });
  const [deleteBotTab, { loading: deleteTabLoading }] = useMutation(DeleteBotTab, {
    onCompleted(data) {
      if (data.deleteBotTab.success) {
        console.log("Deleted bot and data ", data)
        setMessage("The tab was deleted successfully.")
        setSectionId(null)
        setActiveTab(null)
        props.refetch()
        setSectionAction("")
      } else {
        setMessage(data.deleteBotTab.error)
        setSectionId(null)
        setSectionAction("")
      }
      setOpenSnack(true)
    }
  });

  const [getTabCount, { data: DataID }] = useLazyQuery(
    BotTab,
    {
      fetchPolicy: "cache-and-network",
    })
  if (DataID) {
    HandleBotCount(DataID.botTab)
  }
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleClose = () => {
    setTitle("")
    setCheckTab("true")
    setSectionAction("");
  };
  const handleCheck = (bool) => {
    setCheckTab(bool)
    setAnchorEl(null)
  }
  const handleHover = (data) => {
    sethover(data)
  }
  const sidenavItems12 = [
    { "title": "bots", "url": "/bot-store" },
    { "title": "all bots", "url": "/bot-store" },
    { "title": "process", "url": "/bot-store" },
    { "title": "app used", "url": "/bot-store" },
    { "title": "tasks", "url": "/bot-store" },
  ]

  const sidenavItems3 = [
    //to be added later
  ]

  const sidenavItems678 = [
    { "title": "user info", "url": "/user-info" },
    { "title": "audit logs", "url": "/audit-logs" },
    { "title": "password & notifications", "url": "/password-notifications" },
    { "title": "personal vm", "url": "/virtual-machine" }
  ]

  const sidenavItems9 = [
    { "title": "organization info", "url": "/organization-info" },
    { "title": "organization users", "url": "/organization-users" },
    { "title": "organizational vms", "url": "/organizational-vms" },
    { "title": "scheduled bots", "url": "/scheduled-bots" },
    { "title": "manage bots", "url": "/manage-bots" },
    { "title": "manage users", "url": "/manage-users" },
    // { "title": "assign bots", "url": "/assign-bots" },
    // { "title": "assign apis", "url": "/assign-apis" },
  ]

  const sidenavItems105 = [
    { "title": "subscriptions", "url": "/subscriptions" },
    { "title": "addons", "url": "/addons" },
    { "title": "subscription details", "url": "/subscription-details" },
    { "title": "instance usage", "url": "/instance-usage" },
    { "title": "invoices", "url": "/invoices" },
    { "title": "transactions", "url": "/transactions" },
  ]

  const sidenavItems45 = [
    { "title": "Contact", "url": "/support/contact" },
    // { "title": "Lokipedia", "url": "/support/lokipedia" },

  ]



  if (ActiveNavItem === "MyBots") {
    // if (props.bottabs === null || Object.keys(props.bottabs).length === 0)
    //   sidenavitems = [
    //     { "title": "Default" }
    //   ]
    // else {
    sidenavitems = props.bottabs
    // }

  }
  else if (ActiveNavItem === "BotStore")
    sidenavitems = sidenavItems12
  else if (ActiveNavItem === "Api")
    sidenavitems = sidenavItems3
  else if (ActiveNavItem === "User")
    sidenavitems = sidenavItems678
  else if (ActiveNavItem === "Subscriptions")
    sidenavitems = sidenavItems105
  else if (ActiveNavItem === "Support")
    sidenavitems = sidenavItems45
  else if (ActiveNavItem === "Dashboard")
    sidenavitems = sidenavItems9
  else if (ActiveNavItem === "AuditLog")
    sidenavitems = sidenavItems678
  else if (ActiveNavItem === "TaskStore")
    sidenavitems = sidenavItems12
  else if (ActiveNavItem === "lokipidia")
    sidenavitems = sidenavItems12



  const handleDrawerOpen = () => {
    SetOpen(true);
    sideNavActive(true)
    localStorage.setItem("sideNavActive", true)
  };

  const handleDrawerClose = () => {
    SetOpen(false);
    sideNavActive(false)
    localStorage.setItem("sideNavActive", false)
  };

  const MenuClicked = (event, data, id) => {
    setActiveTab(data)
    activeMenuVar(data)

    localStorage.setItem("SideTabId", id)
    console.log("sidetab clicked: datas are " + data + " id " + id)
  };

  const handleTitle = (event) => {
    setTitle(event.target.value)
  }

  const handlesubmit = async (event) => {
    try {
      handleClose()
      setCheckTab(true)
      await createBotTab({ variables: { title: Title } })
      setMessage(`${Title} Tab was added successfully.`)
      setTitle("")
      setOpenSnack(true)
      await props.refetch()
      if (localStorage.getItem('tabCount') === "1") {
        history.replace("/my-bots")
      }

    }
    catch (error) {
      setMessage("Error: There was problem in adding a new Tab. Please try again later.")
    }
    return (false)
  }

  const SetDelete = async (event, id) => {
    setSectionId(id);
    setSectionAction("Delete")
    try {
      await deleteBotTab({ variables: { tabId: id } })
    } catch (error) {
      console.log("Error Deleting the task with id " + id)
      setMessage(`Error: Tab rename was not successfully. Please try again later.`)
      setSectionAction("")
      setSectionId(null)
      setOpenSnack(true)
    }
  }
  const PerformSectionAction = async (event, TabID, newTitle = null) => {
    setSectionId(TabID)
    if (SectionAction === "Delete") {
      console.log("Deleting the task with id " + TabID)

    } else {
      if (newTitle !== null) {
        console.log("Editing the task with id and name " + TabID + " " + newTitle)
        try {
          setCheckTab(true)
          await renameBotTabAPI({ variables: { tabId: TabID, title: newTitle } })
          await props.refetch()
          if (localStorage.getItem('tabCount') === "1") {
            history.replace("/my-bots")
          }
          setActiveTab(newTitle)
        }
        catch (error) {
          console.log("Error renaming  the task with id " + TabID + " " + error)
          setMessage(`Error: Tab rename was not successfully. Please try again later.`)
        }
        setOpenSnack(true)
      }

    }

  }

  const handleSetID = (id) => {
    SetTabID(id)
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

      <div className={classes.toolbar}>
        <ListItem className={classes.logo_container} key="logo">
          {!open ?
            <ListItemAvatar className={classes.logo_container}>
              <Link to="/" >
                <Avatar variant="square" className={classes.drawer_minilogo} src={minilogo} alt="Company Logo" />
              </Link>
            </ListItemAvatar>
            : null}
          {open ?
            <ListItemAvatar className={classes.logo_container}>
              <Link to="/" >
                <Avatar variant="square" className={classes.drawer_logo} src={logoname} alt="Company Logo" />
              </Link>
            </ListItemAvatar>
            : null}
        </ListItem>
        {!open ?
          <div
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, classes.drawer_close_icon, {
              [classes.hide]: open,
            })}
          >
            <CgChevronDoubleRight className={`${classes.drawer_close_icon_shift}`} />
          </div>
          : null}
        {open ?
          <div className={classes.drawer_close_icon} onClick={handleDrawerClose}>
            <CgChevronDoubleLeft />
          </div>
          : null}
      </div>

      <div style={{ display: (open ? "block" : "none") }} className={classes.SectionContainer}>
        <List className={classes.SectionHeight} >
          {
            props.error ?
              <div style={{ padding: '5%', position: 'relative', width: drawerWidth, overflowWrap: 'break-word', }} >
                <MySkeleton SkeletonVariants={SkeletonVariants} />
                <p>{props.error} ! Try again later </p>
              </div>
              :
              props.loading
                ?
                <div style={{ padding: '5%' }} >
                  <MySkeleton SkeletonVariants={SkeletonVariants} />
                </div>
                :
                sidenavitems.length === 0
                  ?
                  <ListItem key={"empty tab"} className={`active ${classes.LinksContainer} ${classes.root} ${classes.active}`}
                    style={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    <ListItemText>
                      No Tabs created yet
                    </ListItemText>
                  </ListItem>
                  :
                  sidenavitems.map((data, index) => (
                    <Droppable
                      droppableId={index}
                      index={`${data.id}{index}`}
                      key={`${index} ${data.id}`}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          button
                          key={"item" + index}
                          ref={provided.innerRef}
                          id={`${data.id}{index}`}
                          className={(data.title === ActiveTab) ? ` active ${classes.LinksContainer} ${classes.root} ${classes.active}` : classes.LinksContainer}
                          // style={{ width: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          onMouseEnter={() => handleHover(data.title)} onMouseLeave={() => handleHover("")}
                        >

                          {(ActiveTab === null && index === 0) ? setActiveTab(data.title) : null}
                          <ListItemText>
                            {
                              SectionAction === "Delete"
                                ?
                                <div style={{ display: "flex" }}>

                                  <p
                                    onClick={ActiveNavItem === "MyBots" ? (e) => { MenuClicked(e, data.title, index); handleSetID(data.id); getTabCount({ variables: { id: data.id } }) } : (e) => { MenuClicked(e, data.title, index); history.replace(data.url) }}
                                    className={`${classes.Links} ${commonClasses.CapitalizeText}`} component="a" underline="none" style={{ paddingLeft: '5%' }}
                                  >
                                    {SectionId === data.id ? <> < CircularProgress size={20} /></> : <FiFolder style={{ color: "grey" }} />}
                                    {" " + data.title}
                                  </p>

                                </div>
                                :
                                SectionAction === "Edit"
                                  ?
                                  SectionId === data.id
                                    ?
                                    <form onSubmit={(event) => {
                                      event.preventDefault()
                                      let y = 1
                                      Array.from(props.bottabs).map((row) => {
                                        if (Title === row.title)
                                          y = 0
                                        return null;
                                      })
                                      if (y === 0) {
                                        setCheckTab(false)
                                        y = 1
                                        setTitle("")
                                      }
                                      else {
                                        PerformSectionAction(event, SectionId, Title)
                                      }
                                    }}>
                                      <div>

                                        <input type="text" style={{ width: "70%", paddingLeft: "2%", borderBottom: "0.5px solid blue" }} value={Title} onChange={(event) => setTitle(event.target.value)} autoFocus required placeholder="Enter new Task Title" />
                                        <Popover
                                          open={!checkTab}
                                          anchorEl={anchorEl}
                                          onClose={() => handleCheck(true)}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}

                                        >
                                          <Typography className={classes.typography}> A Tab with the same name already exists.</Typography>
                                        </Popover>
                                        <Tooltip title="Cancel">
                                          <IconButton disabled={renameTabLoading ? true : false} onClick={(event) => { setSectionId(null); setTitle(""); setCheckTab(true); setSectionAction(""); }} size={"small"} className={`button_no_bg ${classes.SectionEditIcon}`} >
                                            {<CancelRounded />}
                                          </IconButton>
                                        </Tooltip>
                                        {renameTabLoading
                                          ?
                                          <IconButton size={"small"} className={`button_no_bg ${classes.SectionEditIcon}`} >
                                            <CircularProgress size={21} />
                                          </IconButton>

                                          :
                                          <Tooltip title="Save Changes">
                                            <IconButton type="submit" size={"small"} onClick={(e) => handleClick(e)} className={`button_no_bg ${classes.SectionEditIcon}`} >
                                              <FaSave style={{ fontSize: '18px' }} />
                                            </IconButton>
                                          </Tooltip>
                                        }

                                      </div>
                                    </form>
                                    :
                                    <div style={{ display: "flex" }}>
                                      <p

                                        className={`${classes.Links} ${commonClasses.CapitalizeText}`} component="a" underline="none" style={{ paddingLeft: '5%' }}
                                      >
                                        {data.title}
                                      </p>

                                    </div>
                                  :
                                  <div className="valign-wrapper" style={{ display: "flex" }}>
                                    <p
                                      onClick={ActiveNavItem === "MyBots" ? (e) => { MenuClicked(e, data.title, data.id); handleSetID(data.id); getTabCount({ variables: { id: data.id } }) } : (e) => { MenuClicked(e, data.title, index); history.replace(data.url) }}
                                      className={`${classes.Links} ${commonClasses.CapitalizeText}`} component="a" underline="none" style={{ paddingLeft: '5%' }}
                                    >
                                      {ActiveNavItem === "MyBots"
                                        ?
                                        <> {data.title === activeMenuVar() ? <AiTwotoneFolderOpen style={{ color: "purple", marginRight: "2%", fontSize: "21px", marginBottom: "-2%" }} /> : < AiFillFolder style={{ color: "#d1a3d1", marginRight: "2%", fontSize: "21px", marginBottom: "-2%" }} />} {data.title}</>
                                        : data.title
                                      }
                                    </p>
                                    {ActiveNavItem === "MyBots"
                                      ?
                                      hover === data.title
                                        ?
                                        <>
                                          <IconButton size={"small"} className={`button_no_bg ${classes.SectionEditIcon}`}>
                                            <DeleteRounded onClick={(event) => { SetDelete(event, data.id) }} />
                                          </IconButton>
                                          <IconButton size={"small"} className={`button_no_bg ${classes.SectionEditIcon}`} >
                                            <EditRounded onClick={(event) => { setSectionAction("Edit"); setSectionId(data.id) }} />
                                          </IconButton>
                                        </>

                                        : null
                                      : null
                                    }
                                  </div>
                            }
                          </ListItemText>
                        </ListItem>
                      )}
                    </Droppable>
                  ))
          }

        </List>
        <div className={classes.newSection} >
          {ActiveNavItem === "MyBots"
            ?
            <Button
              fullWidth
              disabled={renameTabLoading ? true : deleteTabLoading ? true : false}
              onClick={() => { setSectionAction("Add") }}
              style={{ color: 'white', backgroundColor: '#440042' }}
              className={`${classes.section} ${commonClasses.Plain_Btn} button_no_bg`}>

              Create Tab <AddRoundedIcon />
            </Button>
            :
            null
          }
        </div>
      </div>
      <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={SectionAction === "Add"}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography style={{ fontSize: '16px', fontFamily: "Pangram" }}>Add New Tab</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={(e) => {
            e.preventDefault();
            let x = 1
            Array.from(props.bottabs).map((row) => {
              if (Title === row.title)
                x = 0
              return null
            })
            if (x === 0) {
              setCheckTab(false)
              x = 1
            }
            else {
              handlesubmit(Title)
            }
          }
          }>
            <input
              required
              autoFocus
              margin="dense"
              label="Tab Name"
              placeholder="Enter Tab Name"
              type="text"
              value={Title}
              onChange={(e) => handleTitle(e)}
              fullWidth
            />
            <DialogActions>
              {checkTab ? <span></span> : <span style={{ color: "red" }}>TAB ALREADY EXIST </span>}
              <Button disabled={addTabLoading ? true : false} type="submit" style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary" endIcon={addTabLoading ? <CircularProgress color={"white"} size={21} /> : null}>
                Proceed
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ fontFamily: "Pangram" }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={Message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseRounded fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Drawer>

  )
}

// This is the context variable to hide and display the sidenav
export const SideNavContext = React.createContext();

function SideMenu(props) {
  const classes = useStyles();
  const ActiveNavItem = activeNavVar()
  const [SideNavFlag, setSideNavFlag] = React.useState(true);
  const [botScheduleData, setBotScheduleData] = React.useState(null);

  const [DragData, setDragData] = React.useState({});
  const [openModal, setopenModal] = React.useState(false);
  const [snackData, setsnackData] = React.useState(null);
  const [refetchMyBotData, setrefetchMyBotData] = React.useState(false);


  const [ChangeBotTab, { loading: ChangeBotTabLoading }] = useMutation(EditBotTab, {
    onCompleted(data) {
      console.log("Bot Tab changed Data " + JSON.stringify(data))
      if (data.editBot.success) {
        console.log("Success: Bot Tab changed " + JSON.stringify(data))
        setopenModal(false)
        setDragData({})
        setrefetchMyBotData(true)
        BotTabCheck(true)
        setsnackData({ open: true, message: `The Bot ${DragData.BotName} is successfully moved to tab ${DragData.tabName}` })
      } else {
        setrefetchMyBotData(false)
        console.log("ERROR! while changing the bot tab using drag and drop")
      }
    },
    onError(data) {
      console.log("ERROR! while changing the bot tab using drag and drop")
    }
  });
  
  let tabData = null 
  let bool = false;
  React.useEffect(() => {
    props.callBackFunction(SideNavFlag)
  }, [SideNavFlag, props]);

  const onDragEnd = (result) => {
    const { destination, source, reason } = result;
    if (destination === null || source === null || destination.droppableId === "droppable1")
      return;
    console.log("destination")
    console.log(destination)

    console.log("source")
    console.log(source)


    console.log("reason")
    console.log(reason)

    console.log("Dragged")

    let index = parseInt(destination.droppableId)
    if (isNaN(index)) {
      console.log("the index is nan so returning")
      return;
    }
    if (tabData[index].title === activeMenuVar()) {
      setsnackData({ open: true, message: `The Bot ${source.index[0]} is already in the tab ${tabData[index].title}` })
      return;
    }
    setDragData({ BotName: source.index[0], BotId: source.index[1], tabName: tabData[index].title, tabId: tabData[index].id })
    setopenModal(true)
  }

  const changeBotTabFun = () => {
    console.log("changing bot " + DragData.BotName + " with id " + DragData.BotId + " from tab " + DragData.tabId + " to " + DragData.tabName)
    // botId: ID!, $tabId:
    try {
      ChangeBotTab({
        variables: {
          botId: parseInt(DragData.BotId),
          tabId: parseInt(DragData.tabId)
        }
      })
    } catch (error) {
      console.log("ERROR! while changing the bot tab using drag and drop")
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <SideNavContext.Provider value={{ SideNavFlag, setSideNavFlag, botScheduleData, setBotScheduleData, refetchMyBotData, setrefetchMyBotData }}>
          <div className={classes.root}>

            {SideNavFlag
              ?
              (ActiveNavItem === "MyBots")
                ? <Query query={BotTabs}>{
                  ({ loading, error, data, refetch }) => {
                    if (loading) {
                      HandleBotCount([])
                      return (<SideMenuSlider id="SideNav" bottabs={[]} loading={true} />);
                    }
                    if (error) {
                      return (
                        <SideMenuSlider id="SideNav" bottabs={[]} error={error.message} />
                      )
                    }
                    if (data != null) {
                      tabData = data.botTabs;
                      if (data.botTabs.length === 0) {
                        HandleBotCount([])
                        localStorage.setItem("tabCount", "0")
                        sideNavActive(false)
                        return (
                          <div>
                            <SideMenuSlider id="SideNav" refetch={refetch} bottabs={[]} loading={false} />
                          </div>
                        );
                      }

                      // my bot data is present 
                      data.botTabs.map((tab)=>{
                        if(tab.title === activeMenuVar())
                            bool = true;
                      })
                      localStorage.setItem("tabCount", "" + data.botTabs.length)
                      activeMenuVar() === "null" ?
                        activeMenuVar(data.botTabs[0].title)
                        :
                        bool ? console.log("match")
                        : activeMenuVar(data.botTabs[0].title)
                      localStorage.setItem("SideTabId", data.botTabs[0].id)
                      HandleBotCount(data.botTabs[0])
                      window.TAB = data.botTabs[0].id
                      return (
                        <div>
                          <SideMenuSlider id="SideNav" refetch={refetch} bottabs={data.botTabs} loading={false} />
                        </div>
                      )
                    }
                  }
                }
                </Query>
                :
                <div>
                  <SideMenuSlider bottabs={[]} id="SideNav" loading={false}/>
                </div>
              :
              null
            }
            <div className={isLoggedInVar() ? classes.content : null}>
              <Switch>
                <ProtectedRoute exact path="/" component={Banking} />
                <Route exact path="/login" component={() => isLoggedInVar() ? <Redirect to="/" /> : <LoginPage />} />
                <Route exact path="/logout" component={() =>  <Redirect to="/" /> } />
                {/* My bots+Create Bot related URL Routing*/}
                <ProtectedRoute path="/my-bots/empty-tab" exact component={BotEmptyState} />
                <ProtectedRoute path="/my-bots/bot-details" exact component={EditBotMainScreen} />
                <ProtectedRoute path="/my-bots" exact component={Banking} />
                <ProtectedRoute path="/my-bots/create-bot" exact component={CreateBotTopSection} />

                {/* BotStore related URL Routing*/}
                <Route path="/bot-store" exact component={BotStore} />

                {/* TaskStore related URL Routing*/}
                <ProtectedRoute path="/task-store" exact component={TaskStore} />

                {/* User Avatar Menu related URL Routing*/}
                <ProtectedRoute path="/audit-logs" exact component={AuditLogs} />
                <ProtectedRoute path="/user-info" exact component={UserInfo} />
                <ProtectedRoute path="/subscriptions" exact component={Subscriptions} />
                <ProtectedRoute path="/password-notifications" exact component={PswdNotifications} />
                <ProtectedRoute path="/virtual-machine" exact component={PersonalVM} />

                {/* Subscribtion URL Routing*/}

                <ProtectedRoute path="/addons" exact component={Addons} />
                <ProtectedRoute path="/subscription-details" exact component={SubscriptionDetails} />
                <ProtectedRoute path="/instance-usage" exact component={InstanceUsage} />
                <ProtectedRoute path="/invoices" exact component={Invoices} />
                <ProtectedRoute path="/transactions" exact component={Transactions} />

                {/* Interactive API related URL Routing*/}
                <ProtectedRoute path="/interactiveapi" exact component={InteractiveApi} />
                {/* Lokipedia related URL Routing*/}
                <ProtectedRoute path="/lokipedia" exact component={Lokipedia} />
                {/* Support related URL Routing*/}
                <ProtectedRoute path="/support" exact component={Contact} />
                <ProtectedRoute path="/support/contact" exact component={Contact} />

                {/* Admin Dashboard related URL Routing */}
                <ProtectedRoute path="/organization-info" exact component={OrganizationInfo} />
                <ProtectedRoute path="/organization-users" exact component={OrganizationUsers} />
                <ProtectedRoute path="/organizational-vms" exact component={OrganizationVMs} />
                <ProtectedRoute path="/manage-bots" exact component={ManageBots} />
                <ProtectedRoute path="/manage-users" exact component={ManageUsers} />
                <ProtectedRoute path="/scheduled-bots" exact component={ScheduleBots} />

                <Route component={ErrorPage} />
              </Switch>
            </div>
          </div>
        </SideNavContext.Provider>
      </DragDropContext>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ fontFamily: "Pangram" }}
        open={snackData ? snackData.open : false}
        autoHideDuration={6000}
        onClose={() => setsnackData(null)}
        message={snackData ? snackData.message : null}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setsnackData(null)}>
              <CloseRounded fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <CustomModal
        icon={<FiFolder />}
        title={`Move Bot: ${DragData.BotName}`}
        subheader=""
        openDialog={openModal}
        ParentCallBackFunction={setopenModal}
        render={() =>
          <div>
            <p>
              Moving Bot <b>{DragData.BotName}</b> to tab <b>{DragData.tabName}</b>
            </p>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              style={{ marginRight: '2%' }}
              onClick={() => setopenModal(false)}
              disabled={ChangeBotTabLoading ? true : false}
              endIcon={<CancelRounded />}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => changeBotTabFun()}
              disabled={ChangeBotTabLoading ? true : false}
              endIcon={ChangeBotTabLoading ? <CircularProgress size={21} color="white" /> : <FiCheck />}
            >
              Confirm
            </Button>
          </div>
        }
      />
    </div>
  );
}

export default SideMenu;

import React from 'react';
import { Button, Popover, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// importing required components

// importing required css
import "../styles/css/fonts.css";

const useStyles = makeStyles((theme) => ({
    PopOverBodyContainer: {
        display: 'flex',
        fontSize: '12px',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& $Button': {
            fontSize: '12px',
        },
        '& $input': {
            fontSize: '2px',
        }
    },
    TaskstepAction: {
        fontFamily: "DM Sans",
        fontSize: '11px',
        fontWeight: 'bold',
        marginLeft: '2%',
        color: '#402E3C',
    },
}));

function TaskEditPopOver(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState((props.PopOverFlag));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getContent() {
        switch (props.Action) {
            case "Reorder":
                return <Grid container className={classes.PopOverBodyContainer + " valign-wrapper"}>
                    <Grid item md={2}>Move to:</Grid>
                    <Grid item md={2}>
                        <Button
                            disableRipple
                            style={{ textTransform: 'capitalize' }}
                            variation="text"
                        > Top </Button>
                    </Grid>
                    <Grid item md={2}>
                        <Button
                            disableRipple
                            style={{ textTransform: 'capitalize' }}
                            variation="text"
                        > Bottom </Button>
                    </Grid>
                    <Grid item md={6}><input type="number" placeholder="Enter a row number" /></Grid>
                </Grid>
            case "Copy to":
                return <Grid container className={classes.PopOverBodyContainer + " valign-wrapper"}>
                    <Grid item md={12}><input type="text" placeholder="Search for a task. Eg: Extract Actuals/FMCG Forecasting" /></Grid>
                </Grid>
            case "Clone":
                return <Grid container className={classes.PopOverBodyContainer + " valign-wrapper"}>
                    <Grid item md={2}>Insert to:</Grid>
                    <Grid item md={2}>
                        <Button
                            disableRipple
                            style={{ textTransform: 'capitalize' }}
                            variation="text"
                        > Top </Button>
                    </Grid>
                    <Grid item md={2}>
                        <Button
                            disableRipple
                            style={{ textTransform: 'capitalize' }}
                            variation="text"
                        > Bottom </Button>
                    </Grid>
                    <Grid item md={6}><input type="number" placeholder="Enter a row number" /></Grid>
                </Grid>
            case "Add Step":
                return <div>
                    <Grid container spacing={1} className={classes.PopOverBodyContainer + " valign-wrapper"}>
                        <Grid item md={4}>
                            <input type="text" placeholder="Enter Action" />
                        </Grid>
                        <Grid item md={8}>
                            <input type="text" placeholder="Describe the Action" />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.PopOverBodyContainer + " valign-wrapper"}>
                        <Grid item md={3}>
                            Position on:
                                </Grid>
                        <Grid item md={1}>
                            Top
                                </Grid>
                        <Grid item md={2}>
                            Bottom
                                </Grid>
                        <Grid item md={6}>
                            <input type="number" placeholder="Enter a row number" />
                        </Grid>
                    </Grid>
                </div>
            default:
                return <div>The Action is not recognized.</div>
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'TaskStepEditPopover' : undefined;

    return (
        <>
            <Button
                disableRipple
                className={(!open) ? "button_no_bg " + classes.TaskstepAction : "button_no_bg " + classes.TaskstepAction + " " + classes.activeTaskStepAction}
                style={{ textTransform: 'capitalize' }}
                onClick={handleClick}
                startIcon={props.icon}
            >
                {props.Action}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography >
                    {getContent()}
                </Typography>
            </Popover>
        </>
    );
}

export default TaskEditPopOver
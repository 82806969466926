// importing required packages
import React, { useContext } from 'react';
import { Task } from "./Pages/CreateBotContext";
import { CustomModal } from './modal';
import Menu from '@material-ui/core/Menu';
import { MenuItem, Button, CircularProgress, DialogActions, DialogContent, Typography } from '@material-ui/core/';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import { useMutation } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import { CloseRounded } from "@material-ui/icons"

// importing required css
import "../styles/css/common.css"
import { commonStyles } from "../styles/css/CommonCSS"
import MuiAlert from '@material-ui/lab/Alert';

// importing required queries
import { DeleteTask, PublishUnpublishTask, CloneTask } from "../graphql/queries";

export function CreateBotTaskMenu(props) {
  const classes = commonStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { task } = props
  const id = task.data.id
  const { removeTask, setTaskEditable, duplicateTask } = useContext(Task);
  const [deleteTask] = useMutation(DeleteTask)
  const [publish, setPublish] = React.useState(false)
  const [error, setError] = React.useState({ flag: false, message: "" });
  const [load, setLoad] = React.useState(false);
  const [global, setGlobal] = React.useState(false);
  const [org, setOrg] = React.useState(false);
  const [pubishsnack, setPublishSnack] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const [Clone_Task, { loading: CT_Loading, error: CT_Error }] = useMutation(CloneTask, {
    onCompleted: (data) => {
      if (data.cloneTask.success) {
        console.log("Successfully cloned task", id)
        setError({ flag: false, message: "Task has been cloned Successfully" })
        duplicateTask(task, data.cloneTask.clonedTask.id, data.cloneTask.clonedTask.title, data.cloneTask.clonedTask.order)
      } else
        setError({ flag: true, message: "Something went wrong. Please try again" })
    },
  });
  if (CT_Error) {
    setError({ flag: true, message: "Something went wrong. Please try again" })
  }


  const [publishTask, { loading: PT_Loading, error: ErrorPublish }] = useMutation(PublishUnpublishTask, {
    onCompleted: (data) => {
      console.log(data.publishUnpublishTask.success, data.publishUnpublishTask.error)
      if (data.publishUnpublishTask.success) {
        setLoad(false)
        setPublish(false)
        setError({ flag: false, message: "Task has been added Successfully" })
        setPublishSnack(true)
      }
      else {
        setError({ flag: true, message: "Something went wrong. Please try again" })
      }
      setLoad(false);
    },
    onError: (err) => {
      setLoad(false);
      setError({ flag: true, message: "Something went wrong. Please try again" })
      console.log(err)
    }
  });

  const handleTask = async (event) => {
    setLoad(true)
    await publishTask({ variables: { globalPublished: global, orgPublished: org, taskId: props.task.data.id } });
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleEditTask = () => {
    setTaskEditable({ id, isEdited: true })
    handleClose()
  }

  const handleGlobal = (event) => {
    setGlobal(event.target.checked)
  }

  const handleOrg = (event) => {
    setOrg(event.target.checked)
  }

  const handleRemoveTask = () => {
    removeTask(id)
    deleteTask({
      variables: {
        taskId: id,
      }
    })
    handleClose()
  }

  const handleDuplicateTask = async () => {
    console.log("Cloning task ", id)
    await Clone_Task({
      variables: {
        taskId: id,
      }
    })
    // duplicateTask(task, 123)
    setPublishSnack(true)
    handleClose()
  }


  return (
    <div>
      <IconButton className="button_no_bg" size="small" disableRipple aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem dense>
          <Button
            size="small"
            variant="text"
            disableElevation
            disableRipple
            onClick={() => setPublish(true)}
            className={classes.Plain_Btn}
            startIcon={PT_Loading ? <CircularProgress size={17} /> : <PublishRoundedIcon style={{ color: "#8C91A6" }} />}
            disabled={PT_Loading || CT_Loading}
          >
            Publish
          </Button>
        </MenuItem>

        <MenuItem dense>
          <Button
            size="small"
            variant="text"
            disableElevation
            disableRipple
            className={classes.Plain_Btn}
            startIcon={CT_Loading ? <CircularProgress size={17} /> : <FileCopyRoundedIcon style={{ color: "#8C91A6" }} />}
            onClick={handleDuplicateTask}
            disabled={CT_Loading || PT_Loading}
          >
            Clone
          </Button>
        </MenuItem>
        {
          /*
          <MenuItem dense>
          <Button
            size="small"
            variant="text"
            disableElevation
            disableRipple
            className={classes.Plain_Btn}
            startIcon={<IoCopy style={{ color: "#8C91A6" }} />}
            disabled={PT_Loading || CT_Loading}
          >
            Copy to
            </Button>
        </MenuItem>
          */
        }
        <MenuItem dense>
          <Button
            size="small"
            variant="text"
            disableElevation
            disableRipple
            className={classes.Plain_Btn}
            startIcon={<EditRoundedIcon style={{ color: "#8C91A6" }} />}
            onClick={handleEditTask}
            disabled={PT_Loading || CT_Loading}
          >
            Edit
          </Button>
        </MenuItem>
        <MenuItem dense>
          <Button
            size="small"
            variant="text"
            disableElevation
            disableRipple
            className={classes.Plain_Btn}
            startIcon={<DeleteRoundedIcon style={{ color: "#8C91A6" }} />}
            onClick={handleRemoveTask}
            disabled={PT_Loading || CT_Loading}
          >
            Delete
          </Button>
        </MenuItem>
      </Menu>

      <CustomModal title="Publish a task" subheader="" openDialog={publish} ParentCallBackFunction={setPublish}
        render={() => {
          return (

            <form>
              <DialogContent>
                <Typography style={{ fontSize: "20px", fontFamily: 'DM Sans' }}>
                  Where do you wish to publish the Task ?
                </Typography>
                <DialogActions>
                  <label style={{ textTransform: 'None', fontFamily: 'Pangram', color: "black" }} >
                    <input required type="checkbox" value={global} onChange={(e) => handleGlobal(e)} />
                    <span>Global</span>
                  </label>
                  <p>
                    <label style={{ textTransform: 'None', fontFamily: 'Pangram', color: "black" }}>

                      <input required type="checkbox" value={org} onChange={(e) => handleOrg(e)} />
                      <span>Organization </span>
                    </label>
                  </p>
                  <br />
                  {load ? <CircularProgress size="21px" /> :

                    <Button onClick={() => setPublish(false)} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                      Cancel
                    </Button>}

                  {global === false
                    ? org === false ?
                      <Button disabled={true} type="button" onClick={(e) => handleTask()} style={{ opacity: "0.5", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                        Share
                      </Button> :
                      <Button type="button" onClick={(e) => handleTask()} style={{ opacity: "1", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                        Share
                      </Button>
                    : <Button type="button" onClick={(e) => handleTask()} style={{ opacity: "1", background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                      Share
                    </Button>
                  }
                </DialogActions>
                {error.flag ?
                  <DialogContent>
                    <Typography style={{ fontSize: "14px", fontFamily: 'DM Sans', color: "red" }}>
                      {"Sorry Could not Publish Task"}
                    </Typography>
                  </DialogContent>
                  :
                  null}
              </DialogContent>
            </form>
          )
        }}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ fontFamily: "Pangram" }}
        open={pubishsnack}
        autoHideDuration={6000}
        onClose={() => setPublishSnack(false)}
        action={

          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { setPublishSnack(false) }}>
            <CloseRounded fontSize="small" />
          </IconButton>

        }
      >
        {error.flag
          ?
          <Alert severity="error">
            {error.message}
          </Alert>
          :
          <Alert onClose={() => { setPublishSnack(false) }} severity="success">
            {error.message}
          </Alert>
        }
      </Snackbar>
    </div>
  )
}



// importing Required packages
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { ButtonGroup, TableContainer, Table, TableBody, Paper, TableHead } from '@material-ui/core';

// importing required components
import { BotStoreColumns } from "../Column";
import { StyledTableCell, StyledTableRow } from "../TableComponent";
import FadeMenu from '../FadeMenu';
import Chip from '@material-ui/core/Chip';
// importing required state variables and other required variables

// importing the required images

// importing css
import '../../styles/css/common.css';
import { commonStyles } from "../../styles/css/CommonCSS";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        margin: 'auto',
        borderRadius: '12px',
    },
    fontStyle: {
        fontFamily: 'DM Sans',
        fontSize: '15px',
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#402E3C',

    },
    chips: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.2),
        },
    },

}));

function BotStoreTable({ tab, data, filterString }) {
    const classes = useStyles();
    const commonClasses = commonStyles()

    const columns = React.useMemo(() => BotStoreColumns, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        setGlobalFilter(filterString)
    }, [filterString, setGlobalFilter]);

    return (
        <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" className={classes.table}  {...getTableProps()}>
                <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                    {headerGroups.map(headerGroup => (
                        <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <StyledTableCell  {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps()}>
                                    <div style={{ fontSize: '13px' }}>{column.render("Header")}</div>
                                    <span style={{}}></span>
                                    {column.isSorted ? (column.isSortedDesc ? null : null) : null}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.length === 0 ?

                        <StyledTableRow >
                            <StyledTableCell colSpan={8} align="center" key="data null">

                                <h6>  <SearchRoundedIcon style={{ fontSize: "30px", marginBottom: "-0.5%" }} /> No result found</h6>
                                <p>You may want to try using different keywords</p>
                            </StyledTableCell>
                        </StyledTableRow>
                        : null}
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <StyledTableRow className={commonClasses.rowStyle} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if (cell === row.cells[6]) {
                                        return (
                                            <StyledTableCell key={i}>
                                                <span>
                                                    {cell.value}

                                                    <ButtonGroup
                                                        disableRipple
                                                    >
                                                        {/* do it here */}
                                                        <FadeMenu menuId={4} Tab={tab} botId={row.original.id} />
                                                    </ButtonGroup>
                                                </span>
                                            </StyledTableCell>)
                                    }
                                    if (cell === row.cells[3] || cell === row.cells[4]) {
                                        return (
                                            <StyledTableCell {...cell.getCellProps()}>
                                                <div className={classes.chips}>
                                                    {cell.value === null
                                                        ? <span style={{ fontSize: "1.5rem", alignContent: "center", }}> - </span>
                                                        : cell.value.length === 0
                                                            ? <span style={{ fontSize: "1.5rem", alignContent: "center", }}> - </span>
                                                            :
                                                            cell.value.map(app =>
                                                                <Chip size="small" label={app} />
                                                            )}

                                                </div>
                                            </StyledTableCell>
                                        );
                                    }

                                    return (
                                        <StyledTableCell {...cell.getCellProps()}>
                                            {cell === row.cells[1] ? <div className={commonClasses.BotName}>{cell.render("Cell")}</div> :
                                                cell === row.cells[2] ? <div className={commonClasses.descript}>{cell.render("Cell")}</div> :
                                                    <div className={commonClasses.BotName}>{cell.render("Cell")}</div>}
                                        </StyledTableCell>);
                                })}
                            </StyledTableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default BotStoreTable;
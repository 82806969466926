import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, StyledTableRow } from "./TableComponent";
import Button from "@material-ui/core/Button";
import { TransactionColumns } from "../components/Column";
import { useTable } from "react-table";
import {TranxList } from "../graphql/queries";
import Grid from "@material-ui/core/Grid";
import { TableSkeleton } from "./skeleton";
import { commonStyles } from '../styles/css/CommonCSS';
import { Query } from 'react-apollo'


const useStyles = makeStyles({
  paper: {
    width: "95%",
    margin: "auto",
    borderRadius: "12px",
  },
});

const mockTransactions = [
  {
    date: 12345,
    method: "method",
    amount: "amount",
    status: "status",
  },
  {
    date: 12345,
    method: "method",
    amount: "amount",
    status: "status",
  },
  {
    date: 12345,
    method: "method",
    amount: "amount",
    status: "status",
  },
];

function TransactionDetails(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data= props.data.subscriptionsChargebeeTransactions
  const columns = React.useMemo(() => TransactionColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  });

  return (
    <>
      <h4 className={commonClasses.title}>Transactions</h4>
      <TableContainer style={{marginTop:"1%"}} className={classes.paper} component={Paper}>
        <Table
          size="small"
          className={classes.table}
          {...getTableProps()}
          aria-label="a dense table"
        >
          <TableHead
            style={{ textTransform: "uppercase", border: "3px solid #440042" }}
          >
            {headerGroups.map((headerGroup) => (
              <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableCell align="left" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center" key="data null">
                  <h5>No Data Available!</h5>
                </StyledTableCell>
              </StyledTableRow>
            ) : null}
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <StyledTableCell align="left" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Transaction() {
  const commonClasses = commonStyles();

  return (
    < Query query={TranxList} >
      {({ loading, error, data }) => {
        if (loading)
        return (
          <Grid style={{marginTop:"2%"}} container justify="space-evenly" alignItems="center">
              <Grid item xs={12}>
                  <h5 className={commonClasses.title}>Transactions</h5>
              </Grid>
              <Grid container item xs={12}>
                  <div style={{ width: '100%', marginTop:"1%" }}>
                      <TableSkeleton />
                  </div>
              </Grid>
          </Grid>
      );
        if (error) {
          return (
            <div style={{ margin: "2%" }}>
             
            </div>);
        }
        else {
          return (
            <div>
              <TransactionDetails data={data} />
            </div>);
        }
      }
      }
    </Query >
  );
}

export default Transaction;

// importing required packages
import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Notifications, HelpOutline } from '@material-ui/icons';
import { Badge, Avatar, Menu, MenuItem, Link, IconButton, Button, DialogContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom';
import { Query, useQuery } from 'react-apollo';
import { CustomModal } from './modal'
// importing required images
import generalDP from '../styles/images/general_dp.png';
import logo from '../styles/images/company_logo_name.png';

// importing required css files
import '../styles/css/navbar.css';
import '../styles/css/common.css';

// importing required components
import { default as Notification } from "./SideMenuComponents/Notifications";
import { default as SideMenu } from './sidemenu';

// importing required reactive variables
import { isLoggedInVar, activeNavVar, activeMenuVar, sideNavActive } from "../utils/cache";
import { client } from "../graphql/apolloClient";

// importing required queries
import { GetNotificationCount, UserRoleDetails } from "../graphql/queries";
import { UserDetails, DownloadDA } from "../graphql/queries";



function Notify(props) {
    const { setRefetchFlag } = React.useContext(RefetchContext);

    return (
        <Query query={GetNotificationCount} pollInterval={600000} >
            {({ loading, error, data }) => {
                if (loading) return (<Notifications className={"icon_color "} />)
                if (error) {
                    console.log("ERROR: " + error);
                    return (
                        <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            <Notifications className={"icon_color "} />
                        </StyledBadge>)
                }
                if (data) {
                    const num_noti = data.notificationsTotal
                    let OldNotiCount = localStorage.getItem("NotificationCount") || 0
                    if (OldNotiCount !== num_noti.toString()) {
                        console.log("count changed so refetch has to be done ", OldNotiCount, " ", num_noti, " ")
                        console.log(typeof (OldNotiCount), " ", typeof (num_noti.toString()))
                        setRefetchFlag(true)
                        localStorage.setItem("NotificationCount", num_noti.toString())
                    } else {
                        console.log("same ", OldNotiCount, " ", num_noti)
                        setRefetchFlag(false)
                    }
                    if (num_noti === 0) {
                        return (
                            <Notifications className={"icon_color "} style={{ position: 'relative', top: '7%' }} />
                        )
                    }
                    else {
                        return (
                            <StyledBadge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <NotificationsActiveIcon className={"icon_color"} />
                            </StyledBadge>
                        )
                    }
                }
            }}
        </Query>
    )
}

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: 'purple',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        top: '-8%',
        right: '20%',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    Link: {
        display: 'none',
        background: 'red',
        color: "#402E3C",
    },
    whitePage: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'fixed',
    },
    ActiveAvatar_Container: {
        background: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',
        padding: '1.2px',
        borderRadius: 50,
    },
    Avatar_Container: {
        background: 'transparent',
        padding: '1.2px',
        borderRadius: 50,
    },
    smallDPContainer: {
        position: 'relative',
        marginTop: '10%',
        background: 'linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%)',
        padding: '1.5px',
        borderRadius: 50,
    },
    smallDP: {
        cursor: 'pointer',
        backgroundColor: 'orange',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    da: {
        borderRadius: 50,
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
}));

export const RefetchContext = React.createContext();


function NavBar(props) {
    const classes = useStyles();
    const [NavLogoFlag, setNavLogoFlag] = React.useState(isLoggedInVar());
    const [ActiveNavItem, setActiveNavItem] = React.useState(activeNavVar());
    const { data: RoleData } = useQuery(UserRoleDetails, { client: client });
    const { data: UserData } = useQuery(UserDetails, { client: client });
    const uri = window.location.origin;
    const userAvt = UserData !== undefined ? uri + UserData.userDetails.avatarUrl : null;
    const { data: DesktopApp } = useQuery(DownloadDA, { client });
    const [openDialog,setDialog] = React.useState(false);
    // state variables used for menu bar
    const [menu, setmenu] = React.useState(null);

    const [RefetchFlag, setRefetchFlag] = React.useState(false);

    // state variables used for opening notification and backdrop
    const [Bdopen, setBdOpen] = React.useState(false);

    const history = useHistory();
    React.useEffect(() => {
        setActiveNavItem(activeNavVar())
    }, [activeNavVar()])

    const handleBdClose = () => {
        setBdOpen(false);
    };

    const handleBdToggle = () => {
        setBdOpen(!Bdopen);
    };

    const activeNav = (event) => {
        if (activeNavVar() !== event.currentTarget.id) {
            activeMenuVar(null)
            localStorage.removeItem('SideTab')
        }
        activeNavVar("" + event.currentTarget.id);
        setActiveNavItem(activeNavVar())
        localStorage.setItem("TopNav", activeNavVar())
    }

    const logout = (event) => {
        // Flushing out all the reactive variables
        isLoggedInVar(false)
        activeNavVar("MyBots")
        activeMenuVar(null)
        sideNavActive(false)

        // Flushing out the localStorage
        localStorage.setItem("login", false)
        localStorage.setItem("sideNavActive", false)
        window.localStorage.clear()

        // Flushing out the cash data
        // history.replace("/")
        window.location.href = "/logout"
        client.clearStore()
    }
    const handleClick = (event) => {
        setmenu(event.currentTarget);
    };

    const handleClose = () => {
        setmenu(null);
    };
    return (
        <React.Fragment>
             <CustomModal
                title="Download Desktop App"
                openDialog={openDialog}
                ParentCallBackFunction={setDialog}
                render={() => {
                    return (
                        DesktopApp?
                        <DialogContent align="center">
                                <h6>Do you want to Download Deskop App ? </h6>
                                <Button    className={'button_no_bg'} style={{ float: "right", color: "purple" }} onClick={()=>setDialog(false)}>No </Button>
                                <Button  className={'button_no_bg'} style={{ float: "right", color: "purple" }}  onClick={()=>setDialog(false)}   href={ DesktopApp.downloadDesktopApp} > Yes</Button>   
                        </DialogContent>
                        :
                        <DialogContent align="center">
                        <h6>Please Reload the Page once  </h6>
                       
                        </DialogContent>
                    )
                }}
            />
            <nav className="nav">
                <div className="">
                    <div>
                        {NavLogoFlag ? null :
                            <ul className="left" style={{ cursor: 'pointer' }}>
                                <li onClick={(event) => { history.push("/") }} className="left">
                                    <img className="Navbar-logo" src={logo} alt="company logo" />
                                </li>
                            </ul>
                        }
                        <ul className="right">
                            {isLoggedInVar() ? (
                                <>
                                    <li id="MyBots" onClick={(event) => { activeNav(event); history.push("/my-bots") }} className={(ActiveNavItem === "MyBots") ? "active_nav" : null}>
                                        <Link component="a" underline="none" variant="inherit">
                                            MY BOTS
                                        </Link>
                                    </li>
                                    <li hidden={false} className={(ActiveNavItem === "BotStore") ? "active_nav" : null}>
                                        <Link id="BotStore" component="a" underline="none" variant="inherit" onClick={(event) => { activeNav(event); history.push("/bot-store") }}>
                                            BOT STORE
                                        </Link>
                                    </li>
                                    <li hidden={false} className={(ActiveNavItem === "TaskStore") ? "active_nav" : null}>
                                        <Link id="TaskStore" component="a" underline="none" variant="inherit" onClick={(event) => { activeNav(event); history.push("/task-store") }}>
                                            TASK STORE
                                        </Link>
                                    </li>
                                    <li hidden={false} className={(ActiveNavItem === "Api") ? "active_nav" : null}>
                                        <Link id="Api" onClick={(event) => { activeNav(event); history.push("/interactiveapi") }} component="a" underline="none" variant="inherit" >
                                            INTERACTIVE APIs
                                        </Link>
                                    </li>
                                    <li hidden={false} className={(ActiveNavItem === "lokipidia") ? "active_nav" : null}>
                                        <Link id="lokipidia" onClick={(event) => { activeNav(event); history.push("/lokipedia") }} component="a" underline="none" variant="inherit" >
                                            LOKIPEDIA
                                        </Link>
                                    </li>
                                    <li hidden={false}>
                                        <Tooltip TransitionComponent={Zoom} title="Download Desktop App">
                                            {DesktopApp
                                                ?
                                                <a style={{padding:"0"}} className=" button_no_bg" onClick={()=>setDialog(true)}  >
                                                    <IconButton className=" button_no_bg">
                                                        <Avatar className={classes.da}>DA</Avatar>
                                                    </IconButton>
                                                </a>
                                                :
                                                <a style={{padding:"0"}} className=" button_no_bg" onClick={()=>setDialog(true)}  >
                                                <IconButton className=" button_no_bg">
                                                    <Avatar className={classes.da}>DA</Avatar>
                                                </IconButton>
                                            </a>
                                            }


                                        </Tooltip>
                                    </li>

                                    <li style={{ padding: '2.3% 0% 0% 0%' }}>
                                        <Link className={(ActiveNavItem === "User") ? classes.ActiveAvatar_Container : classes.Avatar_Container}>

                                            <Avatar alt="User DP" src={userAvt} variant="circular" className={classes.smallDP} onClick={handleClick} />
                                        </Link>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={menu}
                                            keepMounted
                                            open={Boolean(menu)}
                                            onClose={handleClose}
                                        >
                                            {RoleData
                                                ?
                                                (RoleData.userDetails.groups.find(role => role.name === "organization_owner") || RoleData.userDetails.groups.find(role => role.name === "organization_admin"))
                                                    ?
                                                    <MenuItem id="Dashboard" onClick={(event) => { activeNav(event); history.push("/organization-info"); handleClose() }}>
                                                        <Link underline="none"  >
                                                            Admin Dashboard
                                                        </Link>
                                                    </MenuItem>
                                                    :
                                                    null
                                                :
                                                null
                                            }
                                            <MenuItem id="User" onClick={(event) => { activeNav(event); history.push("/user-info"); handleClose() }} >
                                                <Link underline="none" >
                                                    User Dashboard
                                                </Link>
                                            </MenuItem>
                                            {
                                            // localStorage.getItem('subid') != null
                                            //     ?
                                                RoleData
                                                ?
                                                (RoleData.userDetails.groups.find(role => role.name === "organization_owner") || RoleData.userDetails.groups.find(role => role.name === "organization_admin"))
                                                    ?
                                                    <MenuItem  id="Subscriptions" onClick={(event) => { activeNav(event); history.replace("/subscriptions"); handleClose() }} >
                                                        <Link underline="none" >
                                                            Subscription Dashboard
                                                        </Link>
                                                    </MenuItem>
                                                    :
                                                    null
                                                :null
                                                // :
                                                // null
                                            }

                                            <MenuItem onClick={handleClose}>
                                                <Link underline="none" onClick={logout} >
                                                    Logout
                                                </Link>
                                            </MenuItem>
                                        </Menu>
                                    </li>
                                </>) :
                                (
                                    <li className={classes.smallDPContainer}>
                                        <Avatar className={classes.smallDP} variant="circular" alt="User DP" src={generalDP} />
                                    </li>

                                )}
                            {isLoggedInVar()
                                ?
                                <li onClick={handleBdToggle} >
                                    <RefetchContext.Provider value={{ RefetchFlag, setRefetchFlag }}>
                                        <Tooltip TransitionComponent={Zoom} title="Notifications">
                                            <Link className="notification_icon" to="#!">
                                                <i className="material-icons">
                                                    <Notify />
                                                </i>
                                            </Link>
                                        </Tooltip>
                                    </RefetchContext.Provider>
                                </li>
                                :
                                null
                            }
                            {isLoggedInVar()
                                ?
                                <li style={{ marginTop: '1%' }}>
                                    <Tooltip TransitionComponent={Zoom} title="Support">
                                        <Link id="Support" component="a" underline="none" className="support" style={{ position: 'relative', top: '10%' }}
                                            onClick={(event) => { activeNav(event); history.push("/support") }} variant="inherit">
                                            <i className="material-icons" style={{ color: "gray" }}>
                                                <HelpOutline></HelpOutline>
                                            </i>
                                        </Link>
                                    </Tooltip>
                                </li>
                                :
                                null
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            <div className={classes.backdrop} open={Bdopen}>
                <RefetchContext.Provider value={{ RefetchFlag, setRefetchFlag }}>
                    <Notification BdHandler={handleBdClose} flag={Bdopen} />
                </RefetchContext.Provider>
            </div>
            {isLoggedInVar() ? <div className={classes.whitePage}>
                <SideMenu callBackFunction={setNavLogoFlag} />
            </div> : null}
        </React.Fragment>
    )
}

export default withRouter(NavBar);

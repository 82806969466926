import { gql } from '@apollo/client'
// import { gql } from 'graphql-tag';

export const MyBots = gql`
  query Bots{
    bots{
      name
      dateOfBirth
      tab {
        title
      }
      id
      description
      published
      favourite
      archived
      active

    }
}
`

export const ActiveBots = gql`
query{
  userActiveBots{
    description
    id
    name
    favourite
    lastExecutedOn
    totalSuccessfulRuns
    totalRuns
    tab{
      title
    }
    botAvatarUrl
    appsUsed
    processTags
    replaySchedules{
      id
      startsAt
      period
      frequency
      repetitions
    }
    organizationPublished
    published
  }
}
`
export const SubEstimates = gql`
query subscriptionUpdateEstimates($targetPlanId: String){
  subscriptionUpdateEstimates(targetPlanId: $targetPlanId) {
    estimateAmountDue
    estimateDescription
    estimateAmount
    estimateType
    estimateRenewal
    estimateStatus
    estimateCredits
  }
}`

export const FavoriteBots = gql`
query{
  userFavouriteBots{
    description
    id
    name
    favourite
    lastExecutedOn
    totalSuccessfulRuns
    totalRuns
    tab{
      title
    }
    botAvatarUrl
    appsUsed
    processTags
    replaySchedules{
      id
      startsAt
      period
      frequency
      repetitions
    }
  }
}
`
export const ArchivedBots = gql`
query{
  userArchivedBots{
    description
    id
    name
    favourite
    lastExecutedOn
    totalSuccessfulRuns
    totalRuns
    tab{
      title
    }
    botAvatarUrl
    appsUsed
    processTags
    replaySchedules{
      id
      startsAt
      period
      frequency
      repetitions
    }
  }
}
`
export const DeactiveBots = gql`
query{
  userDeactivatedBots{
    description
    id
    name
    favourite
    lastExecutedOn
    totalSuccessfulRuns
    totalRuns
    tab{
      title
    }
    botAvatarUrl
    appsUsed
    processTags
    replaySchedules{
      id
      startsAt
      period
      frequency
      repetitions
    }
  }
}
`

export const USERDATA_COLLECTION = gql`
  query UserData{
    notes {
      edges{
        node{
          assignedApi
          selectedApi
          id
          secretId
          quickbooksId
          dedicatedInstance
          user{
            username
            email
            
          }
          organization{
            name
            address
            invoicingDetails
            id
          }
        }
      }
    }
}`;


export const GlobalBotStoreCollection = gql`
  query GlobalBots{
    globalBots{
      id
      botAvatarUrl
      name
      description
      appsUsed
      processTags
      user{
        username
      }
    }
  }
`;

export const UserDetails = gql`
query{
  userDetails{
    lastLogin
    avatarUrl
    firstName
    lastName
    username
    id
    email
    additionalInfo{
      organization{
        name
      }  
    }
    bots{
      name
    }
  }
  }
`
export const AddonsList = gql`
query{
  subscriptionAddons {
    addonName
    addonPrice
    addonPeriodUnit
    addonId
    planId
  }
}`

export const InstanceUse = gql`
query{
  subscriptionsDashboardSidepane {
    id
    subscriptionDetails{
      instances
      users
      status
      customerName
      plan
      planDetails
      planPrice
      nextBillingDate
      currentTermStart
      trialEnd
      totalInstanceConsumption
    }
  }
}`

export const TranxList = gql`
query{
  subscriptionsChargebeeTransactions {
    date
    amount
    status
    paymentMethod
    offset
  }
}`

export const InvoiceList = gql`
query{
  subscriptionsChargebeeInvoices {
    invoiceId
    date
    amount
    status
    offset
  }
}`

export const SubscriptionData = gql`
query{
  userDetails{
    lastLogin
    avatarUrl
    firstName
    lastName
    username
    id
    email
    additionalInfo{
      organization{
        name
      }
      user{
      subscriptionDetails{
        customerName
        instances
        users
        status
        customerName
        plan
        planPrice
        planDetails
        planValidityInDays
        nextBillingDate
        totalInstanceConsumption
      }
    }
    }
    bots{
      name
    }
    
  }
}`

export const CreateBot = gql`
mutation createBot($name: String!, $description: String!, $avatarId: ID!, $tabId: ID!, $appsUsed: [String!]!, $processTags: [String!]!){
  createBot(name:$name, description: $description, avatarId: $avatarId, tabId: $tabId, appsUsed: $appsUsed, processTags: $processTags){
    bot{
      id
      name
      active
      processTags
      appsUsed
      user{
        username
      }
    }
  }
}
`
export const RegisterChargebeeCustomer = gql`
mutation RegisterChargebeeCustomer($input: ValidateAndRegisterChargebeeCustomerInput!){
  validateRegisterChargebeeCustomer(input:$input)
  {
      success
      error
  }

}`
export const LokiBotsUser = gql`
mutation LokiBotsUser($subscriptionId: String){
                        registerLokiUser(subscriptionId: $subscriptionId){
                            success
                            error
                        }
}`

export const SubscribeAddons = gql`
mutation subscribeAddons($addonId: String, $planId: String){
  subscribeAddon(addonId: $addonId, planId:$planId){
    success
    error
  }
}`

export const CreateBotCustomAvatar = gql`
mutation createBotCustomAvatar($name: String!, $description: String!, $avatar: Upload!, $tabId: ID!, $appsUsed: [String!]!, $processTags: [String!]!){
  createBot(name: $name, description: $description, avatar: $avatar, tabId: $tabId, appsUsed: $appsUsed, processTags: $processTags){
    bot{
      id
      name
      active
      processTags
      appsUsed
      user{
        username
      }
    }
  }
}
`

export const DuplicateBot = gql`
mutation cloneBot($botId: ID!){
  cloneBot(botId: $botId){
    success
    clonedBot{
      name
      tasks{
        title
        bot{
          name
        }
      }
    }
  }
} `

export const CreateProcess = gql`
mutation createProcess($tagName: String!){
  createProcess(tagName: $tagName){
    tag{
      id
      tagName
    }
  }
} `

export const CreateApp = gql`
mutation createApp($appName: String!){
  createApp(appName: $appName){
    app{
      appName
    }
  }
} `

export const SendFeedback = gql`
mutation sendFeedback($email: String!, $message: String, $subject: String, $attachment: Upload){
  sendFeedback(email: $email, message: $message, subject: $subject, attachment: $attachment){
    success
    error
  }
} `

export const EditBotDetails = gql`
mutation editBot($botId: ID!, $description: String!, $avatarId: ID!, $name: String!, $tabId: ID!){
  editBot(botId: $botId, description: $description, avatarId: $avatarId, name: $name, tabId: $tabId){
    success
    error
    bot{
      name
      id
      description
      tab{
        id
        title
      }
    }
  }
} `
export const EditBotTab = gql`
mutation editBot($botId: ID!, $tabId: ID!){
  editBot(botId: $botId, tabId: $tabId){
    success
    error
  }
} `

export const EditCustomAvatar = gql`
mutation updateBotAvatar($avatar: Upload!, $avatarId: ID!) {
  updateBotAvatar(avatar: $avatar, avatarId: $avatarId) {
    success
    error
  }
} `

export const EditBotDetailsOptional = gql`
mutation editBotOptional($botId: ID!, $description: String! $name: String!, $tabId: ID!){
  editBot(botId: $botId, description: $description, name: $name, tabId: $tabId){
    success
    error
    bot{
      name
      id
      description
      tab{
        id
        title
      }
    }
  }
} `

export const DeleteBot = gql`
mutation deleteMyBot($botId: ID!){
  deleteMyBot(botId: $botId){
    success
    error
  }
} `

export const CancelSubscription = gql`
mutation cancelSubscription{
    cancelSubscription{
      success
      error
    }
}`

export const ChangePlan = gql`
mutation changePlan($targetPlanId: String){
  changePlan(targetPlanId: $targetPlanId){
    success
    error
  }
} `
export const FavoriteTab = gql`
mutation changebotstate($botId: ID!, $favourite: Boolean!){
  changebotstate(botId: $botId, favourite: $favourite){
    success
    error
  }
}
`

export const UpdateEmailId = gql`
mutation updateUserInfo($email: String!){
  updateUserInfo(email: $email){
    user{
      email
    }
  }
}
`
export const UpdatePassword = gql`
mutation updateUserInfo($oldPassword: String!, $newPassword: String!){
  updateUserInfo(oldPassword: $oldPassword, newPassword: $newPassword) {
    success
    error
  }
}
`
export const TokenAuthentication = gql`
mutation TokenAuth($username: String!, $password: String!){
  tokenAuth(username: $username, password: $password){
    token
  }
}
`;

export const CreateRegistration = gql`
mutation($username: String!,
  $firstname: String!,
  $lastname: String!,
  $email: String!,
  $organization: String!){
  createPendingRegistration(
    inputData: {
    username: $username,
    firstname: $firstname,
    lastname: $lastname,
    email: $email,
    organization: $organization,
  })
  {
    success
    error
    pendingregistration
    {
      username
      firstname
      lastname
      email
      organization
    }
  }
}
`;




export const BOTS = gql`
query Bots {
  bots {
    description
  }
} `;


export const VERIFY_TOKEN = gql`
mutation VerifyToken($token: String) {
  verifyToken(token: $token){
    payload
  }
}
`;


export const GetNotifications = gql`
query GetNoti{
  notifications{
    id
    description
    timestamp
    verb
    recipient{
      username
    }
  }
}
`;

export const GetNotificationCount = gql`
query GetNotificationCount{
  notificationsTotal
}
`;

export const ReadNotification = gql`
mutation Readnoti($id: Int!){
  markNotificationAsRead(notificationId: $id){
    success
    error
  }
}
`;

export const ReadAllNotification = gql`
mutation Readallnoti{
  markAllNotificationAsRead{
    success
    error
  }
}
`;

export const updateUserInfo = gql`
mutation updateUserInfo($avatar: Upload){
  updateUserInfo(avatar: $avatar){
    success
    error
    user{
      avatarUrl
    }
  }
} `

export const OrganizationBots = gql`
query {
  organizationalBots{
    id
    botAvatarUrl
    name
    description
    appsUsed
    processTags
    user{
      username
    }
  }
} `;

export const OrganizationFilterBots = gql`
query organizationalBots($processTags: [String!], $appsUsed: [String!]){
  organizationalBots(processTags: $processTags, appsUsed: $appsUsed){
    id
    botAvatarUrl
    name
    description
    appsUsed
    processTags
    user{
      username
    }
  }
} `;

export const CopyTaskTo = gql`
mutation copyTaskTo($botId: ID, $taskId: ID) {
  copyTaskTo(botId: $botId, taskId: $taskId) {
    success
    error
  }
}
`;

export const CloneTask = gql`
mutation cloneTask($taskId: ID) {
  cloneTask(taskId: $taskId) {
    success
    error
    clonedTask{
      id
      title
      order
    }
  }
}
`;
export const DeleteTask = gql`
mutation deleteTask($taskId: ID) {
  deleteTask(taskId: $taskId) {
    success
    error
  }
}
`;

export const OrderDownTask = gql`
mutation ($taskId: ID) {
  orderDownTask(taskId: $taskId) {
    success
    error
  }
}
`;

export const OrderUpTask = gql`
mutation ($taskId: ID) {
  orderUpTask(taskId: $taskId) {
    success
    error
  }
}
`;

export const CancelSub = gql`
mutation cancelSubscription{
        cancelSubscription{
            success
            error
        }
}`;

export const PublishUnpublishTask = gql`
mutation publishUnpublishTask($globalPublished: Boolean, $orgPublished: Boolean, $taskId: ID) {  
  publishUnpublishTask (globalPublished: $globalPublished, orgPublished: $orgPublished, taskId: $taskId)
    {
      success
      error
  }
}
`;

export const Task = gql`
mutation task($input: TaskMutationInput!) {
  task(input: $input) {
    task {
      id
    }
    success
    error
    errors {
      field
      messages
    }
  }
}
`;

export const BotTasks = gql`
query botTasks($botId: ID) {
  botTasks(botId: $botId) {
    id
    title
    description
    order
  }
}
`;


export const ShareWithMeBots = gql`
query{
  sharedBots
  {
    id
    botAvatarUrl
    name
    description
    appsUsed
    processTags
    user{
      username
    }
  }
} `;

export const ShareFilterBots = gql`
query sharedBots($processTags: [String!], $appsUsed: [String!]){
  sharedBots(processTags: $processTags, appsUsed: $appsUsed){
    id
    botAvatarUrl
    name
    description
    appsUsed
    processTags
    user{
      username
    }
  }
} `;

export const AuditLogsData = gql`
query($username: [String!]!, $dateFrom: Date!, $dateTo: Date!, $botIds: [ID!]!, $offset: Int!, $limit: Int!){
  filterAuditLogs(userNames: $username, botIds: $botIds, dateFrom: $dateFrom, dateTo: $dateTo, offset: $offset, limit: $limit){
    user{
      id
      username
    }
    bot {
      id
      name
    }
    id
    logString
    action
    event
    dateTime
  }
  userDetails{
    lastLogin
    avatarUrl
    groups{
      name
    }
    additionalInfo{
      organization {
        userAdditionalInfo{
          user {
            id
            username
            bots{
              id
              name
            }
          }
        }
      }
    }
  }
} `;

export const FetchUserData = gql`
query {
  userDetails {
    username
    email
    additionalInfo{
      organization
      {
        name
      }
    }
  }
} `;


export const ProcessTags = gql`
query{
  processTags{
    id
    tagName
  }
} `;

export const AppsUsed = gql`
query{
  appsUsed{
    id
    appName
  }
} `;

export const OrgInfo = gql`
query OrgInfo{
  totalOrganizationBots
  userDetails{
    username
    additionalInfo{
      organization {
        name
        address
        invoicingDetails
        userAdditionalInfo{
          user {
            bots {
              id
            }
          }
        }
      }
    }
  }
} `;


export const OrgUsers = gql`
query OrgUsers{
  userDetails{
    additionalInfo{
      organization {
        userAdditionalInfo{
          user {
            id
            groups {
              id
              name
            }
            isActive
            username
            isSuperuser
            bots {
              name
              description
              dateOfBirth
              id
            }
          }
        }
      }
    }
  }
} `

export const OrgVmsPrevious =gql
`
query {
  myVmInstanceTracking(isPersonal: false,previousMonth:true){
    user,
      noTrackings,
      result{
        cost,
        uptime,  
        trackLabels,
        trackTime,
        instanceName,
        trackWeeklyLabels,
        trackMonthlyLabels,
        trackWeeklyTime,
        trackMonthlyTime,
        username,
      }
      labels,
      chartData,
      totalTime,
      totalCost,    
  }
}
`

export const OrgCustomDate =gql
`
query ($isPersonal:Boolean!, $tzOffset: Int!,$startDate:String!,$endDate:String!)
  {
    myVmInstanceTracking(isPersonal:$isPersonal, tzOffset:$tzOffset,startDate:$startDate,endDate:$endDate)
    {
    user,
      noTrackings,
      result{
        cost,
        uptime,  
        trackLabels,
        trackTime,
        instanceName,
        trackWeeklyLabels,
        trackMonthlyLabels,
        trackWeeklyTime,
        trackMonthlyTime,
        username,
      }
      labels,
      chartData,
      totalTime,
      totalCost,    
  }
}
`
export const OrgVMs = gql`
query OrgVMs{
  userDetails{
    additionalInfo{
      organization {
        name
        userAdditionalInfo{
          user {
            id
            username
            vmInstances {
              name
              active
              ipAddress
              idleTimeSetMinutes
            }
          }
        }
      }
    }
  }
  myVmInstanceTracking(isPersonal: false){
    user,
      noTrackings,
      result{
        cost,
        uptime,  
        trackLabels,
        trackTime,
        instanceName,
        trackWeeklyLabels,
        trackMonthlyLabels,
        trackWeeklyTime,
        trackMonthlyTime,
        username,
      }
      labels,
      chartData,
      totalTime,
      totalCost,        
  }
} `;

export const StartVmInstance = gql`
mutation startVmInstance($name : String!){
  startVmInstance(name: $name) {
    success
    error
  }
} `;

export const PublishBot = gql`
mutation publishBot($botId: Int!, $globalPublish: Boolean!, $orgPublish: Boolean!){
  publishBot(botId: $botId, globalPublish: $globalPublish, orgPublish: $orgPublish){
    success
    error
  }

} `;

export const botArchive = gql`
mutation Archive($botId: ID!, $archived: Boolean!){
  changebotstate(botId: $botId, archived: $archived){
    success
    error
  }
} `

export const botDeactivate = gql`
mutation changebotstate($botId: ID!, $favourite: Boolean!, $active: Boolean!){
  changebotstate(botId: $botId, favourite: $favourite, active: $active){
    success
    error
  }
} `;

export const botActivate = gql`
mutation Activate($botId: ID!, $active: Boolean!){
  changebotstate(botId: $botId, active: $active){
    success
    error
  }
} `;
export const StopVmInstance = gql`
mutation stopVmInstance($name : String!){
  stopVmInstance(name: $name) {
    success
    error
  }
} `;

export const ScheduledBotsStatus = gql`
query ScheduledBotsStatus{
  organizationScheduledBots {
    id
    user {
      username
      id
    }
    bot {
      id
      name
    }
    enabled
  }
} `;



export const changeScheduledBotStatus = gql`
mutation changeScheduledBotStatus($id: String!, $state: Boolean!){
  changeScheduledBotStatus(scheduledBotId: $id, state: $state){
    success
    error
  }
} `;

export const ArchivedUsersList = gql`
query ArchivedUsersList{
  archivedUsers {
    lastLogin
    username
    id
  }
  userEmailRegistrations {
    id
    email
    userRole
  }
} `;

export const ArchiveUsers = gql`
mutation ArchiveUsers($id: ID!, $value: Boolean!){
  deactivateUser(id: $id, isActive: $value){
    success
    error
  }
} `;

export const AddNewUserMutation = gql`
mutation AddNewUserMutation($email: String!, $role: String!){
  inviteNewUser(inputData: {
    email: $email
  role: $role
  }){
    success
    error
  }
} `;

export const ResendInvite = gql`
mutation ResendInvite($email: String!){
  resendInvite(email: $email){
    success
    error
  }
} `;

export const ManageBotsData = gql`
query ManageBotsData{
  totalOrganizationPublishedBots {
    id
    user{
      username
    }
    name
    published
    organizationPublished
  }
}
`;

export const PublishBotGlobal = gql`
mutation PublishBotGlobal
  ($botid: Int!,
    $global: Boolean!){
  publishBot(
    botId: $botid,
    globalPublish: $global){
    success
    error
  }
} `;

export const PublishBotOrg = gql`
mutation PublishBotOrg
  ($botid: Int!,
    $org: Boolean!){
  publishBot(
    botId: $botid,
    orgPublish: $org){
    success
    error
  }
} `;

export const ChangeRole = gql`
mutation ChangeRole($id: ID!, $role: String!){
  updateUserRole(userId: $id, role: $role) {
    success
    error
  }
}
`;

export const UploadAvatar = gql`
mutation uploadBotAvatar($avatar: Upload!){
  uploadBotAvatar(avatar: $avatar){
    success
    error
    uploadedAvatar{
      id
    }
  }
} `

export const DeleteUserBot = gql`
mutation DeleteUserBot($botId : ID!){
  deleteBot(botId: $botId){
    success
    error
  }
} `;


export const SchedulerVM = gql `
query{
  userVmInstance{
    schedulerInstance
    name
  }
}
`

export const PreviousVmTracking =gql`
query{
  userVmInstance {
    ipAddress
    startedAt
    active
    name
    user {
      id
      username
    }
    idleTimeSetMinutes
  }
  myVmInstanceTracking (isPersonal:true,tzOffset:1,previousMonth:true){
      user,
      noTrackings,
      result{
        cost,
        uptime,  
        trackLabels,
        trackTime,
        instanceName,
        trackWeeklyLabels,
        trackMonthlyLabels,
        trackWeeklyTime,
        trackMonthlyTime,
      }    
    }
}
`;
export const MyVmInstanceTracking = gql`
query{
  userVmInstance {
    ipAddress
    startedAt
    active
    name
    user {
      id
      username
    }
    idleTimeSetMinutes
  }
  myVmInstanceTracking {
      user,
      noTrackings,
      result{
        cost,
        uptime,  
        trackLabels,
        trackTime,
        instanceName,
        trackWeeklyLabels,
        trackMonthlyLabels,
        trackWeeklyTime,
        trackMonthlyTime,
      }    
    }
} `;


export const CustomDate = gql`
query ($isPersonal:Boolean!, $tzOffset: Int!,$startDate:String!,$endDate:String!)
{
  myVmInstanceTracking(isPersonal:$isPersonal, tzOffset:$tzOffset,startDate:$startDate,endDate:$endDate)
  {
    user,
    noTrackings,
    result{
      cost,
      uptime,  
      trackLabels,
      trackTime,
      instanceName,
      trackWeeklyLabels,
      trackMonthlyLabels,
      trackWeeklyTime,
      trackMonthlyTime,
    }    
  }
  userVmInstance {
    ipAddress
    startedAt
    active
    name
    user {
      id
      username
    }
    idleTimeSetMinutes
  }
}`;

export const AdjustIdleTIme = gql
`mutation($hours:Int!,$minutes:Int!,$name:String!){
  setVmIdleTime(hours:$hours,minutes:$minutes,name:$name){
    success
    error
  }
}
`

export const createNewVm = gql`
mutation($isSchedulerVm:Boolean!){
  createNewVm(isSchedulerVm:$isSchedulerVm)
  {
    success
    error
  }
}`;

export const RequestBotReport = gql `
mutation{
  botVmUsageReport(tzinfo:-19800){
    error
    success
  }
}
`
// Query related to Interactive APIs

export const globalApis = gql`
query{
  globalApis {
    id
    name
    description
    favourite
  }
} `;

export const myOrganizationApis = gql`
query{
  myOrganizationApis {
    id
    name
    description
    favourite
  }
}
`;

export const favouriteApis = gql`
query{
  favouriteApis {
    id
    name
    description
    favourite
  }
} `;

export const apiFavourite = gql`
mutation apiFavourite($id: ID!, $favourite: Boolean!){
  apiFavourite(apiId: $id, favourite: $favourite){
    success
    error
  }
} `;

export const requestApi = gql`
mutation requestApi($DESC: String!, $TITLE: String!, $URL: String!){
  requestApi(description: $DESC, title: $TITLE, url: $URL){
    success
    error
  }
} `;

export const BotAvatars = gql`
query{
  botAvatars {
    user{
      username
    }
    id
    avatarUrl
  }
} `


export const UserRoleDetails = gql`
query{
  userDetails{
    groups{
      name
    }
  }
}
`;



// query related to executing bot
export const ExecuteBot = gql`
mutation ExecuteBot($action: String!, $botId: ID!, $taskId: ID!){
  startVisual(action: $action, botId: $botId, taskId: $taskId){
    success
    error
    lokibotsUrl
  }
} `


// This is used while Executing a bot to get the list of tasks to execute
export const MyVMList = gql`
query{
  myVmInstances{
    ipAddress
    active
  }
}
`

// Query related to Bot Tabs <Read, Create, Edit, Delete> 
export const CreateNewTab = gql`
mutation createBotTab($title: String!){
  createBotTab(title: $title){
    botTab{
      title
    }
  }
} `
export const RenameBotTab = gql`
mutation($tabId: ID!, $title: String!){
  renameBotTab(tabId: $tabId, title: $title){
    success
    error
  }
} `
export const DeleteBotTab = gql`
mutation($tabId: ID!){
  deleteBotTab(id: $tabId){
    success
    error
  }
} `
export const addBotsFromBotstores = gql`
mutation($botId: ID!, $tabId: ID!, $orgStore: Boolean!){
  addBotsFromBotstores(botId: $botId, tabId: $tabId, orgStore: $orgStore)
  {
    success
    error
  }
}
`
export const FilterBot = gql`
query globalBots($processTags: [String!], $appsUsed: [String!]){
  globalBots(processTags: $processTags, appsUsed: $appsUsed){
    id
    botAvatarUrl
    name
    description
    appsUsed
    processTags
    user{
      username
    }
  }
}
`;
export const BotTabs = gql`
query{
  botTabs{
    id
    title
    activeBotsCount
    favouriteBotsCount
    archivedBotsCount
    deactivatedBotsCount
  }
} `;

export const BotTab = gql`
query botTab($id: ID){
  botTab(id: $id){
    activeBotsCount
    favouriteBotsCount
    archivedBotsCount
    deactivatedBotsCount
    title
  }
} `;

export const BotTabsTitle = gql`
query{
  botTabs{
    id
    title
  }
} `;

// Queries related to Instruction and Steps
export const Instruction = gql`
query instruction($id: ID!){
  instruction(id: $id){
    id
  }
}
`

export const TaskInstructions = gql`
query taskInstructions($taskId: ID!){
  taskInstructions(taskId: $taskId){
    id
    timestamp
    device
    keys
    dragRelCoords
    pause
    customCodeBefore
    customCodeAfter
    fullScrHttpsUrl
    fullScrAltUrl
    clickRect
    embedding
    order
    action
    description
  }
}
`
export const GetStepActions = gql`
query{
  __type(name: "InstructionAction"){
    enumValues{
      name
      description
    }
  }
}
`

// Query related to secret-Manager

export const AddSecretStep = gql`
mutation($instId: ID!, $secret: String!){
  createSecretInstruction(instId: $instId, secret: $secret){
    success
    error
    instId
  }
}
`

// Query related to Add, Delete, Edit and Clone steps

export const AddNewStep = gql`
  mutation addNewStep(
    $pause: Int!,
    $below: Boolean!,
    $instructionId: ID!,
    $description: String!,
    $customCodeAfter: String!,
    $customCodeBefore: String!,
    $instructionAction: String!,
  ){
    addNewStep(
      below: $below,
      pause: $pause,
      description: $description,
      instructionId: $instructionId,
      customCodeAfter: $customCodeAfter,
      customCodeBefore: $customCodeBefore,
      instructionAction: $instructionAction,
    ){
      success
      error
      instruction {
        id
        timestamp
      }
  }
}
`
export const DeleteStep = gql`
mutation deleteStep($instructionIds: [ID]) {
  deleteStep(instructionIds: $instructionIds) {
    success
    error
  }
}
`
export const EditStep = gql`
mutation editStep(
  $action: String
  $customCodeAfter: String
  $customCodeBefore: String
  $instructionId: ID
  $keys: String
  $pause: Int
  $clickRect: String
) {
  editStep(
    action: $action
      customCodeAfter: $customCodeAfter
      customCodeBefore: $customCodeBefore
      instructionId: $instructionId
      keys: $keys
      pause: $pause
      clickRect: $clickRect
  ) {
    success
    error
    instruction {
      id
      timestamp
      keys
      customCodeBefore
      customCodeAfter
      order
    }
  }
}
`
export const CloneStep = gql`
mutation($instructionIds: [ID]) {
  cloneSteps(instructionIds: $instructionIds) {
    success
    error
  }
}
`

// Query related to uploading screenshot

export const TaskuploadScreenshot = gql`
mutation($instructionId: ID!, $uploadedScreenshot: Upload!){
  uploadScreenshot(instructionId: $instructionId, uploadedScreenshot: $uploadedScreenshot){
    success
    error
  }
}
`

// Query related forgot password
export const ForgotPasswordCreateLink = gql`
mutation($email: String!, $urlPrefix: String!){
  forgotPasswordVerifyEmail(email: $email, urlPrefix: $urlPrefix){
    success
    error
  }
} `;

export const ResetForgotPassword = gql`
mutation($emailHash: String!, $dateTimeHash: String!, $newPassword: String!){
  passwordReset(emailHash: $emailHash, dateTimeHash: $dateTimeHash, newPassword: $newPassword){
    success
    error
  }
}
`;


// Query related to create, edit, and delete bot schedules
export const CreateNewSchedule = gql`
mutation($botId: ID!, $scheduledTime: DateTime!, $frequency: Int!, $period: String!, $repetitions: Int!, $vmName:String!){
  scheduleABot(botId: $botId, scheduledTime: $scheduledTime, frequency: $frequency, period: $period, repetitions: $repetitions,vmName:$vmName){
    success
    error
  }
}
`
export const deleteBotSchedule = gql`
mutation($schedulerBotId: String!){
  deleteScheduledBot(schedulerBotId: $schedulerBotId){
    success
    error
  }
}
`
export const UpdateBotSchedule = gql`
mutation($frequency: Int!, $period: String!, $repetitions: Int!, $scheduledTime: DateTime!, $schedulerBotId: String!, $vmName:String!){
  updateScheduledBot(frequency: $frequency, period: $period, repetitions: $repetitions, scheduledTime: $scheduledTime, schedulerBotId: $schedulerBotId, vmName:$vmName){
    success
    error
  }
}
`
export const AddTask = gql`
mutation( $botId: ID!, $orgStore: Boolean!, $taskId: ID!){
  addTaskFromTaskStore(botId: $botId, orgStore: $orgStore, taskId: $taskId)
  {
    success
    error
  }
}
`
export const GetBotScheduleData = gql`
query($botId : ID!){
  botSpecificSchedules(botId: $botId){
    startsAt
    period
    frequency
    repetitions
    id
    vmInstance{
      name
    }
  }
}
`
export const BotUsage =gql `
query{
  myBotTracking(tzOffset:-19800, mode:"this_month")
  {
    user
    noTrackings
    result
    {
      botId
      username
      botName
      uptime
      running
      successfulRuns
      botRunCount
      failedRuns
      successUptime
      failureUptime
      trackLabels
      trackTime
      trackWeeklyLabels
      trackWeeklyTime
      trackMonthlyLabels
      trackMonthlyTime
      systemWiseResults
      {
        sysName
        sysCount
        sysSuccess
        sysFailure
        sysUptime
      }
      
    }
    totalTime
    chartMsg
  }
}
`
export const CustomDateBot =gql
`
query myBotTracking($tzOffset:Int!,$startDate:String!,$endDate:String!){
  myBotTracking(tzOffset:$tzOffset, startDate:$startDate, endDate:$endDate)
  {
    user
    noTrackings
    result
    {
      
      botId
      username
      botName
      uptime
      running
      successfulRuns
      botRunCount
      failedRuns
      successUptime
      failureUptime
      trackLabels
      trackTime
      trackWeeklyLabels
      trackWeeklyTime
      trackMonthlyLabels
      trackMonthlyTime
      systemWiseResults
      {
        sysName
        sysCount
        sysSuccess
        sysFailure
        sysUptime
      }
      
    }
    totalTime
    chartMsg
  }
}
`
export const PrevBotUsage = gql
`
query{
  myBotTracking(tzOffset:-19800, mode:"prev_month")
  {
    user
    noTrackings
    result
    {
      botId
      username
      botName
      uptime
      running
      successfulRuns
      botRunCount
      failedRuns
      successUptime
      failureUptime
      trackLabels
      trackTime
      trackWeeklyLabels
      trackWeeklyTime
      trackMonthlyLabels
      trackMonthlyTime
      systemWiseResults
      {
        sysName
        sysCount
        sysSuccess
        sysFailure
        sysUptime
      }
      
    }
    totalTime
    chartMsg
  }
}
`
export const GlobalTask = gql`
query{ globalTasks
  {
    title  
    id
    description
    bot{
      name
      user{
        username
      } 
    }
  }
}
`
 export const RDPFiles= gql`
 query rdpFile($vmName:String!){
  rdpFile(vmName:$vmName)
}
 `
export const OrgTask = gql`
query {
  organizationalTasks
  {
    title
    id
    description
    bot{
      name
      user{
        username
      } 
    }
  }
}


`
export const ActiveBotId = gql`
query{
  userActiveBots{
    id
    name
  }
}
`
export const DownloadDA = gql`
  query{
    downloadDesktopApp 
  }
`
//lokipedia
export const LokipediaAuth = gql`
query {
  allCategory{
    id
    name
    content{
      id
      title
      description
      coverImage{
        imageCaption
        coverImageFile
      }
      contentvideoSet{
        caption
        videoFile
        
      }
      loginMandatory
      enabled
    }
  }
}
`
// importing required packages
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import CreateBotInitialScreen from './CreateBotInitialScreen';
import { CloseRounded, CheckRounded } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

//importing require css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";

// importing required components and pages
import { default as CreateBotTask } from "./Pages/CreateBot_TaskList";
import { default as ScheduleBot } from './Pages/CreateBot_Schedule';
import { default as Workflow } from './Pages/Workflow';

// importing required variables
import { SideNavContext } from "./sidemenu";
import { TaskProvider } from "./Pages/CreateBotContext";

// This is the connector component between two steps
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: '16%',
    },
    line: {
        height: 4,
        backgroundColor: 'gray',
        borderRadius: 1,
        marginLeft: '-50%',
        width: '100%',
        opacity: 0.5
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient(113.62deg, #441142 24.45%, #f11734 58.83%, #fa8b47 94.26%)',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: 'black',
        },
    },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        padding: '1% 4% 0% 4%',
    },
    instructions: {
        width: '100%',
    },
    Step: {
        padding: '0%',
        cursor: 'pointer',
    },
    StepBtn: {
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer',
        },
        backgroundColor: '#fff',
        border: '2px solid purple',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',

    },
    stepper: {
        padding: 0,
        width: '100%',
    },
    option: {
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        fontSize: '12px',
        marginTop: '4%',
        marginRight: '30%',
    },
    labels: {
    },
    Primarylabels: {
        fontFamily: 'Pangram',
        fontWeight: '900',
        fontSize: '16px',
        marginTop: '2%',
        width: '100%',
        color: 'black',
    },
    Secondarylabels: {
        fontFamily: 'Pangram',
        fontWeight: 'bolder',
        fontSize: '12px',
        marginTop: '-5%',
        width: '100%',
        color: 'black',
    },
    optionalLabel: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        width: '100%',
    },
    activelabel: {
        color: 'purple',
    },
    old: {
        background: `linear-gradient(180deg, #8A2387 0%, #701B6D 100%)`,
    },
    StepCheckIcon: {
        color: 'white',
        width: 34,
        height: 34,
        borderRadius: '80%',
    },
    StepCheckIcon1: {
        backgroundColor: 'white',
        color: 'white',
        width: 34,
        height: 34,
        border: '2px solid',
        borderRadius: '50%',
        marginLeft: '6.5%',
    },
    active: {
        width: 40,
        height: 40,
        background: 'linear-gradient(113.62deg, #8A2387 0%, #E94057 49.26%, #F27121 100%)',
        border: 'none',
    },
    inactive: {
        borderColor: 'gray',
        opacity: 0.5,
    },
    finishBtn: {
        width: '30%'
    },
}));



function StepperCreateBot(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(parseInt(localStorage.getItem("ActiveStep")) || 0);
    const CommonClasses = commonStyles();
    const [newBotId, setNewBotId] = React.useState(localStorage.getItem("botid") || null)
    const history = useHistory();

    const handleStep = (step, newBotID = null) => {
        console.log("skipFunction step ", step, " botid ", newBotID)
        localStorage.setItem("ActiveStep", step)
        console.log("going to step ", step)
        setActiveStep(step);
        if (newBotID)
            setNewBotId(newBotID)
        props.setbotName(localStorage.getItem('createbotname'))
    };

    React.useEffect(() => {
        props.setbotName(localStorage.getItem('createbotname'))
    }, [activeStep, props])


    const [Disable, setDisable] = React.useState(true)
    // This function will decide the component to render under different tabs
    function getStepContent(step,) {

        switch (step) {
            case 0:
                return <div>
                    <p><CreateBotInitialScreen callbackFunction={setDisable} skipFunction={handleStep} /></p>
                </div>
            case 1:
                return <div>
                    <CreateBotTask skipFunction={handleStep} botName={localStorage.getItem("createbotname")} botId={newBotId} />
                </div>
            /* case 2:
                 return <div>
                     <Workflow skipFunction={handleStep} />
                 </div>*/
            case 2:
                return <div>
                    <ScheduleBot newBotID={newBotId} saveFlag={false} skipFunction={handleStep} />
                </div>
            case 3:
                return <>
                    <div style={{ marginTop: "4%" }}>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={1}>

                            <Grid item xs={12}>
                                <h4 style={{ textAlign: "center", marginLeft: '-5%' }}>Your Bot is ready </h4>
                            </Grid>
                            {/* for allignment  */}
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}  >
                                <Button
                                    style={{ width: "30%", borderCollapse: 'borderCollapse', color: "purple", fontWeight: 'bold', fontFamily: "Pangram", textTransform: "initial" }}
                                    className={"button_no_bg"} onClick={(event) => { history.replace("/my-bots") }}
                                >Execute Bot
                                    <ArrowForwardIcon style={{ color: "purple" }} />
                                </Button>

                            </Grid>
                            <Grid item xs={4}>

                                <Button
                                    className={`gradient_round_btn ${classes.finishBtn} ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn}`}
                                    variant="contained" onClick={(event) => { history.replace("/my-bots") }}>
                                    Finish
                                </Button>
                            </Grid>

                        </Grid>
                    </div>

                </>
            default:
                return <div>
                    <p>Error</p>
                </div>
        }
    }
    return (
        <div>
            <div>
                <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {props.steps.map((data, index) => {
                        return (
                            <Step disabled={Disable} onClick={() => Disable ? null : handleStep(index)} className={classes.Step} key={index}>
                                {index === 0 ?
                                    <>
                                        <StepLabel
                                            className={(index === activeStep) ? `${classes.StepBtn} ${classes.active}` : (index < activeStep) ? `${classes.StepBtn} ${classes.old}` : `${classes.StepBtn} ${classes.inactive}`}
                                        >
                                            <div className={index === activeStep ? classes.StepCheckIcon1 : classes.StepCheckIcon}>
                                                {index < activeStep ? <CheckRounded style={{ marginLeft: '14%', marginTop: '20%', fontWeight: 900, fontSize: '150%' }} /> : null}
                                            </div>
                                        </StepLabel>
                                        <Typography>
                                            <Typography className={(index === activeStep) ? `${classes.labels} ${classes.activelabel}` : (index < activeStep) ? `${classes.labels}` : `${classes.labels} ${classes.inactive}`}>
                                                <p className={classes.Primarylabels}>{data[0]}<sup style={{ fontSize: "medium" }}>*</sup></p>
                                                <p className={classes.Secondarylabels}>{data[1]}</p>
                                            </Typography>
                                        </Typography>
                                    </>
                                    :
                                    <div>

                                        <StepLabel
                                            className={(index === activeStep) ? `${classes.StepBtn} ${classes.active}` : (index < activeStep) ? `${classes.StepBtn} ${classes.old}` : `${classes.StepBtn} ${classes.inactive}`}
                                        >
                                            <div className={index === activeStep ? classes.StepCheckIcon1 : classes.StepCheckIcon}>
                                                {index < activeStep ? <CheckRounded style={{ marginLeft: '21%', marginTop: '20%', fontWeight: 900, fontSize: '150%' }} /> : null}
                                            </div>
                                        </StepLabel>
                                        <Typography >
                                            <Typography className={(index === activeStep) ? `${classes.labels} ${classes.activelabel}` : (index < activeStep) ? `${classes.labels}` : `${classes.labels} ${classes.inactive}`}>
                                                <div className={classes.optionalLabel}>
                                                    <p className={classes.Primarylabels}>{data[0]} </p>

                                                </div>
                                                <p className={classes.Secondarylabels}>{data[1]}</p></Typography>
                                        </Typography>
                                    </div>
                                }
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <div>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            </div>
        </div>
    );
}

export default function CreateBotTopSection() {
    const classes = useStyles();
    const commonClasses = commonStyles()
    const history = useHistory();
    const { setSideNavFlag } = React.useContext(SideNavContext);
    let disableVar = false;
    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);
    const [botName, setbotName] = React.useState("")

    const setbotNameFun = (data) => {
        setbotName(data)
    }

    React.useEffect(() => {
        console.log("Bot Name changed")
    }, [botName])

    return (
        <TaskProvider>
            <div>
                <Grid className={classes.rootContainer} container >
                    <Grid item md={9} sm={9}>
                        <h5 className={commonClasses.title + " left"}> Create Bot </h5>
                    </Grid>
                    <Grid item md={9} sm={9} style={{ padding: '1% 0% 1% 0%' }}>
                        {botName ? <h5 className={commonClasses.title + " left"}> Bot Name: {botName}</h5> : null}
                    </Grid>
                    <Grid item md={3} sm={3} style={{ borderCollapse: 'borderCollapse', }}>
                        <Button className={"right button_no_bg"} style={{ borderCollapse: 'borderCollapse' }} onClick={(event) => {
                            history.replace("/my-bots");
                            localStorage.setItem("createbotname", "");
                            localStorage.setItem("avatarimg", null);
                            localStorage.setItem("createbotdes", "");
                            localStorage.setItem("createbottab", "");
                            localStorage.setItem("badge", JSON.stringify(true));
                            localStorage.setItem("btnFlag", JSON.stringify(false));
                            localStorage.setItem("createbotapp", JSON.stringify([]));
                            localStorage.setItem("createbotPT", JSON.stringify([]));
                            localStorage.setItem("disable", JSON.stringify(disableVar));
                            localStorage.setItem("ActiveStep", 0);
                        }}><CloseRounded />
                        </Button>
                    </Grid>
                    <Grid item md={12} style={{ marginLeft: '1.5%' }} >
                        <StepperCreateBot setbotName={setbotNameFun} steps={[["Bot Details", "Basic Information about the bot"], ["Task Automation", "List of Tasks & Dependencies"], ["Schedule", "Execution frequency & repetition"], ["Finish", "Finish"]]} ></StepperCreateBot>
                        {/* <StepperCreateBot setbotName={setbotName} steps={[["Bot Details", "Basic Information about the bot"], ["Task Automation", "List of Tasks & Dependencies"], ["Approval Workflow", "Approval for the generated tasks"], ["Schedule", "Execution frequency & repetition"], ["Finish", "Finish"]]} ></StepperCreateBot> */}
                    </Grid>
                </Grid>
            </div>
        </TaskProvider>
    );
}
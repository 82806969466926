// importing required packages
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import { commonStyles } from "../../styles/css/CommonCSS";
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from '@date-io/date-fns';
import "react-clock/dist/Clock.css"
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { AddRounded, DeleteRounded, DoneRounded, ExpandMoreRounded, CloseRounded, UndoRounded, ArrowForwardRounded, AccessTimeRounded } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, DialogContent, IconButton, LinearProgress, Snackbar, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { MdSystemUpdateAlt } from "react-icons/md";
import { useMutation, useLazyQuery, useQuery } from 'react-apollo';
import 'date-fns';

// importing required css
import '../../styles/css/common.css';
import '../../styles/css/CommonCSS';

// importing required queries
import { CreateNewSchedule, deleteBotSchedule, UpdateBotSchedule, GetBotScheduleData, SchedulerVM } from "../../graphql/queries";
import { client } from "../../graphql/apolloClient";

// importing required components/Pages
import { CustomModal } from "../modal";
import { RecordReplayBot } from "../Record_Replay_Bot";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '0%',
        borderCollapse: 'collapse',
    },
    heading: {
        textAlign: 'left',
        float: 'left',
        color: 'black',
        marginTop: '3%',
        marginLeft: '2%',

    },
    skip: {
        fontSize: "14px",
        textAlign: 'right',
        float: 'right',
        color: 'purple',
        background: 'none',
        textTransform: "initial",
        border: 'none',
        marginLeft: '7%',
        marginTop: '-3%',
        marginRight: '2%',
        textDecorationColor: 'purple',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        marginLeft: '5%',
    },
    container: {
        textAlign: 'center',
        borderRadius: '12px',
        marginTop: '0.5%',
    },
    close: {
        float: 'right',
        color: 'purple',
        background: 'none',
        marginRight: '0%',
        maxHeight: '1%',
        marginBottom: '-5%',
    },
    AddNewSchedule: {
        background: '#ebe6e6',
        borderRadius: '0px 0px 7px 7px',
        padding: '1%',
        fontSize: '12px',
        textTransform: 'capitalize',
    },
    finishBtn: {
        marginRight: "5%",
        marginTop: "5%",
        float: 'right',
        width: "10%"
    }
}));


const mapPeriod = { once: "time", hourly: "hour(s)", daily: "day(s)", weekly: "week(s)", monthly: "month(s)" }
const RefetchScheduleDataContext = React.createContext();



function ScheduleDataDisplay(props) {
    const classes = useStyles();
    const [flag, setFlag] = React.useState(false);
    const data = props.data
    const { setrefetchFlag } = React.useContext(RefetchScheduleDataContext);

    const [updateBotSchedule, { loading: updateScheduleLoading }] = useMutation(UpdateBotSchedule, {
        onCompleted(data) {
            if (data.updateScheduledBot.success) {
                console.log("Successfully updated the schedule data " + JSON.stringify(data))
                BotDataChange("remove")
                props.setSnackMessageCallBack("Successfully updated the bot schedule.")
                setrefetchFlag(true)
            } else {
                console.log("graphql error  " + JSON.stringify(data))
                props.setSnackMessageCallBack("Error: there was problem in updating the schedule. Please try again later. ")
            }
        }
    });

    const [botData, setBotData] = React.useState({
        date: new Date(data.startsAt),
        time: new Date(data.startsAt),
        period: data.period.toLowerCase(),
        frequency: data.frequency,
        repetitions: data.repetitions,
        vmName: data.vmInstance ?data.vmInstance.name:data.vmInstance,
    })

    const handleChange = (event, panel) => {
        props.setexpandedCallBack(props.expanded === panel ? false : panel);
    };

    const changeScheduleData = async () => {
        let dateTime = new Date(botData.date.toDateString() + " " + botData.time.toLocaleTimeString())
        console.log("The action is change id is " + data.id)
        console.log("The Data to be updated are " + JSON.stringify(botData))
        console.log("Date: ", botData.date.toDateString())
        console.log("Time: ", botData.time.toLocaleTimeString())
        console.log("DateTime: ", dateTime)
        console.log("period: ", botData.period)
        console.log("frequency: ", botData.frequency)
        console.log("repetitions: ", botData.repetitions)
        try {
            await updateBotSchedule({
                variables: {
                    frequency: botData.frequency,
                    period: botData.period,
                    repetitions: botData.repetitions,
                    schedulerBotId: data.id,
                    scheduledTime: dateTime,
                    vmName: botData.vmName,
                }
            })
        } catch (error) {
            console.log("Error on updating the schedule data " + error)
            props.setSnackMessageCallBack("Error: there was problem in updating the schedule. Please try again later.")
        }
    }

    const undoScheduleData = () => {
        console.log("The action is undo id is " + props.scheduleId)
        setBotData({
            date: new Date(data.startsAt),
            time: new Date(data.startsAt),
            period: data.period.toLowerCase(),
            frequency: data.frequency,
            repetitions: data.repetitions,
            vmName: data.vmInstance? data.vmInstance.name : data.vmInstance
        })
        BotDataChange("remove")
    }

    function handleState(event, key) {
        console.log("index ", event, " key ", key)
        setFlag(true);
        switch (key) {
            case "frequency":
                if (event.target.value)
                    setBotData({ ...botData, "frequency": parseInt(event.target.value) })
                else
                    setBotData({ ...botData, "frequency": "" })
                break
            case "date":
                setBotData({ ...botData, date: event })
                break
            case "time":
                setBotData({ ...botData, "time": event })
                break
            case "period":
                console.log("changing period to ", event.target.value)
                if (botData.period === "once")
                    setBotData({ ...botData, "frequency": 1, "period": (event.target.value) })
                else
                    setBotData({ ...botData, "period": (event.target.value) })
                break
            case "repetitions":
                if (event.target.value)
                    setBotData({ ...botData, "repetitions": parseInt(event.target.value) })
                else
                    setBotData({ ...botData, "repetitions": "" })
                break
            case "vmName":
                setBotData({ ...botData,"vmName":event.target.value})
            default:
                break
        }
    }
    const BotDataChange = useCallback((action) => {
        props.callBack(props.index, action)
        console.log("action ", action)
    }, [props])

    React.useEffect(() => {
        if (flag) {
            setFlag(false)
            if (new Date(botData.date).toDateString() !== new Date(data.startsAt).toDateString()
                || new Date(botData.time).toLocaleTimeString() !== new Date(data.startsAt).toLocaleTimeString()
                || botData.period !== data.period.toLowerCase()
                || botData.frequency !== data.frequency
                || botData.repetitions !== data.repetitions
                || botData.vmName !== data.vmInstance.name
            )
                BotDataChange("add")
            else
                BotDataChange("remove")
        }

    }, [botData, BotDataChange, data, flag, setFlag])

    return (
        <form style={{ width: '100%' }} onSubmit={(event) => { event.preventDefault(); changeScheduleData(); console.log("submit form for editing bot schedule") }}>
            <Accordion key={data.id} expanded={props.expanded === 'panel' + data.id} >
                <AccordionSummary
                    style={{ height: '7px' }}
                    expandIcon={<ExpandMoreRounded onClick={(event) => { handleChange(event, 'panel' + data.id); props.scheduleIdCallBack(data.id) }} />}
                >
                    <div style={{ fontWeight: 'bold', width: '100%' }} onClick={(event) => { handleChange(event, 'panel' + data.id); props.scheduleIdCallBack(data.id) }}>
                        <p className="valign-wrapper">
                            <AccessTimeRounded style={{ color: 'gray', marginRight: '0.5%' }} />
                            {`
                                The Bot will run ${botData.repetitions ? botData.repetitions : "__"}	
                                time(s) ${botData.period === "once" ? "" : ` in every ${botData.frequency ? botData.frequency : "__"} ${mapPeriod[(botData.period).toLowerCase()]}`}	
                                from ${!!botData.date ? `${botData.date.toLocaleDateString()}` : "__"}, ${!!botData.time ? botData.time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "__"}	
                            `}
                        </p>
                    </div>
                    {props.updateList.includes(props.index)
                        ?
                        <React.Fragment>
                            <Tooltip title="Save Changes">
                                <div
                                    disabled={updateScheduleLoading ? true : false}
                                    onClick={(event) => { props.scheduleIdCallBack(data.id); }} style={{ fontWeight: 'bold' }}
                                >
                                    <IconButton type="submit" className="button_no_bg"> {updateScheduleLoading ? <CircularProgress size={21} color="white" /> : <MdSystemUpdateAlt style={{ color: 'purple' }} />} </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip title="Undo Changes">
                                <div
                                    disabled={updateScheduleLoading ? true : false}
                                    onClick={(event) => { undoScheduleData(); props.scheduleIdCallBack(data.id) }} style={{ fontWeight: 'bold' }}>
                                    <IconButton className="button_no_bg"><UndoRounded style={{ color: 'purple' }} /></IconButton>
                                </div>
                            </Tooltip>
                        </React.Fragment>
                        :
                        null
                    }
                    <Tooltip title="Delete Schedule">
                        <div onClick={(event) => { props.setDeleteFlagCallBack(true); props.scheduleIdCallBack(data.id) }} style={{ fontWeight: 'bold' }}>
                            <IconButton className="button_no_bg"><DeleteRounded style={{ color: 'purple' }} /></IconButton>
                        </div>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container style={{ textAlign: 'left' }} alignItems={"baseline"} spacing={1}>
                        <Grid container item xs={12} justify="flex-start" alignItems={"baseline"}>
                            <Grid item xs={2} style={{ paddingLeft: '3%' }}> Date: </Grid>
                            <Grid item xs={2} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        multiline='false'
                                        // variant="inline"
                                        value={botData.date}
                                        onChange={event => handleState(event, "date")}
                                        format="MM/dd/yyyy"
                                        minDate={new Date(data.startsAt)}
                                        required
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2} style={{ paddingLeft: '3%' }}>Time: </Grid>
                            <Grid item xs={2} >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        id="time-picker"
                                        // variant="inline"
                                        multiline='false'
                                        value={botData.time}
                                        onChange={event => handleState(event, "time")}
                                        name="TimeValue"
                                        className="button_no_bg"
                                        required
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2} style={{ paddingLeft: '3%' }}>Period: </Grid>
                            <Grid item xs={2} >
                                <NativeSelect
                                    onChange={event => handleState(event, "period")}
                                    value={botData.period}
                                    name="PeriodValue"
                                    className={classes.selectEmpty}
                                    inputProps={{ 'aria-label': 'time' }}
                                >
                                    <option value={"once"}>Once</option>
                                    <option value={"hourly"}>Hour(s)</option>
                                    <option value={"daily"}>Day(s)</option>
                                    <option value={"weekly"}>Week(s)</option>
                                    <option value={"monthly"}>Month(s)</option>
                                </NativeSelect>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justify="flex-start" alignItems={"baseline"}>
                            <Grid item xs={2} style={{ paddingLeft: '3%' }}>Frequency: </Grid>
                            <Grid item xs={2} >
                                {botData.period === "once"
                                    ? <input name="FrequencyValue" type="number" value="1" min="1" max="20" placeholder="1" disabled />
                                    : <input name="FrequencyValue" required type="number" value={botData.frequency} min="1" max="20" placeholder={botData.frequency} onChange={event => handleState(event, "frequency")} />}
                            </Grid>
                            <Grid item xs={2} style={{ paddingLeft: '3%' }}>No of series: </Grid>
                            <Grid item xs={2} >
                                <input type="number" required name="SeriesValue" min="1" max="20" value={botData.repetitions} onChange={event => handleState(event, "repetitions")} />
                            </Grid>

                            {
                            props.data.vmInstance?
                                props.CheckScheduler
                                    ?
                                    <Grid item xs={2} style={{ paddingLeft: '3%' }}>Scheduler VM: </Grid>
                                    : null
                                :null
                            }
                            {
                                props.data.vmInstance?
                                    props.CheckScheduler
                                    ?
                                    <Grid item xs={2} >
                                    <NativeSelect
                                        onChange={event => handleState(event, "vmName")}
                                        value={botData.vmName}
                                        name="vmName"
                                        className={classes.selectEmpty}
                                        inputProps={{ 'aria-label': 'time' }}
                                    >
                                        <option value={""}>None</option>
                                        {
                                            props.scheduler.map((name) => (
                                                <option value={name}>{name}</option>
                                            ))
                                        }

                                    </NativeSelect>
                                    </Grid>
                                    : null
                                :null
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </form>
    )
}

function ScheduleDataDelete(props) {
    const { setrefetchFlag } = React.useContext(RefetchScheduleDataContext);
    const [deleteBotScheduleAPI, { loading: deleteScheduleLoading }] = useMutation(deleteBotSchedule, {
        onCompleted(data) {
            if (data.deleteScheduledBot.success) {
                props.setSnackMessageCallBack("Successfully deleted the bot schedule.")
            } else
                props.setSnackMessageCallBack("Error: there was problem in deleting the schedule. Please try again later.")
        }
    })

    const closeDialog = (flag) => {
        if (!deleteScheduleLoading)
            props.ParentCallBackFunction(flag)
    }

    const deleteSchedule = async () => {
        console.log("deleting schedule ", props.scheduleId)
        try {
            await deleteBotScheduleAPI({
                variables: {
                    schedulerBotId: props.scheduleId
                }
            })
            console.log("deleted successfully")
            console.log("Setting refetch flag")
            setrefetchFlag(true)
            props.ParentCallBackFunction(false)
            props.ScheduleIdCallBack(null)
        } catch (error) {
            console.log("Error: there was error deleting the schedule ", props.scheduleId + " " + error)
            props.setSnackMessageCallBack("Error: there was problem in deleting the schedule. Please try again later.")
        }
    }

    return (
        <CustomModal title="Delete Schedule" subheader="" openDialog={props.flag} ParentCallBackFunction={closeDialog}
            render={() => {
                return (
                    <DialogContent>
                        <p> Are you sure you want to delete the Schedule data for the bot <b>{props.botData}</b>.</p>
                        <Button disabled={deleteScheduleLoading ? true : false}
                            style={{ background: '#791e76', color: 'white', marginRight: '2%', fontFamily: 'DM SansB', fontSize: '14px' }}
                            onClick={(event) => { deleteSchedule() }}
                            endIcon={deleteScheduleLoading ? <CircularProgress size={21} color="white" /> : <DeleteRounded />}
                        >
                            Delete
                        </Button>
                        <Button disabled={deleteScheduleLoading ? true : false}
                            style={{ background: '#f5c9f2', color: '#290d27', marginRight: '2%', fontFamily: 'DM SansB', fontSize: '14px' }}
                            onClick={(event) => { props.ParentCallBackFunction(false) }}
                            endIcon={<CloseRounded />}
                        >
                            Cancel
                        </Button>
                    </DialogContent>
                )
            }}
        />
    )
}

function ScheduleBot(props) {
    const classes = useStyles();
    const CommonClasses = commonStyles();
    const [ScheduleState, SetSchedule] = React.useState([]);
    const [deleteFlag, setDeleteFlag] = React.useState(false);
    const [updateList, setUpdateList] = React.useState([]);
    const [executeBotFlag, setExecuteBotFlag] = React.useState(false);
    const [ScheduleId, setScheduleId] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [SnackMessage, setSnackMessage] = React.useState(null)
    const [refetchFlag, setrefetchFlag] = React.useState(false)
    const { loading, error, data: ScheduleVM } = useQuery(SchedulerVM, { client: client });
    let scheduleVMInstance = false;
    let VmScheduleName = []
    if (ScheduleVM) {
        for (const [index, value] of ScheduleVM.userVmInstance.entries()) {
            if (value.schedulerInstance) {
                scheduleVMInstance = true;
                VmScheduleName.push(value.name)
            }
        }
    }
    console.log("SVM ", VmScheduleName);
    const [GetBotScheduleDataAPI, { loading: BotScheduleDataLoading, data: BotScheduleData }] = useLazyQuery(GetBotScheduleData,
        {
            fetchPolicy: "cache-and-network",
        }
    )

    const [createNewSchedule, { loading: createScheduleLoading, data: createScheduleData }] = useMutation(CreateNewSchedule, {
        onCompleted(data) {
            RefetchScheduleData()
        }
    });

    const RefetchScheduleData = React.useCallback(() => {
        try {
            GetBotScheduleDataAPI({
                variables: {
                    botId: props.botId ? props.botId : props.newBotID
                }
            })
        } catch (error) {
            console.log("Error: Problem while refetching the schedule data")
        }
    }, [GetBotScheduleDataAPI, props])

    React.useEffect(() => {
        if (refetchFlag) {
            console.log("now refetching after delete or update")
            RefetchScheduleData()
        }
        console.log("Use Effect and refetch flag is ", refetchFlag)
        setrefetchFlag(false)
    }, [refetchFlag])

    React.useEffect(() => {
        RefetchScheduleData()
    }, [])


    const changeUpdateList = (data, action) => {
        console.log(action + "ing " + data)
        if (action === "add") {
            if (!updateList.includes(data))
                setUpdateList(updateList.concat(data))
        } else
            setUpdateList(updateList.filter((item) => item !== data))
    }

    const handleState = (index, event, field) => {

        const values = [...ScheduleState];
        console.log("event ", event)
        if (field === 1) {
            values[index]["DateValue"] = event;
        }
        else if (field === 2) {
            values[index]["TimeValue"] = event;
        }
        else if (field === 3) {
            if (event.target.value === "0") {
                values[index]["FrequencyValue"] = 1;
            }
            values[index][event.target.name] = event.target.value;
        }
        else if (field === 4) {
            values[index][event.target.name] = event.target.value;
        }
        else if (field === 5) {
            values[index][event.target.name] = event.target.value;
        }
        else if (field === 6) {
            values[index][event.target.name] = event.target.value;
        }
        SetSchedule(values)

    }

    const handleAddField = () => {
        SetSchedule([...ScheduleState,
        {
            DateValue: new Date(),
            TimeValue: new Date(),
            PeriodValue: "once",
            FrequencyValue: 1,
            SeriesValue: 1,
            vmName: "",
        }])
    }

    const handleRemoveFields = (index) => {
        const values = [...ScheduleState];
        values.splice(index, 1);
        SetSchedule(values);

    }

    const addNewSchedule = async (event, index) => {
        event.preventDefault();
        const data = ScheduleState[index]
        console.log("this is data",data)
        let scheduleDate = new Date(data.DateValue.toDateString() + " " + data.TimeValue.toLocaleTimeString())
        console.log("saving schedule number ", index)
        console.log(data.TimeValue.toLocaleTimeString(), " ", data.DateValue.toDateString(), new Date(), " ", scheduleDate)
        try {
            await createNewSchedule({
                variables: {
                    botId: props.botId ? props.botId : props.newBotID,
                    scheduledTime: scheduleDate,
                    frequency: data.FrequencyValue,
                    period: data.PeriodValue,
                    repetitions: data.SeriesValue,
                    vmName: data.vmName,
                }
            })
            handleRemoveFields(index)
        } catch (error) {
            console.log("ERROR CREATING NEW SCHEDULE.")
        }
        console.log("create schedule response " + createScheduleData)
    }

    return (
        <React.Fragment>
            <RefetchScheduleDataContext.Provider value={{ setrefetchFlag }}>
                {props.saveFlag ? null : <Button className={`${classes.skip} button_no_bg`} onClick={() => props.skipFunction(3)} >Skip</Button>}
                {executeBotFlag
                    ?

                    <RecordReplayBot action="replay" title={`Execute Bot: ${localStorage.getItem('createbotname')} `} subheader="Executing all task(s)" botId={props.newBotID} CallBack={setExecuteBotFlag} taskId={""} />
                    :
                    null
                }
                <ScheduleDataDelete setSnackMessageCallBack={setSnackMessage} scheduleId={ScheduleId} ScheduleIdCallBack={setScheduleId} flag={deleteFlag} botData={props.botName} ParentCallBackFunction={setDeleteFlag} />
                <div className={classes.root}>
                    {
                        BotScheduleData
                            ?
                            BotScheduleData.botSpecificSchedules.map((data, index) => {
                                return (
                                    <ScheduleDataDisplay
                                        key={data.id}
                                        setSnackMessageCallBack={setSnackMessage}
                                        expanded={expanded}
                                        setexpandedCallBack={setExpanded}
                                        setDeleteFlagCallBack={setDeleteFlag}
                                        scheduleId={ScheduleId}
                                        scheduleIdCallBack={setScheduleId}
                                        updateList={updateList}
                                        callBack={changeUpdateList}
                                        index={index} data={data}
                                        scheduler={VmScheduleName}
                                        CheckScheduler={scheduleVMInstance}

                                    />
                                )
                            })
                            :
                            props.ScheduleData
                                ?
                                props.ScheduleData.map((data, index) => {
                                    return (
                                        <ScheduleDataDisplay
                                            key={data.id}
                                            setSnackMessageCallBack={setSnackMessage}
                                            expanded={expanded}
                                            setexpandedCallBack={setExpanded}
                                            setDeleteFlagCallBack={setDeleteFlag}
                                            scheduleId={ScheduleId}
                                            scheduleIdCallBack={setScheduleId}
                                            updateList={updateList}
                                            callBack={changeUpdateList}
                                            index={index} data={data}
                                        />
                                    )
                                })
                                :
                                null
                    }
                    <div>
                        {BotScheduleDataLoading
                            ?
                            <div>
                                <LinearProgress style={{ color: "green" }} />
                            </div>
                            :
                            null
                        }
                    </div>
                    {ScheduleState.map((field, index) => (
                        <form onSubmit={(event) => { addNewSchedule(event, index) }}>
                            <Paper className={classes.container}>
                                <Grid key={index} container
                                 direction="row"
                                 justifyContent="center"
                                 alignItems="baseline" 
                                 style={{ textAlign: 'left' }} alignItems={"baseline"} spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                <b> <h6 className={classes.heading} >Schedule {index + 1}</h6> </b>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                <IconButton disabled={createScheduleLoading ? true : false} label={"close"} className={classes.close + " button_no_bg"} onClick={() => handleRemoveFields(index)}>
                                                    <CloseRounded />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start" alignItems={"baseline"}>
                                        <Grid item xs={2} style={{ paddingLeft: '3%' }}> Date: </Grid>
                                        <Grid item xs={2} >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    id="date-picker-dialog"
                                                    multiline='false'
                                                    value={field.DateValue}
                                                    onChange={event => handleState(index, event, 1)}
                                                    format="MM/dd/yyyy"
                                                    minDate={new Date()}
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={2} style={{ paddingLeft: '3%' }}>Time: </Grid>
                                        <Grid item xs={2} >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    id="time-picker"
                                                    multiline='false'
                                                    value={field.TimeValue}
                                                    onChange={event => handleState(index, event, 2)}
                                                    name="TimeValue"
                                                    className="button_no_bg"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={2} style={{ paddingLeft: '3%' }}>Period: </Grid>
                                        <Grid item xs={2} >
                                            <NativeSelect
                                                onChange={event => handleState(index, event, 3)}
                                                value={field.PeriodValue}
                                                name="PeriodValue"
                                                className={classes.selectEmpty}
                                                inputProps={{ 'aria-label': 'time' }}
                                                required
                                            >
                                                <option value={"once"}>Once</option>
                                                <option value={"hourly"}>Hourly</option>
                                                <option value={"daily"}>Daily</option>
                                                <option value={"weekly"}>Weekly</option>
                                                <option value={"monthly"}>Monthly</option>
                                            </NativeSelect>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start" alignItems={"baseline"}
                                     
                                    >
                                        <Grid item xs={2} style={{ paddingLeft: '3%' }}>Frequency: </Grid>
                                        <Grid item xs={2} >
                                            <input name="FrequencyValue" type="number"
                                                required value={field.FrequencyValue}
                                                min="1" max="20" placeholder={field.FrequencyValue}
                                                onChange={event => handleState(index, event, 4)}
                                                disabled={field.PeriodValue === "once" ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={2} style={{ paddingLeft: '3%' }}>No of series: </Grid>
                                        <Grid item xs={2} >
                                            <input type="number" name="SeriesValue" min="1" max="20" required value={field.SeriesValue} onChange={event => handleState(index, event, 5)} />
                                        </Grid>
                                        {
                                            scheduleVMInstance
                                                ?
                                                <Grid item xs={2} style={{ paddingLeft: '3%' }}>Scheduler VM: </Grid>
                                                : null
                                        }
                                        {
                                            scheduleVMInstance
                                                ?
                                                <Grid item xs={2} >
                                                <NativeSelect
                                                    onChange={event => handleState(index, event, 6)}
                                                    value={field.vmName}
                                                    name="vmName"
                                                    className={classes.selectEmpty}
                                                    inputProps={{ 'aria-label': 'time' }}
                                                >
                                                    <option value={""}>None</option>
                                                    {
                                                         
                                                        VmScheduleName.map((name) => (
                                                            <option value={name}>{name}</option>
                                                        ))
                                                    }

                                                </NativeSelect>
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={10}>

                                        </Grid>
                                        <Grid item xs={2} >
                                            <Button type="submit"  disabled={createScheduleLoading ? true : false}
                                                variant="contained" style={{ marginRight: '2%', maxWidth:"150px",minWidth:"100px" }} className={`gradient_round_btn  ${CommonClasses.Gradient_Btn} `}
                                                endIcon={createScheduleLoading ? <CircularProgress size={21} color="white" /> : <DoneRounded />}
                                            >
                                                Add
                                            </Button>
                                            <Button disabled={createScheduleLoading ? true : false} style={{maxWidth:"150px",minWidth:"100px",backgroundColor:"greenyellow" }} onClick={() => handleRemoveFields(index)} variant="contained" className={`gradient_round_btn  ${CommonClasses.Gradient_Btn}`}  > Cancel <CloseRounded /> </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ paddingLeft: '3%' }} item xs={12}>
                                        <b>
                                            <p>
                                                {`
                                                    The Bot will run ${field.SeriesValue ? field.SeriesValue : "__"} 
                                                    time(s) ${field.PeriodValue ? field.PeriodValue === "once" ? "" : ` in  every ${field.FrequencyValue ? field.FrequencyValue : "__"} ${mapPeriod[field.PeriodValue]}  ` : null}
                                                    from ${!!field.DateValue ? field.DateValue.toLocaleDateString() : "__"}
                                                    ${!!field.TimeValue ? field.TimeValue.toLocaleTimeString() : "__"} 
                                                `}
                                            </p>
                                        </b>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    ))}
                </div>
                <div>
                    <Button
                        startIcon={<AddRounded />}
                        fullWidth
                        className={classes.AddNewSchedule + " button_gray_bg"}
                        onClick={handleAddField}
                    >
                        Add a new Schedule
                    </Button>
                </div>
                {
                    props.saveFlag
                        ?
                        <Button
                            type='submit' disabled={true}
                            style={{ opacity: '0.5', marginLeft: "90%", marginTop: "5%", height: "4em", width: "13em" }}
                            className={"gradient_round_btn " + CommonClasses.Gradient_Btn + " " + classes.CreateBotBtn}
                            variant="contained"
                        >
                            <span style={{ fontSize: "14px", color: "#FFF", fontFamily: "Pangram", textTransform: "initial" }}>Save & Continue</span>
                        </Button>
                        :
                        <>
                            <Button
                                className={`gradient_round_btn ${classes.finishBtn} ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn}`}
                                variant="contained" onClick={() => { props.skipFunction(3) }}>
                                <span style={{ fontSize: "14px", color: "#FFF", fontFamily: "Pangram", textTransform: "initial" }}>Finish</span>
                            </Button>
                            {props.newBotID
                                ?
                                <Button
                                    style={{ marginRight: "5%", marginTop: "5%", float: 'right', width: "12%", borderCollapse: 'borderCollapse' }}
                                    onClick={(event) => { setExecuteBotFlag(true) }} className={"button_no_bg"}
                                >
                                    <span style={{ fontSize: "14px", color: "purple", fontWeight: 'bold', fontFamily: "Pangram", textTransform: "initial" }}>Execute Bot</span>
                                    <ArrowForwardRounded style={{ color: "purple", marginLeft: "2%" }} />
                                </Button>
                                :
                                <Button
                                    style={{ marginRight: "5%", marginTop: "5%", float: 'right', width: "12%", borderCollapse: 'borderCollapse' }} className={"button_no_bg"}
                                >
                                    <span style={{ fontSize: "14px", color: "purple", fontWeight: 'bold', fontFamily: "Pangram", textTransform: "initial" }}>Execute Bot</span>
                                    <ArrowForwardRounded style={{ color: "purple", marginLeft: "2%" }} />
                                </Button>
                            }
                        </>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    style={{ fontFamily: "Pangram" }}
                    open={SnackMessage ? true : false}
                    autoHideDuration={6000}
                    onClose={() => setSnackMessage(null)}
                    message={SnackMessage}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackMessage(null)}>
                                <CloseRounded fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </RefetchScheduleDataContext.Provider>
        </React.Fragment>
    );
}
export default ScheduleBot;
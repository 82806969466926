// importing Required packages
import React, { useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';

// importing required graphql queries
import { CreateRegistration, RegisterChargebeeCustomer } from "../graphql/queries";

// importing the required images
import logo from '../styles/images/company_logo_name.png'

// importing css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";
import { useMutation } from 'react-apollo';

function RegistrationModified(props) {

  const [createPendingRegistration, { data: UserData, error: RegistrationError }] = useMutation(CreateRegistration);
  const [validateRegisterChargebeeCustomer, { loading: registerLoad, error: RegiError }] = useMutation(RegisterChargebeeCustomer, {
    onCompleted(data) {
      console.log("return: ", data.validateRegisterChargebeeCustomer.success);
      if (data.validateRegisterChargebeeCustomer.success)
        document.getElementById("widget").click();
      else
        setRegMessage(data.validateRegisterChargebeeCustomer.error);
    }
  });
  const planId = props.match.params.planId;
  const [username, setUserName] = React.useState("");
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [billingAddress, setBillingAddress] = React.useState("");

  const [Message, setRegMessage] = React.useState(null);
  const [Address, showAdd] = React.useState(true);
  const CommonClasses = commonStyles()
  const history = useHistory();
  // Here we are handling the token after successful token authentication
  useEffect(() => {
    if (UserData) {
      console.log('Registration Form data', UserData);
      console.log('Success : ', UserData.createPendingRegistration.success);
      setRegMessage(null)
      localStorage.setItem("registration", UserData.createPendingRegistration.success)
      history.replace('/login')
    }
  }, [UserData, history]);

  React.useEffect(() => {
    // Runs after the first render() lifecycle
    const el = document.createElement('script');
    el.onload = () => {
      window.Chargebee.init({
        "site": 'lokibots-test',
        "publishableKey": 'test_cd8bSqcuOKnI60tHIwjGVjMgZzyPPvKcdIC'
      });
      // window.Chargebee.registerAgain();
      /*var cbInstance = window.Chargebee.getInstance();
      console.log("cbInstance: ", cbInstance);
      var cart = cbInstance.getCart();
      cart.setCustomer({ email: email });*/
      // this.setState({ chargebeeReady: true });
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);
  }, []);

  const RegistrationSubmit = async (event) => {
    event.preventDefault()
    //setRegMessage("Registering...")
    console.log('address: ', Address)
    var cbInstance = window.Chargebee.getInstance();
    console.log("cbInstance: ", cbInstance);
    var cart = cbInstance.getCart();
    cart.setCustomer({ email: email });
    //document.getElementById("widget").click();

    try {
      await validateRegisterChargebeeCustomer({
        variables: {
          input: {
            username: username,
            firstname: firstname,
            lastname: lastname,
            email: email,
            organization: organization,
            city: city,
            state: state,
            country: country,
            address: address,
            billingAddress: billingAddress
          }
        }
      })

    } catch (error) {
      console.log(error)
      setRegMessage("Some error occurred! Try Again.")
    }
  }
  const handleAddress = (checked) => {
    showAdd(!Address);
    if (Address === false) {
      console.log("add: ", address)
      setBillingAddress(address)
    }
    else {
      setBillingAddress(billingAddress)
    }

  }
  return (
    <div >
      <Grid style={{ background: 'white', position: 'absolute', width: '100%', marginTop: '0%', height: '88.5%' }} container justify="center" alignItems="center" >
        <Grid item lg={6} xs={8} className="registration_container">
          <img style={{ width: '24%', height: 'auto' }} className="login_media " src={logo} alt="Company logo" />
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <form onSubmit={RegistrationSubmit} style={{ margin: 'auto' }}>

              <Grid item xs={12} className="input_container" >
                <p className="login_header" >Sign Up</p>
              </Grid>
              <Grid item xs={12} >
                <div style={{ display: 'flex' }}>
                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="First Name" required
                    size="60"
                    value={firstname}
                    onChange={(event) => setFirstName(event.target.value)
                    } />
                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginLeft: '5%', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Last Name"
                    value={lastname}
                    onChange={(event) => setLastName(event.target.value)
                    } />
                </div>
                <div style={{ display: 'flex' }}>

                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Username" required
                    size="60"
                    value={username}

                    onChange={(event) => setUserName(event.target.value)}
                    pattern="^\S+$" />
                  <input type="email"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginLeft: '5%', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Email ID" required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)
                    } />
                </div>
                <div style={{ display: 'flex' }}>
                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Organization" required
                    value={organization}
                    onChange={(event) => setOrganization(event.target.value)
                    } />


                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginLeft: '5%', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="City" required
                    value={city}
                    onChange={(event) => setCity(event.target.value)
                    } />
                </div>
                <div style={{ display: 'flex' }}>
                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="State" required
                    value={state}
                    onChange={(event) => setState(event.target.value)
                    } />

                  <input type="text"
                    style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginLeft: '5%', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Country" required
                    value={country}
                    onChange={(event) => setCountry(event.target.value)
                    } />
                </div>
                <div>
                  <input type="text"
                    style={{ borderRadius: 4, width: '98%', border: '0.5px solid #ebe6e6', marginLeft: '0%', marginBottom: '5%', paddingLeft: '2%' }}
                    placeholder="Address" required
                    size="10"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)
                    } />
                  <FormControlLabel
                    style={{ marginTop: '-5%' }}
                    control={
                      <Checkbox
                        //checked={state.checkedB}
                        onChange={handleAddress}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Check this box if billing address is same as above"
                  />
                  {Address ?
                    <input type="text"
                      style={{ borderRadius: 4, width: '98%', border: '0.5px solid #ebe6e6', marginLeft: '0%', marginBottom: '5%', paddingLeft: '2%' }}
                      placeholder="Billing Address" required
                      size="10"
                      value={billingAddress}
                      onChange={(event) => setBillingAddress(event.target.value)
                      } />
                    : null
                  }
                </div>
              </Grid>
              <text > {Message ? Message : null} </text>
              <Grid style={{ fontFamily: 'DM Sans' }} item xs={12} className="input_container " >
                <Button type="submit" variant="contained" className={"login_btn gradient_round_btn " + CommonClasses.Gradient_Btn}  >
                  Register
                </Button>
                {registerLoad ? <CircularProgress /> : null}
                <a id="widget" href="#!" data-cb-type="checkout" data-cb-plan-id={planId} ></a>
                <br /> <br />
                Do you have an account? Back to the <a href="/login">login</a> page.
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegistrationModified;
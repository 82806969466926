// importing required packages
import React, { useState, createContext } from "react";
import { v4 as uuidv4 } from 'uuid';

export const Task = createContext();

export const TaskProvider = ({ children }) => {
  const [tasks, setTasks] = useState([]);

  const removeTask = (id) => {
    setTasks(tasks.filter((t) => t.id !== id));
  };

  const addTask = ({ botId }) => {
    setTasks([
      ...tasks,
      {
        title: "",
        id: uuidv4(),
        description: "",
        bot: botId,
        clientMutationId: "",
        isEdited: false,
        isCreated: true,
      }
    ]);
  };

  const editTask = ({ title, id, newId, description, isEdited }) => {
    setTasks([...(tasks.map(
      task => task.id === id ?
        {
          ...task,
          title,
          id: newId,
          description,
          isEdited,
          isCreated: false,
        }
        : task
    ))])
  }

  const setTaskEditable = ({ isEdited, isCreated, id }) => {
    setTasks([...(tasks.map(
      task => task.id === id ?
        { ...task, isEdited, isCreated }
        : task
    ))])
  }

  const duplicateTask = (oldTask, NewTaskId, NewTaskTitle, NewTaskOrder) => {
    console.log("Cloning task in context", JSON.stringify(oldTask), NewTaskId)

    let oldTaskIndex = undefined;
    tasks.forEach((task, index) => {
      if (task.id === oldTask.data.id) {
        oldTaskIndex = index;
        return;
      }
    })
    // console.log("index is ", oldTaskIndex)


    const duplicatedTask = { ...tasks[oldTaskIndex] }

    // console.log("Duplicate Task is ", JSON.stringify(duplicatedTask))
    duplicatedTask.id = NewTaskId;
    duplicatedTask.title = NewTaskTitle;
    duplicatedTask.order = NewTaskOrder;
    // console.log("Duplicate Task is new id ", JSON.stringify(duplicatedTask))
    const newTasks = tasks.concat(duplicatedTask)
    setTasks(newTasks)
    // console.log("New Tasks", JSON.stringify(newTasks))
  }

  return (
    <Task.Provider
      value={{
        tasks,
        setTasks,
        addTask,
        duplicateTask,
        setTaskEditable,
        editTask,
        removeTask
      }}
    >
      {children}
    </Task.Provider>
  );
};

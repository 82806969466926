import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid} from '@material-ui/core';
import { ErrorOutlineRounded} from '@material-ui/icons'


const useStyles = makeStyles((theme) => ({
    Message: {
        paddingTop: '15%',
        textAlign: 'center',
    },
}));

function ErrorPage(props){
    const classes = useStyles()
    return(
        <Grid container justify="center" className={classes.Message}>
            <Grid item>
                <ErrorOutlineRounded style={{fontSize: 100,}}/>
                <h5>Sorry, we could not locate your resource.</h5>
                <h6>Please check your request once.</h6>
            </Grid>
        </Grid>
    )
}

export default ErrorPage;
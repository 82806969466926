// importing required packages
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Checkbox, IconButton } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { CircularProgress, Tooltip, LinearProgress, Divider, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { useMutation, useQuery } from 'react-apollo';
import { ImWarning } from 'react-icons/im';
import { CancelRounded, CheckRounded, ExpandMoreRounded } from "@material-ui/icons";
import { RiImageEditLine } from 'react-icons/ri';

// imports related to the code editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools"



// importing required queries
import { EditStep, GetStepActions, AddNewStep, DeleteStep, TaskuploadScreenshot, AddSecretStep } from "../graphql/queries";

// importing required variables
import { TaskInstuctionContext } from "./Pages/CreateBot_TaskList";


const useStyles = makeStyles({
  uploadScreenshot: {
    color: 'white',
    background: '#3a414f',
    '&:hover': {
      background: 'black',
    }
  },
  paper: {
    width: "75%",
    margin: "auto",
    borderRadius: "12px",
  },
  changeWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: 500,
    height: "100%",
  },
  cardWrapper: {
    overflowY: "auto",
    width: '100%',
  },
  stateWrapper: {
    width: "100%",
    marginTop: "2%",
    padding: "1%",
  },
  boldWrapper: {
    fontWeight: "bold",
  },
  cardActionsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  activeWrapper: {
    borderRadius: "4px",
    border: "2px solid #8A2387",
  },
  cardContent: {
    overflow: "none",
    "& > *": {
      margin: 4,
    },
  },
});


export const ScreenshotActionList = ['DOUBLECLICK', 'CLICKRIGHT', 'CLICKLEFT', 'TABLEPROCESSING', 'SCROLL']

const initialInstructionState = {
  action: "",
  keys: "",
  pause: 0,
  order: 0,
  afterCode: "",
  beforeCode: "",
  fullScrHttpsUrl: '',
};

function instructionReducer(state, action) {
  switch (action.type) {
    case 'setAction':
      return {
        ...state,
        action: action.payload
      };
    case 'setKeys':
      return {
        ...state,
        keys: action.payload
      };
    case 'setOrder':
      return {
        ...state,
        order: action.payload
      };
    case 'setPause':
      return {
        ...state,
        pause: action.payload
      };
    case 'setAfterCode':
      return {
        ...state,
        afterCode: action.payload
      };
    case 'setBeforeCode':
      return {
        ...state,
        beforeCode: action.payload
      };
    case 'setInstruction':
      return {
        ...action.payload
      };
    default:
      throw new Error();
  }
}


function EditBotInstruction({ action, handleSetOpenEditBotModal }) {

  const classes = useStyles();
  const [state, dispatch] = React.useReducer(instructionReducer, initialInstructionState);
  const { EditInstructions, setrefetchInstruction } = React.useContext(TaskInstuctionContext)
  const instruction = EditInstructions
  const imageref = useRef();
  const [screenshot, setscreenshot] = useState(instruction.fullScrHttpsUrl)
  const [screenshotUpload, setscreenshotUpload] = useState(null)
  const NewPointCord = useRef([])
  const [AddBefore, setAddBefore] = React.useState(false)

  const canvasRef = React.useRef();
  const { loading: GetActionLoading, error: GetActionError, data: GetActionData, refetch } = useQuery(GetStepActions)

  localStorage.setItem("EditInstructionactionType", "single")

  if (GetActionError) {
    console.log("Error in getting the action list. so refetching")
    refetch()
  }

  const [editStep, { loading: editStepLoading }] = useMutation(EditStep, {
    onCompleted: (data) => {
      // const newInstruction = data.editStep.instruction
      // setInstructions([...instructions.map(instruction => newInstruction.id === instruction.id ? newInstruction : instruction )])
      console.log("data is " + JSON.stringify(data))
    }
  })

  // Mutation to add a new secret Step
  const [addSecretInstruction, { loading: addSecretInstructionLoading }] = useMutation(AddSecretStep, {
    onCompleted(data) {
      console.log("Success creating secret " + JSON.stringify(data))
      setrefetchInstruction(true)
      handleSetOpenEditBotModal("")
    }
  })
  // Mutation for adding new Step
  const [addInstruction, { loading: addInstructionLoading }] = useMutation(AddNewStep, {
    async onCompleted(data) {
      console.log("Success creating new step" + JSON.stringify(data))

      if (state.action === "SECRET") {
        // console.log("Now creating secret")
        await addSecretInstruction({
          variables: {
            instId: data.addNewStep.instruction.id,
            secret: state.keys
          }
        })
      }
      setrefetchInstruction(true)
      handleSetOpenEditBotModal("")
    }
  })

  const [ChangeScreenshot, { loading: ChangeScreenshotLoading, error: ChangeScreenshotError }] = useMutation(TaskuploadScreenshot, {
    onCompleted(data) {
      console.log("uploaded successfully")
    }
  });

  const uploadScreenshotFun = async (event) => {
    if (screenshotUpload) {
      try {
        await ChangeScreenshot({
          variables: {
            instructionId: instruction.id,
            uploadedScreenshot: screenshotUpload,
          }
        })
        setrefetchInstruction(true)
      } catch (error) {
        console.log("Error: while uploading screenshot " + JSON.stringify(error))
      }
    }
  }


  const EditStepFunction = async () => {
    console.log("Saving the instructions data " + JSON.stringify(state) + " " + instruction.id)
    try {
      await editStep({
        variables: {
          action: state.action,
          clickRect: NewPointCord.current[0] + "," + NewPointCord.current[1] + "," + NewPointCord.current[0] + 1 + "," + NewPointCord.current[1] + 1,
          customCodeAfter: state.afterCode,
          customCodeBefore: state.beforeCode,
          instructionId: instruction.id,
          keys: state.keys,
          pause: state.pause
        }
      })
      console.log("Successfully edited the step")
      setrefetchInstruction(true)
      handleSetOpenEditBotModal("")
    } catch (error) {
      console.log("Error while editing the instruction " + error)
    }
  }

  const AddStepFunction = async () => {
    console.log("Adding a new instructions data " + JSON.stringify(state) + " " + instruction.id + " " + AddBefore)

    try {
      await addInstruction({
        variables: {
          instructionId: instruction.id,
          instructionAction: state.action,
          below: !AddBefore,
          pause: state.pause,
          customCodeBefore: state.beforeCode,
          customCodeAfter: state.afterCode,
          description: "",
        }
      })
      console.log("Successfully Added the step")
    } catch (error) {
      console.log("Error while adding new instruction " + error)
    }
  }



  const handleKeyChange = (event) => {
    dispatch({
      type: "setAction",
      payload: event.target.value
    });
  };

  const handleKeysChange = (event) => {
    dispatch({
      type: "setKeys",
      payload: event.target.value
    });
  };

  const handleAfterCodeChange = (newValue) => {
    dispatch({
      type: "setAfterCode",
      payload: newValue
    });
  };

  const handleBeforeCodeChange = (newValue) => {
    dispatch({
      type: "setBeforeCode",
      payload: newValue
    });
  };

  useEffect(() => dispatch({
    type: "setInstruction",
    payload: {
      action: instruction.action ?? "",
      keys: instruction.keys ?? "",
      pause: instruction.pause ?? 1,
      order: instruction.order,
      afterCode: instruction.customCodeAfter ?? "",
      beforeCode: instruction.customCodeBefore ?? "",
    }
  }), [instruction])

  const handlePauseChange = (event) => {
    dispatch({
      type: "setPause",
      payload: event.target.value
    });
  };

  function resizeCanvas(canvas, width, height) {
    canvas.width = width;
    canvas.height = height;
  }

  const drawImage = () => {
    if (canvasRef === null || canvasRef.current === null)
      return
    const context = canvasRef.current.getContext('2d')
    console.log("context " + context + " " + context.canvas.width + " " + context.canvas.height + " " + screenshot)
    let img = new Image();
    img.src = screenshot;
    img.onload = () => {
      const width = window.innerWidth
      const height = window.innerWidth * (img.height / (img.width))
      resizeCanvas(context.canvas, width, height)
      context.drawImage(img, 0, 0, width, height);
      console.log("org cords are " + instruction.clickRect)

      if (NewPointCord.current && NewPointCord.current.length > 0) {
        console.log("position is ", NewPointCord.current)
        drawClickPosition(NewPointCord.current[0], NewPointCord.current[1], "white", 17)
        drawClickPosition(NewPointCord.current[0], NewPointCord.current[1], "red", 13)
      }
      else if (instruction.clickRect) {
        let click_position = (instruction.clickRect).split(",").map((Data) => parseInt(Data))
        const x = click_position[0]
        const y = click_position[1]
        console.log("data position is ", x + " " + y)
        drawClickPosition(x, y, "white", 17)
        drawClickPosition(x, y, "red", 13)
      }
    }
  }

  const ChangeScreenshotFun = (event) => {
    const img = event.target.files[0]
    console.log("image ", img)
    if (img) {
      setscreenshot(URL.createObjectURL(img))
      setscreenshotUpload(img)
      drawImage()
    }
  }

  const drawClickPosition = (x, y, color, radius) => {
    const context = canvasRef.current.getContext('2d')
    context.beginPath()
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.fillStyle = color;
    context.fill()
    context.closePath();
  }

  const getClickCord = (event) => {
    const target = event.target
    const rect = target.getBoundingClientRect();

    const elementRelativeX = event.clientX - rect.left;
    const elementRelativeY = event.clientY - rect.top;
    const x = elementRelativeX * target.width / rect.width;
    const y = elementRelativeY * target.height / rect.height;

    console.log(event.clientX + " " + x)
    console.log(event.clientY + " " + y)
    console.log(target.width + " " + target.height + " " + JSON.stringify(rect))
    NewPointCord.current = [Math.round(x), Math.round(y)]
    drawImage()
  }

  React.useEffect(() => {
    if (canvasRef.current) {
      drawImage()
      let click_position = instruction.clickRect
      console.log(click_position + " click ")
      // drawClickPosition(x,y,"white",4)
      // drawClickPosition(x,y,"red",3)
    }
  })

  return (
    <div className={classes.changeWrapper}>
      <div className={classes.cardWrapper}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            if (action === "edit")
              EditStepFunction()
            else AddStepFunction()
          }}
        >
          <CardContent className={classes.cardContent}>
            <Grid container spacing={1} direction="column">

              {action === "edit"
                ?
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="p">
                      Basic settings a
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Order"
                      defaultValue={0}
                      value={state.order}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                </React.Fragment>
                :
                <Grid item xs={12}>
                  <Typography variant="h6" component="p">
                    Add new step
                    <Button
                      size={"small"}
                      style={{ margin: '0% 0.5% 0% 0.5%', background: "#91308e", color: 'white' }}
                      variant="contained"
                      required={AddBefore}
                      onClick={() => { setAddBefore(false) }}
                      startIcon={<Checkbox checked={!AddBefore} style={{ padding: '0%', margin: '0%', color: 'white' }} />}
                    >
                      below
                    </Button>
                    or
                    <Button
                      size={"small"}
                      style={{ margin: '0% 0.5% 0% 0.5%', background: "#91308e", color: 'white' }}
                      variant="contained"
                      onClick={() => { setAddBefore(true) }}
                      startIcon={
                        <Checkbox checked={AddBefore} style={{ padding: '0%', margin: '0%', color: 'white' }} />}
                      required={!AddBefore}
                    >
                      above
                    </Button>

                    step # {EditInstructions.index}
                  </Typography>
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  select
                  label="Action"
                  value={state.action}
                  fullWidth
                  required={action === "add"}
                  onChange={handleKeyChange}
                >

                  <MenuItem disabled={true} value={""}>
                    Select a Task
                  </MenuItem>
                  {GetActionData
                    ?
                    GetActionData.__type.enumValues.map((option, index) => (
                      <MenuItem disabled={option.name === instruction.action} key={`${option.name} ${index}`} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))
                    :
                    null
                  }
                </TextField>
                {GetActionLoading ? <LinearProgress /> : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Pause"
                  fullWidth
                  required={action === "add"}
                  value={state.pause}
                  onChange={handlePauseChange}
                  type="number"
                  helperText="The pause is a max time in seconds the bot is trying to finish the step"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              {ScreenshotActionList.includes(state.action)
                ?
                null
                :
                <Grid item xs={12}>
                  <TextField
                    label="Keys"
                    required={action === "add"}
                    fullWidth
                    value={state.keys}
                    onChange={handleKeysChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    helperText="Edit the Keys field to change what keyboard keys the bot is going to press."
                  />
                </Grid>
              }
            </Grid>
            <Divider />

            {ScreenshotActionList.includes(state.action)
              ?
              <React.Fragment>
                <Accordion style={{ boxShadow: 'none', border: '0.5px solid #f0eded', borderRadius: '7px' }}>
                  <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                    <Typography variant="h7" component="p">
                      Screenshot {state.action}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid container item xs={12} style={{ position: 'relative', width: '100%' }} >
                        <canvas ref={canvasRef} onClick={(event) => getClickCord(event)} style={{ width: '100%', height: '100%', border: '0.5px solid #f0eded', }} />
                        <input accept="image/*" onChange={ChangeScreenshotFun} type="file" ref={imageref} style={{ display: 'none' }} />
                        <div>
                          <Tooltip title="Edit Screenshot">
                            <IconButton
                              style={{ position: 'absolute', right: screenshot && screenshotUpload ? '-1%' : '2%', bottom: '-2%' }}
                              size="medium"
                              onClick={() => imageref.current.click()} className={classes.uploadScreenshot}
                            >
                              <RiImageEditLine style={{ fontSize: '17px' }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                      {
                        screenshotUpload
                          ?
                          <Grid item xs={12}>
                            <Button
                              disabled={ChangeScreenshotLoading}
                              endIcon={ChangeScreenshotLoading ? <CircularProgress size={21} /> : <CheckRounded />}
                              style={{ margin: '2%' }}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                uploadScreenshotFun()
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              disabled={ChangeScreenshotLoading}
                              endIcon={<CancelRounded />}
                              style={{ margin: '2%', background: '#e91e4b', color: 'white' }}
                              variant="contained"
                              onClick={() => {
                                setscreenshot(instruction.fullScrHttpsUrl)
                                setscreenshotUpload(null)
                                imageref.current.value = null
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          :
                          null
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </React.Fragment>
              :
              null
            }
            <Accordion style={{ boxShadow: 'none', border: '0.5px solid #f0eded', borderRadius: '7px' }}>
              <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                <Typography variant="h7" component="p">
                  Custom code
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <AceEditor
                      placeholder="Custom code executed before the step"
                      mode="python"
                      theme="github"
                      name="Before"
                      onChange={handleBeforeCodeChange}
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      value={state.beforeCode}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      height="30vh"
                      width="100vh"
                      editorProps={{ $blockScrolling: true }}

                      style={{ borderRadius: '7px', width: '100%', border: "1px solid #949494" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AceEditor
                      placeholder="Custom code executed after the step"
                      mode="python"
                      theme="github"
                      name="After"
                      onChange={handleAfterCodeChange}
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      value={state.afterCode}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      height="30vh"
                      width="100vh"
                      style={{ borderRadius: '7px', width: '100%', border: "1px solid #949494" }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </CardContent>
          <div className={classes.cardActionsWrapper}>
            <CardActions>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => handleSetOpenEditBotModal("")}
                disabled={editStepLoading || addInstructionLoading ? true : false}
              >
                Close
              </Button>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                className="button_gray_bg"
                size="small"
                disabled={editStepLoading || addInstructionLoading ? true : false}
                endIcon={editStepLoading || addInstructionLoading ? <CircularProgress size={21} color="white" /> : null}
              >
                Confirm
              </Button>
            </CardActions>
          </div>
        </form>
      </div>
    </div>
  );
}

export function AddBotInstruction({ handleSetOpenEditBotModal }) {

  localStorage.setItem("EditInstructionactionType", "single")
  return (
    <EditBotInstruction action="add" handleSetOpenEditBotModal={handleSetOpenEditBotModal} />
  );
}

export function DeleteBotInstruction({ handleSetOpenEditBotModal }) {

  const classes = useStyles();
  const { EditInstructions, setrefetchInstruction, setEditInstructions } = React.useContext(TaskInstuctionContext)
  // const instruction = EditInstructions

  const [deleteInstruction, { loading: deleteInstructionLoading, error: deleteInstructionError }] = useMutation(DeleteStep, {
    onCompleted(data) {
      console.log("Success " + JSON.stringify(data))
      setEditInstructions(null)
      setrefetchInstruction(true)
      handleSetOpenEditBotModal("")
    }
  })

  const handleDeleteInstruction = async () => {
    console.log("delete instruction ", EditInstructions.instructionId + " action is " + EditInstructions.action)
    try {
      await deleteInstruction({
        variables: {
          instructionIds: EditInstructions.instructionId,
        }
      })
    } catch (error) {
      console.log("There was error while deleting the instruction ", error)
    }
  }

  return (
    <div className={classes.changeWrapper}>
      <div className={classes.cardWrapper}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item xs={1} style={{ margin: '0% 2% 0% 2%' }} >
              <ImWarning style={{ fontSize: '34px', color: 'red' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" component="p">
                The step will be deleted permanently. <br />Do you want to delete it ?
                {deleteInstructionError ? <p style={{ color: 'red', fontSize: '14px' }}>There was a problem while deleting. Please try again.</p> : null}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.cardActionsWrapper}>
          <CardActions>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              onClick={() => handleSetOpenEditBotModal("")}
              disabled={deleteInstructionLoading}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="small"
              type="submit"
              variant="outlined"
              onClick={() => handleDeleteInstruction()}
              disabled={deleteInstructionLoading}
              endIcon={deleteInstructionLoading ? <CircularProgress size={21} color="white" /> : null}
            >
              Yes
            </Button>
          </CardActions>
        </div>
      </div>
    </div>
  );
}


export default EditBotInstruction;

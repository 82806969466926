
import React, { useMemo, useEffect } from 'react'
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { CircularProgress, IconButton, Grid, TableContainer, LinearProgress } from '@material-ui/core';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { makeStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Droppable, Draggable } from 'react-beautiful-dnd';


import { StarRounded, DragIndicatorRounded, StarBorderRounded } from '@material-ui/icons';
// importing required components
import FadeMenu from './FadeMenu';
import { default as CustomizedDialogs } from './Dialog'
import { MyBotColumns } from './Column';
import { StyledTableCell, StyledTableRow } from "./TableComponent";
import { TableSkeleton } from "./skeleton";



//importing query 
import { FavoriteTab, ActiveBots, FavoriteBots, DeactiveBots, ArchivedBots } from "../graphql/queries";

// importing required variables
import { activeMenuVar, BotCount, BotTabCheck } from "../utils/cache";
import { SideNavContext } from "./sidemenu";
import Nodata from './Nodata';
import { client } from "../graphql/apolloClient";

// importing required css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';


const useStyles = makeStyles({
  BotEditable: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  BtnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:hover': {
      backgroundColor: 'transparent',
    }
  },

  paper: {
    width: '100%',
    margin: 'auto',
    borderRadius: '12px',
  },
  HideOnDrag: {
    opacity: 1,
  }
});



function MyBotDataTable(props) {

  const commonClasses = commonStyles()
  const classes = useStyles();
  const { setBotScheduleData, refetchMyBotData } = React.useContext(SideNavContext);
  const [refetchLoading, setrefetchLoading] = React.useState(false)
  localStorage.setItem("registration", "false")
  const columns = useMemo(() => MyBotColumns, [])
  const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: []
    },
  },
    useGlobalFilter,
    useSortBy,
  );

  useEffect(() => {
    setrefetchLoading(refetchMyBotData)
  }, [refetchMyBotData])

  // For setting the global filter string whenever the props.filterString is changed
  useEffect(() => {
    setGlobalFilter(props.filterString)
  }, [props.filterString, setGlobalFilter]);


  const [checkwait, setWait] = React.useState(false);
  const [changebotstate] = useMutation(FavoriteTab);
  const history = useHistory();
  const [checkId, setCheckId] = React.useState([]);
  const handleFavorite = async (id, value) => {
    if (checkId.includes(id)) {
      setCheckId(checkId.filter((item) => item !== id))
    }
    else {
      setCheckId([...checkId, id])
    }
    setWait(true);
    try {
      value
        ? await changebotstate({ variables: { botId: id, favourite: false } })
        : await changebotstate({ variables: { botId: id, favourite: true } })

      BotTabCheck(true)
      props.Refetch()
      setWait(false);
      setCheckId(checkId.filter((item) => item !== id))
    }
    catch (error) {
      console.log("Error ", error)
      setWait(false);

    }


  }

  // const grid = 8;
  const getListStyle = isDraggingOver => ({
    // width: '100%',
    overflowX: 'auto',
    background: isDraggingOver ? '#eaeae2' : 'white',
  });
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    position: isDragging ? 'absolute' : 'relative',
    margin: 0,
    padding: 0,
    // change background colour if dragging
    background: isDragging ? '#f4f5f6' : 'white',
    color: 'black',
    // styles we need to apply on draggables
    ...draggableStyle
  });


  return (
    <Grid container>
      <Grid item xs={12}>
        <Droppable droppableId="MyBotsdroppable" >
          {(provided, snapshot) => (
            <TableContainer
              ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}
              className={`${classes.paper}`}
            >
              <Table size="small"   {...getTableProps()}>
                <TableHead style={{ fontFamily: 'Pangram', textTransform: 'uppercase', border: '3px solid #440042', }} >
                  {headerGroups.map(headerGroup => (
                    <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <StyledTableCell {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps()}>
                          <div style={{ fontSize: '13px' }}>{column.render("Header")}</div>
                          <span style={{}}></span>
                        </StyledTableCell>
                      ))}
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableHead>

                <TableBody>
                  {rows.length === 0
                    ?
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center" key="data null">

                        <h6>  <SearchRoundedIcon style={{ fontSize: "30px", marginBottom: "-0.5%" }} /> No result found</h6>
                        <p>You may want to try using different keywords</p>
                      </StyledTableCell>
                    </StyledTableRow>
                    :
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <>
                          {(row.original.tab.title === activeMenuVar())
                            ?
                            <Draggable
                              key={`${row.original.id} ${i}`}
                              draggableId={row.original.id}
                              index={[row.original.name, row.original.id]}
                            >
                              {(provided, snapshot) => (
                                <StyledTableRow
                                  className={[commonClasses.rowStyle, commonClasses.TaskStep]}
                                  key={`${row.original.id} ${i}`}
                                  {...row.getRowProps()}
                                  index={i}

                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}

                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >

                                  {row.cells.map((cell, index) => {
                                    if (cell === row.cells[0]) {
                                      return (
                                        (props.Tab_Id === 0 || props.Tab_Id === 1) ?
                                          <StyledTableCell key={index}>
                                            <IconButton size="small" className={commonClasses.TaskStepDrag + " button_no_bg"}>
                                              <DragIndicatorRounded />
                                            </IconButton>
                                            <span>
                                              <IconButton style={{ padding: 0, margin: 0 }} className={commonClasses.Plain_Btn + " button_no_bg"} onClick={() => { handleFavorite(row.original.id, cell.value) }}>
                                                {
                                                  checkwait ?
                                                    checkId.includes(row.original.id)
                                                      ?
                                                      <CircularProgress size="21px" />
                                                      : (cell.value) ?
                                                        <StarRounded style={{ color: "gold" }}> </StarRounded> :
                                                        <StarBorderRounded className={commonClasses.Plain_Btn} style={{ color: "gold" }} >  </StarBorderRounded>
                                                    :
                                                    (cell.value) ?
                                                      <StarRounded style={{ color: "gold" }}> </StarRounded> :
                                                      <StarBorderRounded className={commonClasses.Plain_Btn} style={{ color: "gold" }} >  </StarBorderRounded>
                                                }
                                              </IconButton>
                                            </span>
                                          </StyledTableCell>
                                          : (props.Tab_Id === 2 || props.Tab_Id === 3
                                            ?
                                            <StyledTableCell
                                              onClick={props.Tab_Id !== 1 ? null : (event) => {
                                                setBotScheduleData(row.original);
                                                history.replace("/my-bots/bot-details");
                                                localStorage.setItem("refetch", props.Refetch);
                                                localStorage.setItem("",)
                                              }}
                                            />
                                            : null
                                          ))
                                    }
                                    else if (cell === row.cells[6]) {

                                      return (
                                        <>
                                          <StyledTableCell
                                            className={snapshot.isDragging ? classes.HideOnDrag : commonClasses.descript}
                                            style={{ fontFamily: 'DM Sans' }}
                                            key={index} {...cell.getCellProps()}
                                            onClick={props.Tab_Id !== 1 ? null : (event) => {
                                              setBotScheduleData(row.original);
                                              history.replace("/my-bots/bot-details");
                                              localStorage.setItem("refetch", props.Refetch);
                                            }}
                                          >
                                            {cell.render("Cell")}
                                          </StyledTableCell>
                                          <StyledTableCell className={snapshot.isDragging ? classes.HideOnDrag : commonClasses.descript} key={index} {...cell.getCellProps()}>
                                            <span>
                                              <FadeMenu original={row.original} refetch={props.Refetch} menuId={props.Tab_Id} botdes={row.values.description} botname={row.values.name} botId={row.original.id} />
                                            </span>
                                          </StyledTableCell>
                                        </>
                                      );
                                    }
                                    else {
                                      return (
                                        <StyledTableCell key={index} {...cell.getCellProps()}
                                          onClick={props.Tab_Id !== 1 ? null : (event) => {
                                            setBotScheduleData(row.original);
                                            history.replace("/my-bots/bot-details");
                                            localStorage.setItem("refetch", props.Refetch);
                                          }}
                                        >
                                          {cell === row.cells[2]
                                            ?
                                            <div className={`${commonClasses.BotName} ${props.Tab_Id === 1 ? classes.BotEditable : null}`}>
                                              {cell.render("Cell")}
                                            </div>
                                            :
                                            cell === row.cells[3]
                                              ?
                                              <div className={snapshot.isDragging ? classes.HideOnDrag : commonClasses.descript}>
                                                {cell.render("Cell")}
                                              </div>
                                              :
                                              <div className={cell !== row.cells[1] && snapshot.isDragging ? classes.HideOnDrag : null} style={{ fontFamily: 'DM Sans' }}>
                                                {cell.render("Cell")}
                                              </div>
                                          }
                                        </StyledTableCell>);
                                    }
                                  })}

                                </StyledTableRow>

                              )}
                            </Draggable>
                            : <div></div>
                          }
                        </>
                      )
                    })
                  }
                </TableBody>
              </Table>
              {refetchLoading
                ?
                <LinearProgress />
                :
                null
              }
              {provided.placeholder}
            </TableContainer>
          )}
        </Droppable>
      </Grid>
    </Grid>
  )
}

function ActiveBot(props) {

  const { setrefetchMyBotData, refetchMyBotData } = React.useContext(SideNavContext);
  const { loading, error, data, refetch } = useQuery(ActiveBots, {
    client: client,
    notifyOnNetworkStatusChange: refetchMyBotData,
    onCompleted(data) {
      console.log("refetch mybots setting false ")
      setrefetchMyBotData(false)
    }
  });
  let Count = BotCount()[1];
  let Check = useReactiveVar(activeMenuVar)
  React.useEffect(() => {
    console.log("CHange here at my bot  : ", activeMenuVar())
    refetch()
  }, [Check])

  if (props.refetchFlag) {
    refetch()
    props.callbackFunction(false)
  }
  if (loading && !refetchMyBotData)
    return (
      <TableSkeleton />
    );
  if (error) {
    return (
      <div>
        {console.log(error.message)}
        <CustomizedDialogs Error={error.message} />
        <h5> Graphql Query Error ! Try again later </h5>
      </div>);
  }

  if (data) {
    if (Count === 0) {
      return (
        <Nodata message="No active bots to display" description="When user creates a bot, it will appear here" />
      );
    }
    return (
      <MyBotDataTable data={data.userActiveBots} Tab_Id={props.Tab_Id} filterString={props.filterString} Refetch={refetch} count={Count} />
    );
  }
}

function FavoriteBot(props) {
  const { loading, error, data, refetch } = useQuery(FavoriteBots, { client: client });
  let Count = BotCount()[0];

  if (props.refetchFlag) {
    refetch()
    props.callbackFunction(false)
  }
  if (loading)
    return (
      <TableSkeleton />
    );
  if (error) {
    return (
      <div>
        {console.log(error.message)}
        <CustomizedDialogs Error={error.message} />
        <h5> Graphql Query Error ! Try again later </h5>
      </div>);



  }
  if (data) {
    if (Count === 0) {
      return (
        <Nodata message="No bots to display" description="When you tag a bot as favorite, it will appear here" />
      );
    }
    return (
      <div>
        <MyBotDataTable data={data.userFavouriteBots} Tab_Id={props.Tab_Id} filterString={props.filterString} Refetch={refetch} count={Count} />
      </div>
    );
  }
}


function DeactiveBot(props) {
  let Count = BotCount()[2];

  const { loading, error, data, refetch } = useQuery(DeactiveBots, { client: client });

  if (props.refetchFlag) {
    refetch()
    props.callbackFunction(false)
  }
  if (loading)
    return (
      <TableSkeleton />
    );
  if (error) {
    return (
      <div>
        {console.log(error.message)}
        <CustomizedDialogs Error={error.message} />
        <h5> Graphql Query Error ! Try again later </h5>
      </div>);


  }
  if (data) {
    if (Count === 0) {
      return (
        <Nodata message="No bots to display" description="When you deactivate a bot, it will appear here" />
      );
    }
    return (
      <div>
        <MyBotDataTable data={data.userDeactivatedBots} Tab_Id={props.Tab_Id} filterString={props.filterString} Refetch={refetch} count={Count} />
      </div>
    );
  }
}

function ArchivedBot(props) {
  const { loading, error, data, refetch } = useQuery(ArchivedBots, { client: client });
  let Count = BotCount()[3];

  if (props.refetchFlag) {
    refetch()
    props.callbackFunction(false)
  }
  if (loading)
    return (
      <TableSkeleton />
    );
  if (error) {
    return (
      <div>
        {console.log(error.message)}
        <CustomizedDialogs Error={error.message} />
        <h5> Graphql Query Error ! Try again later </h5>
      </div>);


  }
  if (data) {
    if (Count === 0) {
      return (
        <Nodata message="No bots to display" description="When you archive a bot, it will appear here" />
      );
    }
    return (
      <div>
        <MyBotDataTable data={data.userArchivedBots} Tab_Id={props.Tab_Id} filterString={props.filterString} Refetch={refetch} count={Count} />
      </div>
    );
  }
}

function MyBotTable(props) {
  const [RefetchFlag, setRefetchFlag] = React.useState(false)
  useEffect(() => {
    setRefetchFlag(true)
  }, [props.Tab_Id])

  const { refetchMyBotData } = React.useContext(SideNavContext);

  useEffect(() => {
    console.log("refetch mybots " + refetchMyBotData)
    if (refetchMyBotData)
      setRefetchFlag(true)
  }, [refetchMyBotData])

  if (props.Tab_Id === 0)
    return (<FavoriteBot refetchFlag={RefetchFlag} callbackFunction={setRefetchFlag} Tab_Id={props.Tab_Id} filterString={props.filterString} />);
  else if (props.Tab_Id === 1)
    return (<ActiveBot refetchFlag={RefetchFlag} callbackFunction={setRefetchFlag} Tab_Id={props.Tab_Id} filterString={props.filterString} />);
  else if (props.Tab_Id === 2)
    return (<DeactiveBot refetchFlag={RefetchFlag} callbackFunction={setRefetchFlag} Tab_Id={props.Tab_Id} filterString={props.filterString} />);
  else if (props.Tab_Id === 3)
    return (<ArchivedBot refetchFlag={RefetchFlag} callbackFunction={setRefetchFlag} Tab_Id={props.Tab_Id} filterString={props.filterString} />);
}

export default MyBotTable;

import bot_dp from '../styles/images/bot_dp.png';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { commonStyles } from "../styles/css/CommonCSS";
import { AddRounded } from '@material-ui/icons'
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useHistory } from 'react-router-dom';
import { SideNavContext } from "./sidemenu";
import Joyride from 'react-joyride';
import { CustomModal } from '../components/modal';
import { BotTabs, BotTab } from '../graphql/queries'
import { CreateNewTab } from '../graphql/queries';
import {  CloseRounded } from '@material-ui/icons';
import { IconButton, MenuItem, CircularProgress, DialogContent, DialogActions, Typography,Snackbar, Input } from '@material-ui/core/';
import { client } from "../graphql/apolloClient";
import { useQuery, useMutation,useLazyQuery } from 'react-apollo';
const useStyles = makeStyles((theme) => ({
    Centercontainer: {
        marginTop: '10%',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    BotImg: {
        width: '183px',
        height: '183px',
    },
    title: {
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '32px',
    },
    subtitle: {
        fontFamily: 'DM Sans',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21px',
        color: '#402E3C',
        letterSpacing: '0em'
    },
    CreateBotBtn: {
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    BotStoreLink: {
        marginLeft: '5%',
        fontWeight: 'bold',
        fontFamily: 'Pangram',
        color: 'purple',
        fontSize: '16px',
    },
    RightArrow: {
        position: 'relative',
        top: '15%',
        left: '2%',
        width: '20px',
        height: '25px'
    }
}));


function BotEmptyState() {
    const classes = useStyles();
    localStorage.setItem("registration", "false")
    const CommonClasses = commonStyles();
    const history = useHistory();
    const { setSideNavFlag } = React.useContext(SideNavContext);
    const [openDialog, setOpendialog] = React.useState(false);
    const [Title, setTitle] = React.useState("");
    const [checkTab, setCheckTab] = React.useState(true);
    const [Message, setMessage] = React.useState(null);
    const [openSnack, setOpenSnack] = React.useState(false);

    const [createBotTab, { loading: addTabLoading }] = useMutation(CreateNewTab, {
        // onCompleted(data) {
        //     history.replace("/")
        // }
    });
    const [getTabs, { loading: loadTabs, data: BotTab, error: tabError }] = useLazyQuery(BotTabs,
        {
            fetchPolicy: "cache-and-network",
        },
    );
    const handlesubmit = async (event) => {
        try {
            setCheckTab(true)
            await createBotTab({ variables: { title: Title } })
            setMessage(`${Title} Tab was added successfully.`)
            setTitle("")
            setOpenSnack(true)
            setOpendialog(false)
            getTabs()
            // localStorage.setItem("tabCount", ""+temp)
            // history.replace("/my-bots")
        }
        catch (error) {
            console.log("I am in error ",error)
            setMessage("Error: There was problem in adding a new Tab. Please try again later.")
        }
        return (false)
    }
    const handleOpen =()=>{ 
        setOpendialog(true);
    }
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnack(false);
      };
      React.useEffect(()=>{
          if(BotTab)
          {
            if(localStorage.getItem('tabCount') < BotTab.botTabs.length)
            {
            console.log("Change in the bot tab lengt ",BotTab.botTabs.length)
            localStorage.setItem('tabCount',BotTab.botTabs.length)
            if(BotTab.botTabs.length > 0)
                history.replace("/my-bots")
          }
          else{
            console.log("Condition else  ",BotTab.botTabs.length)
            if(BotTab.botTabs.length > 0)
             history.replace("/my-bots")
          }
        }
        
      },[BotTab])
    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);
    
    const handleTitle = (event) => {
        setTitle(event.target.value)
    }
    React.useEffect(()=>{
        if(localStorage.getItem('tabCount') > 0)
             history.replace("/my-bots")
    })

    const [steps, setSteps] = React.useState([
        {
            target: '#intro',
            content: 'Hello, I am loki! Welcome onboard',
            disableBeacon: 'true',
            placement: "right"
        },
        {
            target: '.one',
            content: 'It is mandatory to create a tab, so start by creating a tab',
            disableBeacon: 'true',
            placement: "top"
        },
        {
            target: '.second',
            content: 'You can create a bot either by clicking on the create bot or through the bot store',
            disableBeacon: 'true',
            placement: "left"
        },
    ])
    return (
        <React.Fragment>

            <Joyride
                steps={steps}
                continuous={true}
                styles={{
                    options: {

                        backgroundColor: 'white',
                        overlayColor: 'rgba(25, 20, 23, 0.8)',
                        primaryColor: '#000',
                        textColor: 'black',

                    }
                }}
            />
            <CssBaseline />
            <Grid container alignContent="center" alignItems="center" direction="column" className={classes.Centercontainer}>
                <img id="intro" className={classes.BotImg} size='50px' src={bot_dp} alt="This is a Bot Dp" ></img>
                <h5 className={classes.title}>Let's get started!</h5>
                <h5 className={classes.subtitle} >
                    Set the ball rolling by creating a new Tab and adding bot from the Bot Store.
                </h5>
                <br></br>
                <div className='one'>
                    <Button
                        endIcon={<AddRounded />}
                        className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn}`}
                        variant="contained"
                        size="small"
                        onClick={()=>{setOpendialog(true)}}
                    > Creat Tab
                    </Button>
                </div>
                <br></br>
                <div style={{ width: '25%', }} className="second">
                    <Button
                        endIcon={<AddRounded />}
                        className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn}`}
                        variant="contained"
                        onClick={(event) => {
                            history.replace("/my-bots/create-bot");
                            localStorage.setItem("createbotname", "");
                            localStorage.setItem("avatarimg", null)
                            localStorage.setItem("createbotdes", "");
                            localStorage.setItem("btnFlag", JSON.stringify(false));
                            localStorage.setItem("createbottab", "");
                            localStorage.setItem("badge", JSON.stringify(true));
                            localStorage.setItem("createbotapp", JSON.stringify([]));
                            localStorage.setItem("createbotPT", JSON.stringify([]));
                            localStorage.setItem("disable", JSON.stringify(false));
                            localStorage.setItem("ActiveStep", 0);
                        }
                        }>
                        Create Bot
                    </Button>
                    <Link className={classes.BotStoreLink} onClick={() => history.replace("/bot-store")} >
                        Visit Bot Store
                        <ArrowRightAltIcon className={classes.RightArrow} />
                    </Link>
                </div>
            </Grid>
            <CustomModal title="Add New Tab" subheader="" openDialog={openDialog} ParentCallBackFunction={setOpendialog}
                render={() => {
                    return (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let x = 1
                            handlesubmit(Title)
                        }
                        }>
                        <DialogContent> 
                            <DialogActions>
                                
                                <Input
                                        required
                                        autoFocus
                                        margin="dense"
                                        label="Tab Name"
                                        placeholder="Enter Tab Name"
                                        type="text"
                                        value={Title}
                                        onChange={(e) => handleTitle(e)}
                                        fullWidth
                                        disableUnderline="true"
                                    />
                                    <DialogActions>
                                        {checkTab ? <span></span> : <span style={{ color: "red" }}>TAB ALREADY EXIST </span>}
                                        <Button disabled={addTabLoading ? true : false} type="submit" style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary" endIcon={addTabLoading ? <CircularProgress color={"white"} size={21} /> : null}>
                                            Proceed
                                        </Button>
                                    </DialogActions>
                            </DialogActions>
                        </DialogContent>
                        </form>

                    )
                }}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ fontFamily: "Pangram" }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={Message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseRounded fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default BotEmptyState;
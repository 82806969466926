// importing required packages
import React from 'react';
import { useMutation, useQuery } from "react-apollo";
import { Grid, CircularProgress, Button, Select, MenuItem } from '@material-ui/core';
import { VscTasklist } from "react-icons/vsc";
import { Typography, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseRounded } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

// importing required queries
import { ExecuteBot, MyVMList } from "../graphql/queries";

// importing require variables
import { client } from "../graphql/apolloClient";


// importing required css
import { commonStyles } from "../styles/css/CommonCSS";


const useStyles = makeStyles((theme) => ({
    ModalButton: {
        position: 'relative',
        padding: '2.5%',
        fontFamily: "Pangram",
        fontWeight: 'bold',
        margin: '10% 0% 1% 25%',
        width: '50%',
    },
    ModalBody: {
        fontFamily: "DM Sans",
        margin: '2%',
        fontSize: '16px',
        color: 'black',
    },
    ModalHeaderTitle: {
        fontFamily: "Pangram",
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold',
    },
    ModalHeaderSub: {
        padding: '0%',
        marginTop: '-3%',
        fontFamily: "DM Sans",
        fontSize: '14px',
        color: 'white',
    },

    DialogTitle: {
        margin: 0,
        padding: '2% 6% 2% 6%',
        background: `linear-gradient(180deg, #8A2387 0%, #701B6D 100%)`,
        backgroundClip: 'text',
        color: 'white',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white",
    },
}));

export function RecordReplayBot(props) {

    const [vmInstance, setVmInstance] = React.useState("none")
    const classes = useStyles()
    const CommonClasses = commonStyles();

    const { data: VMListData } = useQuery(MyVMList, { client: client });

    const [executeBot, { loading: executeBotLoading }] = useMutation(ExecuteBot, {
        onCompleted(executeBotData) {
            console.log("URL DATA " + JSON.stringify(executeBotData))
            if (executeBotData && executeBotData.startVisual.lokibotsUrl) {
                console.log("success and now opening ", executeBotData.startVisual.lokibotsUrl)
                window.open(executeBotData.startVisual.lokibotsUrl)
            }
        }
    })

    const closeModal = (flag) => {
        props.CallBack(null)
    }

    // This function will execute the bot
    const executeBotFunction = async () => {
        console.log("getting the Execute url for the Bot " + props.botId + " and task id " + props.taskId)
        try {
            await executeBot({
                variables: {
                    action: props.action,
                    botId: props.botId,
                    taskId: props.taskId,
                }
            })
            if (executeBotLoading)
                console.log("Loading URL for execute")
            setVmInstance("none")
            props.CallBack(null)

        }
        catch (error) {
            console.log("Error in bot execute ", error)
        }
    }

    return (
        <Dialog className={classes.Modalroot} maxWidth="sm" fullWidth="true" open={true} onClose={(event) => closeModal(false)}>
            <MuiDialogTitle disableTypography className={classes.DialogTitle} onClose={(event) => closeModal(false)}>
                <Typography >
                    <div>
                        <p className={classes.ModalHeaderTitle}> {props.title}  </p>
                        <p className={classes.ModalHeaderSub}>{props.subheader}</p>
                    </div>
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={(event) => closeModal(false)} >
                    <CloseRounded />
                </IconButton >
            </MuiDialogTitle>
            <DialogContent dividers >
                <Grid style={{ padding: '2%' }} container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={12} md={12} ld={12}>
                        {props.action === "replay"
                            ?
                            <h5 style={{ fontSize: '21px', fontFamily: "DM SansB" }}> Execute Bot </h5>
                            :
                            <Typography variant="body2" color="textSecondary" className={classes.ModalBody}>
                                Lokibots app will start recording your screen while you perform the task to generate the steps.
                            </Typography>
                        }
                    </Grid>
                    <Grid container>
                        {/* <form style={{width: '100%'}} onSubmit={(event) => {
                                event.preventDefault()
                                executeBotFunction()
                            }}
                        > */}
                        <Grid style={{ margin: '2% 0% 5% 0%', }} item xs={12} md={12} ld={12}>
                            <Select
                                fullWidth
                                value={vmInstance}
                                onChange={(event) => setVmInstance(event.target.value)}
                            >
                                <MenuItem disabled value="none">Select an Instance</MenuItem>
                                <MenuItem value="Local Machine">This Machine</MenuItem>
                                {VMListData ?
                                    VMListData.myVmInstances.map((data, index) => {
                                        return (
                                            <MenuItem disabled={data.active ? false : true} value={data.ipAddress} style={{ fontFamily: 'DM SansB' }} key={index}>{data.ipAddress}</MenuItem>
                                        )
                                    })
                                    : null
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={12} ld={12} style={{ paddingLeft: '2%' }}>
                            {props.action === "replay"
                                ?
                                <Button fullWidth
                                    type="submit"
                                    onClick={(event) => executeBotFunction()}
                                    disabled={executeBotLoading ? true : false}
                                    endIcon={executeBotLoading ? <CircularProgress color="white" size={21} /> : <VscTasklist style={{ color: "white" }} />}
                                    style={{ background: 'purple', color: 'white', fontFamily: 'DM SansB', padding: '2%' }}
                                >
                                    Execute
                                </Button>
                                :
                                <Button fullWidth type="submit"
                                    onClick={(event) => executeBotFunction()}
                                    disabled={executeBotLoading ? true : false}
                                    variant="contained"
                                    endIcon={executeBotLoading ? <CircularProgress style={{ color: "white" }} size={21} /> : null}
                                    className={"gradient_round_btn " + CommonClasses.Gradient_Btn + " " + classes.ModalButton}
                                >
                                    Start Automating
                                </Button>
                            }
                        </Grid>
                        {/* </form> */}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

import { makeStyles } from '@material-ui/core/styles';

export const commonStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: '2%',
        fontSize: '150%',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: '0%',
    },
    Emptypanel: {
        border: '0.5px solid #ebe6e6',
        borderRadius: '0px 10px 0px 0px',
        padding: '1.5%',
    },
    panel: {
        // boxShadow: '4px 4px 21px rgba(196, 196, 196, 0.2)',
        border: '0.5px solid #ebe6e6',
        borderRadius: '0px 7px 7px 7px',
        // marginTop: '0.3%',
        padding: '0% 0% 0% 7%',

    },
    Gradient_Btn: {
        borderRadius: '32.6512px',
        color: 'white',
        fontSize: '12px',
        fontFamily: "DM Sans",
    },
    Gray_Icon: {
        color: 'gray',
    },
    Plain_Btn: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
        '&:visited ': {
            backgroundColor: 'transparent',
        },
    },
    CapitalizeText: {
        textTransform: 'capitalize',
    },
    MenuStyle: {
        fontFamily: 'DM Sans',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: '400',
        color: '#1E2749',
        textTransform: 'none'
    },
    rowStyle: {
        borderLeft: '5px solid transparent',
        fontFamily: 'DM Sans',
        fontSize: '16px',
        '&:hover': {
            borderColor: '#440042',
            transition: 'all 0.5s',
            cursor: 'pointer',
            boxShadow: '0px 0px 13px -7px #888888',
        },
    },
    BotName: {
        fontStyle: 'normal',
        fontFamily: 'DM Sans',
        fontSize: '14px',
        color: 'black',
        textTransform: 'capitalize',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    descript: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 'normal',
        // lineHeight: '15.62px',
        color: '#6e6e6e;'
    },
    TaskStep: {
        borderLeft: '5px solid transparent',
        position: 'relative',
        width: '100%',
        '&:hover': {
            borderColor: 'transparent',
            transition: 'all 0.5s',
            cursor: 'pointer',
            boxShadow: '0px 0px 20px -7px #888888',
            '& $TaskStepDrag': {
                opacity: 0.5,
                color: 'black',
            },
        },
    },
    TaskStepDrag: {
        opacity: 0,
    },
}));

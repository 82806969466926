// importing required packages
import React, { useRef, useContext } from 'react';
import { useMutation } from "react-apollo";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Query } from 'react-apollo';
import {
    Avatar, Chip, Breadcrumbs, Box, Button, Card, CardContent, CardHeader, Grid,
    Typography, DialogContent, DialogActions, IconButton, Snackbar, Badge, CircularProgress
} from '@material-ui/core';
import { EditRounded, HistoryRounded, CloseRounded } from '@material-ui/icons';
import { FaMagic } from "react-icons/fa";
import { CgChevronDoubleRight } from 'react-icons/cg';
import { HiArrowNarrowRight } from 'react-icons/hi';
import MuiAlert from '@material-ui/lab/Alert';
import { useQuery } from "react-apollo";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';


//importing required images
import avt1 from '../styles/images/avt1.PNG';
import avt2 from '../styles/images/avt2.PNG';
import avt3 from '../styles/images/avt3.PNG';

// importing required css
import '../styles/css/common.css';
import '../styles/css/navbar.css';
import { commonStyles } from "../styles/css/CommonCSS";

// importing required components and pages
import { AntTab, AntTabs, TabPanel, TabStyle } from "./TabComponent";
import { TaskList } from "./Pages/CreateBot_TaskList";
import {Statistics} from "./Pages/Bot_Statistics";
import { CustomModal } from "./modal";
import { TableSkeleton } from "./skeleton";
import Skeleton from '@material-ui/lab/Skeleton';


import { default as Dependencies } from "./Pages/CreateBot_Dependencies";
import { default as ScheduleBot } from './Pages/CreateBot_Schedule';
import { RecordReplayBot } from "./Record_Replay_Bot";

// importing required queries
import { BotTabs, EditBotDetails, EditBotDetailsOptional, EditCustomAvatar, BotAvatars, UploadAvatar, BotTasks } from "../graphql/queries";

// importing required variables
import { SideNavContext } from "./sidemenu";
import { TaskProvider, Task } from "./Pages/CreateBotContext";
//import { activeMenuVar } from '../utils/cache';

const useStyles = makeStyles((theme) => ({
    Versiondata: {
        position: 'relative',
        width: '100%',
        display: 'inline-flex',
        '&:hover $Revert': {
            display: 'block',
        },
    },
    versionName: {
        position: 'relative',
        fontFamily: "DM SansB",
        fontSize: '16px'
    },
    versionDate: {
        position: 'absolute',
        top: '40%',
        fontFamily: "DM Sans",
        color: 'gray',
        fontSize: '14px',
    },
    Revert: {
        display: 'none',
        position: 'absolute',
        top: '10%',
        right: '0%',
        float: 'right',
        width: '27%',
        cursor: 'pointer',
        '& $p': {
            fontFamily: 'DM SansB',
            fontSize: '14px',
            color: 'purple',
        }
    },
    ImageInput: {
        display: 'none',
    },
    breadcrumbs: {
        '& $p': {
            fontFamily: 'Pangram',
            cursor: 'pointer',
            fontWeight: 'bolder',
            fontSize: '14px',
            lineHeight: '2%',
        },
        '& $h1': {
            textTransform: 'capitalize',
            fontFamily: 'Pangram',
            fontWeight: 'bolder',
            fontSize: '14px',
            lineHeight: '2%',
        }
    },
    rootContainer: {
        padding: '0% 4% 0% 4%',
    },
    Cardroot: {
        borderRadius: '14px',
        border: '0.8px solid #e0dddc',
    },
    HeaderAvatar: {
        marginTop: '50%',
        width: 60,
        height: 60,
    },
    HeaderTitleTime: {
        float: 'left',
        fontFamily: 'DM Sans',
        fontSize: '12px',
        color: 'gray',
        fontWeight: 'bold',
    },
    HeaderTitle: {
        fontFamily: 'PangramB',
        fontSize: '32px',
        textTransform: 'capitalize',
    },
    SubHeader: {
        fontFamily: 'DM SansB',
        fontSize: '15px',
        opacity: 0.7,
        color: '#8C91A6',
        '&:first-letter': {
            textTransform: 'uppercase',
        }
    },
    HeaderButton: {
        float: "right",
        padding: '5% 7% 5% 7%',
        fontFamily: 'Pangram',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    HeaderButton1: {
        padding: '0% 0% 0% 4%',
        margin: '0px',
        float: 'right',
        textTransform: 'capitalize',
        fontFamily: 'Pangram',
        fontSize: '15px',
        fontWeight: 'bold',
        color: 'purple',
    },
    ScheduleData: {
        left: 80,
        marginTop: '-4.5%',
        width: '95%',
        position: 'relative',
        '& $p': {
            fontFamily: 'DM Sans',
            color: 'black',
        },
        '& $b': {
            fontFamily: 'DM Sans',
            padding: '0% 0.5% 0% 0.5%',
            color: 'black',
        },
    },
    Chipdata: {
        fontFamily: 'DM Sans',
        color: '#8C91A6',
        borderRadius: '10px',
        fontSize: '14px',
        fontWeight: 'bold',
        marginRight: '1.5%',
    },
    sizeAvatar: {
        height: theme.spacing(9),
        width: theme.spacing(9),
        background: 'light gray',
    },
    CustomAvatar: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        opacity: 0.9,
        background: 'light gray',
    },
}));

function EditBotRenderComponent() {
    const { setSideNavFlag, botScheduleData } = React.useContext(SideNavContext);

    const history = useHistory();

    const classes = useStyles();
    const TabStyleClass = TabStyle();
    const CommonClasses = commonStyles();
    const uri = window.location.origin;

    const [activetab, setActiveTabValue] = React.useState(parseInt(localStorage.getItem("ActiveEditBotTaskListTab")) || 0);
    let customAvatar = useRef(null);


    const [activeDialog, setActiveDialog] = React.useState(0);
    const [bName, setBname] = React.useState(botScheduleData ? botScheduleData.name : null);
    const [descript, setDescript] = React.useState(botScheduleData.description);
    const [BotAvatar, setBotAvatar] = React.useState(uri + botScheduleData.botAvatarUrl);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [avtid, setAvtid] = React.useState(1);
    const [error, setError] = React.useState(false);
    const [nochange, setNochange] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [disable, setDisable] = React.useState(false);
    const [opacity, setOpacity] = React.useState(1)
    const [imgFile, setImgFile] = React.useState();
    const [updateAvtId, setAvtId] = React.useState(0);
    const localBotId = botScheduleData.id;
    const [customAvtFlag, setCustomAvtFlag] = React.useState(false);
    const [DefaultAvtFlag, setDefaultAvtFlag] = React.useState(false);
    const [tabId, setTabid] = React.useState({ id: localStorage.getItem('SideTabId') }); // where we have set ? 
    const [botAvtRefetch, setBotAvtRefetch] = React.useState(false);
    const [editBot] = useMutation(EditBotDetails);
    const [editBotOptional] = useMutation(EditBotDetailsOptional)
    const [updateBotAvatar, { error: updateBotError }] = useMutation(EditCustomAvatar)

    const [uploadBotAvatar, { error: uploadError }] = useMutation(UploadAvatar, {
        onCompleted(data) {
            const avatId = data.uploadBotAvatar.uploadedAvatar.id
            const tabID = parseInt(tabId.id)
            console.log("tab id: ", tabId.id, typeof (tabId.id))
            editBot({
                variables: {
                    botId: localBotId,
                    avatarId: avatId,
                    description: descript,
                    name: bName,
                    tabId: tabID
                }
            });
            console.log("avatid: ", avatId);
        }
    })


    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);

    React.useEffect(() => {
        console.log("UpdateAvtId: ", updateAvtId);
    }, [updateAvtId]);

    const { setTasks } = useContext(Task)



    const { loading: botTasksLoading } = useQuery(
        BotTasks,
        {
            variables: {
                botId: localBotId
            },
            onCompleted: (data) => {
                const botTasks = data.botTasks
                setTasks(botTasks.map(botTask => ({
                    ...botTask,
                    isCreated: false,
                    isEdited: false,
                })))
            }
        })


    React.useEffect(() => {
        console.log("BotId: ", localBotId);
    }, [localBotId]);
    React.useEffect(() => {
        console.log("tabId edit: ", tabId.id);
    }, [tabId.id]);
    // This is temporary static data
    const versionData = [{ "version": "Current Version", "date": "October 25, 12:24 P.M" }, { "version": "Version 1", "date": "April 27, 12:24 P.M" }, { "version": "Version 2", "date": "June 20, 12:24 P.M" }]

    const changeActiveTab = (event, newValue) => {
        localStorage.setItem("ActiveEditBotTaskListTab", newValue)
        setActiveTabValue(newValue);
    };

    const closeSnack = (event) => {
        setOpenSnack(false);
    };

    const ImgUploadHandler = (event) => {
        let img = event.target.files[0];
        if (img) {
            setBotAvatar(URL.createObjectURL(img));
            customAvatar = BotAvatar;
        }
        setImgFile(img)
        setCustomAvtFlag(true)
    }
    const DialogOpen = (event, DialogNum) => {
        setTabid({ id: localStorage.getItem('SideTabId') })
        setActiveDialog(DialogNum);
        setWait(false)
        setDisable(false);
        setOpacity(1);
    }

    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            border: '1px solid #ced4da',
            fontSize: 20,
            padding: '10px 26px 10px 12px',
            fontFamily: [
                "Pangram"
            ].join(',')
        },
    }))(InputBase);

    function  TabDataComp() {
        return (
            < Query query={BotTabs} >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div className={classes.skeleton}><Skeleton animation="wave" variant="text" /></div>);
                    if (error) {
                        return (
                            <div>
                                {console.log(error.message)}
                                <h5> Graphql Query Error ! Try again later </h5>
                            </div>);

                    }
                    else {
                        if (data.length === 0) {
                            return (
                                <div>
                                    <h5> No Data Available! </h5>
                                </div>);
                        }
                        else {
                            return (
                                <div>
                                    <FormControl style={{ minWidth: 505 }}>
                                        {
                                        //     data.botTabs.length === 1 ?
                                        //     <Select
                                        //     labelId="demo-customized-select-save-label"
                                        //     value={1}
                                        //     id="demo-customized-select-save"
                                        //     input={<BootstrapInput />}
                                        //     disabled={true}
                                        // >
                                        //      <MenuItem value={1} disabled>
                                        //        No secondary tab available  
                                        //     </MenuItem>
                                        //      </Select>
                                        //     :
                                            <Select 
                                            labelId="demo-customized-select-save-label"
                                            value={tabId.id}
                                            id="demo-customized-select-save"
                                            input={<BootstrapInput />}
                                        >
                                            <MenuItem value={localStorage.getItem("SideTabId")} disabled>
                                                Select Bot Tab*
                                            </MenuItem>
                                            {data.botTabs.map((tab) => (
                                                localStorage.getItem('SideTab') !== tab.title
                                                    ? <MenuItem key={tab.id} value={tab.id} onClick={(event) => setTabid({ id: tab.id, name: tab.title })}>
                                                        {tab.title}
                                                    </MenuItem> : null
                                            ))}
                                        </Select>
                                        }
                                   
                                       
                                    </FormControl>
                                </div>
                            );
                        }
                    }
                }
                }
            </Query >
        );
    }

    const Reset = (DialogNum) => {
        setActiveDialog(DialogNum);
        setBotAvatar(uri + botScheduleData.botAvatarUrl)
        setBname(botScheduleData.name)
        setDescript(botScheduleData.description)
    }

    function SetAvatar(props) {
        if (botAvtRefetch)
            props.Refetch()
        return (props.data.botAvatars.map((botavt) => (
            <>
                {(uri + botavt.avatarUrl) === BotAvatar ? setAvtId(botavt.id) : null}

            </>
        ))
        );

    }

    return (
        <div>
            < Query query={BotAvatars} >
                {({ loading, error, data, refetch }) => {
                    if (loading)
                        return (
                            <div className={classes.table_action}></div>
                        );
                    if (error) {
                        return (
                            <div className={classes.table_action}>
                                {console.log(error.message)}
                            </div>
                        );
                    }
                    else {
                        if (data.length === 0) {
                            return (
                                <div>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div>
                                    {
                                        <SetAvatar data={data} Refetch={refetch} />
                                    }
                                </div>
                            );
                        }
                    }
                }}
            </Query >
            <CustomModal title="Edit Bot Details" subheader="" openDialog={activeDialog === 1} ParentCallBackFunction={Reset}
                render={() => {
                    return (
                        <DialogContent>
                            <form onSubmit={async (event) => {
                                event.preventDefault();
                                try {
                                    setError(false)
                                    setDisable(true);
                                    setWait(true);
                                    if (customAvtFlag) {
                                        setOpacity(0.5);
                                        if ([56, 57, 58].includes(parseInt(updateAvtId))) {
                                            await uploadBotAvatar({ variables: { avatar: imgFile } })
                                        }
                                        else {
                                            setBotAvtRefetch(true);
                                            <SetAvatar />
                                            await updateBotAvatar({ variables: { avatar: imgFile, avatarId: updateAvtId } });
                                        }
                                        console.log("avatarurl: ", imgFile)
                                        setActiveDialog(0);
                                        setNochange(false);
                                        setWait(false);

                                    }
                                    else {
                                        if (DefaultAvtFlag) {
                                            setOpacity(0.5);
                                            await editBot({
                                                variables: {
                                                    botId: localBotId,
                                                    avatarId: avtid,
                                                    description: descript,
                                                    name: bName,
                                                    tabId: tabId.id
                                                }
                                            });
                                            if (tabId.id !== localStorage.getItem("SideTabId"))
                                                localStorage.setItem("SideTab", tabId.name)
                                            setActiveDialog(0);
                                            setNochange(false);
                                            setWait(false);
                                        }
                                        else {
                                            if (bName === localStorage.getItem("botname") &&
                                                descript === localStorage.getItem("botdes") &&
                                                tabId.id === localStorage.getItem("SideTabId")
                                            ) {
                                                setNochange(true);
                                                setOpenSnack(true);
                                                setWait(false);
                                                setDisable(false);
                                                setActiveDialog(1);

                                            }

                                        }
                                    }
                                    if (bName !== localStorage.getItem("botname") ||
                                        descript !== localStorage.getItem("botdes") ||
                                        tabId.id !== localStorage.getItem("SideTabId")) {
                                        setOpacity(0.5);
                                        setNochange(false);
                                        await editBotOptional({
                                            variables: {
                                                botId: localBotId,
                                                description: descript,
                                                name: bName,
                                                tabId: tabId.id
                                            }
                                        });
                                        if (tabId.id !== localStorage.getItem("SideTabId"))
                                            localStorage.setItem("SideTab", tabId.name)
                                        setActiveDialog(0);
                                        setBname(bName);
                                        setDescript(descript);
                                    }
                                    setOpenSnack(true);
                                    localStorage.setItem("botname", bName);
                                    localStorage.setItem("botdes", descript);
                                    //setBotAvtRefetch(false);
                                }
                                catch (error) {
                                    setError(true)
                                    console.log("Error in bot edit page with tab is is  ", error,tabId.id)
                                }
                            }}>
                                <Avatar src={BotAvatar} className={classes.sizeAvatar} />
                                <Typography >
                                    Change to:
                                    <Button className="button_no_bg" onClick={(event) => { setBotAvatar(avt1); setDefaultAvtFlag(true); setCustomAvtFlag(false); setAvtid(56); }}><Avatar className={classes.avatarlist} src={avt1} /></Button>
                                    <Button className="button_no_bg" onClick={(event) => { setBotAvatar(avt2); setDefaultAvtFlag(true); setCustomAvtFlag(false); setAvtid(58); }}><Avatar className={classes.avatarlist} src={avt2} /></Button>
                                    <Button className="button_no_bg" onClick={(event) => { setBotAvatar(avt3); setDefaultAvtFlag(true); setCustomAvtFlag(false); setAvtid(57); }}><Avatar className={classes.avatarlist} src={avt3} /></Button>
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}

                                        badgeContent={
                                            <>  <input accept="image/*" ref={customAvatar} className={classes.ImageInput} onChange={ImgUploadHandler} id="icon-button-file" type="file" />
                                                <label htmlFor="icon-button-file">
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <Box borderRadius="50%" borderColor="purple" border="2px solid" style={{ background: 'white', position: 'relative', width: '1.4rem', height: '1.4rem' }} >
                                                            <EditRounded style={{ fontSize: 12, color: 'purple', position: 'absolute', top: '17%', left: '10%' }} />
                                                        </Box>
                                                    </IconButton>
                                                </label></>
                                        }
                                    >
                                        <Avatar src="/broken-image.jpg" className={classes.CustomAvatar} ></Avatar>
                                    </Badge>
                                </Typography>
                                <input required autoFocus value={bName} onChange={(event) => { setBname(event.target.value); }} placeholder="Bot Name*" type="text" fullWidth />
                                <input value={descript} onChange={(event) => { setDescript(event.target.value); }} placeholder="Bot Description (optional)" type="text" fullWidth />
                                <TabDataComp />
                                <DialogActions>
                                    {wait ? <CircularProgress size="21px" /> : null}  <Button disabled={disable} type="submit" style={{ opacity: opacity, background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                                        Save Changes
                                    </Button>

                                </DialogActions>
                            </form>
                            {uploadError && <p style={{ fontFamily: 'DM Sans', color: 'red' }}>Error : Please try again</p>}
                            {updateBotError && <p style={{ fontFamily: 'DM Sans', color: 'red' }}>Error : Please try again</p>}
                        </DialogContent>
                    )
                }}
            />

            <CustomModal title="Manage Versions" subheader="" openDialog={activeDialog === 2} ParentCallBackFunction={setActiveDialog}
                render={() => {
                    return (
                        <DialogContent>
                            <Typography>
                                {versionData.map((data, index) => {
                                    return (
                                        <div id={index} container className={classes.Versiondata}>
                                            <div>
                                                <p className={classes.versionName + " gradient_text"}>{data.version}</p>
                                                <p className={classes.versionDate}>{data.date}</p>
                                            </div>
                                            <div className={classes.Revert}>
                                                <p className="valign-wrapper"><HistoryRounded style={{ marginRight: '2%', fontSize: '120%' }} /> Revert to</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Typography>
                        </DialogContent>
                    )
                }}
            />
            {activeDialog === 3
                ?
                <RecordReplayBot action="replay" title={`Execute Bot: ${bName}`} subheader="Executing all task(s)" botId={botScheduleData.id} CallBack={setActiveDialog} taskId={""} />
                :
                null
            }


            <Grid className={classes.rootContainer} container >
                <Grid item md={12} className={classes.breadcrumbs} >
                    <Breadcrumbs style={{ background: 'transparent', boxShadow: 'none' }} separator={<CgChevronDoubleRight style={{ fontSize: '30px', fontWeight: 100 }} />}>
                        <p onClick={(event) => { history.replace("/my-bots"); }} >
                            My Bots
                        </p>
                        <p onClick={(event) => { history.replace("/my-bots") }}>
                            {localStorage.getItem("SideTab")}
                        </p>
                        <Typography component="h1" color="textPrimary">{botScheduleData.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item md={12}>
                    <Card className={classes.Cardroot}>
                        <CardHeader
                            avatar={<Avatar className={classes.HeaderAvatar} src={BotAvatar} />}
                            title={
                                <Grid justify="center" alignItems="center" direction="row" container>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Grid container direction="row" justify="space-between" alignItems="center" >
                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                <p className={classes.HeaderTitle}> {bName} <EditRounded style={{ cursor: 'pointer' }} onClick={(event) => DialogOpen(event, 1)} /></p>
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                                {/* <div className={`align-wrapper ${classes.HeaderTitleTime}`}><UpdateRounded style={{ paddingRight: '5%', }} /> 1 min 20 sec</div>*/}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <div onClick={(event) => { setActiveDialog(2) }} style={{ cursor: 'pointer', display: 'none' }} className={`button_no_bg valign-wrapper ${classes.HeaderButton1}`} >
                                            Manage Version <HiArrowNarrowRight style={{ fontSize: '24px', paddingLeft: '3%' }} />
                                        </div>
                                        <div style={{ display: 'none' }} className={`button_no_bg valign-wrapper ${classes.HeaderButton1}`} >
                                            Approval Workflow <HiArrowNarrowRight style={{ fontSize: '24px', paddingLeft: '3%' }} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Button
                                            className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn} ${classes.HeaderButton}`}
                                            endIcon={<FaMagic style={{ fontSize: '16px' }} />}
                                            onClick={(event) => { setActiveDialog(3) }}
                                        >
                                            Execute Bot
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        />
                        <CardContent>
                            <Grid className={classes.ScheduleData}>
                                <Grid item sm={12} md={12} ld={12}>
                                    <p className={classes.SubHeader}> {descript} </p>
                                    {botScheduleData.appsUsed
                                        ?
                                        botScheduleData.appsUsed.map((data, index) => {
                                            return (
                                                <Chip className={classes.Chipdata} key={index} label={data} variant="outlined" />
                                            );
                                        })
                                        : null
                                    }
                                </Grid>
                            </Grid>

                            <Grid item md={12}>
                                <Grid container className={TabStyleClass.TabContainer}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <AntTabs value={activetab} onChange={changeActiveTab} aria-label="ant example">
                                            <AntTab label={"Task List"} />
                                            <AntTab style={{ display: 'none' }} label={"Dependencies"} />
                                            <AntTab label={"Schedule"} />
                                            <AntTab label={"Statistics"} />
                                        </AntTabs>
                                    </Grid>
                                </Grid>
                                <TabPanel value={activetab} index={0}>
                                    {botTasksLoading ?
                                        <TableSkeleton />
                                        : <TaskList botName={bName} botId={botScheduleData.id} />
                                    }
                                </TabPanel>
                                <TabPanel className={TabStyleClass.MyTabPanel} value={activetab} index={1}>
                                    <Dependencies />
                                </TabPanel>
                                <TabPanel className={TabStyleClass.MyTabPanel} value={activetab} index={2}>
                                    <ScheduleBot saveFlag={true} botId={botScheduleData.id} botName={botScheduleData.name} ScheduleData={botScheduleData.replaySchedules} />
                                </TabPanel>
                                <TabPanel className={TabStyleClass.MyTabPanel} value={activetab} index={3}>
                                  <Statistics   botId={botScheduleData.id} />
                                </TabPanel>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ fontFamily: "Pangram", zIndex: "99999", }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={closeSnack}
                message={error ? "" : ""}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnack}>
                            <CloseRounded fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                {error
                    ?
                    <MuiAlert elevation={6} variant="filled" severity="error">
                        Error!!! Something went wrong!
                    </MuiAlert>
                    :
                    nochange ? <MuiAlert elevation={6} variant="filled" severity="info">
                        You have not changed any data, there are no changes to save.</MuiAlert>
                        :
                        <MuiAlert elevation={6} variant="filled" onClose={closeSnack} severity="success">
                            Changes have been made successfully !
                        </MuiAlert>
                }
            </Snackbar>
        </div>
    )
}

export default function EditBot(props) {

    const { botScheduleData } = React.useContext(SideNavContext);
    const history = useHistory();
    if (botScheduleData)
        return (
            <TaskProvider>
                <EditBotRenderComponent />
            </TaskProvider>
        )
    history.replace("/my-bots")
    return null
} 
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { BotUsage, PrevBotUsage, CustomDateBot } from "../../graphql/queries";
import { useQuery, useMutation } from 'react-apollo';
import { client } from "../../graphql/apolloClient";
import { TableSkeleton } from "../skeleton";
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//for chart rechart
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import Paper from '@material-ui/core/Paper';
import { purple } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const PurpleRadio = withStyles({
    root: {
        color: purple[400],
        "&$checked": {
            color: purple[600]
        }
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8A2387",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
export function Statistics(props) {
    let date = new Date()
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60;
    const [Changed, setChange] = React.useState(false);
    const [CustDate, setCusDate] = React.useState(false);
    const [prevMonth, setPrevMonth] = React.useState(false);
    const [StartDate, setStartDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
    const [EndDate, setEndDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
    const { loading: customLoading, error: cutomError, data: CustomDate, refetch } = useQuery(CustomDateBot,
        { variables: { tzOffset: timeZoneOffset, startDate: StartDate, endDate: EndDate } },
        { client: client, }
    )
    let Meg = StartDate + "-" + EndDate
    const { loading, error, data } = useQuery(BotUsage,
        {
            client: client,
            onCompleted(data) {
                console.log("refetch  ")
            }
        })
    const { data: preData } = useQuery(PrevBotUsage,
        {
            client: client,
        }

    )
    React.useEffect(() => {
        if (CustDate) {
            refetch(  { variables: { tzOffset: timeZoneOffset, startDate: StartDate, endDate: EndDate } })
        }
    }, [CustDate])
    if (loading || customLoading)
        return (
            <TableSkeleton />
        );
    if (error) {
        return (
            <div>
                {console.log(error.message)}
                <h5> Graphql Query Error ! Try again later </h5>
            </div>);
    }

    if (data) {

        return (
            CustDate ?
                CustomDate.myBotTracking.result.map((bot, index) => (
                    bot.botId == props.botId ?
                        <Stat_Table Bot={bot} parentSetMonth={setPrevMonth} setStart={setStartDate} Message={Meg} CustDate={CustDate} setEnd={setEndDate} custom={setCusDate} check={Changed} setCheck={setChange} />
                        :
                        null
                )):
                 prevMonth ?
                    preData.myBotTracking.result.map((bot, index) => (
                        bot.botId == props.botId ?
                            <Stat_Table Bot={bot} parentSetMonth={setPrevMonth} setStart={setStartDate} Message={Meg} CustDate={CustDate} setEnd={setEndDate} custom={setCusDate} check={Changed} setCheck={setChange} />
                            :
                            null
                     ))
                    :
                data.myBotTracking.result.map((bot, index) => (
                    bot.botId == props.botId ?
                        <Stat_Table Bot={bot} parentSetMonth={setPrevMonth} setStart={setStartDate} Message={Meg} CustDate={CustDate} setEnd={setEndDate} custom={setCusDate} check={Changed} setCheck={setChange} />
                        :
                        null
                ))
        );

    }
}

function Stat_Table(props) {


    let NoExecution = false;
    if (props.check === true)
        NoExecution = false
    else if (props.check === false) {
        if (props.Bot.botRunCount === 0)
            NoExecution = true;
    }
    const [DateRange, setDateRange] = React.useState(new Date());
    const [dailyFilter, setFilter] = React.useState("1");
    const [DateFilter, setDate] = React.useState(props.CustDate ? "3":"1");
    const chartData = [{ name: "Successful Run", value: props.Bot.successfulRuns },
    { name: "Failure Run", value: props.Bot.failedRuns },
    { name: "Run Count", value: props.Bot.botRunCount },
    ]
    const COLORS = ["#8A2387", "#EC5046", "#BC336E", "#FF8042"];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const Data = []
    if (dailyFilter === "1") // daily
    {
        props.Bot.trackLabels.map((label, index) => (
            Data.push({
                usage: props.Bot.trackTime[index],
                month: label,
            }))
        )
    }
    else if (dailyFilter === "2") //weekly
    {
        props.Bot.trackWeeklyLabels.map((label, index) => (
            Data.push({
                usage: props.Bot.trackWeeklyTime[index],
                month: label,
            }))
        )
    }
    else if (dailyFilter === "3") { // monthly
        props.Bot.trackMonthlyLabels.map((label, index) => (
            Data.push({
                usage: props.Bot.trackMonthlyTime[index],
                month: label,
            }))
        )
    }

    const FilterOption = (event) => {
        setFilter(event.target.value);
    }

    const handleChangeDate = (event) => {
        DateRange.map((date, index) => (
            index === 0 ?
                props.setStart(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
                : props.setEnd(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
        ))
        props.parentSetMonth(false)
        props.custom(true)
        props.setCheck(true)

    }
    const handleChange = (event) => {
        setDate(event.target.value);
        if (event.target.value === "2") {
            props.parentSetMonth(true);
            props.setCheck(true)
            props.custom(false)

        }
        else if (event.target.value === "1") {
            props.parentSetMonth(false);
            props.setCheck(false)
            props.custom(false)
        }
    }
    return (
        <div>
            {
                NoExecution
                    ?
                    <Paper variant="outlined">
                        <h5 align={"center"} >Bot is yet to be Executed !</h5>
                        <p align={"center"} > You are seeing this because the bot has not been executed <br></br>
                        </p>
                    </Paper>
                    :
                    <Paper variant="outlined">
                        <Grid container spacing={1} container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item xs={3}>
                                <FormControl style={{ float: "right", maxWidth: '80px', maxHeight: '20px', minWidth: '50px', minHeight: '20px' }}>
                                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                    <Select

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={dailyFilter}
                                        onChange={FilterOption}
                                    >
                                        <MenuItem value={"1"}>Daily</MenuItem>
                                        <MenuItem value={"2"}>Weekly</MenuItem>
                                        <MenuItem value={"3"}>Monthly</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}  >
                                <FormControl component="fieldset" style={{ float: "right" }}>
                                    <RadioGroup
                                        row
                                        aria-label="gender"
                                        value={DateFilter}
                                        onChange={(event) => { handleChange(event) }}
                                        style={{ marginTop: "5%" }}
                                    >
                                        <FormControlLabel value="2" control={<PurpleRadio />} label="Last Month" />
                                        <FormControlLabel value="1" control={<PurpleRadio />} label="This Month" />
                                        <FormControlLabel value="3" control={<PurpleRadio />} label="Custom date" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                            <Grid item xs={6}>
                            </Grid>

                            {
                                DateFilter === "3"
                                    ?
                                    <Grid item xs={4}>
                                        
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleChangeDate(e)
                                        }}>
                                            <FormControl component="fieldset">
                                                <DateRangePicker className="button_no_bg"
                                                    style={{ color: "purple" }}
                                                    views={["year", "month", "date"]}
                                                    onChange={setDateRange}
                                                    value={DateRange}
                                                    maxHeight={100}
                                                    maxDate={new Date()}
                                                    label="Date Range"
                                                    format={"dd-MM-y"}
                                                    default={new Date()}
                                                    borderRadius="10px"
                                                    required
                                                />
                                                <Button type="submit" color=" purple" className="button_no_bg" variant="contained">Submit</Button>
                                            </FormControl>
                                        </form>
                                    </Grid>
                                    : null
                            }


                            <Grid item xs={12}>
                                {
                                    props.CustDate ?
                                    <h6 align="center"> Data Range: {props.Message}</h6>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <LineChart
                                    width={1200}
                                    height={400}
                                    data={Data}
                                    margin={{
                                        top: 2,
                                        right: 10,
                                        left: 10,
                                        bottom: 15,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" angle={310} />
                                    <YAxis name="upTime(sec)" dataKey={"usage"} />
                                    <Tooltip />
                                    <Legend />
                                    {/* <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                                    <Line type="monotone" dataKey="usage" name="Uptime (hrs)" stroke="#9932CC" />
                                </LineChart>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">System Name</StyledTableCell>
                                                <StyledTableCell align="center">Successful Run</StyledTableCell>
                                                <StyledTableCell align="center">Failure Run</StyledTableCell>
                                                <StyledTableCell align="center">Total Run</StyledTableCell>
                                                <StyledTableCell align="center">System Uptime</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.Bot.systemWiseResults.length !== 0 ?
                                                    props.Bot.systemWiseResults.map((Sys, index) => (
                                                        <StyledTableRow>
                                                            <TableCell align="center"> {Sys.sysName} </TableCell>
                                                            <TableCell align="center"> {Sys.sysSuccess} </TableCell>
                                                            <TableCell align="center"> {Sys.sysFailure} </TableCell>
                                                            <TableCell align="center"> {Sys.sysCount} </TableCell>
                                                            <TableCell align="center"> {Sys.sysUptime} </TableCell>
                                                        </StyledTableRow>
                                                    ))
                                                    :
                                                    <TableCell align='center' >No Data </TableCell>

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <h6 style={{ marginLeft: "35%" }}>Bot Run</h6>
                                <PieChart width={400} height={280}>
                                    <Pie
                                        data={chartData}
                                        cx={200}
                                        cy={120}
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {chartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Legend position="top" />
                                    <Tooltip />
                                </PieChart>

                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                        </Grid>
                    </Paper>
            }
        </div>);
}
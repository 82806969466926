import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLoggedInVar } from "../utils/cache";

function ProtectedRoute ({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={
            props => 
                isLoggedInVar() ?
                    <Component {...rest} {...props} /> :
                    <Redirect to="/login" />  
          } />
          )
}

export default ProtectedRoute
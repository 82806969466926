// importing required packages
import React from 'react';
import { Typography, Grid, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { useMutation } from 'react-apollo';
import { TableSkeleton } from "./skeleton";
import { AddRounded } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { commonStyles } from '../styles/css/CommonCSS';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { SideNavContext } from "./sidemenu";


// importing required variables
import { client } from "../graphql/apolloClient"
import { favouriteApis, globalApis, myOrganizationApis, requestApi } from "../graphql/queries";

// importing require components
import { Globalfilter } from './GlobalFilter'
import { default as InteractiveApiTable } from "./Pages/InteractiveApiTable"
import { AntTabs, AntTab, TabPanel, TabStyle } from './TabComponent'
import { CustomModal } from "./modal";

// importing required variables
import { activeNavVar } from '../utils/cache';
import Nodata from './Nodata';

const useStyles = makeStyles((theme) => ({
  APIcontainer: {
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  Button: {
    float: 'right'
  }
}));

function QueryApi(props) {
  let tab = props.tab_name.toString()
  let Data, msg = "No APIs to display", des;
  console.log("qt: ", tab)
  const { loading, error, data, refetch } = useQuery(props.query_type, { client: client });
  if (props.refetchFlag) {
    refetch()
    props.callbackFunction(false)
  }
  if (loading)
    return (
      <TableSkeleton />
    );
  if (error)
    return (
      <div>
        <h5> Please Try Again! </h5>
      </div>);

  if (data)
    if (props.tab_name === 'favouriteApis') {
      Data = data.favouriteApis
      des = "When you tag a API as favorite, it will appear here"
    }
  if (props.tab_name === 'globalApis') {
    Data = data.globalApis
    des = "When admin assigns an API, it will appear here"
  }
  if (props.tab_name === 'myOrganizationApis') {
    Data = data.myOrganizationApis
    des = "When admin assigns an API, it will appear here"
  }
  if (Data.length === 0)
    return (
      <Nodata message={msg} description={des} />
    )
  return (
    <InteractiveApiTable {...props} Data={data[props.tab_name]} Refetch={refetch} />
  )
}


function InteractiveApi(props) {
  const classes = useStyles();
  const TabStyleClass = TabStyle();
  const CommonClasses = commonStyles();
  const [openDialog, setOpendialog] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [RefetchFlag, setRefetchFlag] = React.useState(false)
  const [requestNewAPI] = useMutation(requestApi);
  const { setSideNavFlag } = React.useContext(SideNavContext);
  const [value, setValue] = React.useState(parseInt(localStorage.getItem("ActiveApiTab")) || 0);
  const [FilterString, setFilterString] = React.useState("");
  React.useEffect(() => {
    localStorage.setItem("TopNav", "Api")
    activeNavVar("Api")
    setRefetchFlag(true)
  }, [value])
  React.useEffect(() => {
    setSideNavFlag(false);
    return () => {
      setSideNavFlag(true);
    }
  }, [setSideNavFlag]);
  const GetFilterString = ((ChildFilterString) => {
    setFilterString(ChildFilterString)
  });

  const handleChange = (event, newValue) => {
    localStorage.setItem("ActiveApiTab", newValue)
    setValue(newValue);
  };

  return (
    <div style={{ padding: '0% 2% 0% 2%' }}>
      <Typography className={TabStyleClass.MyContainer}>
        <h4 className={CommonClasses.title}>Interactive API
        </h4>
        <div>
          <div className={classes.APIcontainer}>
            <Grid container>
              <Grid item xs={8} sm={8} lg={8}>
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                  <AntTab label={"Favourites "} />
                  <AntTab label={"Global "} />
                  <AntTab label={"My Organisation "} />
                </AntTabs>
              </Grid>

              <Grid item xs={4} sm={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                <Globalfilter parentCallbackFunction={GetFilterString} />
                <Button
                  endIcon={<AddRounded size={21} />}
                  size={"small"}
                  className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn} ${classes.Button}`}
                  variant="contained"
                  onClick={(event) => { setOpendialog(true) }}>
                  REQUEST NEW API
                </Button>
                <CustomModal title="Request a new API to be added"
                  openDialog={openDialog}
                  ParentCallBackFunction={setOpendialog}
                  render={() => {
                    return (

                      <DialogContent>
                        <form className={classes.container} onSubmit={async (e) => {
                          e.preventDefault();
                          try {
                            await requestNewAPI({ variables: { DESC: desc, TITLE: title, URL: url } })
                            setOpendialog(false)
                          }
                          catch (error) { console.log(error) }
                        }
                        } >
                          <Grid container spacing={1} direction="column">
                            <FormControl className={classes.formControl} >
                              <TextField
                                label="API Title"
                                fullWidth
                                onChange={(event) => setTitle((event.target.value) || '')}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                              <TextField
                                label="URL of the API"
                                fullWidth
                                onChange={(event) => setUrl((event.target.value) || '')}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                              <TextField
                                label="Description"
                                multiline
                                rows={6}
                                onChange={(event) => setDesc((event.target.value) || '')}
                                variant="outlined"
                                fullWidth
                              />

                            </FormControl>
                          </Grid>

                          <DialogActions>
                            <Button
                              onClick={() => setOpendialog(false)}
                              className={"button_no_bg"}>
                              Cancel
                            </Button>
                            <Button type="submit"
                              className={"button_no_bg"}>
                              Submit
                            </Button>
                          </DialogActions>
                        </form>
                      </DialogContent>

                    )
                  }}
                />
              </Grid>
            </Grid>

            <TabPanel value={value} index={0}>
              <QueryApi filterString={FilterString} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} query_type={favouriteApis} tab_name={"favouriteApis"} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <QueryApi filterString={FilterString} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} query_type={globalApis} tab_name={"globalApis"} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <QueryApi filterString={FilterString} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} query_type={myOrganizationApis} tab_name={"myOrganizationApis"} />
            </TabPanel>

          </div>
        </div>
      </Typography >
    </div>
  );
};


export default InteractiveApi;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import CardActions from "@material-ui/core/CardActions"
import { useQuery , useMutation } from "react-apollo";
import { SubEstimates, ChangePlan } from "../graphql/queries";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  paper: {
    width: '75%',
    margin: 'auto',
    borderRadius: '12px',
    },
    changeWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minWidth: 500,
        height: "100%"
    },
    cardWrapper: {
        padding: "1%",
        width: "75%",
    },
    stateWrapper: {
        width: "100%",
        marginTop: "2%",
        padding: "1%"
    },
    boldWrapper: {
        fontWeight: "bold"
    },
    cardActionsWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end"
    },
    activeWrapper: {
        borderRadius: "4px",
        border: "2px solid #8A2387"
    }
});

const mockDate = "May 28, 2021, midnight"

function StateWrapper({ isCurrent, invoice, state, plan, renewal }) {
    const classes = useStyles()
    const { name, billed } = invoice
    
    return (
        <Paper className={classes.stateWrapper}>
        <Typography variant="h5" component="p">
            {/* {`${isCurrent ? "Current state" : "Future state"}`} */}
            {state}
        </Typography>
            <Typography variant="body1" component="p">
                Plan:
            <Typography style={{textTransform:'capitalize'}}className={classes.boldWrapper} variant="body1" display="inline">
            {` ${plan} `}
            </Typography>
                Billed:
            <Typography className={classes.boldWrapper} variant="body1" display="inline">
                    {` ${ billed } `}
            </Typography>
                Next Billing Date:
            <Typography className={classes.boldWrapper} variant="body1" display="inline">
            {` ${renewal} `}
        </Typography>
        </Typography>
    </Paper>
    )
}


function PreviewChanges({ invoice1, invoice2, isActive, handleClose , planid}) {
    const classes = useStyles()
    const [subEstimates, setEst]= React.useState();
    const [planAmt, setAmt]= React.useState(0);
    const [renewal, setRenewal]= React.useState(0);
    const [status, setStatus]= React.useState();
    const [changePlan, { loading: uploadLoad, error: uploadError }] = useMutation(ChangePlan);

    console.log("plan in preview: ",planid)
    const { loading: botTasksLoading } = useQuery(
        SubEstimates,
        {
            variables: {
              targetPlanId: planid
            },  
            onCompleted: (data) => {
                setEst(data.subscriptionUpdateEstimates);
                setAmt(data.subscriptionUpdateEstimates.estimateAmountDue)
                setRenewal(data.subscriptionUpdateEstimates.estimateRenewal)
                setStatus(data.subscriptionUpdateEstimates.estimateStatus)
                console.log("estimates: ",subEstimates)
            }
        })
    function planChange(){
        console.log("in plan change")
        try{
            changePlan({
                variables: {
                    targetPlanId: planid
                }
            })
        }
        catch(e){
            console.log("change plan error")
        }
       
    }
    return (
        <div className={classes.changeWrapper} >
        <Card className={classes.cardWrapper}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Preview of changes
            </Typography>
            <Typography variant="body1" component="p">
                        An invoice for
                        <Typography className={classes.boldWrapper} variant="body1" display="inline">
                            {` ${planAmt.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })} `}
                        </Typography>
                will be generated immediately.
            </Typography>
            <Typography variant="body1" component="p">
                         
                        {/* <Typography className={classes.boldWrapper} variant="body1" display="inline">
                        {` ${invoice2.planAmount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })} `} 
                        </Typography> */}
                Subsequent invoice will be generated on renewal on
                <Typography className={classes.boldWrapper} variant="body1" display="inline">
                            {` ${renewal}`}
                </Typography>
                        .
                </Typography>
            <Typography variant="body1" component="p">
                    Subscription status will remain as
                    <Typography className={classes.boldWrapper} variant="body1" display="inline">
                        {" "+ status}
                    </Typography>
                    .
            </Typography>
                    {/* <StateWrapper invoice={invoice1} state="Current State" plan={planid} renewal={renewal} /> */}
                    <StateWrapper invoice={invoice2} state="Future State"  plan={planid} renewal={renewal}/>
                </CardContent>
                <div className={classes.cardActionsWrapper}>
                    <CardActions>
                        <Button color="secondary" size="small" onClick={handleClose}>Close</Button>
                        {
                        uploadLoad ? 
                        <CircularProgress/>    
                        : 
                        <Button color="primary" size="small" onClick={() => {handleClose(); planChange()}}>Confirm</Button>
                        }   
                    </CardActions>
                </div>
        </Card>
        </div>
      );
    }

export default PreviewChanges
import React, { useMemo, useEffect } from 'react'
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { TableContainer, ThemeProvider, DialogContent, DialogActions, Typography, Input, Button, Select, FormControl, MenuItem } from '@material-ui/core';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import { StarRounded, StarBorderRounded } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { useMutation } from 'react-apollo';
import Truncate from 'react-truncate';
import { CustomModal } from "../modal";
import { HiOutlineEmojiSad } from "react-icons/hi"

// importing required variables
import { apiFavourite } from "../../graphql/queries";


// importing required components
import { InteractiveApiColumns } from '../Column'
import { commonStyles } from "../../styles/css/CommonCSS";

// importing required css
import '../../styles/css/common.css';

// importing require components
import { StyledTableCell, StyledTableRow } from '../TableComponent'

const dropdown = [
  { "name": "Ram" },
  { "name": "Bob" },
  { "name": "Bob1" },
  { "name": "Bob2" },
  { "name": "Bob3" },
  { "name": "Bob4" },
]


function InteractiveApiTable(props) {
  const commonClasses = commonStyles();
  const [markfav] = useMutation(apiFavourite);
  const columns = useMemo(() => InteractiveApiColumns, [])
  const data = useMemo(() => props.Data, [props.Data])
  const [input, setInput] = React.useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const [close, setClose] = React.useState(true)
  const [ApiName, SetName] = React.useState("")
  const [check, setCheck] = React.useState(false)

  const handleClear = () => {
    setCheck(false);
    setInput("")
  }
  const apis_list = {
    "Quickbooks": "https://app.lokibots.com/interactive-apis/quickbooks",
    // "Pollution Monitoring": "https://apis.lokibots.com/pollution-monitoring?bot_id=0",
    // "TRANSCRIBE": "https://transcribe.lokibots.com",
    "INVOICE PROCESSING": "https://apis.lokibots.com/invoice-processing/?bot_id=0",
    // "FORECASTING": "https://apis.lokibots.com/forecasting/?bot_id=0",
    "COMPREHEND": "https://apis.lokibots.com/comprehend/request?bot_id=0",
    "HANDWRITING": "https://apis.lokibots.com/handwriting/request?bot_id=0",
    "RESUME PARSING": "https://apis.lokibots.com/resume-parsing/request?bot_id=0",
    "TEXT TRANSLATION": "https://apis.lokibots.com/text-translation/request?bot_id=0",
    "RECEIPT PROCESSING": "https://apis.lokibots.com/receipt-processing/?bot_id=0",
    "CURRENCY": "https://apis.lokibots.com/currency-conversion/request?bot_id=0",
    "TEXT TO SPEECH": "https://apis.lokibots.com/text-to-speech/request?bot_id=0",
    "YAHOO FINANCE": "https://apis.lokibots.com/yahoo-finance/request?bot_id=0"
  }

  React.useEffect(() => {
    if (!close) {
      handleClear()
    }
  }, [close]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: '100%',
      margin: 'auto',
      borderRadius: '12px',
    },
    nameStyle: {
      paddingRight: '5em'
    },
    divStyle: {
      //width: 'fit-content',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,

    },
    headerStyle: {
      paddingLeft: '2%',
      fontSize: '13px'
    },
    tableRightBorder: {
      paddingLeft: '2%',
      borderWidth: 1,
      borderLeft: `1px solid ${theme.palette.divider}` // or borderTop: '1px solid red'
    },
  }));

  useEffect(() => {
    setGlobalFilter(props.filterString)
  }, [props.filterString, setGlobalFilter]);


  const move_fav = async (id, fav) => {
    try {
      await markfav({ variables: { id: parseInt(id, 10), favourite: !fav } })
      console.log("Success")
      props.Refetch();
    }
    catch (error) {
      console.log("Error")
      console.log(error)
    }
  };

  const classes = useStyles();
  const handleInput = (data) => {
    console.log("input")
    setInput(data)
  }
  return (
    <>
      <TableContainer className={classes.paper} component={Paper}>
        <Table size="small" className={classes.table}  {...getTableProps()}>
          <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
            {headerGroups.map(headerGroup => (
              <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <ThemeProvider>
                    <StyledTableCell {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps()}>
                      {column === headerGroup.headers[2] ? <div className={classes.headerStyle}>{column.render("Header")}</div> : <div style={{ fontSize: '13px' }}>{column.render("Header")}</div>}
                      <span style={{}}></span>
                    </StyledTableCell>
                  </ThemeProvider>
                ))}
              </StyledTableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.length === 0 ?
              <StyledTableRow >
                <StyledTableCell colSpan={8} className={classes.size}>
                  <h5 align="center">No APIs Available!</h5>
                </StyledTableCell>
              </StyledTableRow>
              : null}
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow className={commonClasses.rowStyle} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell === row.cells[0]) {
                      return (
                        <StyledTableCell >
                          <span>
                            <Checkbox
                              id={cell.row.values.id}
                              checked={cell.row.values.favourite}
                              onClick={() => move_fav(cell.row.values.id, cell.row.values.favourite)}
                              style={{ color: "gold" }}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              icon={<StarBorderRounded />}
                              checkedIcon={<StarRounded />}
                            />
                          </span>
                        </StyledTableCell>)
                    }

                    if (cell === row.cells[1]) {
                      return (<ThemeProvider>
                        <StyledTableCell  {...cell.getCellProps()}
                          // onClick={() => window.open(apis_list[cell.value])}
                          onClick={() => { setClose(true); SetName(cell.value) }}
                          style={{ textTransform: 'uppercase' }}
                        >
                          <div className={`${commonClasses.BotName} ${classes.nameStyle}`} >{cell.value}</div>
                        </StyledTableCell>
                      </ThemeProvider>)
                    }

                    if (cell === row.cells[2]) {
                      return (<ThemeProvider>
                        <StyledTableCell className={classes.tableRightBorder} {...cell.getCellProps()}>
                          <Truncate className={commonClasses.descript} lines={1} ellipsis={"..."}>
                            {cell.value}
                          </Truncate>
                        </StyledTableCell>
                      </ThemeProvider>)
                    }

                    else {
                      return <ThemeProvider>
                        <StyledTableCell className={cell === row.cells[2] ? classes.tableRightBorder : null} {...cell.getCellProps()}>
                          {cell === row.cells[1] ? <div className={`${commonClasses.BotName} ${classes.nameStyle}`} >{cell.render("Cell")}</div> :
                            <>
                              <div className={commonClasses.descript}> {cell.render("Cell")}</div></>}
                        </StyledTableCell>

                      </ThemeProvider>;
                    }
                  })}
                </StyledTableRow>
              );
            })
            }

          </TableBody>

        </Table>

      </TableContainer>
      {ApiName === ""
        ? null
        : close ?
          <CustomModal title={ApiName} subheader="" openDialog={close} ParentCallBackFunction={setClose}
            render={() => {

              return (
                <DialogContent>
                  {(() => {
                    switch (ApiName) {
                      case "CURRENCY":
                        return <div>
                          <Input
                            disableUnderline={true}
                            placeholder={"1 USD to EUR"}
                            value={input}
                            fullWidth={true}
                            onChange={(e) => handleInput(e.target.value)}
                          />
                          <br></br>
                          <Button
                            color="grey"
                            variant="outlined"
                            size="small"
                            className="button_gray_bg"
                            style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                            onClick={() => { handleClear(); setClose(false) }}

                          > Proceed </Button>
                        </div>
                      case "COMPREHEND":
                        return <div>
                          <Input
                            disableUnderline={true}
                            placeholder={"Input"}
                            value={input}
                            fullWidth={true}
                            onChange={(e) => handleInput(e.target.value)}
                          />
                          <br></br>
                          <Button
                            color="grey"
                            variant="outlined"
                            size="small"
                            className="button_gray_bg"
                            style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                            onClick={() => { handleClear(); setClose(false) }}
                          > Proceed </Button>
                          <br></br>
                          <h6>Supported Languages</h6>
                          <h9>German, English, Spanish, Italian, Portugese, French, Japanese, Korean, Hindi, Arabic, Chinese</h9>
                        </div>
                      case "INVOICE PROCESSING":
                        return <div fullWidth>
                          {check ?
                            <div>
                              <Input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                              <br></br>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                                onClick={() => { handleClear(); setClose(false) }}
                              > Proceed </Button>
                            </div>
                            :
                            <div>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ marginLeft: '12%', width: "30%", fontSize: "13px" }}
                                onClick={() => { setCheck(true) }}

                              > Request </Button>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ marginLeft: '12%', width: "30%", fontSize: "13px" }}
                              >
                                Result
                              </Button>
                            </div>
                          }
                        </div>
                      case "RECEIPT PROCESSING":
                        return <div fullWidth>
                          {check ?
                            <div>
                              <Input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                              <br></br>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                                onClick={() => { handleClear(); setClose(false) }}
                              > Proceed </Button>
                            </div>
                            :
                            <div>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ marginLeft: '12%', width: "30%", fontSize: "13px" }}
                                onClick={() => { setCheck(true) }}

                              > Request </Button>
                              <Button
                                color="grey"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ marginLeft: '12%', width: "30%", fontSize: "13px" }}
                              >
                                Result
                              </Button>
                            </div>
                          }
                        </div>
                      case "RESUME PARSING":
                        return <div>
                          <div>
                            <Input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                            <br></br>
                            <Button
                              color="grey"
                              variant="outlined"
                              size="small"
                              className="button_gray_bg"
                              style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                              onClick={() => { handleClear(); setClose(false) }}

                            > Proceed </Button>
                          </div>
                        </div>
                      case "HANDWRITING":
                        return <div>
                          <div>
                            <Input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                            <br></br>
                            <Button
                              color="grey"
                              variant="outlined"
                              size="small"
                              className="button_gray_bg"
                              style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                              onClick={() => { handleClear(); setClose(false) }}
                            > Proceed </Button>
                          </div>
                        </div>
                      case "YAHOO FINANCE":
                        return <div>
                          <div>
                            <form onSubmit={(event) => {
                              event.preventDefault()
                              setCheck(true)
                              //add a function here 

                            }}>
                              <DialogContent>
                                <Typography style={{ marginRight: "3%", fontSize: "15px", fontFamily: 'DM Sans' }}>
                                  Select a Country
                                </Typography>

                                <DialogActions>
                                  <div>
                                    <FormControl style={{ minWidth: 350 }}>
                                      <Select required
                                        //disabled={JSON.parse(localStorage.getItem("disable"))}


                                        style={
                                          { marginRight: "3%", fontSize: "15px", fontFamily: 'DM Sans' }

                                        }
                                      >
                                        <MenuItem value="" disabled>
                                          Country
                                        </MenuItem>
                                        {
                                          dropdown.map((x, index) => (
                                            <MenuItem key={index} value={x.name}>
                                              {x.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <br />
                                  <Button onClick={() => { handleClear(); setClose(false) }} style={{ background: 'gray', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                                    Cancel
                                  </Button>
                                  <Button type="submit" style={{ background: 'purple', color: 'white', textTransform: 'None', fontFamily: 'Pangram' }} color="primary">
                                    Proceed
                                  </Button>
                                </DialogActions>
                              </DialogContent>

                            </form>
                          </div>
                          {check ?

                            <div>
                              <span>Input</span> <span> something </span>
                              <span>Result </span>
                              <Button
                                color="green"
                                variant="outlined"
                                size="small"
                                className="button_gray_bg"
                                style={{ marginRight: '3%', width: "30%", fontSize: "11px" }}
                                onClick={() => { handleClear(); setClose(false) }}
                              >Download SpreadSheet</Button>
                            </div>
                            : null
                          }
                        </div>


                      case "TEXT TO SPEECH":
                        return <div>

                          <Input
                            disableUnderline={true}
                            placeholder={"Hello World"}
                            value={input}
                            fullWidth={true}
                            onChange={(e) => handleInput(e.target.value)}
                          />


                          <br></br>
                          <Button
                            color="grey"
                            variant="outlined"
                            size="small"
                            className="button_gray_bg"
                            style={{ float: "right", marginleft: '3%', width: "30%", fontSize: "13px" }}
                            onClick={() => { handleClear(); setClose(false) }}

                          > Proceed </Button>

                        </div>

                      default:
                        return <div style={{ textAlign: "center" }}>
                          {<HiOutlineEmojiSad style={{ fontSize: "40px" }} />}
                          <h5>Currently out of service  </h5>

                          <h6>We will be back soon</h6>
                        </div>
                    }
                  })()}
                </DialogContent>
              )
            }}
          />
          : null}
    </>

  )
}

export default InteractiveApiTable;
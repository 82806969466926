import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { commonStyles } from "../styles/css/CommonCSS";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(true);
  const CommonClasses = commonStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.parentcallback(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Message Sent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Lokibots received your message. We will get back to you soon!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={"gradient_round_btn " + CommonClasses.Gradient_Btn} style={{ color: 'white' }} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

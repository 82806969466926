import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useQuery, useMutation } from 'react-apollo';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CircularProgress, TableContainer } from '@material-ui/core';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { TableSkeleton } from "./skeleton";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Paper } from '@material-ui/core';
import { useTable } from "react-table";


// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { CustomModal } from "./modal";
import { TabStyle } from "./TabComponent";
import { OrgUsersColumn, IndividualUsersColumn } from "./Column";


// importing required queries
import { OrgUsers, ArchiveUsers, ChangeRole, DeleteUserBot } from "../graphql/queries";

// importing required variables
import { client } from "../graphql/apolloClient";
import { commonStyles } from '../styles/css/CommonCSS';

const useStyles = makeStyles({
  BtnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:hover': {
      backgroundColor: 'transparent',
    }
  },
  paper: {
    width: '100%',
    margin: 'auto',
    borderRadius: '12px',
  },
  size: {
    fontSize: '14px',
  },
  table_action: {
    width: '100%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
  head: {
    backgroundColor: '#44004245',
    fontSize: '14px',
  },
});


function SecondTable(props) {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);
  const [checkid, setCheckId] = React.useState([]);

  const [deletebot] = useMutation(DeleteUserBot);

  const data = props.data;
  const columns = React.useMemo(() => IndividualUsersColumn, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const deleteBots = (event) => {
    checkid.map(async (idx) => {
      try {
        await deletebot({ variables: { botId: parseInt(idx, 10) } })
        console.log("Success")
        props.setMsgsnackbar("Bot has been Successfully deleted.");
        props.handleClicksnackbar();
      }
      catch (error) {
        console.log("Error")
        console.log(error)
        props.setMsgsnackbar("There was an Error, Please Try Again!");
        props.handleClicksnackbar();
      }
    })
    props.Refetch();
  };
  const handleCheck = (event, data) => {
    if (checkid.includes(data))
      setCheckId(checkid.filter((item) => item !== data))
    else {
      setCheckId([...checkid, data])
      setChecked(false)
    }
  };


  return (

    <TableContainer className={classes.paper} component={Paper}>
      <Table size="small" className={classes.table}  {...getTableProps()}>
        <TableHead style={{ textTransform: 'uppercase', border: '3px solid #ccbacc' }} >
          {headerGroups.map(headerGroup => (
            <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledTableCell align="left" className={classes.head}>
                  {(column.render("Header") === "Delete") ?
                    (
                      (checked || checkid.length > 0) ?
                        <DeleteRoundedIcon style={{ cursor: 'pointer' }} onClick={deleteBots} />
                        : null
                    ) : (column.render("Header")
                    )
                  }
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length === 0 ?
            <StyledTableRow >
              <StyledTableCell colSpan={8} className={classes.size}>
                <h5 align="center">No Bots!</h5>
              </StyledTableCell>
            </StyledTableRow>
            : null}
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTableRow {...row.getRowProps()}
                key={i}>
                {row.cells.map((cell) => {
                  if (cell === row.cells[0]) {
                    return (
                      <StyledTableCell align="left" className={classes.size}>
                        <Checkbox
                          className={"button_no_bg " + classes.TaskstepAction}
                          id={(cell.row.original.id).toString()}
                          onClick={((event) => handleCheck(event, (cell.row.original.id).toString()))}
                          checked={(checkid.includes((cell.row.original.id).toString())) ? true : checked}
                        />
                      </StyledTableCell>
                    )
                  }

                  if (cell === row.cells[1]) {
                    return (
                      <StyledTableCell align="left" className={classes.size}>
                        {i + 1}
                      </StyledTableCell>
                    )
                  }
                  return (
                    <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTableCell>);
                })}

              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

function OrganizationUserDetails(props) {
  const [checkwait, setWait] = React.useState(false);
  const [openDialog, setOpendialog] = React.useState(false);
  const [opensnackbar, setOpensnackbar] = React.useState(false);
  const [msgsnackbar, setMsgsnackbar] = React.useState("");
  const [roleid, setRoleid] = React.useState("");
  const [role, setRole] = React.useState("");


  const TabStyleClass = TabStyle();
  const classes = useStyles();
  const commonClasses = commonStyles()

  const [archive] = useMutation(ArchiveUsers);

  const data = props.data.userAdditionalInfo;

  const columns = React.useMemo(() => OrgUsersColumn, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const handleSwitchChange = async (event) => {
    setWait(true);
    try {
      await archive({ variables: { id: parseInt(event.target.id, 10), value: false } })
      console.log("Success")
      props.Refetch();
      setWait(false);
    }
    catch (error) {
      console.log("Error")
      console.log(error)
      setWait(false);
    }
  };

  const handleChange = (event) => {
    setRole((event.target.value) || '');
  };

  const handleRow = (panel) => (event) => {
    if (props.Expand === panel) {
      props.Expandfunc(false);
    }
    else {
      props.Expandfunc(panel);
    }
  };


  const handleClicksnackbar = () => {
    setOpensnackbar(true);
  };

  const handleClosesnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpensnackbar(false);
  };



  function ModalPopup(props) {

    const [updaterole] = useMutation(ChangeRole);

    const handleRoleChange = async (event) => {
      try {
        await updaterole({ variables: { id: parseInt(roleid, 10), role: role } })
        console.log("Success")
        props.Refetch();
        setMsgsnackbar("User Role has Successfully been updated.");
        props.handleClicksnackbar();
        props.ParentCallBackFunction(false);
      }
      catch (error) {
        console.log("Error")
        console.log(error)
        setMsgsnackbar("There was an Error, Please Try Again!");
        props.handleClicksnackbar();
        props.ParentCallBackFunction(false);
      }
    };

    return (
      <div>
        <CustomModal title="Change Role " openDialog={props.openDialog} ParentCallBackFunction={props.ParentCallBackFunction}
          render={() => {
            return (
              <DialogContent>

                <form className={classes.container}>
                  <FormControl className={classes.formControl}>
                    <Select
                      native
                      id={props.roleid}
                      value={props.role}
                      onChange={props.handleChange}
                      input={<Input id="demo-dialog-native" />}>
                      <option value={""}>Select a role</option>
                      <option value={"organization_basic_user"}>Organization Basic User</option>
                      <option value={"organization_bot_admin"}>Organization Bot Admin</option>
                      <option value={"organization_owner"}>Organization Owner</option>
                      <option value={"organization_user"}>Organization User</option>
                    </Select>
                  </FormControl></form>
                <DialogActions>
                  <Button onClick={() => props.ParentCallBackFunction(false)} className="button_no_bg">
                    Cancel
                  </Button>
                  <Button onClick={handleRoleChange} className="button_no_bg" >
                    <span id={props.roleid}>OK</span>
                  </Button>
                </DialogActions>
              </DialogContent>
            )
          }}
        />
      </div>

    )
  }

  return (
    <React.Fragment>
      <div>
        <h4 className={commonClasses.title}>Organization Users</h4>
      </div>
      <div className={classes.table_action}>
        <br />
        <div className={TabStyleClass.TabContainer}>
          <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" {...getTableProps()}>
              <TableHead style={{ padding: '0%', textTransform: 'uppercase', border: '3px solid #440042', }} >
                {headerGroups.map((headerGroup, head_idx) => (
                  <StyledTableRow
                    className={classes.tableHead}
                    {...headerGroup.getHeaderGroupProps()}
                    key={head_idx}>
                    {headerGroup.headers.map(column => (
                      <StyledTableCell
                        {...column.getHeaderProps()}
                        align="left" className={classes.size}>
                        {column.render("Header")}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.length === 0 ?
                  <StyledTableRow >
                    <StyledTableCell align="center" className={classes.size}>
                      <h5>There are No Users in Your Organization!</h5>
                    </StyledTableCell>
                  </StyledTableRow>
                  : null}
                {rows.map((row, i) => {
                  prepareRow(row);
                  if (row.original.user.isActive) {
                    return (
                      <React.Fragment>
                        <StyledTableRow {...row.getRowProps()} key={i}>
                          {row.cells.map((cell) => {
                            if (cell === row.cells[0]) {
                              return (
                                <StyledTableCell
                                  className={classes.size}
                                  align="left"
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleRow(cell.row.original.user.id)} >
                                  {cell.render("Cell")}
                                </StyledTableCell>
                              )
                            }
                            if (cell === row.cells[1]) {
                              return (
                                <StyledTableCell align="left" className={classes.size}>
                                  <Button className="button_no_bg"
                                    onClick={(event) => { setOpendialog(true); setRoleid(cell.row.original.user.id) }}
                                  >
                                    {cell.render("Cell")}
                                  </Button>
                                  <ModalPopup {...props} row={row}
                                    openDialog={openDialog} ParentCallBackFunction={setOpendialog}
                                    handleClosesnackbar={handleClosesnackbar}
                                    handleClicksnackbar={handleClicksnackbar}
                                    roleid={roleid} role={role}
                                    handleChange={handleChange}
                                  />
                                  <Snackbar
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    open={opensnackbar}
                                    autoHideDuration={3000}
                                    onClose={handleClosesnackbar} >
                                    <MuiAlert elevation={6}
                                      variant="filled"
                                      onClose={handleClosesnackbar}
                                      severity="info">
                                      {msgsnackbar}
                                    </MuiAlert>
                                  </Snackbar>
                                </StyledTableCell>
                              )
                            }
                            if (cell === row.cells[2]) {
                              return (
                                <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                                  {cell.value.length}
                                </StyledTableCell>
                              )
                            }
                            if (cell === row.cells[3]) {
                              return (
                                <StyledTableCell align="left" className={classes.size}>
                                  {checkwait ?
                                    <CircularProgress size="21px" /> :
                                    (localStorage.getItem("username") === cell.row.original.user.username ? null :
                                      <DeleteRoundedIcon style={{ cursor: 'pointer' }} onClick={handleSwitchChange} id={cell.row.original.user.id} />
                                    )
                                  }
                                </StyledTableCell>
                              )
                            }
                            return (
                              <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </StyledTableCell>);
                          })}
                        </StyledTableRow>
                        <StyledTableRow
                          {...row.getRowProps()}
                          key={row.original.user.id}
                          style={{ display: props.Expand === row.original.user.id ? '' : 'none' }}
                        >
                          <StyledTableCell align="left" className={classes.size} colSpan={8}>
                            <Collapse in={props.Expand === row.original.user.id}
                              style={{ background: 'transparent', padding: "2% 0% 2% 2%" }}
                              className={TabStyleClass.TabContainer}
                              timeout="auto" unmountOnExit>
                              <SecondTable {...props}
                                data={row.original.user.bots}
                                setMsgsnackbar={setMsgsnackbar}
                                handleClicksnackbar={handleClicksnackbar}
                              />
                            </Collapse>
                          </StyledTableCell>
                        </StyledTableRow>
                      </React.Fragment>
                    );
                  }
                  else { return null }
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </React.Fragment>

  );
}

function OrganizationUsers() {
  const commonClasses = commonStyles()

  const [accord_expanded, setExpanded] = React.useState(false);
  const { loading, error, data, refetch } = useQuery(OrgUsers, { client: client });
  if (loading)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Organization Users</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <TableSkeleton />
        </div>
      </div>
    );
  if (error)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Organization Users</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <h5> Please Try Again! </h5>
        </div>
      </div>
    );
  if (data)
    return (
      <div>
        <OrganizationUserDetails data={data.userDetails.additionalInfo.organization} Refetch={refetch} Expandfunc={setExpanded} Expand={accord_expanded} />
      </div>);
}

export default OrganizationUsers;


// importing required packages
import React from 'react';
import { Typography, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseRounded } from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

// importing required css
import "../styles/css/fonts.css";

const useStyles = makeStyles((theme) => ({

    ModalHeaderTitle: {
        fontFamily: "Pangram",
        fontSize: '20px',
        color: 'white',
        fontWeight: 'bold',
    },
    ModalHeaderSub: {
        padding: '0%',
        marginTop: '-0.5%',
        fontFamily: "Pangram",
        fontWeight: 'bold',
        fontSize: '16px',
        color: 'white',
    },

    DialogTitle: {
        margin: 0,
        padding: '0% 6% 2% 6%',
        background: `linear-gradient(180deg, #8A2387 0%, #701B6D 100%)`,
        backgroundClip: 'text',
        color: 'white',
        maxHeight: "60px",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white",
    },
}));


// This is for the modal that pops up when Automate option is clicked
export function CustomModal(props) {
    const classes = useStyles();
    return (
        <Dialog maxWidth={props.size ? props.size : "sm"} fullWidth={true} open={props.openDialog} onClose={(event) => props.ParentCallBackFunction(false)}>
            <MuiDialogTitle disableTypography className={classes.DialogTitle} onClose={(event) => props.ParentCallBackFunction(false)}>
                <Typography >
                    <div className={`valign-wrapper ${classes.ModalHeaderTitle}`}>
                        <p className="valign-wrapper " style={{ paddingRight: '0.5%' }}>{props.icon}</p> <p>{props.title}</p>
                        <br />
                        <p className={classes.ModalHeaderSub}>{props.subheader}</p>
                    </div>
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={(event) => props.ParentCallBackFunction(false)} >
                    <CloseRounded />
                </IconButton >
            </MuiDialogTitle>
            <DialogContent dividers>
                {props.render()}
            </DialogContent>
        </Dialog>
    )
}

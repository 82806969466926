
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Input } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 4px',
    display: 'flex',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    boxShadow: 'none',
  },
  input: {
    marginLeft: theme.spacing(0),
    border: 'none',
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: '#402E3C',
  },
}));

export const Globalfilter = (props) => {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  return (
    <Paper className={classes.root}>
      {show ? <Input

        disableUnderline={true}
        fullWidth={true}
        onChange={e => { props.parentCallbackFunction(e.target.value); }}

      /> : null}
      <IconButton type="submit" onClick={() => setShow(!show)} className={`${classes.iconButton} button_no_bg`} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}


// importing required packages
import React, { useEffect, useMemo } from 'react';
import { Button, Grid, Accordion, AccordionDetails, Snackbar, AccordionSummary, Tooltip, IconButton, Checkbox, LinearProgress, CircularProgress } from '@material-ui/core';
import { useTable } from 'react-table';
import { Table, TableContainer, TableBody } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CheckBoxOutlineBlankRounded, CloseRounded, DragIndicatorRounded, FileCopyRounded, DeleteRounded, ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IoFlash, IoCopy } from "react-icons/io5";
import { useLazyQuery } from 'react-apollo';
import { AiFillDelete, AiOutlineReload, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { CgExtensionAdd } from 'react-icons/cg';
import { BsFillImageFill } from 'react-icons/bs';

// importing required components
import { AntTab, AntTabs, TabPanel, TabStyle } from "../TabComponent";
import { CreateBotTaskMenu } from "../CreateBotTaskMenu";
import { StyledTableCell, StyledTableRow } from "../TableComponent";
import { TaskListStepsColumn } from "../Column";
import { default as Dependencies } from "./CreateBot_Dependencies";
import { CustomModal } from "../modal";
import AddNewButton from "./AddNewButton";
import CreateBotNewTask from "./CreateBotAddTaskDependency";
import { RecordReplayBot } from "../Record_Replay_Bot";
import TaskEditPopOver from "../TaskEditPopOver";
import { default as EditBotInstruction, AddBotInstruction, DeleteBotInstruction } from "../EditBotInstruction";
import { TableSkeleton } from "../skeleton";
import { useQuery, useMutation } from 'react-apollo';

// importing required css
import { commonStyles } from "../../styles/css/CommonCSS";
import "../../styles/css/fonts.css";

// importing required queries
import { TaskInstructions, CloneStep, OrderDownTask, OrderUpTask, BotTasks } from "../../graphql/queries";

// importing required variables
import { ScreenshotActionList } from "../EditBotInstruction";
import { Task, TaskProvider } from "./CreateBotContext";


const useStyles = makeStyles((theme) => ({
    NewTaskBtnCancel: {
        // marginLeft: '25%',
        height: '60%',
        marginTop: '10%',
        '&:hover': {
            background: '#E94057',
            color: 'white',
        },
    },
    NewTaskBtnSave: {
        height: '60%',
        marginTop: '10%',
        '&:hover': {
            background: '#8C91A6',
            color: 'white',
        },
    },
    TaskList: {
        border: '0.5px solid #e0dddc',
        borderBottom: 'none',
        borderRadius: '2px',
        background: 'white',
        padding: '0% 1% 0% 1%',
        fontSize: '12px',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
    },
    Taskinput: {
        width: '50%',
    },
    activeTaskStepAction: {
        color: 'Purple',
        '& $TaskStepActionIcon': {
            color: 'Purple',
        },
    },
    TaskstepAction1: {
        fontFamily: "DM Sans",
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#402E3C',
    },
    TaskStepActionIcon: {
        color: '#8C91A6',
        fontSize: '12px',
    },
    table: {
        boxShadow: 'none',
        position: 'relative',
        width: '100%',
    },
    rowid: {
        padding: '0% 3% 0% 3%',
    },
    selectcheck: {
        marginRight: 850,
        marginTop: -70
    },
    mycheckbox: {
        color: "#8A2387",
    },
    paper: {
        width: '100%',
    },
    TaskName: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
    },
    TaskDesc: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
    },
    Automate: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        color: '#8A2387',
        display: 'flex',
        '& $div': {
            width: '50%',
        }
    },
    TaskAccord: {
        border: '0.5px solid #e0dddc',
        borderBottom: 'none',
        borderRadius: '2px',
        background: 'white',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
        boxShadow: 'none',
    },
    OrderChangeIcons: {
        backgroundColor: '#732370',
        color: 'white',
        margin: '0.5%',
        '&:hover': {
            backgroundColor: '#c478c1',
        }
    },
    TaskContainer: {
        padding: '0.5% 0% 0.5% 0%',
        fontSize: '12px',
        fontFamily: 'DM Sans',
        fontWeight: 'bold',
        // '&:hover $OrderChangeIcons': {
        //     opacity: 1,
        // }
    },
    icon1: {
        marginLeft: '2%',
        color: `linear-gradient(180deg, #8A2387 0%, #701B6D 100%)`,
    },
    icon2: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    skip: {
        fontSize: "14px",
        textAlign: 'right',
        float: 'right',
        color: 'purple',
        textTransform: "initial",
        background: 'none',
        border: 'none',
        marginLeft: '7%',
        marginTop: '2%',
        marginRight: '2%',
        textDecorationColor: 'purple',
    },
}));

const MyCheckbox = withStyles({
    root: {
        color: "gray",
        '&$checked': {
            color: "#8A2387",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const TaskInstuctionContext = React.createContext();

function TaskInstructionTable(props) {

    const classes = useStyles();
    const commonClasses = commonStyles();
    const [edtiTaskId, setedtiTaskId] = React.useState(-1)
    const taskInstructions = props.instructions
    const [instructions, setInstructions] = React.useState(taskInstructions)
    const { seteditTaskFlag, setEditInstructions, refetchInstruction, setrefetchInstruction } = React.useContext(TaskInstuctionContext)

    const [canvasModel, setcanvasModel] = React.useState(null)

    function sortByProperty(property) {
        return function (a, b) {
            if (a[property] > b[property])
                return 1;
            else if (a[property] < b[property])
                return -1;
            return 0;
        }
    }

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(taskInstructions))
        temp = temp.sort(sortByProperty("order"))
        setInstructions([...temp])
    }, [taskInstructions, setrefetchInstruction])

    useEffect(() => {
        if (refetchInstruction === false)
            setedtiTaskId([])
    }, [refetchInstruction])

    const [CloneStepAPI, { loading: cloneStepLoading }] = useMutation(CloneStep)

    const columns = useMemo(() => TaskListStepsColumn, [])
    const data = instructions

    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    );

    const handleCheck = (event, data) => {
        console.log("checkId is " + data + " " + typeof (data))
        if (props.checkId.includes(data))
            props.setCheckIdCallBack(props.checkId.filter((item) => item !== data))
        else {
            props.setCheckIdCallBack([...props.checkId, data])
            props.setCheckedCallBack(false)
        }
    };

    const handleCloneInstruction = async (id) => {
        console.log("Cloning instruction ", id)
        try {
            await CloneStepAPI({ variables: { instructionIds: id } })
            console.log("Successfully cloned the instruction ", JSON.stringify(data))
            setrefetchInstruction(true)
        } catch (error) {
            console.log("Error on cloning API ", error)
        }
    }

    const getcroppedImg = (index, imageSrc, embeddings) => {
        if (imageSrc === null || embeddings === null || embeddings === "null")
            return;
        // console.log("getting cropped image " + embeddings)
        let imageObj = new Image();
        imageObj.src = imageSrc;

        const canvas = document.getElementById("canvas" + index)

        if (canvas) {
            const ctx = canvas.getContext("2d")
            imageObj.onload = function () {
                let embedding = JSON.parse(embeddings)['anchor_embedding']
                let norm_x = embedding[2] + embedding[4] / 2
                let norm_y = embedding[3] + embedding[5] / 2
                let img_w = imageObj.width
                let img_h = imageObj.height
                let x = img_w * norm_x
                let y = img_h * norm_y

                // draw cropped image
                var sourceWidth = 90;
                var sourceHeight = 90;
                var sourceX = x - (sourceHeight / 2);
                var sourceY = y - (sourceWidth / 2);
                var destX = canvas.width / 4;
                var destY = -3;

                ctx.drawImage(imageObj, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, ctx.canvas.width / 2, ctx.canvas.height);
            }
        }
        // return imageObj;
    }
    return (
        <TableContainer className={classes.paper} {...getTableProps()} >
            <Table className={classes.table} >
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={instructions[index].id}>
                                <StyledTableRow className={commonClasses.TaskStep} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell === row.cells[0] && row.index < 10)
                                            return (
                                                <StyledTableCell key={index} {...cell.getCellProps()}>
                                                    {/* Hiding temporarily because reordering to steps is not implemented */}
                                                    {/* <IconButton size="small" className={commonClasses.TaskStepDrag + " button_no_bg"}>
                                                        <DragIndicatorRounded />
                                                    </IconButton> */}
                                                    <span className={classes.rowid}>
                                                        {"00" + (row.index).toString()}
                                                    </span>
                                                    <MyCheckbox size="small"
                                                        className={classes.mycheckbox}
                                                        id={(row.index).toString()}
                                                        onClick={((event) => handleCheck(event, parseInt(instructions[index].id)))}
                                                        checked={(props.checkId.includes(parseInt(instructions[index].id))) ? true : props.checked}
                                                    />
                                                    {cell.render("Cell")}
                                                </StyledTableCell>
                                            );
                                        if (cell === row.cells[0] && row.index >= 10)
                                            return (
                                                <StyledTableCell key={index} {...cell.getCellProps()}>
                                                    {/* Hiding temporarily because reordering to steps is not implemented */}
                                                    {/* <IconButton size="small" className={commonClasses.TaskStepDrag + " button_no_bg"}>
                                                        <DragIndicatorRounded />
                                                    </IconButton> */}
                                                    <span className={classes.rowid}>
                                                        {"0" + (row.index).toString()}
                                                    </span>
                                                    <MyCheckbox size="small"
                                                        className={classes.mycheckbox}
                                                        id={(row.index).toString()}
                                                        onClick={((event) => handleCheck(event, parseInt(instructions[index].id)))}
                                                        checked={(props.checkId.includes(parseInt(instructions[index].id))) ? true : props.checked}
                                                    />
                                                    {cell.render("Cell")}
                                                </StyledTableCell>
                                            );
                                        return (
                                            <StyledTableCell {...cell.getCellProps()}>
                                                {ScreenshotActionList.includes(row.original.action)
                                                    ?
                                                    <canvas onClick={() => setcanvasModel(row.original.fullScrHttpsUrl)} style={{ height: 'auto', width: '14%', border: '0.5px solid #eae3e3', borderRadius: '7px' }} id={`canvas${index}`} alt="cropped" />
                                                    :
                                                    cell.render("Cell")
                                                }
                                                {getcroppedImg(index, row.original.fullScrHttpsUrl, row.original.embedding)}
                                            </StyledTableCell>
                                        )
                                    })}

                                    <StyledTableCell className="" style={{ float: 'right', borderBottom: '0.5px solid transparent' }} >
                                        <Button
                                            disableRipple
                                            variant="text"
                                            className={"button_no_bg " + classes.TaskstepAction1}
                                            style={{ textTransform: 'capitalize' }}
                                            onClick={() => { seteditTaskFlag("edit"); setedtiTaskId(instructions[index].id); setEditInstructions(row.original); }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            disableRipple
                                            variant="text"
                                            className={"button_no_bg " + classes.TaskstepAction1}
                                            style={{ textTransform: 'capitalize' }}
                                            disabled={cloneStepLoading ? instructions[index].id === edtiTaskId : false}
                                            endIcon={cloneStepLoading ? instructions[index].id === edtiTaskId ? <CircularProgress size={14} /> : null : null}
                                            onClick={() => { handleCloneInstruction([instructions[index].id]); setedtiTaskId(instructions[index].id) }}
                                        >
                                            Clone
                                        </Button>
                                        <Button
                                            disableRipple
                                            variant="text"
                                            className={"button_no_bg " + classes.TaskstepAction1}
                                            style={{ textTransform: 'capitalize' }}
                                            onClick={() => {
                                                localStorage.setItem("EditInstructionactionType", "single");
                                                seteditTaskFlag("delete");
                                                setedtiTaskId(instructions[index].id);
                                                setEditInstructions({ instructionId: [instructions[index].id] })
                                            }}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            disableRipple
                                            variant="text"
                                            className={"button_no_bg " + classes.TaskstepAction1}
                                            style={{ textTransform: 'capitalize' }}
                                            onClick={() => {
                                                seteditTaskFlag("add");
                                                setedtiTaskId(instructions[index].id);
                                                setEditInstructions({ id: instructions[index].id, index: index })
                                            }}
                                        >
                                            Add Step
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {refetchInstruction && instructions[index].id === edtiTaskId
                                    ?
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={4}>
                                            <LinearProgress />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    :
                                    null
                                }
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>
            <CustomModal
                icon={<BsFillImageFill />}
                title="Screenshot"
                subheader=""
                openDialog={canvasModel}
                ParentCallBackFunction={setcanvasModel}
                size={"lg"}
                render={() => (
                    <img src={canvasModel} alt="screenshot_image" style={{ width: '100%', height: 'auto' }} />
                )}
            />
        </TableContainer>
    )
}

// This is the Task component: Each task in task-list is rendered using this component
function TaskAccord(props) {
    const classes = useStyles();
    const [isExpanded, setExpanded] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [checkid, setCheckId] = React.useState([]);

    const { seteditTaskFlag, refetchInstruction, setrefetchInstruction, setEditInstructions } = React.useContext(TaskInstuctionContext)
    const [CloneStepAPI, { loading: cloneStepLoading }] = useMutation(CloneStep)

    const { tasks, setTasks } = React.useContext(Task);

    // Function for deleting items from list using index
    const deleteItem = (tempList, index) => {
        return tempList.splice(index, 1);
    };

    // Moving Task one step Down
    const [MoveTaskDown, { loading: MTD_Loading }] = useMutation(OrderDownTask, {
        onCompleted(data) {
            let foundFlag = false;
            console.log("Successfully moved the task down ", data)
            console.log("data " + JSON.stringify(tasks))
            if (data.orderDownTask.success) {
                tasks.forEach((item, index) => {
                    console.log("comparing ", item, item.id, props.data.id)
                    if (item.id === props.data.id && !foundFlag) {
                        foundFlag = true;
                        console.log("found")
                        const removed = deleteItem(tasks, index)[0];
                        console.log("Removed ", JSON.stringify(removed))
                        tasks.splice(index + 1, 0, removed);
                        return;
                    }
                });
                console.log("task moved down " + JSON.stringify(tasks))
                setTasks(tasks.slice())
            } else {
                console.log("Error while moving the task down")
            }
            // getBotTask({ variables: { botId: props.botId } });
        }
    })


    // Moving Task one step Down
    const [MoveTaskUp, { loading: MTU_Loading }] = useMutation(OrderUpTask, {
        onCompleted(data) {
            console.log("Successfully moved the task up ", data)
            console.log("data " + JSON.stringify(tasks))
            if (data.orderUpTask.success) {
                tasks.forEach((item, index) => {
                    console.log("comparing ", item, item.id, props.data.id)
                    if (item.id === props.data.id) {
                        console.log("found")
                        const removed = deleteItem(tasks, index)[0];
                        console.log("Removed ", JSON.stringify(removed))
                        tasks.splice(index - 1, 0, removed);
                        return;
                    }
                });
                console.log("task moved up " + JSON.stringify(tasks))
                setTasks(tasks.slice())
            } else {
                console.log("Error while moving the task up")
            }
            // getBotTask({ variables: { botId: props.botId } });
        }
    })

    const [getTaskInstruction,
        {
            data: instructionsData,
            loading: instructionsLoading,
            error: instructionsError,
            refetch
        }] = useLazyQuery(TaskInstructions, { fetchPolicy: "cache-first", variables: { taskId: props.data.id } })

    function handleChange(event) {
        if (!isExpanded) {
            console.log("Run Query for tab ", props.data.id + " " + props.data.title)
            getTaskInstructionFunction()
        }
        setExpanded(!isExpanded)
    };

    const getTaskInstructionFunction = React.useCallback(() => {
        console.log("Callback for getting the task instruction for " + props.data.id)
        try {
            getTaskInstruction()
        } catch (error) {
            console.log("Error: while getting the task ", error)
        }
    }, [getTaskInstruction, props.data.id])

    React.useEffect(() => {
        const refetchFunction = async () => {
            try {
                await refetch()
            } catch (error) {
                console.log("Error: while getting the task ", error)
            }
            setrefetchInstruction(false)
        }
        if (refetchInstruction && isExpanded) {
            refetchFunction()
        } else if (refetchInstruction === false)
            localStorage.setItem("EditInstructionactionType", "none")
        setCheckId([])
    }, [refetchInstruction, isExpanded, instructionsData, setrefetchInstruction, refetch])

    const checkChanged = (flag) => {
        setChecked(flag)
        if (!checked)
            setCheckId([])
    };

    const handleCloneInstruction = async (event) => {
        console.log("Cloning instruction ", checkid)
        if (checkid.length === 0) {
            seteditTaskFlag("snack")
            return;
        }
        try {
            await CloneStepAPI({ variables: { instructionIds: checkid } })
            localStorage.setItem("EditInstructionactionType", "group")
            console.log("Successfully cloned the instruction ", JSON.stringify(instructionsData))
            setCheckId([])
            setrefetchInstruction(true)
        } catch (error) {
            console.log("Error on cloning API ", error)
        }
    }

    return (
        <div>
            <div className={classes.TaskListTab}>
                <Accordion className={classes.TaskAccord} expanded={isExpanded} >
                    <AccordionSummary >
                        <Grid container className={classes.TaskContainer}>
                           
                            <Grid item container md={4} sm={6} className={classes.TaskName}>
                                <Grid item xs={2}>
                                    <Tooltip title="Move Up" className={classes.OrderChangeIcons} onClick={() => {
                                        console.log("Moved Task Up ", props.data.id)
                                        MoveTaskUp({ variables: { taskId: props.data.id } })
                                    }}>
                                        <IconButton size={"small"} disabled={props.index === 0 || MTU_Loading || MTD_Loading}>
                                            {MTU_Loading ? <CircularProgress size={21} style={{ color: '#732370' }} /> : <AiOutlineArrowUp />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Move Down" className={classes.OrderChangeIcons} onClick={() => {
                                        console.log("Moved Task Down ", props.data.id)
                                        MoveTaskDown({ variables: { taskId: props.data.id } })
                                    }}>
                                        <IconButton size={"small"} disabled={props.index + 1 === props.size || MTU_Loading || MTD_Loading}>
                                            {MTD_Loading ? <CircularProgress size={21} style={{ color: '#732370' }} /> : <AiOutlineArrowDown />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={10} onClick={handleChange}>
                                    {props.data.title}
                                </Grid>
                            </Grid>
                            <Grid item md={5} sm={6} className={classes.TaskDesc} onClick={handleChange}>
                                {props.data.description}
                            </Grid>
                            <Grid item md={2} sm={6} className={classes.Automate} >
                                <div onClick={(event) => { props.callBackExecuteBot({ action: "record", title: props.data.title, taskId: props.data.id }) }}>
                                    Automate Task <IoFlash className={classes.icon1} />
                                </div>
                                <div onClick={(event) => { props.callBackExecuteBot({ action: "replay", title: props.data.title, taskId: props.data.id }) }}>
                                    Execute Task <IoFlash className={classes.icon1} />
                                </div>
                            </Grid>
                            <Grid item md={1} sm={6} className={classes.icon2}>
                                {isExpanded ? <ExpandLessRounded onClick={handleChange} /> : <ExpandMoreRounded onClick={handleChange} />}
                                <CreateBotTaskMenu task={props} />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {instructionsLoading
                                ?
                                <Grid item xs={12}>
                                    <TableSkeleton />
                                </Grid>
                                :
                                instructionsError
                                    ?
                                    <p>Error While fetching the steps. Please try again <IconButton size={"small"} onClick={() => refetch()} ><AiOutlineReload /></IconButton></p>
                                    :
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Button
                                                disableRipple
                                                className={"button_no_bg " + classes.TaskstepAction}
                                                style={{ textTransform: 'capitalize' }}
                                                startIcon={
                                                    !checked
                                                        ?
                                                        <CheckBoxOutlineBlankRounded className={`${classes.TaskStepActionIcon} ${classes.mycheckbox}`} />
                                                        :
                                                        <CheckBoxIcon className={classes.mycheckbox} />
                                                }
                                                onClick={(event) => { checkChanged(true) }}
                                            >
                                                Select All
                                            </Button>
                                            {(checked || checkid.length > 0)
                                                ?
                                                <Button
                                                    disableRipple
                                                    className={"button_no_bg " + classes.TaskstepAction}
                                                    style={{ textTransform: 'capitalize' }}
                                                    startIcon={<CheckBoxOutlineBlankRounded className={classes.mycheckbox} />}
                                                    onClick={(event) => { checkChanged(false) }}
                                                >
                                                    Deselect
                                                </Button>
                                                :
                                                null
                                            }
                                            {/* <TaskEditPopOver icon={<RiArrowUpDownFill className={classes.TaskStepActionIcon} />} Action={"Reorder"} /> */}
                                            <TaskEditPopOver icon={<IoCopy className={classes.TaskStepActionIcon} />} Action={"Copy to"} />
                                            {/* <TaskEditPopOver icon={<FileCopyRounded className={classes.TaskStepActionIcon} />} Action={"Clone"} /> */}
                                            <Button
                                                disableRipple
                                                className={"button_no_bg " + classes.TaskstepAction}
                                                style={{ textTransform: 'capitalize' }}
                                                disabled={cloneStepLoading}
                                                startIcon={cloneStepLoading ? <CircularProgress className={classes.TaskStepActionIcon} size={14} /> : <FileCopyRounded className={classes.TaskStepActionIcon} />}
                                                onClick={(event) => { handleCloneInstruction(event) }}
                                            >
                                                Clone
                                            </Button>

                                            <Button
                                                disableRipple
                                                className={"button_no_bg " + classes.TaskstepAction}
                                                style={{ textTransform: 'capitalize' }}
                                                startIcon={<DeleteRounded className={classes.TaskStepActionIcon} />}
                                                onClick={() => {
                                                    if (checkid.length === 0)
                                                        seteditTaskFlag("snack")
                                                    else {
                                                        localStorage.setItem("EditInstructionactionType", "group");
                                                        seteditTaskFlag("delete");
                                                        setEditInstructions({ instructionId: checkid })
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {instructionsData
                                                ?
                                                <React.Fragment>
                                                    <TaskInstructionTable
                                                        instructions={instructionsData.taskInstructions}
                                                        setCheckIdCallBack={setCheckId}
                                                        checkId={checkid}
                                                        checked={checked}
                                                        setCheckedCallBack={setChecked}
                                                        taskId={props.data.id}
                                                    />
                                                    {refetchInstruction && localStorage.getItem("EditInstructionactionType") === "group"
                                                        ?
                                                        <LinearProgress />
                                                        :
                                                        null
                                                    }
                                                </React.Fragment>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </React.Fragment>
                            }

                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export function TaskList(props) {
    const CommonClasses = commonStyles();
    const [BotExecuteAction, setBotExecuteAction] = React.useState(null);
    const { tasks, setTasks } = React.useContext(Task);
    const botId = props.botId;
    const [editTaskFlag, seteditTaskFlag] = React.useState("")
    const [EditInstructions, setEditInstructions] = React.useState(null)
    const [refetchInstruction, setrefetchInstruction] = React.useState(false);


    const { refetch: getBotTask } = useQuery(
        BotTasks,
        {
            variables: {
                botId: props.botId
            },
            onCompleted: (data) => {
                const botTasks = data.botTasks
                setTasks(botTasks.map(botTask => ({
                    ...botTask,
                    isCreated: false,
                    isEdited: false,
                })))
            }
        })


    React.useEffect(() => {
        if (props && props?.botId)
            getBotTask();
    })

    return (
        <div>
            {
                tasks.length === 0
                    ?
                    <div className={CommonClasses.Emptypanel} />
                    :
                    <TaskInstuctionContext.Provider value={{ seteditTaskFlag, EditInstructions, setEditInstructions, refetchInstruction, setrefetchInstruction }}>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            style={{ fontFamily: "Pangram" }}
                            open={editTaskFlag === "snack"}
                            autoHideDuration={6000}
                            onClose={() => seteditTaskFlag("")}
                            message={"Please select steps for performing the action"}
                            action={
                                <React.Fragment>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => seteditTaskFlag("")}>
                                        <CloseRounded fontSize="small" />
                                    </IconButton>
                                </React.Fragment>
                            }
                        />
                        <CustomModal
                            icon={<BiEdit />}
                            title="Edit Bot's Step"
                            subheader=""
                            openDialog={editTaskFlag === "edit"}
                            ParentCallBackFunction={seteditTaskFlag}
                            size={"lg"}
                            render={() =>
                                <EditBotInstruction
                                    action="edit"
                                    handleSetOpenEditBotModal={seteditTaskFlag}
                                />}
                        />

                        <CustomModal
                            icon={<CgExtensionAdd />}
                            title="Add New Step"
                            subheader=""
                            openDialog={editTaskFlag === "add"}
                            size={"lg"}
                            ParentCallBackFunction={seteditTaskFlag}
                            render={() =>
                                <AddBotInstruction
                                    handleSetOpenEditBotModal={seteditTaskFlag}
                                />}
                        />
                        <CustomModal
                            icon={<AiFillDelete />}
                            title="Delete Step"
                            subheader=""
                            openDialog={editTaskFlag === "delete"}
                            ParentCallBackFunction={seteditTaskFlag}
                            render={() =>
                                <DeleteBotInstruction
                                    handleSetOpenEditBotModal={seteditTaskFlag}
                                />}
                        />
                        {BotExecuteAction
                            ?
                            BotExecuteAction.action === "replay"
                                ?
                                <RecordReplayBot
                                    action={BotExecuteAction.action}
                                    title={`Execute Task: ${BotExecuteAction.title}`}
                                    subheader={`Bot Name: ${props.botName}`}
                                    botId={props.botId}
                                    CallBack={setBotExecuteAction}
                                    taskId={BotExecuteAction.taskId}
                                />
                                :
                                <RecordReplayBot
                                    action={BotExecuteAction.action}
                                    title={`Automate Task: ${BotExecuteAction.title}`}
                                    subheader={`Bot Name: ${props.botName}`}
                                    botId={props.botId}
                                    CallBack={setBotExecuteAction}
                                    taskId={BotExecuteAction.taskId}
                                />
                            :
                            null
                        }
                        {
                            tasks.map((task, index) => (
                                <div key={task.id} >
                                    {/* Flags {`${""+task.isEdited} ${""+task.isCreated}`} */}
                                    {task.isEdited || task.isCreated
                                        ?
                                        <CreateBotNewTask task={task} />
                                        :
                                        <TaskAccord
                                            {...props}
                                            key={task.id} data={task}
                                            index={index} size={tasks.length}
                                            callBackExecuteBot={setBotExecuteAction}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </TaskInstuctionContext.Provider>
            }
            <div>
                <AddNewButton context={0} text="Add a new task" botId={botId} />
            </div>
        </div>
    );
}

function CreateBotTask(props) {

    const TabStyleClass = TabStyle();
    const classes = useStyles();
    const [activetab, setActiveTabValue] = React.useState(parseInt(localStorage.getItem("ActiveCreateBotTaskListTab")) || 0);

    const changeActiveTab = (event, newValue) => {
        localStorage.setItem("ActiveCreateBotTaskListTab", newValue)
        setActiveTabValue(newValue);
    };

    return (
        <div className={TabStyleClass.MyContainer}>
            <Grid item xs={12}>
                {props ?
                    <Button className={classes.skip} style={{ marginRight: "1%" }} onClick={() => props.skipFunction(2)}><span>Skip</span></Button>
                    :
                    null
                }
            </Grid>
            <Grid container className={TabStyleClass.TabContainer}>
                <Grid item xs={10}>
                    <AntTabs value={activetab} onChange={changeActiveTab} aria-label="ant example">
                        <AntTab label={"Task List"} />
                        <AntTab style={{ display: 'none' }} label={"Dependencies"} />
                    </AntTabs>
                </Grid>
            </Grid>
            <TabPanel value={activetab} index={0}>
                <TaskList {...props} />
            </TabPanel>
            <TabPanel className={TabStyleClass.MyTabPanel} value={activetab} index={1}>
                <Dependencies />
            </TabPanel>
        </div>
    )
}

export default function BotTaskList(props) {
    return (
        <TaskProvider>
            <CreateBotTask {...props} />
        </TaskProvider>
    )
}
import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, IconButton } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { Query, useMutation } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { activeMenuVar } from "../utils/cache";



// importing required css
import { commonStyles } from "../styles/css/CommonCSS";


// importing required queries
import { BotTabs, AppsUsed, ProcessTags, CreateBot, CreateBotCustomAvatar } from '../graphql/queries';

// importing requred components/Pages


// importing required images
import avt1 from '../styles/images/avt1.PNG';
import avt2 from '../styles/images/avt2.PNG';
import avt3 from '../styles/images/avt3.PNG';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginTop: '4%',
        padding: '1%',
        fontFamily: 'DM Sans',
        borderRadius: '12px',
        border: '0.5px solid #e0dddc',
        fontSize: '16px',
    },
    box: {
        borderColor: 'grey',
        marginTop: '4%',
    },
    sizeAvatar: {
        height: theme.spacing(10),
        width: theme.spacing(10),
        opacity: 0.9,
        background: 'light gray',
    },
    sizeAvt: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        opacity: 0.9,
        background: 'light gray',
    },
    avatarlist: {
        height: theme.spacing(3.8),
        width: theme.spacing(3.8),
        verticalAlign: 'top'
    },
    formControl: {
        margin: theme.spacing(1),

    },
    title: {
        fontSize: '32px',
        fontFamily: 'Pangram',
        fontWeight: 'bold'
    },
    textField: {
        fontFamily: 'Pangram',
    },
    text: {
        fontSize: '16px',
        fontFamily: 'DM Sans',
        color: '#402E3C',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0em',
        textAlign: 'left',
    },
    avatar: {
        backgroundColor: grey[800],
        borderRadius: 50,
        width: 50,
        padding: 2,
    },
    input: {
        display: 'none',
    },
    skeleton: {
        width: "43%"
    }
}));
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 20,
        padding: '10px 26px 10px 12px',
        fontFamily: [
            "Pangram"
        ].join(',')
    },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateBotInitialScreen(props) {
    //localStorage.setItem("ActiveStep", props.step);
    const [app, setApp] = React.useState(JSON.parse(localStorage.getItem("createbotapp")));
    const [processTag, setProcessTag] = React.useState(JSON.parse(localStorage.getItem("createbotPT")));
    const [botsave, setBotsave] = React.useState(activeMenuVar());
    const [tabId, setTabid] = React.useState(localStorage.getItem("SideTabId"));
    const [openSnack, setOpenSnack] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [descript, setDescript] = React.useState(localStorage.getItem("createbotdes"));
    const [bName, setBname] = React.useState(localStorage.getItem("createbotname"));
    const [isDisabled, setisDisabled] = React.useState(true);
    const [image, setImage] = React.useState(localStorage.getItem("createbotImg"));
    const [showBadge, setshowBadge] = React.useState(JSON.parse(localStorage.getItem("badge")));
    const [snackTitle, setSnackTitle] = React.useState('');
    const [avtid, setAvtid] = React.useState(1);
    const [opacity, setOpacity] = React.useState(0.5)
    const [customAvatar, setCustomAvtFlag] = React.useState(false);
    const [imgFile, setImgFile] = React.useState();

    const [btnFlag, setbtnFlag] = React.useState(JSON.parse(localStorage.getItem("btnFlag")))

    const [createBotCustomAvatar, { loading: customAvtLoad }] = useMutation(CreateBotCustomAvatar, {
        onCompleted(data) {
            const botId = data.createBot.bot.id
            localStorage.setItem("botid", botId)
            console.log("calling skip function with data " + JSON.stringify(data))
            if (customAvatar) {
                console.log("sending from newBotData " + data.createBot.bot.id);
                props.skipFunction(1, data.createBot.bot.id)
            } else {
                console.log("sending from newBotData1 " + data.createBot.bot.id);
                props.skipFunction(1, data.createBot.createBot.bot.id)
            }
            setisDisabled(false);
        },
        onError() {
            setisDisabled(false);
        }
    });
    const [createBot, { loading: createBotLoad, error: mutationError }] = useMutation(
        CreateBot,
        {
            onCompleted(data) {
                const botId = data.createBot.bot.id
                localStorage.setItem("botid", botId)
                console.log("calling skip function with data " + JSON.stringify(data))
                if (customAvatar) {
                    console.log("sending from newBotData " + data.createBot.bot.id);
                    props.skipFunction(1, data.createBot.bot.id)
                } else {
                    console.log("sending from newBotData1 " + data.createBot.bot.id);
                    props.skipFunction(1, data.createBot.bot.id)
                }
            }
        }
    );

    const CommonClasses = commonStyles();
    const classes = useStyles();


    const handleAppChange = (event) => {
        setApp(event.target.value);
        console.log("app:", app);
    };
    const handlePTChange = (event) => {
        setProcessTag(event.target.value);
    };
    const handleChange = (event) => {
        setBotsave(event.target.value);
    };
    const tabkey = (event, tab) => {
        setTabid(tab.id);
        console.log("tabid: ", tab.id);
    }
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };
    function TabDataComp() {
        return (
            < Query query={BotTabs} >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div className={classes.skeleton}><Skeleton animation="wave" variant="text" /></div>);
                    if (error) {
                        return (
                            <div>
                                {console.log(error.message)}
                                {/*<CustomizedDialogs Error={error.message} />*/}
                                <h5> Server Error ! Try again later </h5>
                            </div>);

                    }
                    else {
                        if (data.length === 0) {
                            return (
                                <div>
                                    <h5> No Data Available! </h5>
                                </div>);
                        }
                        else {
                            return (
                                <div>
                                    <FormControl style={{ minWidth: 350 }}>
                                        <Select required
                                            disabled={JSON.parse(localStorage.getItem("disable"))}
                                            labelId="demo-customized-select-save-label"
                                            value={botsave}
                                            onChange={handleChange}

                                            id="demo-customized-select-save"
                                            input={<BootstrapInput />}
                                        >
                                            <MenuItem value="" disabled>
                                                Select Bot Tab*
                                            </MenuItem>
                                            {data.botTabs.map((tab) => (
                                                <MenuItem key={tab.id} value={tab.title} onClick={(event) => tabkey(event, tab)}>
                                                    {tab.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        }
                    }
                }
                }
            </Query >
        );
    }
    function ProcessTagsData() {
        const classes = useStyles();

        return (
            < Query query={ProcessTags} >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div className={classes.skeleton}><Skeleton variant="text" /></div>);
                    if (error) {
                        return (
                            <div>
                                {console.log(error.message)}
                                {/*<CustomizedDialogs Error={error.message} />*/}
                                <h5> Server Error ! Try again later </h5>
                            </div>);

                    }
                    else {
                        if (data.length === 0) {
                            return (
                                <div>
                                    <h5> No Data Available! </h5>
                                </div>);
                        }
                        else {
                            return (
                                <div>
                                    <FormControl className={classes.formControl} style={{ minWidth: 366 }}>
                                        <Select
                                            labelId="demo-mutiple-process-chip-label"
                                            id="demo-mutiple-process-chip"
                                            multiple
                                            disabled={JSON.parse(localStorage.getItem("disable"))}
                                            value={processTag}
                                            onChange={handlePTChange}
                                            input={<BootstrapInput id="select-multiple-process-chip" />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {data.processTags.map((process) => (
                                                <MenuItem key={process.id} value={process.tagName} >
                                                    {process.tagName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        }
                    }
                }

                }
            </Query >
        );
    }
    function AppUsedData() {
        const classes = useStyles();
        return (
            < Query query={AppsUsed} >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div className={classes.skeleton}><Skeleton variant="text" /></div>);
                    if (error) {
                        return (
                            <div>
                                {console.log(error.message)}
                                {/*<CustomizedDialogs Error={error.message} />*/}

                                <h5> Server Error ! Try again later </h5>
                            </div>);

                    }
                    else {
                        if (data.length === 0) {
                            return (
                                <div>
                                    <h5> No Data Available! </h5>
                                </div>);
                        }
                        else {
                            return (
                                <div>
                                    <FormControl className={classes.formControl} style={{ minWidth: 366 }}>
                                        <Select
                                            labelId="demo-mutiple-app-chip-label"
                                            id="demo-mutiple-app-chip"
                                            multiple
                                            disabled={JSON.parse(localStorage.getItem("disable"))}
                                            value={app}
                                            onChange={handleAppChange}
                                            input={<BootstrapInput id="select-multiple-app-chip" />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {data.appsUsed.map((app) => (
                                                <MenuItem key={app.id} value={app.appName} >
                                                    {app.appName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        }
                    }
                }
                }
            </Query >
        );
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }


    const ImgUploadHandler = (event) => {
        let img = event.target.files[0];
        if (!img)
            return
        setImage(URL.createObjectURL(img));
        setisDisabled(false);
        setImgFile(event.target.files[0]);
        setshowBadge(false);
        setCustomAvtFlag(true);
        setOpacity(1);
        getBase64(img).then(base64 => {
            localStorage["createbotImg"] = base64;
        });

    }
    useEffect(() => {
        console.log('imgFile: ', imgFile);
    }, [imgFile])

    function DefaultAvatarHandler(avt) {
        let img = avt;
        setImage(img);
        setshowBadge(false);
        setisDisabled(false);
        setCustomAvtFlag(false);
        switch (avt) {
            case avt1:
                localStorage.setItem("createbotImg", avt1)
                setAvtid(56)
                break
            case avt3:
                localStorage.setItem("createbotImg", avt3)
                setAvtid(57)
                break
            default:
                localStorage.setItem("createbotImg", avt2)
                setAvtid(1)
        }
        setOpacity(1)
    }
    const onChangeDescript = (event) => {
        setDescript(event.target.value);
    };
    const onChangeBname = (event) => {
        setBname(event.target.value);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        style={{ fontFamily: "Pangram" }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={error ? "Error!!! Bot is not created" : bName + " bot has been created successfully !"}
        action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        }
    />
    let test = true;

    return (
        <React.Fragment>
            <CssBaseline />

            <TableContainer className={classes.container}>
                <form
                    encType={'multipart/form-data'}
                    onSubmit={async (e) => {
                        e.preventDefault();
                        setisDisabled(true);
                        console.log("tabid: ", tabId);
                        try {
                            customAvatar ?
                                await createBotCustomAvatar({
                                    variables: {
                                        name: bName,
                                        description: descript,
                                        avatar: imgFile,
                                        tabId: tabId,
                                        appsUsed: app,
                                        processTags: processTag
                                    }
                                })
                                :
                                await createBot({
                                    variables: {
                                        name: bName,
                                        description: descript,
                                        avatarId: avtid,
                                        tabId: tabId,
                                        appsUsed: app,
                                        processTags: processTag
                                    }
                                });

                            setSnackTitle(bName)
                            setOpenSnack(true)
                            setDescript(descript)

                            //setBotsave('Select Bot Tab*')
                            setApp(app)
                            setBname(bName)
                            localStorage.setItem("createbotname", bName);
                            // localStorage.setItem("avatarimg", image);
                            localStorage.setItem("createbotdes", descript);
                            localStorage.setItem("createbottab", botsave);
                            localStorage.setItem("createbotapp", JSON.stringify(app));
                            localStorage.setItem("createbotPT", JSON.stringify(processTag));
                            localStorage.setItem("btnFlag", JSON.stringify(true));
                            localStorage.setItem("disable", JSON.stringify(test));
                            localStorage.setItem("badge", JSON.stringify(false));
                            setProcessTag(processTag)
                            // setshowBadge(true)
                            setisDisabled(true)
                            setOpacity(0.5)
                            console.log("para: ", bName, descript, app);
                            props.callbackFunction(false)

                        }
                        catch (error) {
                            setError(true)
                            return
                        }

                    }}>
                    <Table>

                        <TableBody>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <h6 className={classes.text}>Avatar/Image*:</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none" }}>
                                    {showBadge
                                        ?
                                        <Avatar src="/broken-image.jpg" className={classes.sizeAvatar} ></Avatar>
                                        :
                                        <img width="6%" src={image} alt="avatarImage" />
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}></TableCell>
                                <TableCell scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <Grid item xs={12}>
                                        <Typography >Default Avatars:
                                            <Button disabled={btnFlag} className="button_no_bg" onClick={() => DefaultAvatarHandler(avt1)}><Avatar className={classes.avatarlist} src={avt1}></Avatar></Button>
                                            <Button disabled={btnFlag} className="button_no_bg" onClick={() => DefaultAvatarHandler(avt2)}><Avatar className={classes.avatarlist} src={avt2}></Avatar></Button>
                                            <Button disabled={btnFlag} className="button_no_bg" onClick={() => DefaultAvatarHandler(avt3)}><Avatar className={classes.avatarlist} src={avt3}></Avatar></Button>
                                            <Badge
                                                overlap="circle"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={
                                                    <> <input disabled={btnFlag} accept="image/*" className={classes.input} onChange={ImgUploadHandler} id="icon-button-file" type="file" />
                                                        <label htmlFor="icon-button-file">
                                                            <IconButton disabled={btnFlag} color="primary" aria-label="upload picture" component="span">
                                                                <Box bgcolor='background.paper' borderRadius="50%" borderColor="purple" border="2px solid" style={{ width: '1.5rem', height: '1.6rem' }} >
                                                                    <EditIcon disabled={btnFlag} style={{ fontSize: 15, color: 'purple' }}></EditIcon>
                                                                </Box>
                                                            </IconButton>
                                                        </label></>
                                                }
                                            >
                                                <Avatar src="/broken-image.jpg" className={classes.sizeAvt} ></Avatar>
                                            </Badge>
                                        </Typography></Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <h6 className={classes.text}>Bot Name*:</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none" }}>
                                    <FormControl style={{ minWidth: 350 }}>
                                        <TextField disabled={JSON.parse(localStorage.getItem('disable'))} type='text' required InputLabelProps={{ style: { fontFamily: "DM Sans" } }} width="50%" id="botName" label="Enter bot name"
                                            onChange={onChangeBname} value={bName} variant="outlined" rows={1} multiline fullWidth />
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }} >
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }} >
                                    <h6 className={classes.text}>Select Tab *:</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none" }}>
                                    <TabDataComp />
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <h6 className={classes.text}>Description*:</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none" }}>
                                    <FormControl style={{ minWidth: 366 }}>
                                        <TextField disabled={JSON.parse(localStorage.getItem("disable"))} required type="text"
                                            inputProps={{
                                                maxLength: 150,
                                            }} InputLabelProps={{ style: { fontFamily: "DM Sans" } }} id="des" label="Write a brief description of what this bot does in upto 25 words" variant="outlined"

                                            rows={6} onChange={onChangeDescript} value={descript} multiline fullWidth />
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <h6 className={classes.text}>Applications Used (optional):</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <AppUsedData />
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "none" }}>
                                <TableCell component="th" scope="row" style={{ borderBottom: "none", verticalAlign: 'top' }}>
                                    <h6 className={classes.text}>Process Tag (optional):</h6>
                                </TableCell>
                                <TableCell align="left" style={{ borderBottom: "none" }}>
                                    <ProcessTagsData />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Button
                        type='submit'
                        disabled={isDisabled}
                        style={{
                            opacity: opacity,
                            marginLeft: "80%", marginTop: "5%", height: "4em", width: "18em"
                        }}
                        className={`gradient_round_btn ${CommonClasses.Gradient_Btn} ${classes.CreateBotBtn}`}
                        variant="contained">
                        <span style={{
                            fontSize: "16px",
                            color: "#FFF",
                            fontFamily: "Pangram",
                            textTransform: "initial"
                        }}>Save & Continue</span>
                        {createBotLoad || customAvtLoad ? <CircularProgress style={{ marginLeft: '5%' }} size={21} /> : null}
                    </Button>
                </form>
                {mutationError && <p style={{ fontFamily: 'DM Sans', color: 'Red', display: 'flex' }}><ErrorIcon />Error : Please try again</p>}
                {mutationError ? () => setisDisabled(false) : () => setisDisabled(false)}
            </TableContainer >
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ fontFamily: "Pangram" }}
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={error ? "" : ""}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                {error ? <Alert severity="error">Error!!! Something went wrong!</Alert> : <Alert onClose={handleSnackClose} severity="success">
                    {snackTitle} bot has been created successfully !</Alert>}
            </Snackbar>
        </React.Fragment>
    );
}
// importing required packages
import React from "react";
import { Query } from 'react-apollo'
import { CircularProgress } from "@material-ui/core";
import { OrgInfo } from "../graphql/queries";
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, withStyles, Table, TableBody, TableContainer } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { commonStyles } from "../styles/css/CommonCSS";


const StyledTableRow = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
}
))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '25%',
  },
}
))(TableCell);


const useStyles = makeStyles((theme) => ({
  email: {
    background: 'purple',
    color: 'white',
    marginLeft: '2%',
    '&:hover': {
      background: 'purple',
      color: 'white',
    },

  },
  table: {
    tableLayout: 'fixed',
    width: '90%',
    textAlign: 'center',
    marginLeft: '7%',
    overflow: 'hidden',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    textTransform: 'capitalize',
    borderBottom: 'none',
  },
  heading: {
    float: 'left',
    color: 'purple',
    marginLeft: '5%',
    marginTop: '3%',
    borderBottom: '3px solid purple',
    textDecorationColor: 'purple',
    width: '10%',
    textAlign: 'center',
    fontWeight: '500',
  },
  edit: {
    textAlign: 'right',
    float: 'right',
    color: 'purple',
    border: 'none',
    marginTop: '3%',
    marginRight: '5%',
    textDecorationColor: 'purple',
    width: '15%',
    fontVariant: 'normal',
    fontStyle: 'normal',


  },
  table_action: {
    marginLeft: '5%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
  body: {

    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textTransform: 'capitalize'
  },
}));


function OrganizationDetails(data) {

  const classes = useStyles();
  const commonClasses = commonStyles();


  return (

    <React.Fragment>
      <div className={classes.table_action}>
        <h4 className={commonClasses.title}>Organization Info</h4>
      </div>
      <h6 className={classes.heading}>Personal</h6>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <TableCell className={classes.body}>
                {data.data.userDetails.username}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>

        <h6 className={classes.heading}>Organization</h6>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <TableCell className={classes.body}>
                {data.data.userDetails.additionalInfo.organization.name ?
                  data.data.userDetails.additionalInfo.organization.name :
                  "No data"}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                Address
              </StyledTableCell>
              <TableCell className={classes.body}>
                {data.data.userDetails.additionalInfo.organization.address ?
                  data.data.userDetails.additionalInfo.organization.address :
                  "No data"}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                Invoicing Details
              </StyledTableCell>
              <TableCell className={classes.body}>
                {data.data.userDetails.additionalInfo.organization.invoicingDetails ?
                  data.data.userDetails.additionalInfo.organization.invoicingDetails :
                  "No data"}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                #Bot in Organization
              </StyledTableCell>
              <TableCell className={classes.body}>
                {data.data.totalOrganizationBots}
              </TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                #User in Organization
              </StyledTableCell>
              <TableCell className={classes.body}>
                {(data.data.userDetails.additionalInfo.organization.userAdditionalInfo).length}
              </TableCell>
            </StyledTableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function OrganizationInfo() {
  const commonClasses = commonStyles()
  return (
    < Query query={OrgInfo} >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div>
              <div>
                <h4 className={commonClasses.title}>Organization Info</h4>
              </div>
              <div className="valign-wrapper" style={{ margin: "2%" }}>
                <CircularProgress size="25px" /> Loading data ...
              </div>
            </div>
          );
        if (error) {
          return (
            <div style={{ margin: "2%" }}>
              <div>
                <h4 className={commonClasses.title}>Organization Info</h4>
              </div>
              <div style={{ margin: '2%' }}>
                <h5> Please Try Again! </h5>
              </div>
            </div>);
        }
        else {
          return (
            <div>
              <OrganizationDetails data={data} />
            </div>);
        }
      }
      }
    </Query >
  );
}

export default OrganizationInfo;

import { makeVar } from '@apollo/client';

export const isLoggedInVar = makeVar<boolean>(Boolean(localStorage.getItem("login")) || false);

export const activeNavVar = makeVar<string>((localStorage.getItem("TopNav")) || "MyBots");

export const activeMenuVar = makeVar<string>((localStorage.getItem("SideTab") || ""));

export const sideNavActive = makeVar<boolean>(Boolean(localStorage.getItem("sideNavActive")) || false);

export const BotCount = makeVar(localStorage.getItem("BCount")||[]);

export const BotTabCheck = makeVar<boolean>(Boolean(localStorage.getItem("BotRefetch")) || false);


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Grid, TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import { TableSkeleton } from "./skeleton";
import { useTable } from "react-table";
import { Paper } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';


// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { CustomModal } from "./modal";
import { ArchivedUsersColumn, ManageNewUsersColumn } from "./Column";
import { TabStyle } from "./TabComponent";


// importing required queries
import { useQuery, useMutation } from 'react-apollo';
import { CircularProgress } from "@material-ui/core";
import { ArchivedUsersList, ArchiveUsers, AddNewUserMutation, ResendInvite } from "../graphql/queries";

// importing required variables
import { client } from "../graphql/apolloClient";
import AutorenewRounded from '@material-ui/icons/Autorenew';
import { AddRounded } from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import { commonStyles } from '../styles/css/CommonCSS';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';



const useStyles = makeStyles({
    paper: {
        width: '100%',
        margin: 'auto',
        borderRadius: '12px',
    },
    size: {
        fontSize: '14px',
    },
    table_action: {
        width: '100%',
        ">h5": {
            fontFamily: 'Pangram',
            fontWeight: 'bolder',
        },
    },
});


function AddNewUser(props) {
    const classes = useStyles();
    const TabStyleClass = TabStyle();
    const CommonClasses = commonStyles();


    const [Resend] = useMutation(
        ResendInvite, {
        onCompleted: () => {
            console.log("Success")
            setMsgsnackbar("An Email has been RESEND successfully");
            handleClicksnackbar();
            props.Refetch();
        },
        onError: (error) => {
            console.log("Error")
            console.log(error)
            setMsgsnackbar("There was an Error, Please Try Again");
            handleClicksnackbar();
        }
    });

    const [opensnackbar, setOpensnackbar] = React.useState(false);
    const [msgsnackbar, setMsgsnackbar] = React.useState("");

    const data = props.data;
    const columns = React.useMemo(() => ManageNewUsersColumn, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    );
    const handleClicksnackbar = () => {
        setOpensnackbar(true);
    };

    const handleClosesnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpensnackbar(false);
    };


    return (
        <React.Fragment>
            <div>
                <br />
                <div className={TabStyleClass.TabContainer}>
                    <TableContainer className={classes.paper} component={Paper}>
                        <Table size="small" className={classes.table}  {...getTableProps()}>
                            <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                                {headerGroups.map(headerGroup => (
                                    <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <StyledTableCell
                                                {...column.getHeaderProps()}
                                                align="left" className={classes.size}>
                                                {column.render("Header")}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                ))}
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <StyledTableRow {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell === row.cells[2]) {
                                                    return (
                                                        <StyledTableCell align="left">
                                                            <Button
                                                                className={`gradient_round_btn ${CommonClasses.Gradient_Btn}`}
                                                                variant="contained"
                                                                onClick={async (e) => {
                                                                    e.preventDefault();
                                                                    try {
                                                                        await Resend({ variables: { email: cell.row.original.email } })
                                                                    }
                                                                    catch (error) { console.log("Error !") }
                                                                }}
                                                                id={cell.id}
                                                            >
                                                                RESEND
                                                            </Button>
                                                            <Snackbar
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                open={opensnackbar}
                                                                autoHideDuration={6000}
                                                                onClose={handleClosesnackbar} >
                                                                <MuiAlert elevation={6}
                                                                    variant="filled"
                                                                    onClose={handleClosesnackbar}
                                                                    severity="info">
                                                                    {msgsnackbar}
                                                                </MuiAlert>
                                                            </Snackbar>
                                                        </StyledTableCell>
                                                    )
                                                }
                                                return (
                                                    <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </StyledTableCell>);
                                            })}
                                        </StyledTableRow>
                                    );
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </React.Fragment>
    );
}


function ManageUsersDetails(props) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const TabStyleClass = TabStyle();

    const [reactivate] = useMutation(
        ArchiveUsers, {
        onCompleted: () => {
            console.log("Success")
            props.Refetch();
            setWait(false);
        },
        onError: (error) => {
            console.log("Error")
            console.log(error)
            setWait(false);
        }

    });



    const [checkwait, setWait] = React.useState(false);

    const data = props.data;
    const columns = React.useMemo(() => ArchivedUsersColumn, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    );


    return (
        <React.Fragment>
            <div className={classes.table_action}>
                <h5 className={commonClasses.title}>Manage Archived Users</h5>
            </div>
            <div>
                <br />
                <div className={TabStyleClass.TabContainer}>
                    <TableContainer className={classes.paper} component={Paper}>
                        <Table size="small" className={classes.table}  {...getTableProps()}>
                            <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                                {headerGroups.map(headerGroup => (
                                    <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <StyledTableCell
                                                {...column.getHeaderProps()}
                                                align="left" className={classes.size}>
                                                {column.render("Header")}
                                                <span style={{}}></span>
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                ))}
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <StyledTableRow {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell === row.cells[2]) {
                                                    return (
                                                        <StyledTableCell align="left" className={classes.size}>
                                                            <Tooltip
                                                                title={"Activate User"}
                                                                placement="left"
                                                                key={cell.row.original.id} >
                                                                {checkwait ?
                                                                    <CircularProgress size="21px" /> :
                                                                    <AutorenewRounded
                                                                        style={{ cursor: 'pointer', }}
                                                                        onClick={async (e) => {
                                                                            e.preventDefault();
                                                                            try {
                                                                                await reactivate({ variables: { id: parseInt(cell.row.original.id, 10), value: true } })
                                                                            }
                                                                            catch (error) { console.log("Error !") }
                                                                        }}

                                                                        id={cell.row.original.id} />}
                                                            </Tooltip>
                                                        </StyledTableCell>
                                                    )
                                                }
                                                return (
                                                    <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </StyledTableCell>);
                                            })}
                                        </StyledTableRow>
                                    );
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </React.Fragment>

    );
}

function ManageUsers(props) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const [openDialog, setOpendialog] = React.useState(false);
    const [newemail, setNewEmail] = React.useState("");
    const [newrole, setNewRole] = React.useState("");


    const [AddNew] = useMutation(
        AddNewUserMutation, {
        onCompleted: (data) => {
            console.log("Success")
            props.Refetch();
            setOpendialog(false);
        },
        onError: (error) => {
            console.log("Error")
            console.log(error)
            setOpendialog(false);
        }

    });

    const handleEmail = (event) => {
        setNewEmail((event.target.value) || '');
    };

    const handleRole = (event) => {
        setNewRole((event.target.value) || '');
    };

    const { loading, error, data, refetch } = useQuery(ArchivedUsersList, { client: client });
    if (loading)
        return (
            <Grid container justify="space-evenly" alignItems="center">
                <Grid item xs={12}>
                    <h5 className={commonClasses.title}>Manage New Users</h5>
                </Grid>
                <Grid container item xs={12}>
                    <div style={{ width: '100%' }}>
                        <TableSkeleton />
                    </div>
                </Grid>
            </Grid>
        );
    if (error)
        return (
            <Grid container justify="space-evenly" alignItems="center">
                <Grid item xs={12}>
                    <h5 className={commonClasses.title}>Manage New Users</h5>
                </Grid>
                <Grid container item xs={12}>
                    <div style={{ width: '100%' }}>
                        <h5> Please Try Again! </h5>
                    </div>
                </Grid>
            </Grid>
        );

    return (
        <div className={classes.table_action}>
            <Grid container justify="space-evenly" alignItems="center">
                <Grid item xs={12}>
                    <h5 className={commonClasses.title}>Manage New Users</h5>
                    <Button style={{ float: 'right', marginRight: '2%' }}
                        endIcon={<AddRounded />}
                        className={`gradient_round_btn ${commonClasses.Gradient_Btn}`}
                        variant="contained"
                        onClick={(event) => { setOpendialog(true) }}>
                        ADD NEW USER
                    </Button>

                    <CustomModal title="Please fill in the details of the user" openDialog={openDialog} ParentCallBackFunction={setOpendialog}
                        render={() => {
                            return (

                                <DialogContent>
                                    <form className={classes.container} onSubmit={async (e) => {
                                        e.preventDefault();
                                        try {
                                            await AddNew({ variables: { email: newemail, role: newrole } })
                                        }
                                        catch (error) { console.log("Error !") }
                                    }
                                    } >
                                        <FormControl style={{ width: '100%' }} className={classes.formControl} >
                                            <input
                                                type="email"
                                                placeholder="Email ID"
                                                value={newemail}
                                                required
                                                onChange={handleEmail}
                                            />
                                            <Select
                                                native
                                                required
                                                value={newrole}
                                                onChange={handleRole}
                                                input={<Input id="demo-dialog-native" />}>
                                                <option value={""}>Select a Role</option>
                                                <option value={"organization_user"}>Organization User</option>
                                                <option value={"organization_basic_user"}>Organization Basic User</option>
                                                <option value={"organization_bot_admin"}>Organization Bot Admin</option>
                                                <option value={"organization_owner"}>Organization Owner</option>
                                            </Select>
                                        </FormControl>
                                        <DialogActions>
                                            <Button onClick={() => setOpendialog(false)} className={"button_no_bg"}>
                                                Cancel
                                            </Button>
                                            <Button type="submit"
                                                className={"button_no_bg"}>
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </DialogContent>

                            )
                        }}

                    />

                </Grid>
                {data
                    ?
                    <React.Fragment>
                        <Grid container item xs={12}>
                            {(data.userEmailRegistrations.length)
                                ?
                                <div style={{ width: '100%' }}>
                                    <AddNewUser data={data.userEmailRegistrations} Refetch={refetch} />
                                </div>
                                : null
                            }
                        </Grid>
                        <Grid container item xs={12}>
                            {data.archivedUsers.length
                                ?
                                <div style={{ width: '100%' }}>
                                    <ManageUsersDetails data={data.archivedUsers} Refetch={refetch} />
                                </div>
                                :
                                null
                            }
                        </Grid>
                    </React.Fragment>
                    :
                    <div style={{ width: '100%' }}>
                        <h5> Please Try Again! </h5>
                    </div>
                }
            </Grid>
        </div>
    )
}
export default ManageUsers;
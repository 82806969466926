import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import { useQuery, useMutation } from 'react-apollo';
import DialogContent from '@material-ui/core/DialogContent';
import { CircularProgress, TableContainer } from '@material-ui/core';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import IconButton from '@material-ui/core/IconButton';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { TableSkeleton } from "./skeleton";
import { Paper } from '@material-ui/core';
import { useTable } from "react-table";

import { AddRounded, BorderBottom } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography';
import { purple } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { CustomModal } from "./modal";
import { TabStyle } from "./TabComponent";
import { OrgVMColumn, IndividualVMColumn } from "./Column";


// importing required queries
import { OrgVMs, StartVmInstance, StopVmInstance, OrgCustomDate, OrgVmsPrevious, OrgVMsTracking } from "../graphql/queries";

// importing required variables
import { client } from "../graphql/apolloClient";
import { commonStyles } from '../styles/css/CommonCSS';

const PurpleRadio = withStyles({
  root: {
    color: purple[400],
    "&$checked": {
      color: purple[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const chartRootStyles = {
  chart: {
    paddingRight: '20px',
  },
};
const legendStyles = {
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
};
const legendLabelStyles = theme => ({
  label: {
    paddingTop: theme.spacing(1),
  },
});
const legendItemStyles = {
  item: {
    flexDirection: 'column',
  },
};


const UseStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const useStyles = makeStyles({
  paper: {
    width: '100%',
    margin: 'auto',
    borderRadius: '12px',
  },
  size: {
    fontSize: '14px',
  },
  table_action: {
    width: '100%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
  redstatus: {
    backgroundColor: 'red',
    'border-radius': '50%',
    'border-width': '0.5px',
    height: '30px',
    width: '30px',
    display: 'inline-block',
  },

  greenstatus: {
    backgroundColor: 'green',
    'border-radius': '50%',
    'border-width': '0.5px',
    height: '30px',
    width: '30px',
    display: 'inline-block',
  },

  head: {
    backgroundColor: '#44004245',
    fontSize: '14px',
  },
});


function SecondTable(props) {
  const classes = useStyles();
  const StyleClasses = UseStyles();
  const [checkwait, setWait] = React.useState(false);
  const [vmName, setVmName] = React.useState(props.CustomDate ? props.Remember : null);
  const [Start] = useMutation(StartVmInstance);
  const [Stop] = useMutation(StopVmInstance);
  const [Filter, SetFilter] = React.useState("1");
  const [DateRange, setDateRange] = React.useState(new Date());
  let CusDate = "Total Vm Usage Chart From " + props.StartDate + " To " + props.EndDate
  console.log("custom date ", CusDate, " flag is ", props.CustomDate)
  const [value, setValue] = React.useState(props.CustomDate ? "3" : "1");
  const [checkDate, reSetDate] = React.useState(props.CustomDate);
  const data = props.data;
  const columns = React.useMemo(() => IndividualVMColumn, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const handleSwitchChange = async (event) => {
    setWait(true);
    try {
      if (event.target.checked) { await Start({ variables: { name: event.target.id } }) }
      else { await Stop({ variables: { name: event.target.id } }) }
      console.log("Success")
      props.Refetch();
      setWait(false);
    }
    catch (error) {
      console.log("Error")
      console.log(error)
      setWait(false);
    }
  };
  const handleClose = () => {
    setValue("1")
    SetFilter("1")
    setDateRange(new Date());
    setVmName(null);
    props.setDate(false)
    props.setthis(false)
    reSetDate(false)
  };
  const FilterOption = (event) => {
    SetFilter(event.target.value);
  }
  const handleChangeDate = (event) => {
    DateRange.map((date, index) => (
      index === 0 ?
        props.setStart(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
        : props.setEnd(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
    ))
    console.log(DateRange, " Date ho")
    props.setthis(false)
    props.setRemember(vmName)
    props.setDate(true)
  }
  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "2") {
      props.setthis(true)
      props.setDate(false)
      reSetDate(false)
    }
    else if (event.target.value === "1") {
      props.setDate(false)
      props.setthis(false)
      reSetDate(false)
    }
  };
  React.useEffect(() => {
    if (vmName === false) {
      handleClose()
      console.log("I am close")
    }
  }, [vmName])

  function ModalPopup(props) {

    const Data = [];

    if (Filter === "1") {
      props.tracking.trackLabels.map((val_1, idx_1) => {
        Data.push({
          month: val_1,
          usage: props.tracking.trackTime[idx_1]
        })
      })
    }
    else if (Filter === "2") {
      props.tracking.trackWeeklyLabels.map((val_1, idx_1) => {
        Data.push({
          month: val_1,
          usage: props.tracking.trackWeeklyTime[idx_1],
        })
      })
    }
    else {
      props.tracking.trackMonthlyLabels.map((val_1, idx_1) => {
        Data.push({
          month: val_1,
          usage: props.tracking.trackMonthlyLabels[idx_1],

        })
      })
    }



    return (
      <CustomModal
        title="Track VM Instance"
        openDialog={vmName}
        ParentCallBackFunction={setVmName}
        size={"lg"}
        render={() => {
          return (
            <DialogContent align="center">
              <Grid container spacing={2} container
                direction="row"
                justifyContent="center"
                alignItems="flex-start" >
                <Grid item xs={12}>
                  <h5>Total Usage Data</h5>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 600 }}>
                    User/Instance
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 600 }}>
                    Total usage
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 600 }}>
                    Total cost
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 400 }}>
                    {props.tracker + " / " + vmName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 400 }}>
                    {"hr:mins:sec"} <br></br>
                    {props.tracking.uptime}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: 400 }}>
                    {"$" + props.tracking.cost}
                  </Typography>
                </Grid>
              </Grid>
              <br>
              </br>
              <div>
                <Grid container>
                  {
                    value === "3"
                      ?
                      <Grid item xs={12}>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleChangeDate(e)
                        }}>
                          <FormControl component="fieldset" style={{ float: "right" }}>
                            <DateRangePicker className="button_no_bg"
                              style={{ color: "purple" }}
                              views={["year", "month", "date"]}
                              onChange={setDateRange}
                              value={DateRange}
                              maxDate={new Date()}
                              label="Date Range"
                              format={"dd-MM-y"}
                              default={new Date()}
                              borderRadius="10px"
                              required
                            />
                            <Button type="submit" color=" purple" className="button_no_bg" variant="contained">Submit</Button>
                          </FormControl>
                        </form>
                      </Grid>
                      : null
                  }
                  <Grid item xs={4}>
                    <FormControl className={classes.FormControl} style={{ float: "left", maxWidth: '80px', maxHeight: '20px', minWidth: '50px', minHeight: '20px' }}>
                      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Filter}
                        onChange={FilterOption}
                      >
                        <MenuItem value={"1"}>Daily</MenuItem>
                        <MenuItem value={"2"}>Weekly</MenuItem>
                        <MenuItem value={"3"}>Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}  >
                    <FormControl component="fieldset" style={{ float: "right" }}>
                      <RadioGroup
                        row
                        aria-label="gender"
                        value={value}
                        onChange={(event) => { handleChange(event) }}
                      >
                        <FormControlLabel value="2" control={<PurpleRadio />} label="Last Month" />
                        <FormControlLabel value="1" control={<PurpleRadio />} label="This Month" />
                        <FormControlLabel value="3" control={<PurpleRadio />} label="Custom date" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {
                   checkDate ?
                      <h6 align="center"> {CusDate}</h6>
                      :
                      null
                  }
                </Grid>
                <Paper>
                  <Grid item xs={12}>
                    <LineChart width={1000} height={350} data={Data}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="usage" name="Uptime (hrs)" stroke="#9932CC" />
                    </LineChart>
                  </Grid>
                </Paper>
              </div>
              <DialogActions>
                <Button onClick={() => setVmName(null)} className="button_no_bg">
                  CLOSE
                </Button>
              </DialogActions>
            </DialogContent>
          )
        }}
      />

    )
  }

  return (

    <TableContainer className={classes.paper} component={Paper}>
      <Table size="small" className={classes.table}  {...getTableProps()}>
        <TableHead style={{ textTransform: 'uppercase', border: '3px solid #ccbacc' }} >
          {headerGroups.map(headerGroup => (
            <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <StyledTableCell align="left" className={classes.head}>
                  {column.render("Header")}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length === 0 ?
            <StyledTableRow >
              <StyledTableCell colSpan={8} className={classes.size}>
                <h5 align="center">No VM!</h5>
              </StyledTableCell>
            </StyledTableRow>
            : null}
          {rows.map((row, i) => {
            prepareRow(row);
            console.log("vm instance: ", row)
            return (
              <StyledTableRow {...row.getRowProps()}
                key={i}>
                {row.cells.map((cell, idx) => {

                  if (cell === row.cells[0]) {
                    return (
                      <StyledTableCell align="left" style={{ paddingLeft: '2%' }} className={classes.size}>
                        {i + 1}
                      </StyledTableCell>
                    )
                  }
                  if (cell === row.cells[3]) {
                    return (
                      cell.value ?
                        (
                          <StyledTableCell align="left" >
                            <span className={classes.greenstatus}></span>
                          </StyledTableCell>
                        )
                        :
                        (
                          <StyledTableCell align="left" >
                            <span className={classes.redstatus}></span>
                          </StyledTableCell>
                        )
                    )
                  }
                  if (cell === row.cells[4]) {
                    return (
                      <StyledTableCell align="left" className={classes.size}>
                        <div className="toggle switch switch-dropdown">
                          {checkwait ?
                            <CircularProgress size="21px" /> :
                            <label className={classes.size}>
                              Disable
                              <input className={classes.toggle} checked={cell.row.original.active} onChange={handleSwitchChange} id={cell.row.original.name} type="checkbox"></input>
                              <span className="lever"></span>
                              Enable
                            </label>
                          }
                        </div>
                      </StyledTableCell>
                    )
                  }

                  if (cell === row.cells[5]) {

                    return (
                      <StyledTableCell align="left" className={classes.size}>
                        <IconButton color="secondary" className="button_no_bg" onClick={(event) => { setVmName(row.original.name) }}>
                          <ShowChartIcon align="left" />
                        </IconButton>
                        {props.tracking.result.map(each_track => {
                          if (vmName === each_track.instanceName) {
                            return (
                              <ModalPopup
                                tracking={each_track} tracker={props.tracker} />)
                          }
                        })
                        }
                      </StyledTableCell>

                    )
                  }

                  return (
                    <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </StyledTableCell>);
                })}

              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>

  )
}

function OrganizationVMDetails(props) {

  const TabStyleClass = TabStyle();
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [value, setValue] = React.useState(props.CustomDate ? "3" : "1");
  const [checkDate, reSetDate] = React.useState(props.CustomDate);
  const [Filter, SetFilter] = React.useState("1");
  const [DateRange, setDateRange] = React.useState(new Date());
  const data = props.data;
  const [openDialog, setOpenDialog] = React.useState(props.CustomDate ? true:false);
  const columns = React.useMemo(() => OrgVMColumn, []);
  let CusDate = "Total Vm Usage Chart From " +  props.StartDate  + " To " + props.EndDate
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const ChartData=[]
  props.tracking.labels.map((label, index) => (
    ChartData.push({
        usage: props.tracking.chartData[index],
        month: label,
    }))
)
  const handleRow = (panel) => (event) => {
    if (props.Expand === panel) {
      props.Expandfunc(false);
    }
    else {
      props.Expandfunc(panel);
    }
  };
  const handleClose = () => {
    setValue("1")
    SetFilter("1")
    setDateRange(new Date());
    setOpenDialog(false);
    props.setDate(false)
    props.setthis(false)
    reSetDate(false)
  };
  const handleChangeDate = (event) => {
    DateRange.map((date, index) => (
      index === 0 ?
        props.setStart(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
        : props.setEnd(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear())
    ))
    props.setthis(false)
    props.setDate(true)
  }
  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "2") {
      props.setthis(true)
      props.setDate(false)
      reSetDate(false)
    }
    else if (event.target.value === "1") {
      props.setDate(false)
      props.setthis(false)
      reSetDate(false)
    }
  };
  return (
    <React.Fragment>
     <CustomModal
        title="Track VM Instance"
        openDialog={openDialog}
        ParentCallBackFunction={handleClose}
        size={"lg"}
        render={() => {
          return (
            <DialogContent align="center">
              <Grid container spacing={2} container
                direction="row"
                justifyContent="center"
                alignItems="flex-start" >
                <Grid item xs={12}>
                  <h5> Organization Total Usage Data </h5>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 600 }}>
                    Organization
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 600 }}>
                    Total VM
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 600 }}>
                    Total usage
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 600 }}>
                    Total cost
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 400 }}>
                    {props.OrgName}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 400 }}>
                    {props.tracking.noTrackings}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 400 }}>
                    {"hr:mins:sec"} <br></br>
                    {props.tracking.totalTime}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: 400 }}>
                    {"$ " + props.tracking.totalCost}
                  </Typography>
                </Grid>
              </Grid>
              <br>
              </br>
              <div>
                <Grid container>
                  {
                    value === "3"
                      ?
                      <Grid item xs={12}>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleChangeDate(e)
                        }}>
                          <FormControl component="fieldset" style={{ float: "right" }}>
                            <DateRangePicker className="button_no_bg"
                              style={{ color: "purple" }}
                              views={["year", "month", "date"]}
                              onChange={setDateRange}
                              value={DateRange}
                              maxDate={new Date()}
                              label="Date Range"
                              format={"dd-MM-y"}
                              default={new Date()}
                              borderRadius="10px"
                              required
                            />
                            <Button type="submit" color=" purple" className="button_no_bg" variant="contained">Submit</Button>
                          </FormControl>
                        </form>
                      </Grid>
                      : null
                  }
                  <Grid item xs={4}>
                    {/* <FormControl className={classes.FormControl} style={{ float: "left", maxWidth: '80px', maxHeight: '20px', minWidth: '50px', minHeight: '20px' }}>
                      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Filter}
                    //    onChange={FilterOption}
                      >
                        <MenuItem value={"1"}>Daily</MenuItem>
                        <MenuItem value={"2"}>Weekly</MenuItem>
                        <MenuItem value={"3"}>Monthly</MenuItem>
                      </Select>
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={8}  >
                    <FormControl component="fieldset" style={{ float: "right" }}>
                      <RadioGroup
                        row
                        aria-label="gender"
                        value={value}
                        onChange={(event) => { handleChange(event) }}
                      >
                        <FormControlLabel value="2" control={<PurpleRadio />} label="Last Month" />
                        <FormControlLabel value="1" control={<PurpleRadio />} label="This Month" />
                        <FormControlLabel value="3" control={<PurpleRadio />} label="Custom date" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {
                   checkDate ?
                      <h6 align="center"> {CusDate}</h6>
                      :
                      null
                  }
                </Grid>
                <Paper>
                  <Grid item xs={12}>
                    <LineChart width={1000} height={350} data={ChartData}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="usage" name="Uptime (hrs)" stroke="#9932CC" />
                    </LineChart>
                  </Grid>
                </Paper>
              </div>
              <DialogActions>
                <Button onClick={() => handleClose()} className="button_no_bg">
                  CLOSE
                </Button>
              </DialogActions>
            </DialogContent>
          )
        }}
      />

     <Grid container spacing={0} container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start" >
      <Grid item xs={4}>
        <h4 className={commonClasses.title}>Organization VMs</h4>
      </Grid>
      <Grid item xs={6}>
      <IconButton color="secondary" style={{marginBottom:"0", float:"right",padding:"0"}} onClick={(e)=>{setOpenDialog(true)}}  className="button_no_bg">
         <p className="button_no_bg" style={{ fontFamily:"Dm Sans",fontSize:"medium",color:"blue",}}>Tracker</p> <ShowChartIcon style={{marginBottom:"0"}} />
        </IconButton>
        </Grid>
      </Grid>
      <div className={classes.table_action}>
        <div className={TabStyleClass.TabContainer}>
          <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" className={classes.table}  {...getTableProps()}>
              <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                {headerGroups.map((headerGroup, head_idx) => (
                  <StyledTableRow
                    className={classes.tableHead}
                    {...headerGroup.getHeaderGroupProps()}
                    key={head_idx}>
                    {headerGroup.headers.map(column => (
                      <StyledTableCell
                        {...column.getHeaderProps()}
                        align="left" className={classes.size}>
                        {column.render("Header")}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.length === 0 ?
                  <StyledTableRow >
                    <StyledTableCell align="center" className={classes.size}>
                      <h5>There are No VMs in Your Organization!</h5>
                    </StyledTableCell>
                  </StyledTableRow>
                  : null}
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment>
                      <StyledTableRow {...row.getRowProps()} key={i}>
                        {row.cells.map((cell) => {
                          if (cell === row.cells[0]) {
                            return (
                              <StyledTableCell
                                className={classes.size}
                                align="left"
                                style={{ cursor: 'pointer' }}
                                onClick={handleRow(cell.row.original.user.id)}
                              >
                                {cell.render("Cell")}
                              </StyledTableCell>
                            )
                          }
                          if (cell === row.cells[1]) {
                            return (
                              <StyledTableCell align="left" className={classes.size}>
                                {cell.value.length}
                              </StyledTableCell>
                            )
                          }
                          return (
                            <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </StyledTableCell>);
                        })}
                      </StyledTableRow>

                      <StyledTableRow
                        {...row.getRowProps()}
                        key={row.original.user.id}
                        style={{ display: props.Expand === row.original.user.id ? '' : 'none' }}
                      >
                        <StyledTableCell align="left" className={classes.size} colSpan={8}>
                          <Collapse in={props.Expand === row.original.user.id}
                            style={{ background: 'transparent', padding: '2%' }}
                            className={TabStyleClass.TabContainer}
                            timeout="auto" unmountOnExit>
                            <SecondTable {...props}
                              data={row.original.user.vmInstances}
                              tracker={row.original.user.username}

                            />
                          </Collapse>
                        </StyledTableCell>
                      </StyledTableRow>

                    </React.Fragment>
                  );
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </React.Fragment>

  );
}

function OrganizationVMs() {
  const [accord_expanded, setExpanded] = React.useState(false);
  const commonClasses = commonStyles()
  let date = new Date()
  const { loading, error, data, refetch } = useQuery(OrgVMs, { client: client });
  const { loading: Loads, error: prevError, data: prevData } = useQuery(OrgVmsPrevious, { client: client });
  const [StartDate, setStartDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
  const [EndDate, setEndDate] = React.useState(date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear());
  const { loading: CustomLoad, error: DateError, data: CustomData, refetch: GetData } = useQuery(OrgCustomDate,
    { variables: { isPersonal: false, tzOffset: 0, startDate: StartDate, endDate: EndDate } },
    { client: client });
  const [PreMonth, setPrevous] = React.useState(false);
  const [remember, setRemember] = React.useState("");
  const [checkDate, setDate] = React.useState(false);
  React.useEffect(() => {
    if (checkDate) {
      GetData({ variables: { isPersonal: false, tzOffset: 0, startDate: StartDate, endDate: EndDate } })
    }
  }, [checkDate])
  if (loading || CustomLoad || Loads)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Organization VMs</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <TableSkeleton />
        </div>
      </div>
    );
  if (error || DateError)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Organization VMs</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <h5> Please Try Again! </h5>
        </div>
      </div>
    );
  if (data)
    return (
      <div>
        <OrganizationVMDetails
          data={data.userDetails.additionalInfo.organization.userAdditionalInfo}
          OrgName={data.userDetails.additionalInfo.organization.name}
          Refetch={refetch}
          Expand={accord_expanded}
          Expandfunc={setExpanded}
          tracking={PreMonth ? prevData.myVmInstanceTracking : checkDate ? CustomData.myVmInstanceTracking : data.myVmInstanceTracking}
          StartDate={StartDate} EndDate={EndDate}
          Remember={remember} setRemember={setRemember}
          CustomDate={checkDate} setStart={setStartDate} setEnd={setEndDate}
          setDate={setDate} setthis={setPrevous}
        />
      </div>);
}

export default OrganizationVMs;
// importing Required packages
import React, { useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// importing required graphql queries
import { CreateRegistration } from "../graphql/queries";

// importing the required images
import logo from '../styles/images/company_logo_name.png'

// importing css
import '../styles/css/common.css';
import { commonStyles } from "../styles/css/CommonCSS";
import { useMutation } from 'react-apollo';

function Registration(props) {

  const [createPendingRegistration, { data: UserData, error: RegistrationError }] = useMutation(CreateRegistration);
  const [username, setUserName] = React.useState("");
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [Message, setRegMessage] = React.useState(null);

  const CommonClasses = commonStyles()
  const history = useHistory();

  // Here we are handling the token after successful token authentication
  useEffect(() => {
    if (UserData) {
      console.log('Registration Form data', UserData);
      console.log('Success : ', UserData.createPendingRegistration.success);
      setRegMessage(null)
      localStorage.setItem("registration", UserData.createPendingRegistration.success)
      history.replace('/login')
    }
  }, [UserData, history]);

  const RegistrationSubmit = async (event) => {
    event.preventDefault()
    setRegMessage("Registering...")
    try {
      await createPendingRegistration({
        variables: {
          username: username,
          firstname: firstname,
          lastname: lastname,
          email: email,
          organization: organization
        }
      })

    } catch (error) {
      console.log(error)
      setRegMessage("Some error occurred! Try Again.")
    }
  }

  return (
    <div >
      <Grid style={{ background: 'white', position: 'absolute', width: '100%', height: '88.5%' }} container justify="center" alignItems="center" >
        <Grid item lg={4} xs={8} className="registration_container">
          <img className="login_media " src={logo} alt="Company logo"></img>
          <Grid container >
            <Grid item xs={12} className="input_container" >
              <p className="login_header" >Sign Up</p>
            </Grid>
            <form onSubmit={RegistrationSubmit}>
              <Grid item xs={12} >
                <input type="text"
                  style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                  placeholder="Username" required
                  value={username}
                  onChange={(event) => setUserName(event.target.value)}
                  pattern="^\S+$" />

                <input type="text"
                  style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                  placeholder="First Name" required
                  value={firstname}
                  onChange={(event) => setFirstName(event.target.value)
                  } />

                <input type="text"
                  style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(event) => setLastName(event.target.value)
                  } />

                <input type="email"
                  style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                  placeholder="Email ID" required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)
                  } />

                <input type="text"
                  style={{ borderRadius: 4, border: '0.5px solid #ebe6e6', marginBottom: '5%', paddingLeft: '2%' }}
                  placeholder="Organization" required
                  value={organization}
                  onChange={(event) => setOrganization(event.target.value)
                  } />

              </Grid>
              <text > {Message ? Message : null} </text>
              <Grid style={{ fontFamily: 'DM Sans' }} item xs={12} className="input_container " >
                <Button type="submit" variant="contained" className={"login_btn gradient_round_btn " + CommonClasses.Gradient_Btn}  >
                  Register
                </Button>
                <br /> <br />
                Do you have an account? Back to the <bold style={{ fontWeight: 'bold', color: 'purple', cursor: 'pointer' }} onClick={() => history.push("/login")}> login </bold> page.
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Registration;
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from 'react-dom';
import React from "react";
import { ApolloProvider } from 'react-apollo';
import "materialize-css/dist/css/materialize.css";
import "materialize-css/dist/js/materialize.js";

import { client } from "./graphql/apolloClient"

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';

// importing required components
import { default as LoginPage } from "../Login";
import { default as ErrorPage } from "../ErrorPage";
import { default as NavBar } from "../navbar";
import { default as ForgotPasswordReset } from "../Pages/ForgotPasswordReset";
import { default as Registration } from "../Registration";
import { default as RegistrationModified } from "../RegistrationModified";
import { default as SuccessSubscription } from "../SuccessSubscription";


// importing required variables
import { isLoggedInVar } from "../../utils/cache";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/" component={() => isLoggedInVar() ? null : <LoginPage />} />
          <Route exact path="/login" component={() => isLoggedInVar() ? <Redirect to="/" /> : <LoginPage />} />
          <Route exact path="/registration" component={() => <Registration />} />
          <Route exact path="/logout" component={() =>  <Redirect to="/" /> } />
          <Route exact path="/chargebee-registration/:planId" component={RegistrationModified} />
          <Route exact path="/success-subscription/:subid" component={SuccessSubscription} />


          {/* Forgot Password -> Reset URL Routing */}
          <Route path="/reset-password/:hashed_email/:hashed_timestamp" component={() => isLoggedInVar() ? <Redirect to="/" /> : <ForgotPasswordReset />} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </Router>
  );
}
export default App;

// importing Required packages
import React from 'react';
import { Grid, Typography, FormHelperText, CircularProgress, LinearProgress } from '@material-ui/core';
import { Query } from 'react-apollo';
import { useQuery } from "react-apollo";
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { client } from "../graphql/apolloClient";
import { CancelRounded } from '@material-ui/icons';
import { FiCheck } from 'react-icons/fi';
import { useLazyQuery } from "react-apollo";

// importing required components
import { AntTab, AntTabs, TabPanel, TabStyle } from "./TabComponent";
import { Globalfilter } from "./GlobalFilter";
import { TableSkeleton } from "./skeleton";
import { default as BotStoreTable } from './Pages/BotStoreTable';
import { default as CustomizedDialogs } from './Dialog';
import Nodata from './Nodata';
import FormControl from '@material-ui/core/FormControl';

//importing required queries 
import { GlobalBotStoreCollection, OrganizationBots, ShareWithMeBots, FilterBot, AppsUsed, ProcessTags, ShareFilterBots, OrganizationFilterBots } from "../graphql/queries";

// importing required state variables and other required variables
import { activeNavVar } from '../utils/cache';
import { SideNavContext } from "./sidemenu";

// importing css
import '../styles/css/common.css';

function QueryBot(props) {
    var Data = ''
    let des;
    let filter = '';

    if (props.dataFilter === undefined)
        return null;

    if (props.Filter === 0) {
        if (props.data === 'sharedBots')
            filter = props.dataFilter.sharedBots
        else if (props.data === 'organizationalBots')
            filter = props.dataFilter.organizationalBots
        else filter = props.dataFilter.globalBots
    }

    return (
        <div>
            {props.Filter === 0 ?
                props.dataFilter !== null ?
                    <div>
                        <BotStoreTable tab={props.data} filterString={props.filterString} data={filter} />
                    </div>
                    :
                    <div>

                    </div>

                :
                <Query query={props.QueryType} >
                    {({ loading, error, data, refetch }) => {

                        if (props.refetchFlag) {
                            refetch()
                            props.callbackFunction(false)
                        }
                        if (loading)
                            return (
                                <TableSkeleton />
                            );
                        if (error) {
                            return (
                                <div>
                                    {console.log(error.message)}
                                    <CustomizedDialogs Error={error.message} />
                                    <h5> Error! <b>{error.message}</b> Try again later </h5>
                                </div>);
                        }
                        else if (data) {
                            if (props.data === 'sharedBots') {
                                Data = data.sharedBots
                                des = "When a bot is shared with you, it will appear here"
                            }
                            else if (props.data === 'organizationalBots') {
                                Data = data.organizationalBots
                                des = "When a bot is published, it will appear here"
                            }
                            else {
                                Data = data.globalBots
                                des = "When a bot is published, it will appear here"
                            }
                            if (Data.length === 0) {
                                return (
                                    <div>
                                        <Nodata message="No bots to display" description={des} />
                                    </div>);
                            }
                            else {
                                return (
                                    <div>
                                        <BotStoreTable tab={props.data} filterString={props.filterString} data={Data} />
                                    </div>
                                );
                            }
                        }
                    }
                    }
                </Query >}
        </div>
    );

}


const useStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: '2%',
        fontSize: '150%',
        fontFamily: 'Pangram',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        margin: '0%',
    },
    formControl: {
        padding: '0% 7% 0% 7%',
        minWidth: "100%",
        maxWidth: "100%"
    },
}))

function BotStore(props) {
    const TabStyleClass = TabStyle();
    const Classes = useStyles()

    const { setSideNavFlag } = React.useContext(SideNavContext);

    const [BotStoreTab, setValue] = React.useState(parseInt(localStorage.getItem("ActiveBotStoreTab")) || 0);
    const [RefetchFlag, setRefetchFlag] = React.useState(false)
    const [app, setApp] = React.useState([]);
    const [processTag, setProcessTag] = React.useState([]);
    const [filter, setFilter] = React.useState(1);
    const [filterloading, setLoading] = React.useState(false);
    const [FilterString, setFilterString] = React.useState("");
    const d1 = "sharedBots", d2 = "organizationalBots", d3 = "globalBots";

    const { loading: loadapps, error: apperror, data: Apps } = useQuery(AppsUsed, { client: client });
    const { loading: loadprocess, error: processerror, data: Process } = useQuery(ProcessTags, { client: client });

    React.useEffect(() => {
        setRefetchFlag(true)
        console.log("calling filter")
        filterDataFun();
    }, [BotStoreTab])


    React.useEffect(() => {
        setSideNavFlag(false);
        return () => {
            setSideNavFlag(true);
        }
    }, [setSideNavFlag]);


    React.useEffect(() => {
        localStorage.setItem("TopNav", "BotStore")
        activeNavVar("BotStore")
    }, [])

    const handleclear = () => {
        setApp([])
        setProcessTag([])
        setFilter(1);
        setLoading(false);
    }

    const getProcessTag = () => {
        return processTag.filter((data) => data !== "default");
    }

    const getApp = () => {
        return app.filter((data) => data !== "default");
    }

    const [globalfilter, { loading: globalload, data: DataID, error: GlobalError }] = useLazyQuery(FilterBot,
        {
            fetchPolicy: "cache-and-network",
        },
    )
    if (GlobalError) {
        console.log("Global Filter Error", GlobalError)
    }



    const [orgfilter, { loading: orgLoad, data: OrgData, error: OrgError }] = useLazyQuery(OrganizationFilterBots,
        {
            fetchPolicy: "cache-and-network",
        },
    )

    if (OrgError) {
        console.log("Org Filter Error", OrgError)
    }

    const [sharefilter, { loading: ShareLoad, data: shareData, error: shareError }] = useLazyQuery(ShareFilterBots,
        {
            fetchPolicy: "cache-and-network",
        },
    )
    if (shareError) {
        console.log("Share Filter Error", shareError)
    }

    const GetFilterString = ((ChildFilterString) => {
        setFilterString(ChildFilterString)
    });



    const handleChange = (event, newValue) => {
        localStorage.setItem("ActiveBotStoreTab", newValue)
        setValue(newValue);
    };

    const handleAppChange = (event) => {
        setApp(event.target.value);
    };
    const handleProcessTag = (event) => {
        setProcessTag(event.target.value)
    }

    React.useEffect(() => {
        if (app.length === 2 && app.includes("default")) {
            setApp([app[1]])
            filterDataFun()
            return;
        }
        if (app.length !== 1) {
            filterDataFun()
        }
        if (app.length === 0)
            setApp(["default"])
    }, [app])

    React.useEffect(() => {
        if (processTag.length === 2 && processTag.includes("default")) {
            setProcessTag([processTag[1]])
            filterDataFun()
            return;
        }
        if (processTag.length !== 1) {
            filterDataFun()
        }
        if (processTag.length === 0)
            setProcessTag(["default"])
    }, [processTag])


    const filterDataFun = async () => {
        setLoading(true);
        console.log("Filtering botstore and active tab is ", BotStoreTab)
        try {
            if (BotStoreTab === 0) {
                if (getApp().length === 0 && getProcessTag().length === 0)
                    await sharefilter({ variables: {} });
                else
                    await sharefilter({ variables: { processTags: getProcessTag(), appsUsed: getApp() } })
                setLoading(false);
            }
            else {
                if (BotStoreTab === 1) {
                    if (getApp().length === 0 && getProcessTag().length === 0)
                        await orgfilter({ variables: {} });
                    else
                        await orgfilter({ variables: { processTags: getProcessTag(), appsUsed: getApp() } });
                    setLoading(false);
                }
                else {
                    if (getApp().length === 0 && getProcessTag().length === 0)
                        await globalfilter({ variables: {} });
                    else
                        await globalfilter({ variables: { processTags: getProcessTag(), appsUsed: getApp() } });
                    setLoading(false);
                }
            }
            setFilter(0)
        }
        catch (error) { console.log("Error !", error) }
    }
    return (

        <div style={{ padding: '0% 0% 0% 0%' }}>
            <Typography className={TabStyleClass.MyContainer}>

                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} sm={2} md={2} style={{ borderRight: " .5rem groove white" }} >
                        <FormControl variant="outlined" size="small" className={Classes.formControl}>
                            <h6 style={{ marginLeft: "5%" }}>Filters</h6>
                            <FormHelperText>App Used </FormHelperText>
                            {loadapps
                                ?
                                <LinearProgress />
                                :
                                <Select
                                    style={{ width: "100%" }}
                                    multiple
                                    displayEmpty
                                    disabled={Apps.appsUsed.length === 0 ? true : false}
                                    fullWidth={true}
                                    value={app}
                                    onChange={(event) => handleAppChange(event)}
                                >
                                    <MenuItem disabled value="default" >
                                        {Apps.appsUsed.length === 0
                                            ?
                                            "No App Used Data"
                                            :
                                            "Select the Apps"
                                        }
                                    </MenuItem>
                                    {Apps.appsUsed.map((app, index) => (
                                        <MenuItem key={app.id} value={app.appName} >
                                            {app.appName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                        </FormControl>
                        {app.length > 0
                            ?
                            <div style={{ padding: '4% 7% 4% 7%' }}>
                                {app.map((value, index) => {
                                    if (value !== "default")
                                        return (<Chip size="small" key={value} style={{ margin: '0% 0% 2% 2%' }} label={value} deleteIcon={<CancelRounded />} onDelete={() => setApp(app.filter((data) => data !== value))} />)
                                    return null;
                                })}
                            </div>
                            :
                            <div>
                                <br />
                            </div>
                        }

                        <FormControl variant="outlined" size="small" className={Classes.formControl} >
                            <FormHelperText>Process Tag {processTag} </FormHelperText>
                            {loadprocess
                                ?
                                <LinearProgress />
                                :
                                <Select
                                    style={{ width: "100%" }}
                                    multiple
                                    displayEmpty
                                    disabled={Process.processTags.length === 0 ? true : false}
                                    fullWidth={true}
                                    value={processTag}
                                    onChange={(event) => handleProcessTag(event)}
                                >
                                    <MenuItem disabled value="default" >
                                        {Process.processTags.length === 0
                                            ?
                                            "No Process Tag Data"
                                            :
                                            "Select the Process Tag"
                                        }
                                    </MenuItem>

                                    {Process.processTags.map((process) => (
                                        <MenuItem key={process.id} value={process.tagName} >
                                            {process.tagName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                        </FormControl>
                        {processTag.length > 0
                            ?
                            <div style={{ padding: '4% 7% 4% 7%' }}>
                                {processTag.map((value, index) => {

                                    if (value !== "default")
                                        return (<Chip size="small" key={value} style={{ margin: '0% 0% 2% 2%' }} label={value} deleteIcon={<CancelRounded />} onDelete={() => setProcessTag(processTag.filter((data) => data !== value))} />)
                                    return null;
                                })}
                            </div>
                            :
                            <div>
                                <br />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} >

                        <h4 className={Classes.title}>Bot Store</h4>
                        <div>

                            <div className={TabStyleClass.TabContainer}>

                                <Grid container >
                                    <Grid item xs={4}>
                                        <AntTabs value={BotStoreTab} onChange={handleChange} aria-label="ant example">
                                            <AntTab label={"Shared With Me"} />
                                            <AntTab label={"My Organization"} />
                                            <AntTab label={"Global"} />
                                        </AntTabs>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={2} style={{ float: "right" }} >
                                        <Globalfilter parentCallbackFunction={GetFilterString} />
                                    </Grid>
                                </Grid>
                                <TabPanel value={BotStoreTab} index={0}>
                                    {
                                        ShareLoad ?
                                            <TableSkeleton />
                                            : <QueryBot QueryType={ShareWithMeBots} Filter={filter} dataFilter={shareData} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} data={d1} filterString={FilterString} />
                                    }
                                </TabPanel>

                                <TabPanel value={BotStoreTab} index={1}>
                                    {
                                        orgLoad ?
                                            <TableSkeleton />
                                            : <QueryBot QueryType={OrganizationBots} Filter={filter} dataFilter={OrgData} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} data={d2} filterString={FilterString} />
                                    }

                                </TabPanel>
                                <TabPanel value={BotStoreTab} index={2}>
                                    {
                                        globalload ?
                                            <TableSkeleton />
                                            : <QueryBot QueryType={GlobalBotStoreCollection} Filter={filter} dataFilter={DataID} callbackFunction={setRefetchFlag} refetchFlag={RefetchFlag} data={d3} filterString={FilterString} />}
                                </TabPanel>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Typography>
        </div>
    );
};

export default BotStore;
// importing required packages
import React from 'react';
import { Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddRounded } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

// importing require components
import { Globalfilter } from '../GlobalFilter'
import { default as MyBotTable } from "../MyBotTable"
import { AntTab, AntTabs, TabPanel, TabStyle } from "../TabComponent"

// importing required variables
import { activeMenuVar, activeNavVar, BotCount } from '../../utils/cache';

// this is used just to create the static data
import { commonStyles } from "../../styles/css/CommonCSS";

const useStyles = makeStyles((theme) => ({
  CreateBotBtn: {
    minWidth: '40px',
    height: '70%',
    fontSize: '0.7em',
    top: '14%',
    fontFamily: 'DM Sans'
  },
  avatar: {
    backgroundColor: grey[800],
    borderRadius: 50,
    width: 50,
    padding: 2,
  },
}));


// Actual React Component
function Banking(props) {
  const classes = useStyles();
  const CommonClasses = commonStyles();
  const TabStyleClass = TabStyle();
  const history = useHistory();
  const [value, setValue] = React.useState(parseInt(localStorage.getItem("ActiveMyBotTab")) || 0);

  const [FilterString, setFilterString] = React.useState("");
  const [Count, setCount] = React.useState([0, 0, 0, 0]);

  React.useEffect(() => {
    activeNavVar("MyBots")
    localStorage.setItem("TopNav", "MyBots")
  }, [])
  const handleChange = (event, newValue) => {
    localStorage.setItem("ActiveMyBotTab", newValue)
    setValue(newValue);
  };

  const GetFilterString = ((ChildFilterString) => {
    setFilterString(ChildFilterString)
    console.log("in parent call back setter " + FilterString)
  });
  const handleCount = () => {
    let x = [0, 0, 0, 0]
    if (BotCount().length === 0)
      setCount(x)
    else
      setCount(BotCount())
  }
  let heading = useReactiveVar(activeMenuVar);
  let temp1 = useReactiveVar(BotCount);
  React.useEffect(() => {
    handleCount()
  }, [temp1])



  if (localStorage.getItem('tabCount') === "0") {
    console.log(" tab count in banking ", localStorage.getItem('tabCount') )
    history.replace("/my-bots/empty-tab")
    return null;
  }
  else{
    console.log(" tab count in banking ", localStorage.getItem('tabCount') )
  }

  let disFlag = false;
  return (
    <div>
      <h4 className={CommonClasses.title}>{activeMenuVar()}</h4>
      <div>
        <div className={TabStyleClass.TabContainer}>
          <Grid container>
            <Grid item xs={8} sm={8} lg={8}>
              <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                <AntTab label={"Favourites (" + Count[0] + ")"} />
                <AntTab label={"Active (" + Count[1] + ")"} />
                <AntTab label={"Deactivated (" + Count[2] + ")"} />
                <AntTab label={"Archived (" + Count[3] + ")"} />
              </AntTabs>
            </Grid>
            <Grid item xs={4} sm={4} lg={4} style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end' }}>
              <Globalfilter parentCallbackFunction={GetFilterString} />
              <Button
                endIcon={<AddRounded size={21} />}
                size={"small"}
                className={` ${classes.CreateBotBtn} gradient_round_btn ${CommonClasses.Gradient_Btn}`}
                variant="contained" to="#!"
                onClick={(event) => {
                  history.replace("/my-bots/create-bot");
                  localStorage.setItem("createbotname", "");
                  localStorage.setItem("avatarimg", null)
                  localStorage.setItem("createbotdes", "");
                  localStorage.setItem("btnFlag", JSON.stringify(false));
                  localStorage.setItem("createbottab", "");
                  localStorage.setItem("badge", JSON.stringify(true));
                  localStorage.setItem("createbotapp", JSON.stringify([]));
                  localStorage.setItem("createbotPT", JSON.stringify([]));
                  localStorage.setItem("disable", JSON.stringify(disFlag));
                  localStorage.setItem("ActiveStep", 0);
                }}
              >
                Create Bot
              </Button>
            </Grid>
          </Grid>
          <TabPanel className={TabStyleClass.MyTabPanel} value={value} index={0}>
            <MyBotTable Tab_Id={value} filterString={FilterString} />
          </TabPanel>

          <TabPanel className={TabStyleClass.MyTabPanel} value={value} index={1}>
            <MyBotTable Tab_Id={value} filterString={FilterString} />
          </TabPanel>

          <TabPanel className={TabStyleClass.MyTabPanel} value={value} index={2}>
            <MyBotTable Tab_Id={value} filterString={FilterString} />
          </TabPanel>

          <TabPanel className={TabStyleClass.MyTabPanel} value={value} index={3}>
            <MyBotTable Tab_Id={value} filterString={FilterString} />
          </TabPanel>
        </div>
      </div>
    </div >
  );
};


export default Banking;
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { ArrowForward, CloseRounded, EmailRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { commonStyles } from "../styles/css/CommonCSS";

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../styles/css/common.css';
import { LokiBotsUser } from "../graphql/queries";



const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: 'DM Sans',

    },
    subhead: {
        fontFamily: "DM Sans",
        fontSize: '20px',
        color: 'gray'
    },
    login: {
        fontFamily: 'Pangram',
       
        fontSize: '25px',
        //fontWeight: 'bold',
      
    }
}));
function SuccessSubscription(props) {
    const subid = props.match.params.subid;
    localStorage.setItem("subid", subid);
    const classes = useStyles();
    const CommonClasses = commonStyles()
    const history = useHistory();
    const [registerLokiUser]= useMutation(LokiBotsUser);
    React.useEffect(() => {
        const register = async()=>{
            await registerLokiUser({
                    variables:{
                        subscriptionId: subid
                    }
                })
            } 
    });

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"

        >
            <Grid item xs={12} md={5} style={{ paddingTop: '3%' }}>
                <Typography className={classes.heading} variant="h2" component="h4">Thanks for Subscribing</Typography>
            </Grid>
            <Grid item xs={12} md={5} style={{ marginTop: '5%' }}>
                <div style={{ transform: 'scale(6)', color: 'purple' }}><HowToRegIcon /></div>
            </Grid>
            <Grid item xs={12} md={5} style={{ marginTop:'3%',marginLeft:'5%' }}>
                <Typography className={classes.subhead} >
                    Hi there, you have successfully registered into LokiBots. <br />
                    You can start using LokiBots using the credentials sent to the mail.
                </Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop:'5%'}}>
                <Button
                    variant="contained"
                    className={"login_btn gradient_round_btn " + CommonClasses.Gradient_Btn}
                    onClick={() => { history.replace("/login") }}
                >
                   Login
                </Button>
            </Grid>    
            </Grid>

            );
}

export default SuccessSubscription;
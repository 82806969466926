import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import { useTable } from "react-table";
import { Paper } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';
import { CircularProgress } from "@material-ui/core";

// importing required Components/Pages
import { StyledTableCell, StyledTableRow } from './TableComponent'
import { TabStyle } from "./TabComponent";
import { ScheduleBotsColumn } from "./Column";


// importing required queries
import { ScheduledBotsStatus, changeScheduledBotStatus } from "../graphql/queries";

// importing required variables
import { client } from "../graphql/apolloClient";
import { TableSkeleton } from "./skeleton";
import { commonStyles } from '../styles/css/CommonCSS';


const useStyles = makeStyles({
  paper: {
    width: '100%',
    margin: 'auto',
    borderRadius: '12px',
  },
  size: {
    fontSize: '14px',
  },
  table_action: {
    width: '100%',
    paddingLeft: '2%',
    ">h5": {
      fontFamily: 'Pangram',
      fontWeight: 'bolder',
    },
  },
});

function ScheduleBotsData(props) {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const TabStyleClass = TabStyle();

  const data = props.data.organizationScheduledBots;
  const columns = React.useMemo(() => ScheduleBotsColumn, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  );

  const [ScheduledBotStatus] = useMutation(changeScheduledBotStatus);
  const [checkwait, setWait] = React.useState(false);

  const handleSwitchChange = async (event) => {
    setWait(true);
    try {
      await ScheduledBotStatus({ variables: { id: event.target.id, state: event.target.checked } })
      console.log("Success")
      props.Refetch();
      setWait(false);
    }
    catch (error) {
      console.log("Error")
      console.log(error)
      setWait(false);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.table_action}>
        <h4 className={commonClasses.title}>Schedule Bots</h4>
      </div>
      <div>
        <br />
        <div className={TabStyleClass.TabContainer}>
          <TableContainer className={classes.paper} component={Paper}>
            <Table size="small" className={classes.table}  {...getTableProps()}>
              <TableHead style={{ textTransform: 'uppercase', border: '3px solid #440042', }} >
                {headerGroups.map(headerGroup => (
                  <StyledTableRow className={classes.tableHead} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <StyledTableCell
                        {...column.getHeaderProps()}
                        align="left" className={classes.size}>
                        {column.render("Header")}
                        <span style={{}}></span>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.length === 0 ?
                  <StyledTableRow >
                    <StyledTableCell colSpan={8} align="center" className={classes.size}>
                      <h5>No Data Available!</h5>
                    </StyledTableCell>
                  </StyledTableRow>
                  : null}
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <StyledTableRow {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        if (cell === row.cells[2]) {
                          return (
                            <StyledTableCell align="left" className={classes.size}>
                              <div className="toggle switch switch-dropdown">
                                {checkwait ?
                                  <CircularProgress size="21px" /> :
                                  <label className={classes.size}>
                                    Disable
                                    <input className={classes.toggle}
                                      id={cell.row.original.id}
                                      checked={cell.row.original.enabled}
                                      onChange={handleSwitchChange}
                                      type="checkbox"></input>
                                    <span className="lever"></span>
                                    Enable
                                  </label>}
                              </div>
                            </StyledTableCell>
                          )
                        }
                        return (
                          <StyledTableCell align="left" className={classes.size} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </StyledTableCell>);
                      })}
                    </StyledTableRow>
                  );
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </React.Fragment>
  );
}

function ScheduleBots() {
  const { loading, error, data, refetch } = useQuery(ScheduledBotsStatus, { client: client });
  const commonClasses = commonStyles()
  if (loading)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Schedule Bots</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <TableSkeleton />
        </div>
      </div>
    );

  if (error)
    return (
      <div>
        <div>
          <h4 className={commonClasses.title}>Schedule Bots</h4>
        </div>
        <div style={{ margin: '2%' }}>
          <h5> Please Try Again! </h5>
        </div>
      </div>
    );

  if (data)
    return (
      <ScheduleBotsData data={data} Refetch={refetch} />
    );
}

export default ScheduleBots;



// importing required packages
import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { Card, CardHeader, Grid, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import logo from '../styles/images/company_logo.png';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import '../styles/css/common.css';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: "5%",
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#9e9e9e",
        },
        secondary: {
            main: "#e53935",
        },
        success:{
            main:"#e0e0e0",
        }
    },
});

export default function MySkeleton({ SkeletonVariants }) {
    return (
        <React.Fragment>
            {SkeletonVariants.map((variant) => (
                <Typography component="div" key={variant} variant={variant}>
                    <Skeleton />
                </Typography>
            ))}
        </React.Fragment>
    )
}

export function TableSkeleton(props) {

    const classes = useStyles();
    let count = props.count || 7;
    // const [progress, setProgress] = React.useState(0);
    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((oldProgress) => {
    //             if (oldProgress === 100) {
    //                 return 0;
    //             }
    //             const diff = Math.random() * 30;
    //             return Math.min(oldProgress + diff, 100);
    //         });
    //     }, 500);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    return (
        // <Card>
        //     {
        //         Array.from(Array(count), (e, i) => {
        //             return (
        //                 <CardHeader
        //                     key={i}
        //                     avatar={<Skeleton animation="wave" variant="circle" width={30} height={30} />}
        //                     title={
        //                         <Grid container justify="center" alignItems="center">
        //                             <Grid item xs={11} sm={11} md={11} lg={11}>
        //                                 <Skeleton animation="wave" height={10} width="100%" />
        //                                 <Skeleton animation="wave" height={10} width="100%" />
        //                             </Grid>
        //                             <Grid item xs={1} md={1} sm={1} lg={1}>
        //                                 <MoreVertRounded size="small" style={{ color: 'gray', margin: '7% 0% 0% 30%' }} />
        //                             </Grid>
        //                         </Grid>
        //                     }
        //                 />)
        //         })}
        // </Card>
        <div className={classes.root} style={{position:"relative"}} >
            <ThemeProvider theme={theme}>
                <Grid   
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                    spacing={1}
                >
                    <Grid item xs={12}  align={"center"}>
                        <img style={{ width: "12%", height: "auto", opacity: "0.7" }} align="center" src={logo} alt="company logo" />                       
                    </Grid>
                    <Grid item xs={12} align={"center"} >
                        <CircularProgress color='primary' size={"2rem"}  />
                        {/* <Typography style={{ fontFamily: "inherit", fontWeight: "500", color: "grey" }} variant='h5' align="center" >Loading... </Typography> */}
                    </Grid><Grid item xs={12}></Grid>
                </Grid>

            </ThemeProvider>
        </div>
    );
}
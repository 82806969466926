// importing required packages

import React from 'react';
import { Badge, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from "../../styles/css/CommonCSS";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import '../../styles/css/common.css';
import  { default as ScheduleBot} from './CreateBot_Schedule';
// importing required images
import workflow from "../../styles/images/workflow.PNG";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: '1%',

        borderCollapse: 'collapse',
    },
    skip: {
        fontSize: "14px",
        textAlign: 'right',
        float: 'right',
        color: 'purple',
        textTransform: "initial",
        background: 'none',
        border: 'none',
        marginLeft: '7%',
        marginTop: '2%',
        marginRight: '2%',
        textDecorationColor: 'purple',
    },
    container: {
        textAlign: 'center',
        borderRadius: '2%',
        borderColor: 'green',
        borderWidth: '2%',
        marginLeft: '2%',
        marginRight: '3%',
        margin: theme.spacing(2),

    },
    image: {
        float: 'center',
        width: '80%',
        height: 'auto'

    },
}));

export default function CreateBotWorkflow(props){
    const classes = useStyles();
    const CommonClasses = commonStyles();
    return(
        <React.Fragment>
            <Grid item xs={12}>
                    <Button className={classes.skip} style={{ marginRight: "1%"}} onClick= {()=>props.skipFunction(3)}><span>Skip</span></Button>
            </Grid>
            <Grid container  className={classes.root}  justify="flex-start" alignItems="center">  
                <Grid item md={12}  style= {{marginLeft: '4%'}}>
                    <img className={classes.image} alt="Workflow Diagram" src={workflow} />
                </Grid>
            </Grid>
            <Button
                style={{  float: 'right', width: "10%", padding: '0.8% 0.5% 0.8% 0.5%' }}
                className={"gradient_round_btn " + CommonClasses.Gradient_Btn + " " + classes.CreateBotBtn}
                variant="contained" onClick= {()=>props.skipFunction(3)} disableRipple>
                <span style={{ fontSize: "14px", color: "#FFF", fontFamily: "Pangram", textTransform: "initial" , fontWeight: 'bold'}}>Continue</span>
            </Button>
            <Button
                style={{marginTop: "0.5%", float: 'right', width: "12%", borderCollapse: 'borderCollapse' } }
                 className={"button_no_bg"} >
                <span style={{ fontSize: "14px", color: "purple", fontWeight: 'bold', fontFamily: "Pangram", textTransform: "initial"}} badge visible>Execute Bot</span>
                <ArrowForwardIcon style={{ color: "purple", marginLeft: "2%" }} />
            </Button>
        </React.Fragment>
    );
}